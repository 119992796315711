import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IAdmin from 'models/IAdmin';

import { IAdminInitialData } from '../../index';

interface ICreateAdminSuccessAction {
  email: string;
  id: number;
  name: string;
}

function createAdminSuccess(
  draft: Draft<IAdminInitialData>,
  action: PayloadAction<ICreateAdminSuccessAction>,
) {
  const newAdmin: IAdmin = {
    email: action.payload.email,
    id: action.payload.id,
    name: action.payload.name,
  };
  draft.list.data = [...draft.list.data, newAdmin];
  draft.create.config.isLoading = false;
}

export default createAdminSuccess;
