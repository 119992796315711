import { Draft } from '@reduxjs/toolkit';

import { IOrderInitialData } from '../../index';

function listOrderFailure(draft: Draft<IOrderInitialData>) {
  draft.list.config.isError = true;
  draft.list.config.isLoading = false;
}

export default listOrderFailure;
