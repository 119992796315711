import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface ISetLinkOptionsAction {
  data: {
    option: {
      id: string;
      name: string;
      sku?: string;
    }[];
  };
}

function setLinkOptions(
  draft: Draft<IListInitialData>,
  action: PayloadAction<ISetLinkOptionsAction>,
) {
  draft.linkOptions.data = action.payload.data.option.map(listItem => ({
    label: listItem.sku ? `${listItem.sku} - ${listItem.name}` : listItem.name,
    value: listItem.id,
  }));
}

export default setLinkOptions;
