import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCustomerAccessResponse from 'services/httpClient/responses/customerAccess/IGetCustomerAccessResponse';

import { customerAccessActions } from '..';

import { IGetAllRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllRequestAction>) {
  try {
    const params = {
      page: action.payload.data.page,
      date: action.payload.data.date,
    };
    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetCustomerAccessResponse> = yield call(
      httpClient.get,
      'admin/customers-access',
      { params },
    );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        customerAccessActions.getAllSuccess({
          data: { list: [], totalPages: 0 },
        }),
      );
      return;
    }
    yield put(
      customerAccessActions.getAllSuccess({
        data: {
          list,
          totalPages,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerAccessActions.getAllFailure());
  }
}

export default getAll;
