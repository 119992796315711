import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthInitialData } from '../../index';

export interface IForgotPasswordRequestAction {
  data: {
    email: string;
  };
  functions: {
    goBack: () => void;
  };
}

function forgotPasswordRequest(
  draft: Draft<IAuthInitialData>,
  _: PayloadAction<IForgotPasswordRequestAction>,
) {
  draft.forgotPassword.config.isLoading = true;
}

export default forgotPasswordRequest;
