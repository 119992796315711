import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllProductRequestRequestDTO from 'services/httpClient/request/productRequest/IGetAllProductRequestRequestDTO';
import IGetAllProductRequestResponse from 'services/httpClient/responses/productRequest/IGetAllProductRequestResponse';

import { productRequestActions } from '..';

import { IGetAllRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllRequestAction>) {
  try {
    const params: IGetAllProductRequestRequestDTO = {
      page: action.payload.data.page,
    };
    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetAllProductRequestResponse> = yield call(
      httpClient.get,
      'admin/products-request',
      { params },
    );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        productRequestActions.getAllSuccess({
          data: {
            list: [],
            totalPages: 0,
          },
        }),
      );
      return;
    }

    yield put(
      productRequestActions.getAllSuccess({
        data: {
          list,
          totalPages,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(productRequestActions.getAllFailure());
  }
}

export default getAll;
