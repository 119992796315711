import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { ListContainer, Subtitle } from '../styles';
import PriceTableItem from './Item';

interface IPriceTablesParams extends Record<string, string | undefined> {
  id: string;
}

const PriceTablesList = () => {
  const params = useParams<IPriceTablesParams>();
  const reduxDispatch = useReduxDispatch();
  const priceTables = useReduxSelector(customerSelectors.getPriceTables);
  const isLoading = useReduxSelector(customerSelectors.getPriceTablesIsLoading);
  const isError = useReduxSelector(customerSelectors.getPriceTablesIsError);

  const handlePriceTables = useCallback(() => {
    if (!params.id) {
      return;
    }
    reduxDispatch(
      customerActions.priceTablesRequest({
        data: {
          customerId: Number(params.id),
        },
      }),
    );
  }, [params.id, reduxDispatch]);

  useEffect(() => {
    handlePriceTables();
  }, [handlePriceTables]);

  return (
    <>
      <Subtitle>Tabelas de preço</Subtitle>
      <ListContainer>
        <ComponentIsVisible
          when={!isLoading && !isError && priceTables.length !== 0}
        >
          <PriceTableItem.Header />
          {priceTables.map((priceTable, position) => (
            <PriceTableItem.Body
              key={priceTable.id}
              paymentMethod={priceTable}
              position={position}
            />
          ))}
        </ComponentIsVisible>
        <ComponentEmpty
          message="Nenhuma tabela encontrada"
          show={!isError && priceTables.length === 0 && !isLoading}
        />
        <ComponentIsVisible when={isError}>
          <ComponentError
            message="Erro na busca pelas tabelas"
            onClick={handlePriceTables}
          />
        </ComponentIsVisible>
        <ComponentLoadingList rows={8} show={isLoading} />
      </ListContainer>
    </>
  );
};

export default PriceTablesList;
