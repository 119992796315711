import { Draft } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

function nonPositiveFailure(draft: Draft<ICustomerInitialData>) {
  draft.nonPositive.config.isLoading = false;
  draft.nonPositive.config.isError = true;
}

export default nonPositiveFailure;
