import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import formatters from 'formatters';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentBackButton from 'components/button/BackButton';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import ProductItem from './Item';
import { Content, ContentDrug, Info, Label, Subtitle, Value } from './styles';

interface IProductDetailsParams extends Record<string, string | undefined> {
  id: string;
}

const ProductDetails = () => {
  const reduxDispatch = useReduxDispatch();
  const params = useParams<IProductDetailsParams>();
  const productDetails = useReduxSelector(productSelectors.details);
  const isIsLoading = useReduxSelector(productSelectors.detailsIsLoading);
  const productIsLoading = useReduxSelector(productSelectors.getAllIsLoading);
  const isError = useReduxSelector(productSelectors.detailsIsError);

  const loadProductDetails = useCallback(() => {
    if (params.id) {
      reduxDispatch(productActions.detailsRequest({ data: { id: params.id } }));
    }
  }, [reduxDispatch, params.id]);

  useEffect(() => {
    loadProductDetails();
  }, [loadProductDetails]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle hasBackButton>
          <ComponentBackButton />
          Detalhes do produto
        </General.PageTitle>
      </General.PageTitleContainer>

      <Subtitle>Dados gerais</Subtitle>
      <Content>
        <Info>
          <Label>Código</Label>
          <Value isLoading={isIsLoading}>{productDetails?.id}</Value>
        </Info>
        <Info>
          <Label>SKU</Label>
          <Value isLoading={isIsLoading}>{productDetails?.sku}</Value>
        </Info>
        <Info>
          <Label>Nome</Label>
          <Value isLoading={isIsLoading}>{productDetails?.name}</Value>
        </Info>
        <Info>
          <Label>Nome do fabricante</Label>
          <Value isLoading={isIsLoading}>{productDetails?.manufacturer}</Value>
        </Info>

        <Info>
          <Label>Cod. Barras</Label>
          <Value isLoading={isIsLoading}>{productDetails?.barcode}</Value>
        </Info>
        <Info>
          <Label center>Status</Label>
          <Value center isLoading={isIsLoading}>
            <ComponentIsVisible when={!!productDetails?.active}>
              Ativo
            </ComponentIsVisible>
            <ComponentIsVisible when={!productDetails?.active}>
              Inativo
            </ComponentIsVisible>
          </Value>
        </Info>
        <Info>
          <Label center>Pontos</Label>
          <Value center isLoading={isIsLoading}>
            {productDetails?.coinsPerReal === -1
              ? 'Não informado'
              : productDetails?.coinsPerReal}
          </Value>
        </Info>
        <Info>
          <Label>Estoque</Label>
          <Value isLoading={isIsLoading}>{productDetails?.stock}</Value>
        </Info>
        <Info width="8rem">
          <Label>Estoque mínimo</Label>
          <Value isLoading={isIsLoading}>
            {productDetails?.minimumStock
              ? productDetails?.minimumStock
              : 'Não informado'}
          </Value>
        </Info>
      </Content>

      <Subtitle>Dados de medicamento</Subtitle>
      <ContentDrug>
        <Info>
          <Label>Princípio ativo</Label>
          <Value isLoading={isIsLoading}>
            {productDetails?.drugActiveIngredient}
          </Value>
        </Info>
        <Info>
          <Label>Farmácia popular</Label>
          <Value isLoading={isIsLoading}>{productDetails?.drugIsFp}</Value>
        </Info>
        <Info>
          <Label>Controlado</Label>
          <Value isLoading={isIsLoading}>
            {productDetails?.drugControlled}
          </Value>
        </Info>
        <Info>
          <Label>Registro Ministério da Saúde</Label>
          <Value isLoading={isIsLoading}>
            {productDetails?.drugRegistrationNumberMs}
          </Value>
        </Info>
        <Info>
          <Label>Tipo</Label>
          <Value isLoading={isIsLoading}>
            {formatters.dragType(productDetails?.drugType)}
          </Value>
        </Info>
        <Info>
          <Label>Quantidade em caixas</Label>
          <Value center isLoading={isIsLoading}>
            {productDetails?.drugPackingBox}
          </Value>
        </Info>
      </ContentDrug>

      <Subtitle>Escala de preços</Subtitle>
      <Tables.Items>
        <ProductItem.HeaderProductPriceScales />

        <ComponentLoadingList rows={6} show={productIsLoading} />

        <ComponentIsVisible when={!productIsLoading}>
          <ComponentIsVisible when={!isError}>
            {productDetails?.productPriceScales?.map(
              (productPriceScales, position) => (
                <ProductItem.BodyProductPriceScales
                  key={productPriceScales.id}
                  position={position}
                  productPriceScales={productPriceScales}
                />
              ),
            )}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há escala de preços para esse produto"
            show={!productDetails?.productPriceScales.length && !isError}
          />

          <ComponentIsVisible when={isError}>
            <ComponentError
              message="Não foi possível carregar os produtos"
              onClick={loadProductDetails}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
      </Tables.Items>

      <Subtitle>Tabela de preços</Subtitle>
      <Tables.Items>
        <ProductItem.HeaderProductPriceList />

        <ComponentLoadingList rows={6} show={productIsLoading} />

        <ComponentIsVisible when={!productIsLoading}>
          <ComponentIsVisible when={!isError}>
            {productDetails?.priceList.map((priceList, position) => (
              <ProductItem.BodyProductPriceList
                key={priceList.id}
                position={position}
                priceList={priceList}
              />
            ))}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há tabelas de preço para esse produto"
            show={!productDetails?.priceList.length && !isError}
          />

          <ComponentIsVisible when={isError}>
            <ComponentError
              message="Não foi possível carregar os produtos"
              onClick={loadProductDetails}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
      </Tables.Items>
    </Containers.Global>
  );
};

export default ProductDetails;
