import createFailure from './create/failure';
import createRequest from './create/request';
import createSuccess from './create/success';
import listFailure from './list/failure';
import listRequest from './list/request';
import listSuccess from './list/success';
import removeFailure from './remove/failure';
import removeRequest from './remove/request';
import removeSuccess from './remove/success';
import updateFailure from './update/failure';
import updateRequest from './update/request';
import updateSuccess from './update/success';

const adminReducers = {
  createFailure,
  createRequest,
  createSuccess,
  listFailure,
  listRequest,
  listSuccess,
  removeFailure,
  removeRequest,
  removeSuccess,
  updateFailure,
  updateRequest,
  updateSuccess,
};

export default adminReducers;
