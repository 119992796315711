import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IMaintenanceSuccessAction {
  isMaintenance: boolean;
}

function maintenanceSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IMaintenanceSuccessAction>,
) {
  draft.data.others.isMaintenance = action.payload.isMaintenance;
  draft.isMaintenance.config.isLoading = false;
}

export default maintenanceSuccess;
