import createIsLoading from './createIsLoading';
import createItemIsLoading from './createItemIsLoading';
import linkOptions from './linkOptions';
import linkOptionSelected from './linkOptionSelected';
import list from './list';
import removeIsLoading from './removeIsLoading';
import removeItemIsLoading from './removeItemIsLoading';
import updateIsLoading from './updateIsLoading';
import updateItemIsLoading from './updateItemIsLoading';

const listSelectors = {
  createIsLoading,
  createItemIsLoading,
  linkOptions,
  linkOptionSelected,
  list,
  removeIsLoading,
  removeItemIsLoading,
  updateIsLoading,
  updateItemIsLoading,
};

export default listSelectors;
