import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IMeuCatalogoSuccessAction {
  email: string;
  password: string;
}

function meuCatalogoSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IMeuCatalogoSuccessAction>,
) {
  draft.meuCatalogo.config.isLoading = false;
  draft.data.meuCatalogo = {
    email: action.payload.email,
    password: action.payload.password,
  };
}

export default meuCatalogoSuccess;
