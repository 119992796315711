import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import { ReduxStore } from 'store/types';

function linkOptions(
  state: ReduxStore,
): IComponentSelectProps<string, unknown>[] {
  return state.list.linkOptions.data;
}

export default linkOptions;
