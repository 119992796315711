import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IRemoveSuccessAction {
  data: {
    id: number;
  };
}

function removeSuccess(
  draft: Draft<IListInitialData>,
  action: PayloadAction<IRemoveSuccessAction>,
) {
  const list = draft.data.filter(list => list.id !== action.payload.data.id);
  draft.data = list;
  draft.remove.config.isLoading = false;
}

export default removeSuccess;
