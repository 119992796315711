import styled, { css } from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const CardTitleInformation = styled.div`
  align-items: center;
  background-color: ${colors.label};
  border-radius: 100%;
  color: ${colors.white};
  cursor: help;
  display: inline-flex;
  height: 1.15rem;
  justify-content: center;
  margin-left: -0.5rem;
  position: relative;
  width: 1.15rem;

  &:hover {
    &::before {
      border-bottom: 0.5rem solid transparent;
      border-right: 0.5rem solid ${colors.label};
      border-top: 0.5rem solid transparent;
      content: '';
      height: 0;
      position: absolute;
      right: -0.75rem;
      top: 0.25rem;
      width: 0;
      z-index: 2;
    }
    &::after {
      background-color: ${colors.label};
      border-radius: 0.5rem;
      color: ${colors.white};
      content: 'Sugerimos que utilize a imagem de sua logo na posição horizontal para uma melhor visualização';
      font-size: 0.875rem;
      height: fit-content;
      left: calc(100% + 0.75rem);
      line-height: 1.15rem;
      padding: 0.5rem 0.75rem;
      position: absolute;
      top: -0.5rem;
      width: 10rem;
      z-index: 4;
    }
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
`;

export const CardInfo = styled.div``;

export const CardButton = styled.button`
  align-items: center;
  background-color: ${colors.primary};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.white};
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2rem;
  justify-content: center;
  margin-top: 1rem;
  padding: 0 1rem;
`;

export const ChoosePicture = styled.input`
  display: none;
`;

export const ThumbnailInfo = styled.div`
  display: grid;
  justify-content: flex-end;
`;

export const Thumbnail = styled.div`
  align-items: center;
  display: flex;
  height: 5rem;
  justify-content: center;
  max-width: 15rem;
  overflow: hidden;
  position: relative;
`;

interface IActuallyImageProps {
  isVisible?: boolean;
}

export const ActuallyImage = styled.img<IActuallyImageProps>`
  display: none;
  height: 100%;
  width: 100%;

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};
`;

interface IActuallyImageLoadingProps {
  isVisible?: boolean;
}

export const ActuallyImageLoading = styled.div<IActuallyImageLoadingProps>`
  animation: ${animations.shimmer} 2s infinite linear;
  background: linear-gradient(to right, #f1f1f1 4%, #fafafa 25%, #f1f1f1 36%);
  background-size: 1000px 100%;
  border-radius: 0.5rem;
  display: none;
  height: 5rem;
  width: 100%;

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};
`;
