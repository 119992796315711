import styled, { css } from 'styled-components';

import colors from 'styles/colors';
import Tables from 'styles/tables';

export const CustomerItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 0.15fr 1fr 0.65fr 0.75fr 0.45fr 8.5rem 8rem;
  padding: 1rem 1.5rem;
`;

export const PedcoinButton = styled.button`
  align-items: center;
  background-color: ${colors.tabBackground};
  border: 1px solid ${colors.primary};
  border-radius: 0.75rem;
  color: ${colors.primary};
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
`;

interface IConnectedContainerProps {
  isConnected: boolean;
}

export const ConnectedContainer = styled.div<IConnectedContainerProps>`
  background: red;
  border-radius: 0.3rem;
  height: 0.6rem;
  margin: auto;
  width: 0.6rem;
  ${props =>
    props.isConnected &&
    css`
      background: green;
    `}
`;
