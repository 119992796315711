import { FiEdit, FiTrash2 } from 'react-icons/fi';

import IAdmin from 'models/IAdmin';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { AdminItemsBody } from '../styles';

interface IItemBodyProps {
  admin: IAdmin;
  openEditAdmin: (admin: IAdmin) => void;
  openRemoveAdmin: (id: number) => void;
  position: number;
}

const ItemBody = ({
  admin,
  openEditAdmin,
  openRemoveAdmin,
  position,
}: IItemBodyProps): JSX.Element => {
  return (
    <AdminItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{admin.id}</Tables.ItemBody>
      <Tables.ItemBody>{admin.name}</Tables.ItemBody>
      <Tables.ItemBody>{admin.email}</Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction
            onClick={() => openEditAdmin(admin)}
            title="Editar administrador"
            type="button"
          >
            <FiEdit color={colors.orange} size={20} />
          </Tables.ItemAction>
          <Tables.ItemAction
            onClick={() => openRemoveAdmin(admin.id)}
            title="Remover administrador"
            type="button"
          >
            <FiTrash2 color={colors.red} size={20} />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </AdminItemsBody>
  );
};

export default ItemBody;
