import styled from 'styled-components';

export const Fields = styled.div`
  align-items: flex-end;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-end;
  row-gap: 0.5rem;
`;

export const ContainerButton = styled.div`
  align-self: flex-end;
  margin-top: 0.8rem;
`;
