import address from './address';
import addressIsLoading from './addressIsLoading';
import columns from './columns';
import columnsIsLoading from './columnsIsLoading';
import company from './company';
import companyIsLoading from './companyIsLoading';
import contactIsLoading from './contactIsLoading';
import designer from './designer';
import externalScriptIsLoading from './externalScriptIsLoading';
import faviconIsLoading from './faviconIsLoading';
import filters from './filters';
import filtersIsLoading from './filtersIsLoading';
import homeControlIsLoading from './homeControlIsLoading';
import internalControl from './internalControl';
import internalControlIsLoading from './internalControlIsLoading';
import logoIsLoading from './logoIsLoading';
import maintenanceIsLoading from './maintenanceIsLoading';
import meuCatalogo from './meuCatalogo';
import meuCatalogoIsLoading from './meuCatalogoIsLoading';
import others from './others';
import pedcoins from './pedcoins';
import pedcoinsIsLoading from './pedcoinsIsLoading';
import products from './products';
import productsViewModeIsLoading from './productViewModeIsLoading';
import purchases from './purchases';
import purchasesIsLoading from './purchasesIsLoading';
import shipping from './shipping';
import shippingIsLoading from './shippingIsLoading';
import socialMedia from './socialMedia';
import socialMediaIsLoading from './socialMediaIsLoading';
import termsControlIsLoading from './termsControlIsLoading';
import termsIsLoading from './termsIsLoading';
import themesIsLoading from './themesIsLoading';

const settingSelectors = {
  address,
  addressIsLoading,
  columns,
  columnsIsLoading,
  company,
  companyIsLoading,
  contactIsLoading,
  others,
  externalScriptIsLoading,
  faviconIsLoading,
  designer,
  filters,
  filtersIsLoading,
  homeControlIsLoading,
  logoIsLoading,
  maintenanceIsLoading,
  meuCatalogo,
  meuCatalogoIsLoading,
  pedcoins,
  pedcoinsIsLoading,
  productsViewModeIsLoading,
  products,
  purchases,
  purchasesIsLoading,
  shipping,
  shippingIsLoading,
  socialMedia,
  socialMediaIsLoading,
  termsIsLoading,
  termsControlIsLoading,
  internalControlIsLoading,
  themesIsLoading,
  internalControl,
};

export default settingSelectors;
