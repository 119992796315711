import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm } from '../styles';
import UpdatePassword, { IUpdatePasswordRefProps } from '../UpdateTerms';
import { ContainerButton, Fields } from './styles';

interface ITermsFormData {
  showTerms: boolean;
}

function Terms(): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const termsFormRef = useRef<FormikProps<ITermsFormData>>(null);
  const updatePasswordRef = useRef<IUpdatePasswordRefProps>(null);

  const isLoading = useReduxSelector(settingSelectors.termsControlIsLoading);
  const others = useReduxSelector(settingSelectors.others);

  const handleShowTerms = useCallback(
    (data: ITermsFormData) => {
      reduxDispatch(
        settingActions.termsControlRequest({
          data: {
            showTerms: data.showTerms,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChange = useCallback(() => {
    termsFormRef.current?.setFieldValue(
      'showTerms',
      !termsFormRef.current?.values.showTerms,
    );
    termsFormRef.current?.submitForm();
  }, []);

  const openUpdateTerms = () => {
    updatePasswordRef.current?.open();
  };

  return (
    <>
      <Formik
        initialValues={{
          showTerms: others.showTerms,
        }}
        innerRef={termsFormRef}
        onSubmit={handleShowTerms}
      >
        {({ values }) => (
          <FormikForm>
            <Card>
              <CardTitle>
                Termos de uso
                <ComponentIsVisible when={!isLoading}>
                  <FiCheckCircle color={colors.green} size={18} />
                </ComponentIsVisible>
                <ComponentIsVisible when={isLoading}>
                  <ClipLoader size={18} />
                </ComponentIsVisible>
              </CardTitle>

              <CardSubtitle>
                Preencha os campos abaixo para editar os termos de uso
              </CardSubtitle>

              <Fields>
                <ComponentInputCheckbox
                  disabled={isLoading}
                  isChecked={values.showTerms}
                  label="Habilitar"
                  name="showTerms"
                  onChange={handleOnChange}
                />
                <ContainerButton>
                  <ComponentButtonBase
                    disabled={isLoading}
                    isLoading={isLoading}
                    onClick={openUpdateTerms}
                    type="button"
                    width="8rem"
                  >
                    Abrir Termos
                  </ComponentButtonBase>
                </ContainerButton>
              </Fields>
            </Card>
          </FormikForm>
        )}
      </Formik>
      <UpdatePassword ref={updatePasswordRef} />
    </>
  );
}

export default Terms;
