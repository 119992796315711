import details from './details';
import detailsIsError from './detailsIsError';
import detailsIsLoading from './detailsIsLoading';
import getAll from './getAll';
import getAllIsError from './getAllIsError';
import getAllIsLoading from './getAllIsLoading';
import getAllPage from './getAllPage';
import getAllProducts from './getAllProducts';
import getAllProductsIsError from './getAllProductsIsError';
import getAllProductsIsLoading from './getAllProductsIsLoading';
import getAllProductsPage from './getAllProductsPage';
import getAllProductsTotalPages from './getAllProductsTotalPages';
import getAllTotalPages from './getAllTotalPages';
import select from './select';
import selectIsError from './selectIsError';
import selectIsLoading from './selectIsLoading';
import selectSelected from './selectSelected';

const priceListSelectors = {
  details,
  detailsIsError,
  detailsIsLoading,
  getAll,
  getAllIsError,
  getAllIsLoading,
  getAllPage,
  getAllTotalPages,
  getAllProducts,
  getAllProductsIsError,
  getAllProductsIsLoading,
  getAllProductsPage,
  getAllProductsTotalPages,
  select,
  selectIsError,
  selectIsLoading,
  selectSelected,
};

export default priceListSelectors;
