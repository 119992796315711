import Tables from 'styles/tables';

import { DrugsHeader } from '../styles';

const ItemHeader = () => {
  return (
    <DrugsHeader>
      <Tables.ItemHeader>Código</Tables.ItemHeader>
      <Tables.ItemHeader>Descrição</Tables.ItemHeader>
    </DrugsHeader>
  );
};

export default ItemHeader;
