import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;

  & svg {
    color: ${colors.title};
  }
`;
