import { Draft } from '@reduxjs/toolkit';

import { IKitInitialData } from '../../index';

function listFailure(draft: Draft<IKitInitialData>) {
  draft.data.config.isLoading = false;
  draft.data.config.isError = true;
}

export default listFailure;
