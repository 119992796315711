import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface ITermsControlSuccessAction {
  showTerms: boolean;
}

function termsControlSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<ITermsControlSuccessAction>,
) {
  draft.termsControl.config.isLoading = false;
  draft.data.others.showTerms = action.payload.showTerms;
}

export default termsControlSuccess;
