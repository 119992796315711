import { REHYDRATE } from 'redux-persist';
import { all, takeLatest } from 'redux-saga/effects';

import { authActions } from '../index';
import forgotPassword from './forgotPassword';
import setToken from './setToken';
import signIn from './signIn';
import updatePassword from './updatePassword';

const authSagas = all([
  takeLatest(REHYDRATE, setToken),
  takeLatest(authActions.signInRequest, signIn),
  takeLatest(authActions.forgotPasswordRequest, forgotPassword),
  takeLatest(authActions.updatePasswordRequest, updatePassword),
]);

export default authSagas;
