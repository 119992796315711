import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { dashboardActions } from '../index';

function* order() {
  try {
    const {
      data,
    }: AxiosResponse<{
      billing: number;
      cancellation: number;
      closing: number;
      opening: number;
      processing: number;
    }> = yield call(httpClient.get, `admin/dashboard/orders`);

    yield put(
      dashboardActions.orderSuccess({
        data: {
          billing: data.billing,
          cancellation: data.cancellation,
          closing: data.closing,
          opening: data.opening,
          processing: data.processing,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(dashboardActions.orderFailure());
  }
}

export default order;
