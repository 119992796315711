import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsColumnsRequest from 'services/httpClient/request/settings/IPutSettingsColumnsRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { settingActions } from '..';

import { IColumnsRequestAction } from '../reducers/columns/request';

function* columns(action: PayloadAction<IColumnsRequestAction>) {
  try {
    const body: IPutSettingsColumnsRequest = {
      showOnlyPriceTotal: action.payload.data.showOnlyPriceTotal,
      showProductsWithoutLimit: action.payload.data.showProductsWithoutLimit,
      displayColorsStock: action.payload.data.displayColorsStock,
    };
    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/columns',
      body,
    );
    ToastManager.show({
      title: message,
      type: 'success',
    });

    yield put(
      settingActions.columnsSuccess({
        showOnlyPriceTotal: action.payload.data.showOnlyPriceTotal,
        showProductsWithoutLimit: action.payload.data.showProductsWithoutLimit,
        displayColorsStock: action.payload.data.displayColorsStock,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.columnsFailure());
  }
}

export default columns;
