import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetOrdersResponse from 'services/httpClient/responses/orders/IGetOrdersResponse';

import { IListOrderRequestAction } from '../reducers/list/request';

import { orderActions } from '..';

function* listOrder(action: PayloadAction<IListOrderRequestAction>) {
  try {
    const params = {
      page: action.payload.data.page,
      status: action.payload.data.status
        ? action.payload.data.status
        : undefined,
      origin: action.payload.data.origin
        ? action.payload.data.origin
        : undefined,
      name: action.payload.data.name ? action.payload.data.name : undefined,
      dateStart: action.payload.data.dateStart
        ? action.payload.data.dateStart
        : undefined,
      dateEnd: action.payload.data.dateEnd
        ? action.payload.data.dateEnd
        : undefined,
    };
    const response: AxiosResponse<IGetOrdersResponse> = yield call(
      httpClient.get,
      `admin/orders`,
      { params },
    );
    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      yield put(orderActions.listSuccess({ list: [], totalPages: 0 }));
      return;
    }
    yield put(
      orderActions.listSuccess({
        list: response.data.list,
        totalPages: response.data.totalPages,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(orderActions.listFailure());
  }
}

export default listOrder;
