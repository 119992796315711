import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import ComponentButtonBase from 'components/button/Base';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import colors from 'styles/colors';

import {
  Actions,
  Content,
  ImagePreview,
  ImagePreviewLoading,
  Thumbnail,
  Title,
  TitleContainer,
} from './styles';

export interface IPreviewBannerRefProps {
  open: (imageUrl: string) => void;
}

const PreviewBanner: React.ForwardRefRenderFunction<IPreviewBannerRefProps> = (
  _,
  ref,
) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const openModal = useCallback((imageUrl: string) => {
    componentModalBaseRef.current?.open();
    setIsLoading(true);
    const newImage = new Image();

    newImage.src = imageUrl;
    newImage.onload = () => {
      setIsLoading(false);
    };

    setImageSrc(imageUrl);
  }, []);
  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <TitleContainer>
          <Title>Visualizar imagem</Title>
        </TitleContainer>

        <Thumbnail>
          <ImagePreviewLoading isVisible={isLoading} />
          <ImagePreview isVisible={!isLoading} src={imageSrc} />
        </Thumbnail>

        <Actions>
          <ComponentButtonBase
            backgroundColor={colors.subtitle}
            onClick={closeModal}
          >
            Fechar
          </ComponentButtonBase>
        </Actions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(PreviewBanner);
