import { customerActions } from '..';

import httpClient from 'services/httpClient';

import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import helpers from 'helpers';

import { IPriceTablesRequestAction } from '../reducers/priceTables/request';

import IGetAllPriceTablesResponse from 'services/httpClient/responses/customer/IGetAllPriceTablesResponse';

import { call, put } from 'redux-saga/effects';
import EHttpStatusCodes from 'enums/httpStatusCodes';

function* priceTables(action: PayloadAction<IPriceTablesRequestAction>) {
  try {
    const { data, status }: AxiosResponse<IGetAllPriceTablesResponse> =
      yield call(
        httpClient.get,
        `admin/customers/${action.payload.data.customerId}/price-tables`,
      );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(customerActions.priceTablesSuccess({ list: [] }));
      return;
    }
    yield put(customerActions.priceTablesSuccess({ list: data.list }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.priceTablesFailure());
  }
}

export default priceTables;
