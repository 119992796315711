import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IDashboardInitialData } from '../../index';

function customerRequest(
  draft: Draft<IDashboardInitialData>,
  _: PayloadAction,
) {
  draft.customer.config.isLoading = true;
}

export default customerRequest;
