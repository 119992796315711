import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.button`
  align-items: center;
  background-color: ${colors.primary};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  height: 3.5rem;
  justify-content: center;
  margin-top: 2.5rem;
  width: 100%;

  &:hover {
    filter: brightness(0.8);
  }
`;
