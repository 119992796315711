import { StylesConfig } from 'react-select';

import colors from 'styles/colors';

import { IOptionTypeBase } from './index';

export const customStyles: StylesConfig<IOptionTypeBase, false> = {
  control: provided => ({
    ...provided,
    height: '2.25rem',
    minHeight: '2.25rem',
    width: '100%',
    margin: '0',
    fontSize: '.875rem',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 0,
    borderRadius: '.5rem',
    boxShadow: 'none',
    '&:hover': {
      border: 0,
    },
    position: 'relative',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 1rem',
    color: colors.placeholder,
  }),
  menu: provided => ({
    ...provided,
    margin: '.25rem 0',
    zIndex: 19,
    borderRadius: '.5rem',
    width: '100%',
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.placeholder,
    fontSize: '.875rem',
  }),
  input: provided => ({
    ...provided,
    color: colors.input,
  }),
  noOptionsMessage: provided => ({
    ...provided,
    fontSize: '.875rem',
  }),
  singleValue: provided => ({ ...provided, color: colors.input }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: ' 0 .5rem 0 0',
    color: colors.placeholder,
  }),
  option: provided => ({
    ...provided,
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};
