import { FiEye, FiTrash2 } from 'react-icons/fi';

import colors from 'styles/colors';

import { Action, Actions, Container, Path, PathContainer } from './styles';

interface IUploadedItemProps {
  image: {
    id: number | string;
    url: string;
  };
  openPreview: (url: string) => void;
  removeImage: () => void;
}

function UploadedItem({
  image,
  openPreview,
  removeImage,
}: IUploadedItemProps): JSX.Element {
  return (
    <Container>
      <PathContainer title={image.url}>
        <Path>{image.url}</Path>
      </PathContainer>
      <Actions>
        <Action onClick={() => openPreview(image.url)}>
          <FiEye color={colors.white} size={18} />
        </Action>
        <Action>
          <FiTrash2 color={colors.red} onClick={removeImage} size={18} />
        </Action>
      </Actions>
    </Container>
  );
}

export default UploadedItem;
