import { adminReducers, IAdminInitialData } from './slices/admin';
import { authReducers, IAuthInitialData } from './slices/auth';
import { bannerReducers, IBannerInitialData } from './slices/banner';
import { configReducers, IConfigInitialData } from './slices/config';
import { customerReducers, ICustomerInitialData } from './slices/customer';
import {
  customerAccessReducers,
  ICustomerAccessInitialData,
} from './slices/customerAccess';
import { dashboardReducers, IDashboardInitialData } from './slices/dashboard';
import { feedbackReducers, IFeedbackInitialData } from './slices/feedback';
import { IImageInitialData, imageReducers } from './slices/image';
import { IKitInitialData, kitReducers } from './slices/kit';
import { IListInitialData, listReducers } from './slices/list';
import {
  IManufacturerInitialData,
  manufacturerReducers,
} from './slices/manufacturer';
import { IOrderInitialData, orderReducers } from './slices/order';
import { IPriceListInitialData, priceListReducers } from './slices/priceList';
import { IProductInitialData, productReducers } from './slices/product';
import {
  IProductRequestInitialData,
  productRequestReducers,
} from './slices/productRequest';
import { ISettingInitialData, settingReducers } from './slices/setting';

export interface IApplicationState {
  admin: IAdminInitialData;
  auth: IAuthInitialData;
  banner: IBannerInitialData;
  config: IConfigInitialData;
  customer: ICustomerInitialData;
  customerAccess: ICustomerAccessInitialData;
  dashboard: IDashboardInitialData;
  feedback: IFeedbackInitialData;
  image: IImageInitialData;
  kit: IKitInitialData;
  list: IListInitialData;
  manufacturer: IManufacturerInitialData;
  order: IOrderInitialData;
  priceList: IPriceListInitialData;
  product: IProductInitialData;
  productRequest: IProductRequestInitialData;
  setting: ISettingInitialData;
}

const rootReducers = {
  admin: adminReducers,
  auth: authReducers,
  banner: bannerReducers,
  config: configReducers,
  customer: customerReducers,
  customerAccess: customerAccessReducers,
  dashboard: dashboardReducers,
  image: imageReducers,
  kit: kitReducers,
  list: listReducers,
  manufacturer: manufacturerReducers,
  order: orderReducers,
  priceList: priceListReducers,
  product: productReducers,
  productRequest: productRequestReducers,
  setting: settingReducers,
  feedback: feedbackReducers,
};

export default rootReducers;
