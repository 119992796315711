import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm, Options } from '../styles';

interface IHomeFormData {
  showHome: boolean;
}

function HomeControl(): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const homeConfigFormRef = useRef<FormikProps<IHomeFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.homeControlIsLoading);
  const others = useReduxSelector(settingSelectors.others);

  const handleHomeConfig = useCallback(
    (data: IHomeFormData) => {
      reduxDispatch(
        settingActions.homeControlRequest({
          data: {
            showHome: data.showHome,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChange = useCallback(() => {
    homeConfigFormRef.current?.setFieldValue(
      'showHome',
      !homeConfigFormRef.current?.values.showHome,
    );
    homeConfigFormRef.current?.submitForm();
  }, []);

  return (
    <Formik
      initialValues={{
        showHome: others.showHome,
      }}
      innerRef={homeConfigFormRef}
      onSubmit={handleHomeConfig}
    >
      {({ values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Controle de exibição da página inicial
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>
            <CardSubtitle>
              Define se a página inicial (a que contém banners e listagem) deve
              ser exibida para o cliente
            </CardSubtitle>

            <Options>
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.showHome}
                label="Exibir página inicial"
                name="showHome"
                onChange={handleOnChange}
              />
            </Options>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
}

export default HomeControl;
