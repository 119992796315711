import styled from 'styled-components';

import Tables from 'styles/tables';

export const ManufacturerItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 5rem 6rem 1fr 7rem 8rem 5rem;
  padding: 1.25rem 1rem;
`;

export const ManufacturerItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 5rem 6rem 1fr 7rem 8rem 5rem;
  padding: 1rem;
`;
