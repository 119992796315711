import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { formatters } from 'racsys-js-tools';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsAddressRequest from 'services/httpClient/request/settings/IPutSettingsAddressRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IAddressRequestAction } from '../reducers/address/request';

import { settingActions } from '..';

function* address(action: PayloadAction<IAddressRequestAction>) {
  try {
    const body: IPutSettingsAddressRequest = {
      city: action.payload.data.city,
      neighborhood: action.payload.data.neighborhood,
      complement: action.payload.data.complement,
      number: action.payload.data.number,
      postalCode: formatters.removeMask(action.payload.data.postalCode),
      state: action.payload.data.state,
      street: action.payload.data.street,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/address',
      body,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });

    yield put(
      settingActions.addressSuccess({
        city: action.payload.data.city,
        neighborhood: action.payload.data.neighborhood,
        complement: action.payload.data.complement,
        number: action.payload.data.number,
        postalCode: action.payload.data.postalCode,
        state: action.payload.data.state,
        street: action.payload.data.street,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.addressFailure());
  }
}

export default address;
