import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import settingSelectors from 'store/slices/setting/selectors';

import pages from 'constants/pages';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentBackButton from 'components/button/BackButton';

import Containers from 'styles/containers';

import CustomerPedcoinsExtract from './Extract';
import CustomerPedcoinsRedeem from './Redeem';
import {
  Subheader,
  Subtitle,
  Tab,
  TabContent,
  Tabs,
  Title,
  TitleContainer,
} from './styles';

enum EPedcoinsTabName {
  EXTRACT = 'extract',
  RESCUE = 'rescue',
}

interface ICustomerPedcoinsParams extends Record<string, string | undefined> {
  id: string;
}
interface ICustomerPedcoinsLocation {
  customerName?: string;
}

const CustomerPedcoins = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<ICustomerPedcoinsParams>();

  const {
    state,
  }: {
    state: ICustomerPedcoinsLocation;
  } = useLocation();

  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  const [tabActivated, setTabActivated] = useState<EPedcoinsTabName>(
    EPedcoinsTabName.EXTRACT,
  );

  const selectTabActivated = (tabName: EPedcoinsTabName) => {
    setTabActivated(tabName);
  };

  useEffect(() => {
    if (!pedcoins.isEnable) {
      navigate(pages.home, { replace: true });
    }
  }, [navigate, pedcoins.isEnable]);

  return (
    <Containers.Global>
      <Subheader>
        <TitleContainer>
          <Title hasBackButton>
            <ComponentBackButton />
            {`Visualizando ${pedcoins.name} ${
              state?.customerName ? `de ${state.customerName}` : ''
            }`}
          </Title>
          <Subtitle>{`Acompanhe abaixo o extrato de ${pedcoins.name}`}</Subtitle>
        </TitleContainer>
        <Tabs>
          <Tab
            isSelected={tabActivated === EPedcoinsTabName.EXTRACT}
            onClick={() => selectTabActivated(EPedcoinsTabName.EXTRACT)}
          >
            Extrato
          </Tab>
          <Tab
            isSelected={tabActivated === EPedcoinsTabName.RESCUE}
            onClick={() => selectTabActivated(EPedcoinsTabName.RESCUE)}
          >
            Resgate
          </Tab>
        </Tabs>
      </Subheader>

      <TabContent isActivated={tabActivated === EPedcoinsTabName.EXTRACT}>
        <CustomerPedcoinsExtract customerId={Number(params.id)} />
      </TabContent>
      <TabContent
        isActivated={tabActivated === EPedcoinsTabName.RESCUE}
        rightOut
      >
        <CustomerPedcoinsRedeem customerId={Number(params.id)} />
      </TabContent>
    </Containers.Global>
  );
};

export default CustomerPedcoins;
