import React, {
  forwardRef,
  InputHTMLAttributes,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FiAlertCircle } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons/lib';

import ComponentIsVisible from 'components/utils/IsVisible';

import {
  Container,
  Error,
  FieldContainer,
  IFieldContainerProps,
  IInputProps,
  Input,
  Label,
} from './styles';

export interface IComponentInputDateProps
  extends Omit<IFieldContainerProps, 'isErrored' | 'isFocused' | 'isFilled'>,
    IInputProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'height'> {
  errorMessage?: string;
  hasError?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  isLoading?: boolean;
  label?: string;
  name: string;
  title?: string;
}

export interface IComponentInputDateRefProps {
  setValueRef: (text: string) => void;
}

const ComponentInputDate: React.ForwardRefRenderFunction<
  IComponentInputDateRefProps,
  IComponentInputDateProps
> = (
  {
    backgroundColor,
    errorMessage,
    hasError = false,
    height,
    icon: InputIcon,
    isLoading = false,
    label,
    name,
    onChange,
    readOnly = false,
    textAlign,
    title,
    ...rest
  },
  ref,
) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (inputRef.current) {
      inputRef.current.value = text;
    }
    if (onChange) {
      onChange(event);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  };

  useImperativeHandle(ref, () => ({
    setValueRef: (text: string) => {
      if (inputRef.current) {
        inputRef.current.value = text;
      }
    },
  }));

  return (
    <Container>
      <ComponentIsVisible when={!!label}>
        <Label>{label}</Label>
      </ComponentIsVisible>

      <FieldContainer
        backgroundColor={backgroundColor}
        height={height}
        isErrored={hasError}
        isFilled={isFilled}
        isFocused={isFocused}
        isIcon={!!InputIcon}
        isReadOnly={readOnly}
      >
        {InputIcon && <InputIcon size={20} />}
        <Input
          isLoading={isLoading}
          name={name}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          readOnly={readOnly || isLoading}
          ref={inputRef}
          textAlign={textAlign}
          title={title}
          type="date"
          uppercase
          {...rest}
        />
        <ComponentIsVisible when={hasError && !readOnly}>
          <Error title={errorMessage as string}>
            <FiAlertCircle size={18} />
          </Error>
        </ComponentIsVisible>
      </FieldContainer>
    </Container>
  );
};

export default forwardRef(ComponentInputDate);
