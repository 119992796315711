import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IColumnSuccessAction {
  displayColorsStock: boolean;
  showOnlyPriceTotal: boolean;
  showProductsWithoutLimit: boolean;
}

function columnsSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IColumnSuccessAction>,
) {
  draft.columns.config.isLoading = false;
  draft.data.columns = {
    onlyAmountFinal: action.payload.showOnlyPriceTotal,
    showProductsWithoutLimit: action.payload.showProductsWithoutLimit,
    displayColorsStock: action.payload.displayColorsStock,
  };
}

export default columnsSuccess;
