import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerAccessInitialData } from '../../index';

export interface IGetAllRequestAction {
  data: {
    date?: string;
    page: number;
  };
}

function getAllRequest(
  draft: Draft<ICustomerAccessInitialData>,
  action: PayloadAction<IGetAllRequestAction>,
) {
  draft.getAll.data = [];
  draft.getAll.config.isLoading = true;
  draft.getAll.config.isError = false;
  draft.getAll.config.page = action.payload.data.page ?? 1;
}

export default getAllRequest;
