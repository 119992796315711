import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IContactSuccessAction {
  email: string;
  phone: string;
}

function contactSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IContactSuccessAction>,
) {
  draft.company.config.isLoading = false;
  draft.data.company = {
    ...draft.data.company,
    email: action.payload.email,
    phone: action.payload.phone,
  };
}

export default contactSuccess;
