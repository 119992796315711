import styled from 'styled-components';

import Tables from 'styles/tables';

export const CustomerOrdersHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 4rem repeat(7, 1fr);
  padding: 1.25rem 1rem;
`;

export const CustomerOrdersBody = styled(Tables.ItemsBody)`
  grid-template-columns: 4rem repeat(7, 1fr);
  padding: 1rem;
`;
