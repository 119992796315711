import {
  CustomerPedcoinsItemBody,
  CustomerPedcoinsItemsBody,
} from 'pages/Customers/Pedcoins/styles';

interface IRedeemItem {
  date: string;
  invoice: string;
  orderId: number;
  pedcoins: number;
}

interface IRedeemItemBodyProps {
  redeemItem: IRedeemItem;
}

function RedeemItemBody({ redeemItem }: IRedeemItemBodyProps): JSX.Element {
  return (
    <CustomerPedcoinsItemsBody>
      <CustomerPedcoinsItemBody
        output
      >{`-${redeemItem.pedcoins}`}</CustomerPedcoinsItemBody>
      <CustomerPedcoinsItemBody>{`#${redeemItem.invoice}`}</CustomerPedcoinsItemBody>
      <CustomerPedcoinsItemBody center>
        {redeemItem.date}
      </CustomerPedcoinsItemBody>
    </CustomerPedcoinsItemsBody>
  );
}

export default RedeemItemBody;
