import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { FiEye, FiFileText, FiKey } from 'react-icons/fi';

import ICustomer from 'models/ICustomer';
import settingSelectors from 'store/slices/setting/selectors';

import pages from 'constants/pages';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { ConnectedContainer, CustomerItemsBody, PedcoinButton } from './styles';

interface ICustomerItemBodyProps {
  customer: ICustomer;
  openChangePassword: (customer: ICustomer) => void;
  openCustomerPedcoins: (id: number, customerName?: string) => void;
  openLaunchPedcoins: (customer: ICustomer) => void;
  position: number;
}

const CustomerItemBody: React.FC<ICustomerItemBodyProps> = ({
  customer,
  openChangePassword,
  openCustomerPedcoins,
  openLaunchPedcoins,
  position,
}) => {
  const navigate = useNavigate();
  const internalControl = useReduxSelector(settingSelectors.internalControl);
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);
  const coinIconName = useMemo(() => {
    return pedcoins.name.toUpperCase().split('')[0];
  }, [pedcoins.name]);

  return (
    <CustomerItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>
        <ConnectedContainer isConnected={customer.isConnected} />
      </Tables.ItemBody>
      <Tables.ItemBody>
        {internalControl.hasPreferenceCompanyName
          ? customer.companyName
          : customer.name}
      </Tables.ItemBody>
      <Tables.ItemBody>{customer.document}</Tables.ItemBody>
      <Tables.ItemBody>{customer.email}</Tables.ItemBody>
      <Tables.ItemBody>{customer.phone}</Tables.ItemBody>
      <Tables.ItemBody>{customer.situation}</Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction
            onClick={() => navigate(pages.customer.details(customer.id))}
            title="Detalhes"
            type="button"
          >
            <FiEye color={colors.blue} size={22} />
          </Tables.ItemAction>
          <Tables.ItemAction
            onClick={() => openChangePassword(customer)}
            title="Alterar senha"
            type="button"
          >
            <FiKey color={colors.orange} size={22} />
          </Tables.ItemAction>
          <ComponentIsVisible when={!!pedcoins?.isEnable}>
            <Tables.ItemAction
              onClick={() => openCustomerPedcoins(customer.id, customer.name)}
              title="Extrato de moedas"
              type="button"
            >
              <FiFileText color={colors.blue} size={22} />
            </Tables.ItemAction>
            <PedcoinButton
              onClick={() => openLaunchPedcoins(customer)}
              title="Adicionar/remover moedas"
              type="button"
            >
              {coinIconName}
            </PedcoinButton>
          </ComponentIsVisible>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </CustomerItemsBody>
  );
};

export default CustomerItemBody;
