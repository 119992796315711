import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IPedcoinsSuccessAction {
  expirationTermInMonths: number;
  isEnable: boolean;
  name: string;
  pointsPerReal: number;
}

function pedcoinsSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IPedcoinsSuccessAction>,
) {
  draft.pedcoins.config.isLoading = false;
  draft.data.pedcoins = {
    expirationTerm: action.payload.expirationTermInMonths,
    isEnable: action.payload.isEnable,
    name: action.payload.name,
    pointsPerReal: action.payload.pointsPerReal,
  };
}

export default pedcoinsSuccess;
