import Tables from 'styles/tables';

import { PriceTableHeader } from '../styles';

const ItemHeader = () => {
  return (
    <PriceTableHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader center>SKU</Tables.ItemHeader>
      <Tables.ItemHeader>TIPO</Tables.ItemHeader>
      <Tables.ItemHeader>NOME</Tables.ItemHeader>
    </PriceTableHeader>
  );
};

export default ItemHeader;
