import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductRequestInitialData } from '../../index';

export interface IGetAllRequestAction {
  data: {
    page: number;
    perPage?: number;
  };
}

function getAllRequest(
  draft: Draft<IProductRequestInitialData>,
  action: PayloadAction<IGetAllRequestAction>,
) {
  draft.getAll.data = [];
  draft.getAll.config.isLoading = true;
  draft.getAll.config.isError = false;
  draft.getAll.config.page = action.payload.data.page;
}

export default getAllRequest;
