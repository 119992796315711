import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAdminInitialData } from '../../index';

export interface ICreateAdminRequestAction {
  data: {
    email: string;
    name: string;
    password: string;
  };
  functions: {
    success: () => void;
  };
}

function createAdmin(
  draft: Draft<IAdminInitialData>,
  _: PayloadAction<ICreateAdminRequestAction>,
) {
  draft.create.config.isLoading = true;
}

export default createAdmin;
