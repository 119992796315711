import styled from 'styled-components';

import Tables from 'styles/tables';

export const AdminItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 5rem 25rem 1fr 5rem;
  padding: 1rem;
`;

export const AdminItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 5rem 25rem 1fr 5rem;
  padding: 1.25rem 1rem;
`;
