import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FiArrowDownCircle, FiArrowUpCircle, FiX } from 'react-icons/fi';

import ELaunchPedcoinsType from 'enums/launchPedcoinsType';
import { Formik, FormikProps } from 'formik';
import ICustomer from 'models/ICustomer';
import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputNumber from 'components/input/Number';
import ComponentTextarea from 'components/input/Textarea';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import {
  ClientName,
  CloseButton,
  Content,
  FieldsGrid,
  FormActions,
  FormikForm,
  Label,
  Subtitle,
  Title,
  TransactionType,
  TransactionTypes,
} from './styles';

export interface ICustomerLaunchPedcoinsRefProps {
  open: (data: Pick<ICustomer, 'id' | 'name'>) => void;
}

interface ICustomerLaunchPedcoinsFormData {
  quantity: string;
  reason: string;
}

const customerLaunchPedcoinsSchema = Yup.object().shape({
  quantity: Yup.string().required('Informe a quantidade'),
  reason: Yup.string().required('Informe o motivo'),
});

const CustomerLaunchPedcoins: React.ForwardRefRenderFunction<
  ICustomerLaunchPedcoinsRefProps
> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();
  const [transactionName, setTransactionName] = useState<ELaunchPedcoinsType>(
    ELaunchPedcoinsType.In,
  );
  const [customer, setCustomer] = useState<
    Pick<ICustomer, 'id' | 'name'> | undefined
  >(undefined);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const launchPedcoinsFormRef =
    useRef<FormikProps<ICustomerLaunchPedcoinsFormData>>(null);

  const launchPedcoinsLoading = useReduxSelector(
    customerSelectors.launchPedcoinsIsLoading,
  );
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  const openModal = useCallback((data: Pick<ICustomer, 'id' | 'name'>) => {
    setCustomer(data);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const selectTransactionType = (type: ELaunchPedcoinsType) => {
    setTransactionName(type);
  };

  const handleLaunchPedcoins = useCallback(
    (data: ICustomerLaunchPedcoinsFormData) => {
      if (!customer) {
        return;
      }
      reduxDispatch(
        customerActions.launchPedcoinsRequest({
          data: {
            customerId: customer.id,
            quantity: data.quantity,
            reason: data.reason,
            type: transactionName,
          },
          functions: {
            success() {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, customer, reduxDispatch, transactionName],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>{`Lançamento de ${pedcoins.name}`}</Title>
        <Subtitle>Preencha os campos abaixo para concluir a ação</Subtitle>

        <CloseButton onClick={closeModal} type="button">
          <FiX size={24} />
        </CloseButton>

        <ClientName>
          <Label>Cliente</Label>
          {customer?.name}
        </ClientName>

        <Formik
          initialValues={{
            quantity: '',
            reason: '',
          }}
          innerRef={launchPedcoinsFormRef}
          onSubmit={handleLaunchPedcoins}
          validationSchema={customerLaunchPedcoinsSchema}
        >
          {({ errors, handleChange, values }) => (
            <FormikForm>
              <FieldsGrid>
                <ComponentInputNumber
                  errorMessage={errors.quantity}
                  hasError={!!errors.quantity}
                  label="Qtde. Pedcoins"
                  name="quantity"
                  onChange={handleChange('quantity')}
                  textAlign="right"
                  value={values.quantity}
                />

                <TransactionTypes>
                  <Label>Tipo</Label>
                  <TransactionType
                    entry={transactionName === ELaunchPedcoinsType.In}
                    onClick={() =>
                      selectTransactionType(ELaunchPedcoinsType.In)
                    }
                    type="button"
                  >
                    <FiArrowUpCircle size={18} />
                    Entrada
                  </TransactionType>
                  <TransactionType
                    onClick={() =>
                      selectTransactionType(ELaunchPedcoinsType.Out)
                    }
                    out={transactionName === ELaunchPedcoinsType.Out}
                    type="button"
                  >
                    <FiArrowDownCircle size={18} />
                    Saída
                  </TransactionType>
                </TransactionTypes>
              </FieldsGrid>

              <ComponentTextarea
                errorMessage={errors.reason}
                hasError={!!errors.reason}
                label="Motivo"
                name="reason"
                onChange={handleChange('reason')}
                value={values.reason}
              />

              <FormActions>
                <ComponentButtonBase
                  disabled={launchPedcoinsLoading}
                  isLoading={launchPedcoinsLoading}
                  type="submit"
                >
                  Confirmar
                </ComponentButtonBase>
              </FormActions>
            </FormikForm>
          )}
        </Formik>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(CustomerLaunchPedcoins);
