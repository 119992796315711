import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { EDashboardBilling, IDashboardInitialData } from '../../index';

export interface IBillingFailureAction {
  data: {
    type: EDashboardBilling;
  };
}

function billingFailure(
  draft: Draft<IDashboardInitialData>,
  action: PayloadAction<IBillingFailureAction>,
) {
  if (action.payload.data.type === EDashboardBilling.Daily) {
    draft.billing.daily.config.isLoading = true;
  }
  if (action.payload.data.type === EDashboardBilling.Monthly) {
    draft.billing.monthly.config.isLoading = true;
  }
  if (action.payload.data.type === EDashboardBilling.Semiannual) {
    draft.billing.semiannual.config.isLoading = true;
  }
  if (action.payload.data.type === EDashboardBilling.Weekly) {
    draft.billing.weekly.config.isLoading = true;
  }
  if (action.payload.data.type === EDashboardBilling.Yearly) {
    draft.billing.yearly.config.isLoading = true;
  }
}

export default billingFailure;
