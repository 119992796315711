import { createSlice } from '@reduxjs/toolkit';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';

import reducers from './reducers';

export interface IPriceList {
  dateTimeEnd: string;
  dateTimeStart: string;
  id: number;
  name: string;
  sku: string;
  type: string;
}

export interface IPriceListProductDetails {
  amountDiscount: string;
  amountFee: string;
  amountFinal: string;
  amountGross: string;
  amountNet: string;
  id: number;
  manufacturer: string;
  name: string;
  sku: string;
}

export interface IPriceListDetails {
  dateTimeEnd: string;
  dateTimeStart: string;
  id: number;
  name: string;
  sku: string;
  state: string;
  type: string;
}
export interface IPriceListInitialData {
  details: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: IPriceListDetails | null;
  };
  getAll: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    data: IPriceList[];
  };
  getAllProducts: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    data: IPriceListProductDetails[];
  };
  select: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: IComponentSelectProps<number>[];
    selected: IComponentSelectProps<number> | null;
  };
}

const initialState: IPriceListInitialData = {
  details: {
    config: {
      isError: false,
      isLoading: false,
    },
    data: null,
  },
  getAll: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    data: [],
  },
  getAllProducts: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    data: [],
  },
  select: {
    config: {
      isError: false,
      isLoading: false,
    },
    data: [],
    selected: null,
  },
};

const priceListSlice = createSlice({
  name: '@priceList',
  initialState,
  reducers,
});

export const priceListActions = priceListSlice.actions;
export const priceListReducers = priceListSlice.reducer;
