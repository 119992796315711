import { useCallback, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Formik, FormikProps } from 'formik';
import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';
import * as Yup from 'yup';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import authImage from 'assets/auth/auth-image.svg';

import AuthButton from '../Button';
import AuthField from '../Field';
import {
  AnimatedContent,
  AuthFields,
  BackButton,
  Container,
  Content,
  ContentSubtitle,
  ForgotPassword,
  FormikForm,
  Image,
  Small,
  Subtitle,
  Title,
} from '../styles';

interface IAuthenticateCredentialsFormData {
  email: string;
  password: string;
}

interface IForgotPasswordFormData {
  email: string;
}

const authenticateSchema = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
});

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Informe o e-mail'),
});

function SignIn(): JSX.Element {
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const signInIsLoading = useReduxSelector(authSelectors.signInIsLoading);
  const forgotPasswordIsLoading = useReduxSelector(
    authSelectors.forgotPasswordIsLoading,
  );
  const token = useReduxSelector(authSelectors.authToken);

  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);
  const [hideAnimation, setHideAnimation] = useState<boolean>(false);

  const authenticateFormRef =
    useRef<FormikProps<IAuthenticateCredentialsFormData>>(null);
  const forgotPasswordFormRef =
    useRef<FormikProps<IForgotPasswordFormData>>(null);

  const toggleIsForgotPassword = (state: boolean) => {
    setHideAnimation(true);
    const animated = document.getElementById('animatedContent');
    if (animated) {
      animated.addEventListener('animationend', () => {
        setIsForgotPassword(state);
        setHideAnimation(false);
      });
    }
  };

  const handleAuthenticate = useCallback(
    (data: IAuthenticateCredentialsFormData) => {
      reduxDispatch(
        authActions.signInRequest({
          data: {
            email: data.email,
            password: data.password,
          },
          functions: {
            navigateTo: (): void => {
              navigate(pages.home, { replace: true });
            },
          },
        }),
      );
    },
    [navigate, reduxDispatch],
  );

  const handleForgotPasswordMail = useCallback(
    (data: IForgotPasswordFormData) => {
      reduxDispatch(
        authActions.forgotPasswordRequest({
          data: {
            email: data.email,
          },
          functions: {
            goBack: () => {
              setIsForgotPassword(false);
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  useEffect(() => {
    if (token) {
      navigate(pages.dashboard, { replace: true });
    }
  }, [navigate, token]);

  return (
    <Container>
      <Content blueBackground>
        <Title>
          <Small>Bem-vindo ao painel do seu</Small>
          Pedido Eletrônico!
        </Title>
        <Subtitle>
          Entre, configure e edite seu sistema através deste painel
        </Subtitle>

        <Image src={authImage} />
      </Content>

      <ComponentIsVisible when={!isForgotPassword}>
        <Content>
          <AnimatedContent className={hideAnimation ? 'hide' : ''}>
            <Title formTitle>Fazer login</Title>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              innerRef={authenticateFormRef}
              onSubmit={handleAuthenticate}
              validationSchema={authenticateSchema}
            >
              {({ errors, handleChange, values }) => (
                <FormikForm>
                  <AuthFields>
                    <AuthField
                      errorMessage={errors.email}
                      hasError={!!errors.email}
                      isBorder
                      name="email"
                      onChange={handleChange('email')}
                      placeholder="E-mail"
                      value={values.email}
                    />
                    <AuthField
                      errorMessage={errors.password}
                      hasError={!!errors.password}
                      name="password"
                      onChange={handleChange('password')}
                      placeholder="Senha"
                      secure
                      type="password"
                      value={values.password}
                    />
                  </AuthFields>

                  <ForgotPassword
                    disabled={signInIsLoading}
                    onClick={() => toggleIsForgotPassword(true)}
                    type="button"
                  >
                    Esqueci minha senha
                  </ForgotPassword>

                  <AuthButton isLoading={signInIsLoading} type="submit">
                    Entrar
                  </AuthButton>
                </FormikForm>
              )}
            </Formik>
          </AnimatedContent>
        </Content>
      </ComponentIsVisible>

      <ComponentIsVisible when={isForgotPassword}>
        <Content>
          <AnimatedContent className={hideAnimation ? 'hide' : ''}>
            <Title forgotPassword>Esqueceu sua senha?</Title>
            <ContentSubtitle>
              Não se preocupe, preencha seu e-mail e enviaremos um link para
              você recuperar sua senha
            </ContentSubtitle>
            <Formik
              initialValues={{
                email: '',
              }}
              innerRef={forgotPasswordFormRef}
              onSubmit={handleForgotPasswordMail}
              validationSchema={forgotPasswordSchema}
            >
              {({ errors, handleChange, values }) => (
                <FormikForm>
                  <AuthFields forgotPassword>
                    <AuthField
                      errorMessage={errors.email}
                      hasError={!!errors.email}
                      name="email"
                      onChange={handleChange('email')}
                      placeholder="E-mail"
                      value={values.email}
                    />
                  </AuthFields>

                  <AuthButton isLoading={forgotPasswordIsLoading} type="submit">
                    Enviar e-mail
                  </AuthButton>

                  <BackButton
                    disabled={forgotPasswordIsLoading}
                    onClick={() => toggleIsForgotPassword(false)}
                    type="button"
                  >
                    Voltar ao login
                  </BackButton>
                </FormikForm>
              )}
            </Formik>
          </AnimatedContent>
        </Content>
      </ComponentIsVisible>
    </Container>
  );
}

export default SignIn;
