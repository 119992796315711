import styled from 'styled-components';

import Tables from 'styles/tables';

export const FeedbackItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 5rem 20rem 1fr repeat(2, 8rem);
  padding: 1.25rem 1rem;
`;

export const FeedbackItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 5rem 20rem 1fr repeat(2, 8rem);
  padding: 1rem;
  & div:nth-child(2) {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
`;
