import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { FiSearch } from 'react-icons/fi';

import { format } from 'date-fns';
import { Formik, FormikProps } from 'formik';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputDate from 'components/input/Date';

import colors from 'styles/colors';

import { FormikForm } from './styles';

export interface ICustomerAccessFilterFormData {
  date: string;
}

interface ICustomersFilterProps {
  isLoading: boolean;
  onSubmit: (data: ICustomerAccessFilterFormData) => void;
}

const AccessFilter: React.ForwardRefRenderFunction<
  FormikProps<ICustomerAccessFilterFormData>,
  ICustomersFilterProps
> = ({ isLoading, onSubmit }, ref) => {
  const filterFormRef = useRef<FormikProps<ICustomerAccessFilterFormData>>(
    {} as FormikProps<ICustomerAccessFilterFormData>,
  );

  useImperativeHandle(ref, () => ({
    ...filterFormRef.current,
  }));

  const currentDate = useMemo(() => {
    return format(new Date(), 'yyyy-MM-dd');
  }, []);

  return (
    <Formik
      initialValues={{
        date: currentDate,
      }}
      innerRef={filterFormRef}
      onSubmit={onSubmit}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <ComponentInputDate
            backgroundColor={colors.white}
            name="date"
            onChange={handleChange('date')}
            value={values.date}
          />
          <ComponentButtonBase disabled={isLoading}>
            <FiSearch size={18} />
          </ComponentButtonBase>
        </FormikForm>
      )}
    </Formik>
  );
};

export default forwardRef(AccessFilter);
