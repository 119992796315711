import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { feedbackActions } from '..';

import IGetAllFeedbackResponse from 'services/httpClient/responses/feedback/IGetAllFeedbackResponse';

function* getAll() {
  try {
    const { data, status }: AxiosResponse<IGetAllFeedbackResponse> = yield call(
      httpClient.get,
      'admin/feedbacks',
    );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(feedbackActions.getAllSuccess({ list: [] }));
      return;
    }
    yield put(feedbackActions.getAllSuccess({ list: data.list }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(feedbackActions.getAllFailure());
  }
}

export default getAll;
