import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentBackButton from 'components/button/BackButton';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import ManufacturerProductItem from './Item';
import { Content, Info, Label, Subtitle, Value } from './styles';

interface IManufacturerDetailsParams
  extends Record<string, string | undefined> {
  id: string;
}

const ManufacturerDetails = () => {
  const reduxDispatch = useReduxDispatch();
  const params = useParams<IManufacturerDetailsParams>();

  const manufacturerDetails = useReduxSelector(manufacturerSelectors.details);
  const manufacturerDetailsIsLoading = useReduxSelector(
    manufacturerSelectors.detailsIsLoading,
  );
  const manufacturerProducts = useReduxSelector(
    manufacturerSelectors.getAllProductsList,
  );
  const manufacturerProductsPage = useReduxSelector(
    manufacturerSelectors.getAllProductsPage,
  );
  const manufacturerProductsTotalPages = useReduxSelector(
    manufacturerSelectors.getAllProductsTotalPages,
  );
  const manufacturerProductsIsLoading = useReduxSelector(
    manufacturerSelectors.getAllProductsIsLoading,
  );
  const manufacturerProductsIsError = useReduxSelector(
    manufacturerSelectors.getAllProductsIsError,
  );

  const loadManufacturerDetails = useCallback(() => {
    if (params.id) {
      reduxDispatch(
        manufacturerActions.detailsRequest({
          data: {
            id: params.id,
          },
        }),
      );
    }
  }, [reduxDispatch, params.id]);

  const loadManufacturerProducts = useCallback(() => {
    if (params.id) {
      reduxDispatch(
        manufacturerActions.getAllProductsRequest({
          data: {
            page: 1,
            id: params.id,
          },
        }),
      );
    }
  }, [reduxDispatch, params.id]);

  const handlePagination = useCallback(
    (page: number) => {
      if (params.id) {
        reduxDispatch(
          manufacturerActions.getAllProductsRequest({
            data: {
              page,
              id: params.id,
            },
          }),
        );
      }
    },
    [reduxDispatch, params.id],
  );

  useEffect(() => {
    loadManufacturerDetails();
  }, [loadManufacturerDetails]);

  useEffect(() => {
    loadManufacturerProducts();
  }, [loadManufacturerProducts]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle hasBackButton>
          <ComponentBackButton />
          Detalhes do fabricante
        </General.PageTitle>
      </General.PageTitleContainer>

      <Subtitle>Dados gerais</Subtitle>
      <Content>
        <Info>
          <Label>Código</Label>
          <Value isLoading={manufacturerDetailsIsLoading}>
            {manufacturerDetails?.id}
          </Value>
        </Info>
        <Info>
          <Label>SKU</Label>
          <Value isLoading={manufacturerDetailsIsLoading}>
            {manufacturerDetails?.sku}
          </Value>
        </Info>
        <Info>
          <Label>Nome do fabricante</Label>
          <Value isLoading={manufacturerDetailsIsLoading}>
            {manufacturerDetails?.name}
          </Value>
        </Info>

        <Info>
          <Label center>Status</Label>
          <Value center isLoading={manufacturerDetailsIsLoading}>
            <ComponentIsVisible when={!!manufacturerDetails?.active}>
              Ativo
            </ComponentIsVisible>
            <ComponentIsVisible when={!manufacturerDetails?.active}>
              Inativo
            </ComponentIsVisible>
          </Value>
        </Info>
        <Info>
          <Label center>Pontos</Label>
          <Value center isLoading={manufacturerDetailsIsLoading}>
            {manufacturerDetails?.coinsPerReal === -1
              ? 'Não definido'
              : manufacturerDetails?.coinsPerReal}
          </Value>
        </Info>
        <Info>
          <Label center>Qtde. produtos</Label>
          <Value center isLoading={manufacturerDetailsIsLoading}>
            {manufacturerDetails?.products}
          </Value>
        </Info>
      </Content>

      <Subtitle>Produtos deste fabricante</Subtitle>
      <Tables.Items>
        <ManufacturerProductItem.Header />

        <ComponentLoadingList rows={6} show={manufacturerProductsIsLoading} />

        <ComponentIsVisible when={!manufacturerProductsIsLoading}>
          <ComponentIsVisible
            when={!!manufacturerProducts.length && !manufacturerProductsIsError}
          >
            {manufacturerProducts.map((product, position) => (
              <ManufacturerProductItem.Body
                key={product.id}
                position={position}
                product={product}
              />
            ))}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há produtos para este fabricante"
            show={!manufacturerProducts.length && !manufacturerProductsIsError}
          />

          <ComponentIsVisible when={manufacturerProductsIsError}>
            <ComponentError
              message="Não foi possível carregar os produtos deste fabricante"
              onClick={loadManufacturerProducts}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
      </Tables.Items>
      <ComponentPaginate
        currentPage={manufacturerProductsPage}
        onPage={handlePagination}
        show={!!manufacturerProducts.length}
        totalPages={manufacturerProductsTotalPages}
      />
    </Containers.Global>
  );
};

export default ManufacturerDetails;
