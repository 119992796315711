import { useMemo } from 'react';

import { FiEye } from 'react-icons/fi';

import { IProductList } from 'store/slices/product';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import { PedcoinButton } from 'pages/Customers/Item/Body/styles';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { IPedcoin } from '../../Pedcoin';
import { ProductItemsBody } from '../styles';

interface IItemBodyProps {
  openDetails: () => void;
  openPedcoin: (data: IPedcoin) => void;
  position: number;
  product: IProductList;
}

const ItemBody = ({
  openDetails,
  openPedcoin,
  position,
  product,
}: IItemBodyProps): JSX.Element => {
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  const coinIconName = useMemo(() => {
    return pedcoins.name.toUpperCase().split('')[0];
  }, [pedcoins.name]);

  return (
    <ProductItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{product.id}</Tables.ItemBody>
      <Tables.ItemBody center>{product.sku}</Tables.ItemBody>
      <Tables.ItemBody>{product.name}</Tables.ItemBody>
      <Tables.ItemBody>{product.manufacturer}</Tables.ItemBody>
      <Tables.ItemBody>{product.barcode}</Tables.ItemBody>
      <Tables.ItemBody center>
        <ComponentIsVisible when={product.active}>Ativo</ComponentIsVisible>
        <ComponentIsVisible when={!product.active}>Inativo</ComponentIsVisible>
      </Tables.ItemBody>
      <Tables.ItemBody center>
        {product.coinsPerReal === -1
          ? 'Não definido'
          : product.coinsPerRealFormatted}
      </Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <PedcoinButton
            onClick={() =>
              openPedcoin({
                productId: product.id,
                productName: product.name,
                amount: product.coinsPerReal,
              })
            }
            title="Definir pontuação"
            type="button"
          >
            {coinIconName}
          </PedcoinButton>

          <Tables.ItemAction onClick={openDetails} title="Detalhes">
            <FiEye color={colors.blue} size={20} />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </ProductItemsBody>
  );
};

export default ItemBody;
