import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { formatters } from 'racsys-js-tools';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCnpjOrCpf from 'components/input/CnpjOrCpf';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  Card,
  CardContent,
  CardSubtitle,
  CardTitle,
  FormikForm,
  TitleContainer,
  UpdatePasswordButton,
} from '../styles';
import { Fields } from './styles';

interface IGeneralCompanyFormData {
  corporateName: string;
  document: string;
}

const contactSchema = Yup.object().shape({
  corporateName: Yup.string().required('Informe a razão social'),
});

interface ICompanyGeneral {
  openUpdatePassword: () => void;
}

const CompanyGeneral = ({
  openUpdatePassword,
}: ICompanyGeneral): JSX.Element => {
  const reduxDispatch = useReduxDispatch();

  const companyFormRef = useRef<FormikProps<IGeneralCompanyFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.companyIsLoading);
  const company = useReduxSelector(settingSelectors.company);

  const handleOpenUpdatePassword = () => {
    openUpdatePassword();
  };

  const handleCompanyData = useCallback(
    (data: IGeneralCompanyFormData) => {
      reduxDispatch(
        settingActions.companyRequest({
          data: {
            corporateName: data.corporateName,
            email: company.email,
            phone: company.phone,
          },
        }),
      );
    },
    [company.email, company.phone, reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        corporateName: company.corporateName,
        document: company.document ? formatters.cnpj(company.document) : '',
      }}
      innerRef={companyFormRef}
      onSubmit={handleCompanyData}
      validationSchema={contactSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardContent>
              <TitleContainer>
                <CardTitle>
                  Dados da empresa
                  <ComponentIsVisible when={!isLoading}>
                    <FiCheckCircle color={colors.green} size={18} />
                  </ComponentIsVisible>
                  <ComponentIsVisible when={isLoading}>
                    <ClipLoader size={18} />
                  </ComponentIsVisible>
                </CardTitle>
                <CardSubtitle>
                  Preencha os campos abaixo com os dados
                </CardSubtitle>

                <UpdatePasswordButton
                  onClick={handleOpenUpdatePassword}
                  type="button"
                >
                  Alterar senha
                </UpdatePasswordButton>
              </TitleContainer>

              <Fields>
                <ComponentInputCnpjOrCpf
                  disabled
                  errorMessage={errors.document}
                  hasError={!!errors.document}
                  label="CNPJ"
                  name="document"
                  onChange={handleChange('document')}
                  value={values.document}
                />
                <ComponentInputSimple
                  errorMessage={errors.corporateName}
                  hasError={!!errors.corporateName}
                  label="Razão social"
                  name="corporateName"
                  onChange={handleChange('corporateName')}
                  value={values.corporateName}
                />
                <ComponentButtonBase
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                >
                  Salvar
                </ComponentButtonBase>
              </Fields>
            </CardContent>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default CompanyGeneral;
