import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EListTypes from 'enums/listType';

import { ISettingInitialData } from '../../index';

export interface IProductsViewModeRequestAction {
  data: {
    productsViewMode: EListTypes;
  };
}

function productsViewModeRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IProductsViewModeRequestAction>,
) {
  draft.productsViewMode.config.isLoading = true;
}

export default productsViewModeRequest;
