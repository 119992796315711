interface IPhoneOptions {
  defaultValue?: string;
}

function phone(value?: string | null, options?: IPhoneOptions): string {
  if (!value) {
    return options?.defaultValue ?? '';
  }
  const valueClean = value.replace(/[^0-9]/g, '');
  if (valueClean.substring(0, 4) === '0800') {
    const phoneSpecialFormatted = value
      .replace(/[^0-9]/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4}) (\w+)/, '$1 $2')
      .replace(/(\d{4}) (\w+)(\d{4})/, '$1 $2 $3');
    return phoneSpecialFormatted;
  }
  const phoneCommonFormatted = valueClean
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4,5})(\d{4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
  return phoneCommonFormatted;
}

export default phone;
