import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

export interface ICoinsPerRealRequestAction {
  data: {
    id: number;
    value: number;
  };
  functions: {
    success: () => void;
  };
}

function coinsPerRealRequest(
  draft: Draft<IManufacturerInitialData>,
  _: PayloadAction<ICoinsPerRealRequestAction>,
) {
  draft.coinsPerReal.config.isLoading = true;
}

export default coinsPerRealRequest;
