import { PayloadAction } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutBannerRequest from 'services/httpClient/request/banner/IPutBannerRequest';

import { bannerActions } from '..';

import { IUpdateRequestAction } from '../reducers/update/request';

function* update(action: PayloadAction<IUpdateRequestAction>) {
  try {
    const body: IPutBannerRequest = {
      images: action.payload.data.images,
      thumbnails: action.payload.data.thumbnails
        ? {
            one: action.payload.data.thumbnails.one,
            two: action.payload.data.thumbnails.two,
          }
        : undefined,
      type: action.payload.data.type,
    };

    yield call(httpClient.put, 'admin/settings/banners', body);
    ToastManager.show({
      title: 'Configurações dos banners salva com sucesso',
      type: 'success',
    });
    yield put(bannerActions.updateSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(bannerActions.updateFailure());
  }
}

export default update;
