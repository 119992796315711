import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IExternalScriptSuccessAction {
  externalScript: string;
}

function externalScriptSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IExternalScriptSuccessAction>,
) {
  draft.externalScript.config.isLoading = false;
  draft.data.others.externalScript = action.payload.externalScript;
}

export default externalScriptSuccess;
