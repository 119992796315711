import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IFaviconRequestAction {
  data: {
    url: string;
  };
}

function faviconRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IFaviconRequestAction>,
) {
  draft.favicon.config.isLoading = true;
}

export default faviconRequest;
