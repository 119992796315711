import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

function httpClientSetAuthorization(token: string): void {
  httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
}

function httpClientSetPedToken(token: string): void {
  httpClient.defaults.headers.common['ped-token'] = token;
}

export { httpClientSetAuthorization, httpClientSetPedToken };
export default httpClient;
