import { useCallback, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormikProps } from 'formik';
import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import ManufacturerFilter, { IManufacturerFilterFormData } from './Filter';
import ManufacturerItem from './Item';
import Pedcoins, { IPedcoin, IPedcoinsRefProps } from './Pedcoin';

const ManufacturerList = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();
  const navigate = useNavigate();
  const pedcoinRef = useRef<IPedcoinsRefProps>(null);
  const manufacturerFilterRef =
    useRef<FormikProps<IManufacturerFilterFormData>>(null);

  const manufacturersLoading = useReduxSelector(
    manufacturerSelectors.getAllIsLoading,
  );
  const manufacturers = useReduxSelector(manufacturerSelectors.getAllList);
  const manufacturerPage = useReduxSelector(manufacturerSelectors.getAllPage);
  const manufacturerTotalPages = useReduxSelector(
    manufacturerSelectors.getAllTotalPages,
  );
  const isError = useReduxSelector(manufacturerSelectors.getAllIsError);

  const loadManufacturers = useCallback(() => {
    reduxDispatch(
      manufacturerActions.getAllRequest({
        data: {
          page: 1,
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    (page: number) => {
      const id = manufacturerFilterRef.current?.values.id;
      const name = manufacturerFilterRef.current?.values.name;
      const sku = manufacturerFilterRef.current?.values.sku;
      const status = manufacturerFilterRef.current?.values.status;
      reduxDispatch(
        manufacturerActions.getAllRequest({
          data: {
            page,
            id,
            name,
            sku,
            status: status ? status.value : undefined,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilterManufacturers = useCallback(
    (data: IManufacturerFilterFormData) => {
      reduxDispatch(
        manufacturerActions.getAllRequest({
          data: {
            page: 1,
            id: data.id,
            name: data.name,
            sku: data.sku,
            status: data.status?.value,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnClear = useCallback(() => {
    manufacturerFilterRef.current?.resetForm();
    reduxDispatch(
      manufacturerActions.getAllRequest({
        data: {
          page: 1,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleOpenDetails = useCallback(
    (id: number) => {
      navigate(pages.manufacturer.details(id));
    },
    [navigate],
  );

  const openPedcoin = useCallback((data: IPedcoin) => {
    pedcoinRef.current?.open(data);
  }, []);

  useEffect(() => {
    loadManufacturers();
  }, [loadManufacturers]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle>Fabricantes</General.PageTitle>
      </General.PageTitleContainer>

      <ManufacturerFilter
        isLoading={manufacturersLoading}
        onClear={handleOnClear}
        onSubmit={handleFilterManufacturers}
        ref={manufacturerFilterRef}
      />

      <Tables.Items hasBorder marginTop=".5rem">
        <ComponentIsVisible when={!manufacturersLoading}>
          <ComponentIsVisible when={!!manufacturers.length && !isError}>
            <ManufacturerItem.Header />

            {manufacturers.map((manufacturer, position) => (
              <ManufacturerItem.Body
                key={manufacturer.id}
                manufacturer={manufacturer}
                openDetails={() => handleOpenDetails(manufacturer.id)}
                openPedcoin={openPedcoin}
                position={position}
              />
            ))}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há fabricantes para serem exibidos"
            show={!manufacturers.length && !isError}
          />

          <ComponentIsVisible when={isError}>
            <ComponentError
              message="Não foi possível carregar os fabricantes"
              onClick={loadManufacturers}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
        <ComponentIsVisible when={manufacturersLoading}>
          <ComponentLoadingList rows={8} show={manufacturersLoading} />
        </ComponentIsVisible>
      </Tables.Items>
      <ComponentPaginate
        currentPage={manufacturerPage}
        onPage={handlePagination}
        show={!!manufacturers.length}
        totalPages={manufacturerTotalPages}
      />

      <Pedcoins ref={pedcoinRef} />
    </Containers.Global>
  );
};

export default ManufacturerList;
