import { FiEye } from 'react-icons/fi';

import { IPriceList } from 'store/slices/priceList';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { PriceListItemsBody } from '../styles';

interface IItemBodyProps {
  openDetails: () => void;
  position: number;
  priceList: IPriceList;
}

const ItemBody = ({
  openDetails,
  position,
  priceList,
}: IItemBodyProps): JSX.Element => {
  return (
    <PriceListItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{priceList.id}</Tables.ItemBody>
      <Tables.ItemBody center>{priceList.sku}</Tables.ItemBody>
      <Tables.ItemBody>{priceList.name}</Tables.ItemBody>
      <Tables.ItemBody>{priceList.type}</Tables.ItemBody>
      <Tables.ItemBody center>{priceList.dateTimeStart}</Tables.ItemBody>
      <Tables.ItemBody center>{priceList.dateTimeEnd}</Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <Tables.ItemAction onClick={openDetails} title="Detalhes">
            <FiEye color={colors.blue} size={20} />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </PriceListItemsBody>
  );
};

export default ItemBody;
