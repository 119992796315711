import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormikProps } from 'formik';
import ICustomer from 'models/ICustomer';
import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';
import { customerAccessActions } from 'store/slices/customerAccess';
import customerAccessSelectors from 'store/slices/customerAccess/selectors';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import AccessFilter, { ICustomerAccessFilterFormData } from './Access/Filter';
import CustomerAccessItem from './Access/Item';
import CustomerChangePassword, {
  ICustomerChangePasswordRefProps,
} from './ChangePassword';
import CustomersFilter, { ICustomerFilterFormData } from './Filter';
import CustomerItem from './Item';
import CustomerLaunchPedcoins, {
  ICustomerLaunchPedcoinsRefProps,
} from './Pedcoins/Launch';
import {
  ConnectedCustomers,
  FileButtons,
  FilterContainer,
  FilterContainerAccesses,
} from './styles';

enum EListType {
  Access = 'ACCESS',
  Customers = 'CUSTOMERS',
}

const Customers: React.FC = () => {
  const reduxDispatch = useReduxDispatch();
  const navigate = useNavigate();
  const [listType, setListType] = useState<EListType>(EListType.Customers);
  const customersFilterRef = useRef<FormikProps<ICustomerFilterFormData>>(null);
  const customersAccessFilterRef =
    useRef<FormikProps<ICustomerAccessFilterFormData>>(null);
  const customerChangePasswordRef =
    useRef<ICustomerChangePasswordRefProps>(null);
  const customerLaunchPedcoinsRef =
    useRef<ICustomerLaunchPedcoinsRefProps>(null);

  const onlyConnected = useReduxSelector(customerSelectors.onlyConnected);
  const customersLoading = useReduxSelector(customerSelectors.getAllIsLoading);
  const customersAccessLoading = useReduxSelector(
    customerAccessSelectors.getAllIsLoading,
  );
  const customers = useReduxSelector(customerSelectors.getAll);
  const customerPage = useReduxSelector(customerSelectors.getAllPage);
  const customerTotalPages = useReduxSelector(
    customerSelectors.getAllTotalPages,
  );
  const isError = useReduxSelector(customerSelectors.getAllIsError);
  const customersAccess = useReduxSelector(customerAccessSelectors.getAll);
  const customerAccessPage = useReduxSelector(
    customerAccessSelectors.getAllPage,
  );
  const customerAccessTotalPages = useReduxSelector(
    customerAccessSelectors.getAllTotalPages,
  );
  const pdfIsLoading = useReduxSelector(customerAccessSelectors.pdfIsLoading);
  const spreadsheetIsLoading = useReduxSelector(
    customerAccessSelectors.spreadsheetIsLoading,
  );

  const openChangePassword = useCallback((customer: ICustomer) => {
    customerChangePasswordRef.current?.open(customer);
  }, []);

  const openLaunchPedcoins = useCallback((customer: ICustomer) => {
    customerLaunchPedcoinsRef.current?.open({
      id: customer.id,
      name: customer.name,
    });
  }, []);

  const loadCustomers = useCallback(() => {
    reduxDispatch(
      customerActions.getAllRequest({
        data: {
          page: 1,
          withPagination: true,
        },
      }),
    );
  }, [reduxDispatch]);

  const loadOnlyCustomersConnected = useCallback(() => {
    reduxDispatch(
      customerActions.getAllRequest({
        data: {
          withPagination: false,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleFilterCustomers = useCallback(
    (data: ICustomerFilterFormData) => {
      reduxDispatch(
        customerActions.getAllRequest({
          data: {
            page: 1,
            document: data.document,
            name: data.name,
            situation: data.situation?.value,
            withPagination: true,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnClear = useCallback(() => {
    customersFilterRef.current?.resetForm();
    reduxDispatch(
      customerActions.getAllRequest({
        data: {
          page: 1,
          withPagination: true,
        },
      }),
    );
  }, [reduxDispatch]);

  const loadCustomersAccess = useCallback(() => {
    reduxDispatch(
      customerAccessActions.getAllRequest({
        data: {
          page: 1,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleCustomerPagination = useCallback(
    (page: number) => {
      const document = customersFilterRef.current?.values.document;
      const name = customersFilterRef.current?.values.name;
      const situation = customersFilterRef.current?.values.situation;
      reduxDispatch(
        customerActions.getAllRequest({
          data: {
            page,
            document,
            name,
            situation: situation?.value,
            withPagination: true,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleCustomerAccessPagination = useCallback(
    (page: number) => {
      const date = customersAccessFilterRef.current?.values.date;
      reduxDispatch(
        customerAccessActions.getAllRequest({
          data: {
            page,
            date,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilterCustomersAccess = useCallback(
    (data: ICustomerAccessFilterFormData) => {
      reduxDispatch(
        customerAccessActions.getAllRequest({
          data: {
            page: 1,
            date: data.date,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleSetOnlyConnected = useCallback(
    (value: boolean) => {
      reduxDispatch(
        customerActions.setOnlyConnected({
          data: {
            value,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleListType = useCallback(
    (type: EListType) => {
      setListType(type);
      if (type === EListType.Access) {
        loadCustomersAccess();
        handleSetOnlyConnected(false);
      }
      if (type === EListType.Customers) {
        loadCustomers();
      }
    },
    [handleSetOnlyConnected, loadCustomers, loadCustomersAccess],
  );

  const handleGeneratePdf = useCallback(() => {
    const date = customersAccessFilterRef.current?.values.date;
    reduxDispatch(
      customerAccessActions.pdfCustomerAccessRequest({
        data: {
          page: customerAccessPage,
          date,
        },
      }),
    );
  }, [reduxDispatch, customerAccessPage]);

  const handleGenerateSpreadsheet = useCallback(() => {
    const date = customersAccessFilterRef.current?.values.date;
    reduxDispatch(
      customerAccessActions.spreadsheetCustomerAccessRequest({
        data: {
          page: customerAccessPage,
          date,
        },
      }),
    );
  }, [reduxDispatch, customerAccessPage]);

  const customersOnline = useMemo(() => {
    const total = customers.filter(customer => customer.isConnected);
    return total.length > 1
      ? `${total.length} conectados`
      : `${total.length} conectado`;
  }, [customers]);

  const customersParsed = useMemo(() => {
    if (onlyConnected) {
      return customers.filter(customer => customer.isConnected);
    }
    return customers;
  }, [customers, onlyConnected]);

  const openCustomerPedcoins = useCallback(
    (customerId: number, customerName?: string) => {
      navigate(pages.customer.pedcoins(customerId), {
        state: {
          customerName,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    loadCustomers();
    loadCustomersAccess();
  }, [loadCustomers, loadCustomersAccess]);

  return (
    <Containers.Global>
      <ComponentIsVisible when={listType === EListType.Customers}>
        <General.PageTitle>
          Clientes{' '}
          <ComponentIsVisible when={onlyConnected}>
            <ConnectedCustomers>({customersOnline})</ConnectedCustomers>
          </ComponentIsVisible>
        </General.PageTitle>

        <FilterContainer>
          <CustomersFilter
            isLoading={customersLoading}
            loadCustomers={loadCustomers}
            loadOnlyCustomersConnected={loadOnlyCustomersConnected}
            onClear={handleOnClear}
            onlyConnected={onlyConnected}
            onSubmit={handleFilterCustomers}
            ref={customersFilterRef}
            setOnlyConnected={handleSetOnlyConnected}
          />
          <ComponentButtonBase onClick={() => handleListType(EListType.Access)}>
            Acessos
          </ComponentButtonBase>
        </FilterContainer>

        <Tables.Items hasBorder marginTop=".5rem">
          <ComponentIsVisible when={!customersLoading}>
            <ComponentIsVisible when={!!customers.length && !isError}>
              <CustomerItem.Header />
              {customersParsed.map((customer, position) => (
                <CustomerItem.Body
                  customer={customer}
                  key={customer.id}
                  openChangePassword={openChangePassword}
                  openCustomerPedcoins={openCustomerPedcoins}
                  openLaunchPedcoins={openLaunchPedcoins}
                  position={position}
                />
              ))}
            </ComponentIsVisible>

            <ComponentEmpty
              message="Não há clientes para serem exibidos"
              show={!customersParsed.length && !isError}
            />

            <ComponentIsVisible when={isError}>
              <ComponentError
                message="Não foi possível carregar os clientes"
                onClick={loadCustomers}
              />
            </ComponentIsVisible>
          </ComponentIsVisible>
          <ComponentIsVisible when={customersLoading}>
            <ComponentLoadingList show={customersLoading} />
          </ComponentIsVisible>
        </Tables.Items>
        <ComponentIsVisible when={!onlyConnected}>
          <ComponentPaginate
            currentPage={customerPage}
            onPage={handleCustomerPagination}
            show={!!customers.length}
            totalPages={customerTotalPages}
          />
        </ComponentIsVisible>
        <CustomerChangePassword ref={customerChangePasswordRef} />
        <CustomerLaunchPedcoins ref={customerLaunchPedcoinsRef} />
      </ComponentIsVisible>

      <ComponentIsVisible when={listType === EListType.Access}>
        <General.PageTitle>Acessos</General.PageTitle>
        <FilterContainerAccesses>
          <AccessFilter
            isLoading={customersLoading}
            onSubmit={handleFilterCustomersAccess}
            ref={customersAccessFilterRef}
          />
          <FileButtons>
            <ComponentButtonBase
              disabled={spreadsheetIsLoading}
              isLoading={spreadsheetIsLoading}
              onClick={handleGenerateSpreadsheet}
              title="Gerar Planilha"
            >
              Gerar Planilha
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={pdfIsLoading}
              isLoading={pdfIsLoading}
              onClick={handleGeneratePdf}
              title="Gerar PDF"
              type="button"
            >
              Gerar PDF
            </ComponentButtonBase>
          </FileButtons>
          <ComponentButtonBase
            onClick={() => handleListType(EListType.Customers)}
          >
            Clientes
          </ComponentButtonBase>
        </FilterContainerAccesses>

        <Tables.Items hasBorder marginTop=".5rem">
          <ComponentIsVisible when={!customersAccessLoading}>
            <ComponentIsVisible when={!!customersAccess.length && !isError}>
              <CustomerAccessItem.Header />

              {customersAccess.map((customerAccess, position) => (
                <CustomerAccessItem.Body
                  customerAccess={customerAccess}
                  key={customerAccess.id}
                  position={position}
                />
              ))}
            </ComponentIsVisible>

            <ComponentEmpty
              message="Não há acessos para serem exibidos"
              show={!customersAccess.length && !isError}
            />

            <ComponentIsVisible when={isError}>
              <ComponentError
                message="Não foi possível carregar os acessos"
                onClick={loadCustomersAccess}
              />
            </ComponentIsVisible>
          </ComponentIsVisible>
          <ComponentIsVisible when={customersAccessLoading}>
            <ComponentLoadingList show={customersAccessLoading} />
          </ComponentIsVisible>
        </Tables.Items>
        <ComponentPaginate
          currentPage={customerAccessPage}
          onPage={handleCustomerAccessPagination}
          show={!!customers.length}
          totalPages={customerAccessTotalPages}
        />
      </ComponentIsVisible>
    </Containers.Global>
  );
};

export default Customers;
