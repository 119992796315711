import { Draft } from '@reduxjs/toolkit';

import { ICustomerAccessInitialData } from '../..';

function spreadsheetCustomerAccessSucess(
  draft: Draft<ICustomerAccessInitialData>,
): void {
  draft.spreadsheet.config.isLoading = false;
}

export default spreadsheetCustomerAccessSucess;
