import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface INonPositiveSpreadsheetRequestAction {
  data: {
    dateEnd?: string;
    dateStart?: string;
    state?: string;
  };
}

function nonPositiveSpreadsheetRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<INonPositiveSpreadsheetRequestAction>,
) {
  draft.nonPositiveSpreadsheet.config.isLoading = true;
}

export default nonPositiveSpreadsheetRequest;
