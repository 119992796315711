import { useCallback, useEffect, useMemo } from 'react';

import { ClipLoader } from 'react-spinners';

import formatters from 'formatters';
import { dashboardActions } from 'store/slices/dashboard';
import dashboardSelectors from 'store/slices/dashboard/selectors';
import { VictoryPie } from 'victory';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  Container,
  ContainerItems,
  ContainerLoading,
  ContainerStatus,
  Content,
  Dashboard,
  DashboardContainer,
  Items,
  LinePercentage,
  List,
  MainContainer,
  Status,
  TextStatus,
  Title,
  TotalNumber,
  TotalPercentage,
} from './styles';

const Graphics = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();

  const customerActive = useReduxSelector(dashboardSelectors.customerActive);
  const customerInactive = useReduxSelector(
    dashboardSelectors.customerInactive,
  );
  const customerDefaulter = useReduxSelector(
    dashboardSelectors.customerDefaulter,
  );
  const customerNonPositive = useReduxSelector(
    dashboardSelectors.customerNonPositive,
  );
  const orderClosing = useReduxSelector(dashboardSelectors.orderClosing);
  const orderBilling = useReduxSelector(dashboardSelectors.orderBilling);
  const orderOpening = useReduxSelector(dashboardSelectors.orderOpening);
  const orderProcessing = useReduxSelector(dashboardSelectors.orderProcessing);
  const orderCancellation = useReduxSelector(
    dashboardSelectors.orderCancellation,
  );
  const customerIsLoading = useReduxSelector(
    dashboardSelectors.customerIsLoading,
  );
  const orderIsLoading = useReduxSelector(dashboardSelectors.orderIsLoading);

  const handleCustomerGraphic = useCallback(() => {
    reduxDispatch(dashboardActions.customerRequest());
  }, [reduxDispatch]);

  const handleOrderGraphic = useCallback(() => {
    reduxDispatch(dashboardActions.orderRequest());
  }, [reduxDispatch]);

  const totalAmountCustomer = useMemo(() => {
    return (
      customerActive +
      customerInactive +
      customerDefaulter +
      customerNonPositive
    );
  }, [
    customerActive,
    customerDefaulter,
    customerInactive,
    customerNonPositive,
  ]);

  const totalAmountOrder = useMemo(() => {
    return (
      orderBilling +
      orderCancellation +
      orderClosing +
      orderOpening +
      orderProcessing
    );
  }, [
    orderBilling,
    orderCancellation,
    orderClosing,
    orderOpening,
    orderProcessing,
  ]);

  const customerActivePercent = useMemo(() => {
    return formatters.percent(customerActive, totalAmountCustomer);
  }, [customerActive, totalAmountCustomer]);

  const customerInactivePercent = useMemo(() => {
    return formatters.percent(customerInactive, totalAmountCustomer);
  }, [customerInactive, totalAmountCustomer]);

  const customerDefaulterPercent = useMemo(() => {
    return formatters.percent(customerDefaulter, totalAmountCustomer);
  }, [customerDefaulter, totalAmountCustomer]);

  const customerNonPositivePercent = useMemo(() => {
    return formatters.percent(customerNonPositive, totalAmountCustomer);
  }, [customerNonPositive, totalAmountCustomer]);

  const orderOpeningPercent = useMemo(() => {
    return formatters.percent(orderOpening, totalAmountOrder);
  }, [orderOpening, totalAmountOrder]);

  const orderClosingPercent = useMemo(() => {
    return formatters.percent(orderClosing, totalAmountOrder);
  }, [orderClosing, totalAmountOrder]);

  const orderProcessingPercent = useMemo(() => {
    return formatters.percent(orderProcessing, totalAmountOrder);
  }, [orderProcessing, totalAmountOrder]);

  const orderBillingPercent = useMemo(() => {
    return formatters.percent(orderBilling, totalAmountOrder);
  }, [orderBilling, totalAmountOrder]);

  const orderCancellationPercent = useMemo(() => {
    return formatters.percent(orderCancellation, totalAmountOrder);
  }, [orderCancellation, totalAmountOrder]);

  useEffect(() => {
    handleCustomerGraphic();
  }, [handleCustomerGraphic]);

  useEffect(() => {
    handleOrderGraphic();
  }, [handleOrderGraphic]);

  return (
    <Content>
      <MainContainer>
        <Title>Clientes</Title>
        <ComponentIsVisible when={customerIsLoading}>
          <ContainerLoading>
            <ClipLoader color={colors.blue} size={70} />
          </ContainerLoading>
        </ComponentIsVisible>

        <ComponentIsVisible when={!customerIsLoading}>
          <Container>
            <List>
              <ContainerStatus>
                <Status statusColor={colors.green} />
                <ContainerItems>
                  <TextStatus>Ativo</TextStatus>
                  <Items>
                    <TotalNumber>{customerActive}</TotalNumber>
                    <LinePercentage />
                    <TotalPercentage>{customerActivePercent}</TotalPercentage>
                  </Items>
                </ContainerItems>
              </ContainerStatus>

              <ContainerStatus>
                <Status statusColor={colors.red} />
                <ContainerItems>
                  <TextStatus>Inativo</TextStatus>
                  <Items>
                    <TotalNumber>{customerInactive}</TotalNumber>
                    <LinePercentage />
                    <TotalPercentage>{customerInactivePercent}</TotalPercentage>
                  </Items>
                </ContainerItems>
              </ContainerStatus>

              <ContainerStatus>
                <Status statusColor={colors.blue} />
                <ContainerItems>
                  <ContainerItems>
                    <TextStatus>Inadimplentes</TextStatus>
                    <Items>
                      <TotalNumber>{customerDefaulter}</TotalNumber>
                      <LinePercentage />
                      <TotalPercentage>
                        {customerDefaulterPercent}
                      </TotalPercentage>
                    </Items>
                  </ContainerItems>
                </ContainerItems>
              </ContainerStatus>

              <ContainerStatus>
                <Status statusColor={colors.orange} />
                <ContainerItems>
                  <ContainerItems>
                    <TextStatus>Não positivados</TextStatus>
                    <Items>
                      <TotalNumber>{customerNonPositive}</TotalNumber>
                      <LinePercentage />
                      <TotalPercentage>
                        {customerNonPositivePercent}
                      </TotalPercentage>
                    </Items>
                  </ContainerItems>
                </ContainerItems>
              </ContainerStatus>
            </List>
            <Dashboard>
              <DashboardContainer>
                <svg viewBox="50 50 200 200">
                  <VictoryPie
                    colorScale={[
                      colors.blue,
                      colors.yellow,
                      colors.green,
                      colors.red,
                    ]}
                    data={[
                      { x: 1, y: customerDefaulter },
                      { x: 2, y: customerNonPositive },
                      { x: 3, y: customerActive },
                      { x: 4, y: customerInactive },
                    ]}
                    height={300}
                    innerRadius={40}
                    padAngle={() => 1}
                    standalone={false}
                    style={{
                      labels: {
                        display: 'none',
                      },
                    }}
                    width={300}
                  />
                </svg>
              </DashboardContainer>
            </Dashboard>
          </Container>
        </ComponentIsVisible>
      </MainContainer>

      <MainContainer>
        <Title>Pedidos</Title>
        <ComponentIsVisible when={orderIsLoading}>
          <ContainerLoading>
            <ClipLoader color={colors.blue} size={70} />
          </ContainerLoading>
        </ComponentIsVisible>

        <ComponentIsVisible when={!orderIsLoading}>
          <Container>
            <List>
              <ContainerStatus>
                <Status statusColor={colors.yellow} />
                <ContainerItems>
                  <TextStatus>Em aberto</TextStatus>
                  <Items>
                    <TotalNumber>{orderOpening}</TotalNumber>
                    <LinePercentage />
                    <TotalPercentage>{orderOpeningPercent}</TotalPercentage>
                  </Items>
                </ContainerItems>
              </ContainerStatus>

              <ContainerStatus>
                <Status statusColor={colors.label} />
                <ContainerItems>
                  <TextStatus>Fechado</TextStatus>
                  <Items>
                    <TotalNumber>{orderClosing}</TotalNumber>
                    <LinePercentage />
                    <TotalPercentage>{orderClosingPercent}</TotalPercentage>
                  </Items>
                </ContainerItems>
              </ContainerStatus>

              <ContainerStatus>
                <Status statusColor={colors.blue} />
                <ContainerItems>
                  <TextStatus>Processando</TextStatus>
                  <Items>
                    <TotalNumber>{orderProcessing}</TotalNumber>
                    <LinePercentage />
                    <TotalPercentage>{orderProcessingPercent}</TotalPercentage>
                  </Items>
                </ContainerItems>
              </ContainerStatus>

              <ContainerStatus>
                <Status statusColor={colors.green} />
                <ContainerItems>
                  <TextStatus>Faturado</TextStatus>
                  <Items>
                    <TotalNumber>{orderBilling}</TotalNumber>
                    <LinePercentage />
                    <TotalPercentage>{orderBillingPercent}</TotalPercentage>
                  </Items>
                </ContainerItems>
              </ContainerStatus>

              <ContainerStatus>
                <Status statusColor={colors.red} />
                <ContainerItems>
                  <TextStatus>Cancelado</TextStatus>
                  <Items>
                    <TotalNumber>{orderCancellation}</TotalNumber>
                    <LinePercentage />
                    <TotalPercentage>
                      {orderCancellationPercent}
                    </TotalPercentage>
                  </Items>
                </ContainerItems>
              </ContainerStatus>
            </List>
            <Dashboard>
              <DashboardContainer>
                <svg viewBox="50 50 200 200">
                  <VictoryPie
                    colorScale={[
                      colors.blue,
                      colors.yellow,
                      colors.green,
                      colors.red,
                      colors.label,
                    ]}
                    data={[
                      { x: 1, y: orderProcessing },
                      { x: 2, y: orderOpening },
                      { x: 3, y: orderBilling },
                      { x: 4, y: orderCancellation },
                      { x: 5, y: orderClosing },
                    ]}
                    height={300}
                    innerRadius={40}
                    labelRadius={() => 95}
                    padAngle={() => 1}
                    standalone={false}
                    style={{
                      labels: {
                        display: 'none',
                      },
                    }}
                    width={300}
                  />
                </svg>
              </DashboardContainer>
            </Dashboard>
          </Container>
        </ComponentIsVisible>
      </MainContainer>
    </Content>
  );
};

export default Graphics;
