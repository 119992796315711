import Tables from 'styles/tables';

import { ManufacturerProductItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <ManufacturerProductItemsHeader>
      <Tables.ItemHeader>Código</Tables.ItemHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>SKU</Tables.ItemHeader>
      <Tables.ItemHeader>Cód. barras</Tables.ItemHeader>
      <Tables.ItemHeader>Tipo</Tables.ItemHeader>
      <Tables.ItemHeader center>Em estoque</Tables.ItemHeader>
    </ManufacturerProductItemsHeader>
  );
};

export default ItemHeader;
