import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import ECustomerSituation from 'enums/customersSituation';
import { Formik, FormikProps } from 'formik';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';

import customerSituations from 'constants/customerSituations';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentInputCnpjOrCpf from 'components/input/CnpjOrCpf';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';

import { CheckboxContent, FormikForm } from './styles';

export interface ICustomerFilterFormData {
  document: string;
  name: string;
  situation: IComponentSelectProps<ECustomerSituation> | null;
}

interface ICustomersFilterProps {
  isLoading: boolean;
  loadCustomers: (filter?: ICustomerFilterFormData | undefined) => void;
  loadOnlyCustomersConnected: () => void;
  onClear: () => void;
  onSubmit: (data: ICustomerFilterFormData) => void;
  onlyConnected: boolean;
  setOnlyConnected: (value: boolean) => void;
}

const CustomersFilter: React.ForwardRefRenderFunction<
  FormikProps<ICustomerFilterFormData>,
  ICustomersFilterProps
> = (
  {
    isLoading,
    loadCustomers,
    loadOnlyCustomersConnected,
    onClear,
    onlyConnected,
    onSubmit,
    setOnlyConnected,
  },
  ref,
) => {
  const filterFormRef = useRef<FormikProps<ICustomerFilterFormData>>(
    {} as FormikProps<ICustomerFilterFormData>,
  );

  useImperativeHandle(ref, () => ({
    ...filterFormRef.current,
  }));

  const handleSelectChange = useCallback((option: IOptionTypeBase | null) => {
    filterFormRef.current?.setFieldValue(
      'situation',
      option ?? {
        label: '',
        value: null,
      },
    );
  }, []);

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.checked) {
        loadCustomers();
        setOnlyConnected(event.target.checked);
      }
      if (event.target.checked) {
        loadOnlyCustomersConnected();
        setOnlyConnected(event.target.checked);
        filterFormRef.current.resetForm();
      }
    },
    [loadCustomers, loadOnlyCustomersConnected, setOnlyConnected],
  );

  return (
    <Formik
      initialValues={{
        document: '',
        name: '',
        situation: null,
      }}
      innerRef={filterFormRef}
      onSubmit={onSubmit}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <ComponentInputSimple
            backgroundColor={colors.white}
            disabled={onlyConnected}
            name="name"
            onChange={handleChange('name')}
            placeholder="Pesquisar por nome"
            value={values.name}
          />
          <ComponentInputCnpjOrCpf
            backgroundColor={colors.white}
            disabled={onlyConnected}
            name="document"
            onChange={handleChange('document')}
            placeholder="Pesquisar por CPF/CNPJ"
            value={values.document}
          />
          <ComponentSelectSimple
            backgroundColor={colors.white}
            isClearable
            isDisabled={onlyConnected}
            name="situation"
            onChange={handleSelectChange}
            options={customerSituations}
            placeholder="Selecione um status"
            value={values.situation}
          />
          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={isLoading || onlyConnected}
            onClick={onClear}
            type="button"
          >
            <FiX size={18} />
          </ComponentButtonBase>
          <ComponentButtonBase
            disabled={isLoading || onlyConnected}
            type="submit"
          >
            <FiSearch size={18} />
          </ComponentButtonBase>
          <CheckboxContent>
            <ComponentInputCheckbox
              isChecked={onlyConnected}
              label="Somente usuários conectados"
              name="isEnable"
              onChange={handleChangeCheckbox}
            />
          </CheckboxContent>
        </FormikForm>
      )}
    </Formik>
  );
};

export default forwardRef(CustomersFilter);
