import styled, { css } from 'styled-components';

import colors from 'styles/colors';

interface IContainerProps {
  isDragging: boolean;
}
export const Container = styled.div<IContainerProps>`
  width: fit-content;
  ${props =>
    props.isDragging &&
    css`
      height: 10rem !important;
      opacity: 1 !important;
    `};
`;

interface IThumbnailProps {
  isDragging: boolean;
}
export const Thumbnail = styled.div<IThumbnailProps>`
  height: 10rem;
  position: relative;
  transition: all 0.1s ease-in-out;
  width: 10rem;
  @media screen and (min-width: 1600px) {
    height: 10rem;
  }
  ${props =>
    props.isDragging &&
    css`
      transform: scale(1.1) !important;
      transform: rotateZ(8deg) !important;
    `};
`;

export const Image = styled.img`
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
`;

export const Actions = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
  margin-top: 1rem;
  max-width: 10rem;
`;

interface IActionProps {
  remove?: boolean;
}

export const Action = styled.button<IActionProps>`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.primary};
  column-gap: 0.3rem;
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.remove &&
    css`
      color: ${colors.red};
    `};
`;
