import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IRemoveItemRequestAction {
  data: {
    id: number;
    listId: number;
  };
  functions: {
    success: () => void;
  };
}

function removeItemRequest(
  draft: Draft<IListInitialData>,
  _: PayloadAction<IRemoveItemRequestAction>,
) {
  draft.removeItem.config.isLoading = true;
}

export default removeItemRequest;
