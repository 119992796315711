import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

export interface IGetAllProductsRequestAction {
  data: {
    id: string;
    page: number;
    perPage?: number;
  };
}

function getAllProductsRequest(
  draft: Draft<IManufacturerInitialData>,
  action: PayloadAction<IGetAllProductsRequestAction>,
) {
  draft.getAllProducts.list = [];
  draft.getAllProducts.config.isError = false;
  draft.getAllProducts.config.isLoading = true;
  draft.getAllProducts.config.page = action.payload.data.page;
}

export default getAllProductsRequest;
