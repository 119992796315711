import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllCustomersNonPositiveResponse from 'services/httpClient/responses/customerNonPositive/IGetAllCustomersNonPositiveResponse';

import { customerActions } from '..';

import { INonPositiveRequestAction } from '../reducers/nonPositive/request';

function* nonPositive(action: PayloadAction<INonPositiveRequestAction>) {
  try {
    const params = {
      dateStart: action.payload.data.dateStart
        ? action.payload.data.dateStart
        : undefined,
      dateEnd: action.payload.data.dateEnd
        ? action.payload.data.dateEnd
        : undefined,
      state: action.payload.data.state ? action.payload.data.state : undefined,
      page: action.payload.data.page,
    };
    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetAllCustomersNonPositiveResponse> = yield call(
      httpClient.get,
      `admin/customers/non-positive`,
      {
        params,
      },
    );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        customerActions.nonPositiveSuccess({
          data: {
            list: [],
            totalPages: 0,
          },
        }),
      );
      return;
    }
    yield put(
      customerActions.nonPositiveSuccess({
        data: {
          list,
          totalPages,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.nonPositiveFailure());
  }
}

export default nonPositive;
