import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPatchSettingsTermsRequest from 'services/httpClient/request/settings/IPatchSettingsTermsRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { ITermsRequestAction } from '../reducers/terms/request';

import { settingActions } from '..';

function* terms(action: PayloadAction<ITermsRequestAction>) {
  try {
    const body: IPatchSettingsTermsRequest = {
      terms: action.payload.data.terms,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.patch,
      'admin/settings/terms',
      body,
    );
    ToastManager.show({
      title: response.data.message,
      type: 'success',
    });
    action.payload.functions.closeModal();
    yield put(
      settingActions.termsSuccess({ terms: action.payload.data.terms }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.termsFailure());
  }
}

export default terms;
