import { all, takeLatest } from 'redux-saga/effects';

import { manufacturerActions } from '../index';
import coinsPerReal from './coinsPerReal';
import details from './details';
import getAll from './getAll';
import getAllProducts from './getAllProducts';
import select from './select';

const manufacturerSagas = all([
  takeLatest(manufacturerActions.getAllRequest, getAll),
  takeLatest(manufacturerActions.getAllProductsRequest, getAllProducts),
  takeLatest(manufacturerActions.detailsRequest, details),
  takeLatest(manufacturerActions.selectRequest, select),
  takeLatest(manufacturerActions.coinsPerRealRequest, coinsPerReal),
]);

export default manufacturerSagas;
