import { IPaymentMethodsData } from 'store/slices/customer';

import Tables from 'styles/tables';

import { PaymentMethodBody } from '../styles';

interface IProps {
  paymentMethod: IPaymentMethodsData;
  position: number;
}

const ItemBody = ({ paymentMethod, position }: IProps) => {
  return (
    <PaymentMethodBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{paymentMethod.id}</Tables.ItemBody>
      <Tables.ItemBody center>{paymentMethod.sku}</Tables.ItemBody>
      <Tables.ItemBody>
        {paymentMethod.active ? 'Ativo' : 'Inativo'}
      </Tables.ItemBody>
      <Tables.ItemBody>{paymentMethod.name}</Tables.ItemBody>
    </PaymentMethodBody>
  );
};

export default ItemBody;
