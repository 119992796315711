import { useMemo } from 'react';

import { FiEye } from 'react-icons/fi';

import IManufacturer from 'models/IManufacturer';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import { PedcoinButton } from 'pages/Customers/Item/Body/styles';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { IPedcoin } from '../../Pedcoin';
import { ManufacturerItemsBody } from '../styles';

interface IItemBodyProps {
  manufacturer: IManufacturer;
  openDetails: () => void;
  openPedcoin: (data: IPedcoin) => void;
  position: number;
}

const ItemBody = ({
  manufacturer,
  openDetails,
  openPedcoin,
  position,
}: IItemBodyProps): JSX.Element => {
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);
  const coinIconName = useMemo(() => {
    return pedcoins.name.toUpperCase().split('')[0];
  }, [pedcoins.name]);

  return (
    <ManufacturerItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{manufacturer.id}</Tables.ItemBody>
      <Tables.ItemBody center>{manufacturer.sku}</Tables.ItemBody>
      <Tables.ItemBody>{manufacturer.name}</Tables.ItemBody>
      <Tables.ItemBody center>
        <ComponentIsVisible when={manufacturer.active}>
          Ativo
        </ComponentIsVisible>
        <ComponentIsVisible when={!manufacturer.active}>
          Inativo
        </ComponentIsVisible>
      </Tables.ItemBody>
      <Tables.ItemBody center>
        {manufacturer.coinsPerReal === -1
          ? `Não definido`
          : manufacturer.coinsPerRealFormatted}
      </Tables.ItemBody>
      <Tables.ItemBody center>
        <Tables.ItemActions>
          <PedcoinButton
            onClick={() =>
              openPedcoin({
                manufacturerId: manufacturer.id,
                manufacturerName: manufacturer.name,
                amount: manufacturer.coinsPerReal,
              })
            }
            title="Definir pontuação"
            type="button"
          >
            {coinIconName}
          </PedcoinButton>
          <Tables.ItemAction onClick={openDetails} title="Detalhes">
            <FiEye color={colors.blue} size={20} />
          </Tables.ItemAction>
        </Tables.ItemActions>
      </Tables.ItemBody>
    </ManufacturerItemsBody>
  );
};

export default ItemBody;
