import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

export interface IDetailsRequestAction {
  data: {
    id: string;
  };
}

function detailsRequest(
  draft: Draft<IPriceListInitialData>,
  _: PayloadAction<IDetailsRequestAction>,
) {
  draft.details.data = null;
  draft.details.config.isLoading = true;
  draft.details.config.isError = false;
}

export default detailsRequest;
