import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useHotkeys } from 'react-hotkeys-hook';
import { FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCheckbox from 'components/input/Checkbox';

import ComponentModalBase, { IComponentModalBaseRefProps } from '../Base';
import {
  CloseButton,
  Content,
  ContentActions,
  Subtitle,
  Title,
} from '../styles';
import {
  Bold,
  Form,
  Note,
  Notes,
  Version,
  VersionContainer,
  Versions,
} from './styles';

export interface IComponentUpdateNotesRefProps {
  open: () => void;
}

interface IToggleShowUpdateNotesFormData {
  status: boolean;
}

const ComponentUpdateNotes: React.ForwardRefRenderFunction<
  IComponentUpdateNotesRefProps
> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();

  const [hideModal, setHideModal] = useState<boolean>(false);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const formRef = useRef<FormikProps<IToggleShowUpdateNotesFormData>>(null);

  const hideUpdateNotes = useReduxSelector(authSelectors.hideUpdateNotes);

  const openModal = useCallback(() => {
    setHideModal(false);
    componentModalBaseRef.current?.open();
  }, []);

  const handleCloseModal = useCallback(() => {
    formRef.current?.submitForm();
    setHideModal(true);
    componentModalBaseRef.current?.close();
  }, []);

  const handleToggleShowUpdateNotes = useCallback(
    (data: IToggleShowUpdateNotesFormData) => {
      reduxDispatch(
        authActions.setHideUpdateNotes({
          data: {
            status: data.status,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChange = useCallback(() => {
    formRef.current?.setFieldValue('status', !formRef.current?.values.status);
  }, []);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  useHotkeys('esc', () => handleCloseModal());

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content className={hideModal ? 'hideModal' : ''}>
        <CloseButton onClick={handleCloseModal} type="button">
          <FiX size={20} />
        </CloseButton>
        <Title>Notas de atualização</Title>
        <Subtitle>
          Veja abaixo as novidades, mudanças e correções desta versão
        </Subtitle>

        <Versions>
          <VersionContainer>
            <Version>Versão 2.0.0</Version>

            <Bold>Novidades</Bold>
            <Notes>
              <Note>Dashboard;</Note>
            </Notes>

            <Notes>
              <Note>
                Gerenciamento de ponto gerados por produto e fabricantes;
              </Note>
            </Notes>

            <Notes>
              <Note>Correções e melhorias de performance;</Note>
            </Notes>
          </VersionContainer>
        </Versions>

        <Formik
          initialValues={{ status: hideUpdateNotes }}
          innerRef={formRef}
          onSubmit={handleToggleShowUpdateNotes}
        >
          {({ values }) => (
            <Form>
              <ComponentInputCheckbox
                isChecked={values.status}
                label="Não mostrar novamente"
                name="status"
                onChange={handleOnChange}
              />
            </Form>
          )}
        </Formik>

        <ContentActions width={50}>
          <ComponentButtonBase onClick={handleCloseModal}>
            Ok, entendi
          </ComponentButtonBase>
        </ContentActions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ComponentUpdateNotes);
