import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IFiltersSuccessAction {
  showHasStock: boolean;
  showKit: boolean;
  showRedeem: boolean;
}

function filtersSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IFiltersSuccessAction>,
) {
  draft.filters.config.isLoading = false;
  draft.data.filters = {
    hasStock: action.payload.showHasStock,
    kit: action.payload.showKit,
    redeem: action.payload.showRedeem,
  };
}

export default filtersSuccess;
