import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllCustomerDrugsResponse from 'services/httpClient/responses/customer/IGetAllCustomerDrugsResponse';

import { customerActions } from '..';

import { IDrugsRequestAction } from '../reducers/drugs/request';

function* drugs(action: PayloadAction<IDrugsRequestAction>) {
  try {
    const { data, status }: AxiosResponse<IGetAllCustomerDrugsResponse> =
      yield call(
        httpClient.get,
        `admin/customers/${action.payload.data.customerId}/drug-list`,
      );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(customerActions.drugsSuccess({ list: [] }));
      return;
    }
    yield put(customerActions.drugsSuccess({ list: data.list }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.drugsFailure());
  }
}

export default drugs;
