import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EUploadImageType from 'enums/uploadImageType';

import { IImageInitialData } from '../../index';

export interface IUploadSuccessAction {
  uploadType: EUploadImageType;
  url: string;
}

function uploadSuccess(
  draft: Draft<IImageInitialData>,
  action: PayloadAction<IUploadSuccessAction>,
) {
  draft.config.isLoading = false;
  if (action.payload.uploadType === EUploadImageType.logo) {
    draft.url = action.payload.url;
  }
  if (action.payload.uploadType === EUploadImageType.favicon) {
    draft.faviconUrl = action.payload.url;
  }
}

export default uploadSuccess;
