import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Main = styled.div`
  height: 10rem;
`;

interface IItemProps {
  isFocused: boolean;
}
export const Item = styled.div<IItemProps>`
  align-items: center;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  transition: background-color 0.5s ease;
  width: 20%;
  ${props =>
    props.isFocused &&
    css`
      background-color: ${colors.primary};
      transition: background-color 1s ease;
    `}
`;

interface IItemTextProps {
  isFocused: boolean;
}
export const ItemText = styled.p<IItemTextProps>`
  font-size: 1.2rem;
  margin: 0;
  transition: color 0.5s ease;
  ${props =>
    props.isFocused &&
    css`
      color: ${colors.white};
      transition: color 0.5s ease;
    `}
`;

export const Card = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.primary};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 0 1rem;
`;

export const ItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const BodyBilling = styled.div`
  color: ${colors.primary};
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 2.5rem;
  padding: 1rem;
`;

export const ContainerLoading = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 3rem;
  width: 100%;
`;
