import styled from 'styled-components';

import { Form } from 'formik';

import colors from 'styles/colors';

export const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.5rem;
`;

export const CompanySocialName = styled.h2`
  color: ${colors.orange};
  display: grid;
  font-size: 1.15rem;
  font-weight: 600;
  text-align: right;
`;

export const Small = styled.small`
  color: ${colors.title};
  font-size: 0.875rem;
  font-weight: 400;
  text-align: right;
`;

export const TitleContainer = styled.div``;

export const UpdatePasswordButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.primary};
  font-size: 0.875rem;
  letter-spacing: 1px;
  position: absolute;
  right: 0;
  text-decoration: underline;
  top: 0;

  &:hover {
    opacity: 0.75;
  }
`;

export const FormikForm = styled(Form)``;

export const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1.5rem;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CardTitle = styled.h4`
  align-items: center;
  color: ${colors.primary};
  column-gap: 1rem;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

export const CardSubtitle = styled.p`
  color: ${colors.subtitle};
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

export const Options = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Option = styled.div`
  column-gap: 1rem;
  display: flex;
  width: 100%;
`;

export const ColorPicker = styled.div`
  max-width: 75%;
`;

export const UnformActions = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 1rem;
  max-width: 10rem;
  width: 100%;
`;
