import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IDrugsRequestAction {
  data: {
    customerId: number;
  };
}

function drugsRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<IDrugsRequestAction>,
) {
  draft.drugs.config.isLoading = true;
  draft.drugs.config.isError = false;
}

export default drugsRequest;
