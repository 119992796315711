import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductInitialData } from '../../index';

export interface IGetAllRequestAction {
  data: {
    barcode?: string;
    id?: string;
    manufacturer?: string;
    name?: string;
    page: number;
    perPage?: number;
    sku?: string;
    status?: string;
  };
}

function getAllRequest(
  draft: Draft<IProductInitialData>,
  action: PayloadAction<IGetAllRequestAction>,
) {
  draft.details.data = null;
  draft.getAll.data = [];
  draft.getAll.config.isLoading = true;
  draft.getAll.config.isError = false;
  draft.getAll.config.page = action.payload.data.page;
}

export default getAllRequest;
