import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IManufacturerInitialData } from '../../index';

export interface IGetAllSuccessAction {
  data: {
    list: {
      active: boolean;
      coinsPerReal: number;
      id: number;
      name: string;
      sku: string;
    }[];
    totalPages: number;
  };
}

function getAllSuccess(
  draft: Draft<IManufacturerInitialData>,
  action: PayloadAction<IGetAllSuccessAction>,
) {
  draft.getAll.config.isLoading = false;
  draft.getAll.config.totalPages = action.payload.data.totalPages;
  draft.getAll.list = action.payload.data.list.map(item => ({
    coinsPerRealFormatted: formatters.money(
      Number(item.coinsPerReal.toFixed(2)),
    ),
    active: item.active,
    coinsPerReal: item.coinsPerReal,
    id: item.id,
    name: item.name,
    sku: item.sku,
  }));
}

export default getAllSuccess;
