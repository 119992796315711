import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { adminActions } from 'store/slices/admin';
import adminSelectors from 'store/slices/admin/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import AdminForm, { IAdminFormData } from '../Form';

export interface IAdminCreateRefProps {
  close: () => void;
  open: () => void;
}

const schema = Yup.object().shape({
  name: Yup.string()
    .max(100, "Tamanho máximo do campo 'Nome' é de 100 caracteres")
    .required("O campo 'Nome' é obrigatório"),
  password: Yup.string()
    .min(6, "Tamanho mínimo do campo 'Senha' deve ser 6 caracteres")
    .required("O campo 'Senha' é obrigatório"),
  email: Yup.string()
    .email('Digite um e-mail válido')
    .required("O campo 'E-mail' é obrigatório")
    .max(100, "Tamanho máximo do campo 'E-mail' deve ser 100 caracteres"),
});

const AdminCreate: React.ForwardRefRenderFunction<IAdminCreateRefProps> = (
  _,
  ref,
) => {
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(adminSelectors.createIsLoading);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const openModal = useCallback(() => {
    componentModalBaseRef.current?.open();
  }, []);
  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleCreate = useCallback(
    (data: IAdminFormData) => {
      reduxDispatch(
        adminActions.createRequest({
          data: {
            email: data.email,
            name: data.name,
            password: data.password,
          },
          functions: {
            success() {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content maxWidth="25">
        <Title>Novo administrador</Title>
        <AdminForm
          close={closeModal}
          initialValues={{
            email: '',
            name: '',
            password: '',
          }}
          isLoading={isLoading}
          onSubmit={handleCreate}
          validationSchema={schema}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(AdminCreate);
