import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';
import { EProductDrugType } from 'models/IProduct';

import { IProductInitialData } from '../../index';

interface IProductPriceList {
  amountDiscount: number;
  amountFee: number;
  amountFinal: number;
  amountGross: number;
  amountNet: number;
  expirationDate: string;
  id: number;
  name: string;
  quantityMax: string | number;
  quantityMin: string | number;
  sku: string;
}

interface IProductPriceScales {
  amountDiscount: number;
  amountFee: number;
  amountFinal: number;
  amountGross: number;
  amountNet: number;
  id: number;
  quantity: number;
  sku: string;
}

export interface IDetailsSuccessAction {
  data: {
    active: boolean;
    barcode: string | null;
    coinsPerReal: number;
    drugActiveIngredient: string | null;
    drugControlled: boolean;
    drugIsFp: boolean;
    drugPackingBox: number | null;
    drugRegistrationNumberMs: string | null;
    drugType: EProductDrugType | null;
    id: number;
    manufacturer: string;
    minimumStock: number | null;
    name: string;
    priceList: IProductPriceList[];
    productPriceScales: IProductPriceScales[];
    sku: string;
    stock: number | null;
  };
}

function detailsSuccess(
  draft: Draft<IProductInitialData>,
  action: PayloadAction<IDetailsSuccessAction>,
) {
  draft.details.data = null;
  draft.details.config.isLoading = false;
  draft.details.data = {
    minimumStock: action.payload.data.minimumStock,
    coinsPerRealFormatted: formatters.money(
      Number(action.payload.data.coinsPerReal.toFixed(2)),
    ),
    priceList: action.payload.data.priceList.map(priceList => ({
      amountGross: formatters.money(priceList.amountGross),
      amountFinal: formatters.money(priceList.amountFinal),
      amountNet: formatters.money(priceList.amountNet),
      amountFee: formatters.money(priceList.amountFee),
      amountDiscount: `${formatters.money(priceList.amountDiscount)}%`,
      id: priceList.id,
      expirationDate: priceList.expirationDate,
      quantityMin: priceList.quantityMin,
      quantityMax: priceList.quantityMax,
      name: priceList.name,
      sku: priceList.sku,
    })),
    productPriceScales: action.payload.data.productPriceScales.map(
      priceScale => ({
        amountGross: formatters.money(priceScale.amountGross),
        amountFinal: formatters.money(priceScale.amountFinal),
        amountNet: formatters.money(priceScale.amountNet),
        amountFee: formatters.money(priceScale.amountFee),
        amountDiscount: `${formatters.money(priceScale.amountDiscount)}%`,
        id: priceScale.id,
        quantity: priceScale.quantity,
        sku: priceScale.sku,
      }),
    ),
    stock: action.payload.data.stock
      ? action.payload.data.stock
      : 'NÃO INFORMADO',
    barcode: action.payload.data.barcode
      ? action.payload.data.barcode
      : 'NÃO INFORMADO',
    drugActiveIngredient: action.payload.data.drugActiveIngredient
      ? action.payload.data.drugActiveIngredient
      : 'NÃO INFORMADO',
    drugPackingBox: action.payload.data.drugPackingBox
      ? action.payload.data.drugPackingBox
      : 'NÃO INFORMADO',
    drugRegistrationNumberMs: action.payload.data.drugRegistrationNumberMs
      ? action.payload.data.drugRegistrationNumberMs
      : 'NÃO INFORMADO',
    drugType: action.payload.data.drugType
      ? action.payload.data.drugType
      : 'NÃO INFORMADO',
    drugControlled: action.payload.data.drugControlled ? 'SIM' : 'NÃO',
    drugIsFp: action.payload.data.drugIsFp ? 'SIM' : 'NÃO',
    active: action.payload.data.active,
    coinsPerReal: action.payload.data.coinsPerReal,
    id: action.payload.data.id,
    manufacturer: action.payload.data.manufacturer,
    name: action.payload.data.name,
    sku: action.payload.data.sku,
  };
}

export default detailsSuccess;
