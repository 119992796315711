import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import ICustomer from 'models/ICustomer';
import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import {
  ClientName,
  CloseButton,
  Content,
  FormActions,
  FormikForm,
  Label,
  Subtitle,
  Title,
} from './styles';

interface ICustomerChangePasswordFormData {
  confirmNewPassword: string;
  newPassword: string;
}

export interface ICustomerChangePasswordRefProps {
  open: (customer: ICustomer) => void;
}

const customerChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string().required('Informe a nova senha'),
  confirmNewPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Senhas são diferentes')
    .required('Informe a confirmação da nova senha'),
});

const CustomerChangePassword: React.ForwardRefRenderFunction<
  ICustomerChangePasswordRefProps
> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();
  const [customer, setCustomer] = useState<ICustomer | undefined>(undefined);
  const changePasswordFormRef =
    useRef<FormikProps<ICustomerChangePasswordFormData>>(null);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const changePasswordLoading = useReduxSelector(
    customerSelectors.changePasswordIsLoading,
  );

  const openModal = useCallback((data: ICustomer) => {
    setCustomer(data);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleChangePassword = useCallback(
    (data: ICustomerChangePasswordFormData) => {
      if (!customer) {
        return;
      }
      reduxDispatch(
        customerActions.changePasswordRequest({
          data: {
            customerId: customer.id,
            newPassword: data.newPassword,
          },
          functions: {
            success() {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, customer, reduxDispatch],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Alteração de senha</Title>
        <Subtitle>
          Preencha os campos abaixo para alterar a senha deste cliente
        </Subtitle>

        <CloseButton onClick={closeModal}>
          <FiX size={24} />
        </CloseButton>

        <ClientName>
          <Label>Cliente</Label>
          {customer?.name}
        </ClientName>

        <Formik
          initialValues={{
            newPassword: '',
            confirmNewPassword: '',
          }}
          innerRef={changePasswordFormRef}
          onSubmit={handleChangePassword}
          validationSchema={customerChangePasswordSchema}
        >
          {({ errors, handleChange, values }) => (
            <FormikForm>
              <ComponentInputPassword
                errorMessage={errors.newPassword}
                hasError={!!errors.newPassword}
                label="Nova senha"
                name="newPassword"
                onChange={handleChange('newPassword')}
                value={values.newPassword}
              />

              <ComponentInputPassword
                errorMessage={errors.confirmNewPassword}
                hasError={!!errors.confirmNewPassword}
                label="Confirmar nova senha"
                name="confirmNewPassword"
                onChange={handleChange('confirmNewPassword')}
                value={values.confirmNewPassword}
              />

              <FormActions>
                <ComponentButtonBase
                  disabled={changePasswordLoading}
                  isLoading={changePasswordLoading}
                  type="submit"
                >
                  Confirmar
                </ComponentButtonBase>
              </FormActions>
            </FormikForm>
          )}
        </Formik>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(CustomerChangePassword);
