import { PayloadAction } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { listActions } from '..';

import { IUpdateItemPositionRequestAction } from '../reducers/updateItemPosition/request';

function* updateItemPosition(
  action: PayloadAction<IUpdateItemPositionRequestAction>,
) {
  try {
    const body = {
      ids: action.payload.data.ids,
    };
    yield call(
      httpClient.put,
      `admin/settings/lists/${action.payload.data.listId}/items/positions`,
      body,
    );
    yield put(
      listActions.updateItemPositionSuccess({ data: action.payload.data }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(listActions.updateItemPositionFailure());
  }
}

export default updateItemPosition;
