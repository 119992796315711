import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IDashboardInitialData } from '../../index';

export interface ICustomerRequestAction {
  data: {
    active: number;
    defaulter: number;
    inactive: number;
    nonPositive: number;
  };
}

function customerSuccess(
  draft: Draft<IDashboardInitialData>,
  action: PayloadAction<ICustomerRequestAction>,
) {
  draft.customer.config.isLoading = false;
  draft.customer.values.active = action.payload.data.active;
  draft.customer.values.defaulter = action.payload.data.defaulter;
  draft.customer.values.inactive = action.payload.data.inactive;
  draft.customer.values.nonPositive = action.payload.data.nonPositive;
}

export default customerSuccess;
