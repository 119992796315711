import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';
import { EPriceListType } from 'models/IPriceList';

import { IPriceListInitialData } from '../../index';

export interface IDetailsSuccessAction {
  data: {
    dateTimeEnd: string;
    dateTimeStart: string;
    id: number;
    name: string;
    sku: string;
    state: string;
    type: EPriceListType;
  };
}

function detailsSuccess(
  draft: Draft<IPriceListInitialData>,
  action: PayloadAction<IDetailsSuccessAction>,
) {
  draft.details.data = {
    dateTimeEnd: formatters.date(action.payload.data.dateTimeEnd, {
      mask: 'dd/MM/yyyy HH:mm',
    }),
    dateTimeStart: formatters.date(action.payload.data.dateTimeStart, {
      mask: 'dd/MM/yyyy HH:mm',
    }),
    id: action.payload.data.id,
    name: action.payload.data.name,
    sku: action.payload.data.sku,
    state: action.payload.data.state
      ? action.payload.data.state
      : 'NÃO INFORMADO',
    type: formatters.priceListType(action.payload.data.type),
  };
  draft.details.config.isLoading = false;
}

export default detailsSuccess;
