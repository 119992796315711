import styled from 'styled-components';

import colors from 'styles/colors';

export const ColorPicker = styled.div`
  align-items: flex-end;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 10rem 8rem 8rem;
  row-gap: 0.5rem;
  width: 100%;
  @media screen and (min-width: 1366px) {
    max-width: 75%;
    grid-template-columns: 10rem 8rem 8rem;
  }
`;

interface IColorFieldProps {
  color?: string;
}

export const ColorField = styled.button<IColorFieldProps>`
  align-items: center;
  background: ${colors.tabBackground};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  position: relative;
  width: 10rem;
  &::after {
    background-color: ${props => (props.color ? props.color : colors.primary)};
    border-radius: 0.25rem;
    content: '';
    height: 1.125rem;
    left: 0.75rem;
    position: absolute;
    width: calc(10rem - 1.5rem);
  }
`;

interface ISketchPickerPopoverProps {
  showPicker?: boolean;
}

export const SketchPickerPopover = styled.div<ISketchPickerPopoverProps>`
  display: ${props => (props.showPicker ? 'block' : 'none')};
  position: absolute;
  z-index: 2;
`;

export const SketchPickerCover = styled.div`
  bottom: 0px;
  left: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
`;
