import { ICustomerAccessStateData } from 'store/slices/customerAccess';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import Tables from 'styles/tables';

import { CustomerItemsBody } from './styles';

interface ICustomerItemBodyProps {
  customerAccess: ICustomerAccessStateData;
  position: number;
}

const CustomerItemBody: React.FC<ICustomerItemBodyProps> = ({
  customerAccess,
  position,
}) => {
  const internalControl = useReduxSelector(settingSelectors.internalControl);
  return (
    <CustomerItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>
        {internalControl.hasPreferenceCompanyName
          ? customerAccess.companyName
          : customerAccess.customerName}
      </Tables.ItemBody>
      <Tables.ItemBody>{customerAccess.document}</Tables.ItemBody>
      <Tables.ItemBody>{customerAccess.dateAccess}</Tables.ItemBody>
    </CustomerItemsBody>
  );
};

export default CustomerItemBody;
