import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllProductRequest from 'services/httpClient/request/product/IGetAllProductRequest';
import IGetAllProductResponse from 'services/httpClient/responses/product/IGetAllProductResponse';

import { productActions } from '..';

import { IGetAllRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllRequestAction>) {
  try {
    const params: IGetAllProductRequest = {
      id: action.payload.data.id ? action.payload.data.id : undefined,
      name: action.payload.data.name ? action.payload.data.name : undefined,
      barcode: action.payload.data.barcode
        ? action.payload.data.barcode
        : undefined,
      page: action.payload.data.page,
      manufacturer: action.payload.data.manufacturer
        ? action.payload.data.manufacturer
        : undefined,
      sku: action.payload.data.sku ? action.payload.data.sku : undefined,
      status: action.payload.data.status
        ? action.payload.data.status
        : undefined,
    };

    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetAllProductResponse> = yield call(
      httpClient.get,
      'admin/products',
      { params },
    );

    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        productActions.getAllSuccess({
          data: {
            list: [],
            totalPages: 0,
          },
        }),
      );
      return;
    }

    yield put(
      productActions.getAllSuccess({
        data: {
          list,
          totalPages,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(productActions.getAllFailure());
  }
}

export default getAll;
