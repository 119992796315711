import { useCallback, useEffect, useRef } from 'react';

import { FiPlus } from 'react-icons/fi';

import IAdmin from 'models/IAdmin';
import { adminActions } from 'store/slices/admin';
import adminSelectors from 'store/slices/admin/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentAlert, {
  IComponentAlertRefProps,
} from 'components/modal/Alert';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import AdminCreate, { IAdminCreateRefProps } from '../Create';
import AdminEdit, { IAdminEditRefProps } from '../Edit';
import AdminItem from './Item';

const AdminList = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();

  const admins = useReduxSelector(adminSelectors.list);
  const listIsError = useReduxSelector(adminSelectors.listIsError);
  const listIsLoading = useReduxSelector(adminSelectors.listIsLoading);
  const pagination = useReduxSelector(adminSelectors.listPagination);
  const removeIsLoading = useReduxSelector(adminSelectors.removeIsLoading);

  const adminCreateRef = useRef<IAdminCreateRefProps>(null);
  const adminEditRef = useRef<IAdminEditRefProps>(null);
  const componentAlertRef = useRef<IComponentAlertRefProps>(null);

  const openCreateAdmin = useCallback(() => {
    adminCreateRef.current?.open();
  }, []);
  const openEditAdmin = useCallback((data: IAdmin) => {
    adminEditRef.current?.open(data);
  }, []);
  const openRemoveAdmin = useCallback((id: number) => {
    componentAlertRef.current?.open({
      id,
    });
  }, []);

  const loadAdmins = useCallback(() => {
    reduxDispatch(
      adminActions.listRequest({
        data: {
          page: 1,
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    (page: number) => {
      reduxDispatch(
        adminActions.listRequest({
          data: {
            page,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleRemove = useCallback(
    (id: number) => {
      reduxDispatch(
        adminActions.removeRequest({
          data: {
            id,
          },
          functions: {
            success() {
              componentAlertRef.current?.close();
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  useEffect(() => {
    loadAdmins();
  }, [loadAdmins]);

  return (
    <>
      <Containers.Global>
        <General.PageTitleContainer>
          <General.PageTitle>Administradores</General.PageTitle>

          <General.PageActions>
            <General.PageAction onClick={openCreateAdmin}>
              Novo administrador
              <FiPlus size={20} />
            </General.PageAction>
          </General.PageActions>
        </General.PageTitleContainer>

        <Tables.Items hasBorder marginTop=".5rem">
          <ComponentIsVisible when={!listIsLoading}>
            <ComponentIsVisible when={!!admins.length && !listIsError}>
              <AdminItem.Header />

              {admins.map((admin, position) => (
                <AdminItem.Body
                  admin={admin}
                  key={admin.id}
                  openEditAdmin={openEditAdmin}
                  openRemoveAdmin={openRemoveAdmin}
                  position={position}
                />
              ))}
            </ComponentIsVisible>

            <ComponentEmpty
              message="Não há administradores para serem exibidos"
              show={!admins.length && !listIsError}
            />

            <ComponentIsVisible when={listIsError}>
              <ComponentError
                message="Não foi possível carregar os administradores"
                onClick={loadAdmins}
              />
            </ComponentIsVisible>
          </ComponentIsVisible>
          <ComponentIsVisible when={listIsLoading}>
            <ComponentLoadingList rows={8} show={listIsLoading} />
          </ComponentIsVisible>
        </Tables.Items>
        <ComponentPaginate
          currentPage={pagination.page}
          onPage={handlePagination}
          show={!!admins.length}
          totalPages={pagination.totalPages}
        />
      </Containers.Global>

      <AdminCreate ref={adminCreateRef} />
      <AdminEdit ref={adminEditRef} />
      <ComponentAlert
        isLoading={removeIsLoading}
        message="Tem certeza que deseja excluir este administrador?"
        onConfirm={handleRemove}
        ref={componentAlertRef}
      />
    </>
  );
};

export default AdminList;
