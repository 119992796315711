import EHttpStatusCodes from 'enums/httpStatusCodes';
import { Store } from 'redux';
import { authActions } from 'store/slices/auth';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from '../index';

const store = (store: Store): void => {
  httpClient.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.response.status === EHttpStatusCodes.UNAUTHORIZED) {
        const {
          auth: {
            authorization: { token: isSigned },
          },
        } = store.getState();
        if (isSigned) {
          ToastManager.show({
            title: 'Sessão expirada',
            type: 'error',
          });
          store.dispatch(authActions.signOutRequest());
        }
      }
      return await Promise.reject(error);
    },
  );
};

export default store;
