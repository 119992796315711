import Tables from 'styles/tables';

import { CustomerItemsHeader } from './styles';

const CustomerAccessItemHeader: React.FC = () => {
  return (
    <CustomerItemsHeader>
      <Tables.ItemHeader>Nome do cliente</Tables.ItemHeader>
      <Tables.ItemHeader>CPF/CNPJ</Tables.ItemHeader>
      <Tables.ItemHeader>Acesso</Tables.ItemHeader>
    </CustomerItemsHeader>
  );
};

export default CustomerAccessItemHeader;
