import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IRemoveRequestAction {
  data: {
    id: number;
  };
  functions: {
    success: () => void;
  };
}

function removeRequest(
  draft: Draft<IListInitialData>,
  _: PayloadAction<IRemoveRequestAction>,
) {
  draft.remove.config.isLoading = true;
}

export default removeRequest;
