import ComponentBackButton from 'components/button/BackButton';

import Containers from 'styles/containers';
import General from 'styles/general';

import CustomerOrdersList from './CustomerOrders';
import DrugsList from './Drugs';
import GeneralData from './GeneralData';
import PaymentMethodsList from './PaymentMethods';
import PriceTablesList from './PriceTables';

const CustomerDetails = () => {
  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle hasBackButton>
          <ComponentBackButton />
          Detalhes do cliente
        </General.PageTitle>
      </General.PageTitleContainer>
      <GeneralData />
      <PaymentMethodsList />
      <CustomerOrdersList />
      <PriceTablesList />
      <DrugsList />
    </Containers.Global>
  );
};

export default CustomerDetails;
