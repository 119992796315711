import styled from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Content = styled.div`
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: ${animations.appear};
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${colors.white};
  border-radius: 0.5rem;
  max-height: 95%;
  max-width: 40rem;
  overflow: visible;
  padding: 1.75rem 2rem;
  width: 100%;
  z-index: 12;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Title = styled.h5`
  color: ${colors.primary};
  font-size: 1.25rem;
  font-weight: 500;
`;

export const Thumbnail = styled.div`
  align-items: center;

  display: flex;
  height: 25rem;
  justify-content: center;
  margin: 1rem auto;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const HelperImage = styled.img`
  height: 100%;
  width: 100%;
`;

export const Actions = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 10rem;
  justify-content: center;
  margin: 1rem auto 0;
`;
