import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EBannersType from 'enums/bannersType';

import { IBannerInitialData } from '../../index';

export interface IUpdateRequestAction {
  data: {
    images: string[];
    thumbnails?: {
      one: string;
      two: string;
    };
    type: EBannersType;
  };
}

function updateRequest(
  draft: Draft<IBannerInitialData>,
  _: PayloadAction<IUpdateRequestAction>,
) {
  draft.config.isLoading = true;
}

export default updateRequest;
