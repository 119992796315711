import { all, takeLatest } from 'redux-saga/effects';

import { priceListActions } from '../index';
import details from './details';
import getAll from './getAll';
import getAllProducts from './getAllProducts';
import select from './select';

const priceListSagas = all([
  takeLatest(priceListActions.detailsRequest, details),
  takeLatest(priceListActions.getAllRequest, getAll),
  takeLatest(priceListActions.getAllProductsRequest, getAllProducts),
  takeLatest(priceListActions.selectRequest, select),
]);

export default priceListSagas;
