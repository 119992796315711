import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface ISocialMediaRequestAction {
  data: {
    facebook: string;
    instagram: string;
    linkedin: string;
    whatsapp: string;
  };
}

function socialMediaRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<ISocialMediaRequestAction>,
) {
  draft.socialMedia.config.isLoading = true;
}

export default socialMediaRequest;
