import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface INonPositivePdfRequestAction {
  data: {
    dateEnd?: string;
    dateStart?: string;
    state?: string;
  };
}

function nonPositivePdfRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<INonPositivePdfRequestAction>,
) {
  draft.nonPositivePdf.config.isLoading = true;
}

export default nonPositivePdfRequest;
