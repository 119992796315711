import getAll from './getAll';
import getAllIsError from './getAllIsError';
import getAllIsLoading from './getAllIsLoading';
import getAllPage from './getAllPage';
import getAllTotalPages from './getAllTotalPages';

const productRequestSelectors = {
  getAll,
  getAllIsError,
  getAllIsLoading,
  getAllPage,
  getAllTotalPages,
};

export default productRequestSelectors;
