import { createSlice } from '@reduxjs/toolkit';
import ICustomer from 'models/ICustomer';
import ICustomerNonPositive from 'models/ICustomerNonPositive';
import { EPriceListType } from 'models/IPriceList';

import reducers from './reducers';

export enum EExtractItemType {
  CANCELLED = 'CANCELADO',
  ENTRY = 'ENTRADA',
  OUTPUT = 'SAIDA',
  RESERVED = 'RESERVADO',
}
export interface ICustomerPedcoinsExtractData {
  date: string;
  description: string;
  id: number;
  quantity: string;
  reason: string;
  type: string;
}

export interface ICustomerPedcoinsRedeemData {
  date: string;
  invoice: string;
  orderId: number;
  pedcoins: number;
}

export interface IPaymentMethodsData {
  active: boolean;
  id: number;
  name: string;
  sku: string;
}

export interface ICustomerOrdersData {
  dateBilling: Date | null;
  dateCancellation: Date | null;
  dateClosing: Date | null;
  dateOpening: Date | null;
  dateProcessing: Date | null;
  id: number;
  paymentMethod: {
    id: number | null;
    name: string | null;
  };
}

export interface IPriceTablesData {
  id: number;
  name: string;
  sku: string;
  type: EPriceListType;
}

export interface ICustomerDetails {
  companyName: string | null;
  creditLimit: number;
  document: string;
  email: string;
  hasAvisa: boolean;
  hasCrf: boolean;
  hasSivisa: boolean;
  id: number;
  name: string;
  network: number | null;
  phone: string | null;
  situation: string;
  sku: string;
  state: string | null;
}

export interface ICustomerDrugData {
  code: string;
  description: string;
}

export interface ICustomerInitialData {
  changePassword: {
    config: {
      isLoading: boolean;
    };
  };
  details: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: ICustomerDetails | null;
  };
  drugs: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: ICustomerDrugData[];
  };
  getAll: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    customersConnected: number[];
    data: ICustomer[];
    onlyConnected: boolean;
  };
  launchPedcoins: {
    config: {
      isLoading: boolean;
    };
  };
  nonPositive: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    data: ICustomerNonPositive[];
  };
  nonPositivePdf: {
    config: {
      isLoading: boolean;
    };
  };
  nonPositiveSpreadsheet: {
    config: {
      isLoading: boolean;
    };
  };
  orders: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: ICustomerOrdersData[];
  };
  paymentMethods: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: IPaymentMethodsData[];
  };
  pedcoins: {
    extract: {
      config: {
        isError: boolean;
        isLoading: boolean;
        page: number;
        totalPages: number;
      };
      data: ICustomerPedcoinsExtractData[];
    };
    redeem: {
      config: {
        isError: boolean;
        isLoading: boolean;
        page: number;
        totalPages: number;
      };
      data: ICustomerPedcoinsRedeemData[];
    };
    summary: {
      available: number;
      config: {
        isLoading: boolean;
      };
      entries: number;
      expiresToday: number;
      outputs: number;
    };
  };
  priceTables: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: IPriceTablesData[];
  };
}

const initialState: ICustomerInitialData = {
  drugs: {
    config: {
      isError: false,
      isLoading: false,
    },
    data: [],
  },
  orders: {
    config: {
      isError: false,
      isLoading: false,
    },
    data: [],
  },
  priceTables: {
    config: {
      isLoading: false,
      isError: false,
    },
    data: [],
  },
  paymentMethods: {
    config: {
      isLoading: false,
      isError: false,
    },
    data: [],
  },
  details: {
    config: {
      isError: false,
      isLoading: false,
    },
    data: null,
  },
  changePassword: {
    config: {
      isLoading: false,
    },
  },
  getAll: {
    config: {
      isError: false,
      page: 1,
      totalPages: 0,
      isLoading: false,
    },
    customersConnected: [],
    data: [],
    onlyConnected: false,
  },
  launchPedcoins: {
    config: {
      isLoading: false,
    },
  },
  nonPositive: {
    config: {
      page: 1,
      totalPages: 0,
      isError: false,
      isLoading: false,
    },
    data: [],
  },
  nonPositivePdf: {
    config: {
      isLoading: false,
    },
  },
  nonPositiveSpreadsheet: {
    config: {
      isLoading: false,
    },
  },
  pedcoins: {
    summary: {
      available: 0,
      config: {
        isLoading: false,
      },
      entries: 0,
      expiresToday: 0,
      outputs: 0,
    },
    extract: {
      config: {
        isError: false,
        page: 1,
        totalPages: 0,
        isLoading: false,
      },
      data: [],
    },
    redeem: {
      config: {
        isError: false,
        page: 1,
        totalPages: 0,
        isLoading: false,
      },
      data: [],
    },
  },
};

const customerSlice = createSlice({
  name: '@customer',
  initialState,
  reducers,
});

export const customerActions = customerSlice.actions;
export const customerReducers = customerSlice.reducer;
