import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { priceListActions } from 'store/slices/priceList';
import priceListSelectors from 'store/slices/priceList/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentBackButton from 'components/button/BackButton';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import PriceListItemDetails from './Item';
import { Content, Info, Label, Subtitle, Value } from './styles';

interface IPriceListDetailsParams extends Record<string, string | undefined> {
  id: string;
}

const PriceListDetails = () => {
  const reduxDispatch = useReduxDispatch();
  const params = useParams<IPriceListDetailsParams>();

  const priceListDetails = useReduxSelector(priceListSelectors.details);
  const isIsLoading = useReduxSelector(priceListSelectors.detailsIsLoading);
  const priceListProducts = useReduxSelector(priceListSelectors.getAllProducts);
  const totalPages = useReduxSelector(
    priceListSelectors.getAllProductsTotalPages,
  );
  const page = useReduxSelector(priceListSelectors.getAllProductsPage);
  const isErrorProducts = useReduxSelector(
    priceListSelectors.getAllProductsIsError,
  );
  const priceListProductIsLoading = useReduxSelector(
    priceListSelectors.getAllProductsIsLoading,
  );

  const loadPriceListDetails = useCallback(() => {
    if (params.id) {
      reduxDispatch(
        priceListActions.detailsRequest({ data: { id: params.id } }),
      );
    }
  }, [reduxDispatch, params.id]);

  const loadPriceListProducts = useCallback(() => {
    if (params.id) {
      reduxDispatch(
        priceListActions.getAllProductsRequest({
          data: {
            page: 1,
            id: params.id,
          },
        }),
      );
    }
  }, [reduxDispatch, params.id]);

  const handlePagination = useCallback(
    (page: number) => {
      if (params.id) {
        reduxDispatch(
          priceListActions.getAllProductsRequest({
            data: {
              page,
              id: params.id,
            },
          }),
        );
      }
    },
    [reduxDispatch, params.id],
  );

  useEffect(() => {
    loadPriceListDetails();
  }, [loadPriceListDetails]);

  useEffect(() => {
    loadPriceListProducts();
  }, [loadPriceListProducts]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle hasBackButton>
          <ComponentBackButton />
          Detalhes da tabela de preço
        </General.PageTitle>
      </General.PageTitleContainer>

      <Subtitle>Dados gerais</Subtitle>
      <Content>
        <Info>
          <Label>Código</Label>
          <Value isLoading={isIsLoading}>{priceListDetails?.id}</Value>
        </Info>
        <Info>
          <Label>Nome</Label>
          <Value isLoading={isIsLoading}>{priceListDetails?.name}</Value>
        </Info>
        <Info>
          <Label>SKU</Label>
          <Value isLoading={isIsLoading}>{priceListDetails?.sku}</Value>
        </Info>
        <Info>
          <Label>Tipo</Label>
          <Value isLoading={isIsLoading}>{priceListDetails?.type}</Value>
        </Info>
        <Info>
          <Label>UF</Label>
          <Value isLoading={isIsLoading}>{priceListDetails?.state}</Value>
        </Info>
        <Info>
          <Label>Início</Label>
          <Value isLoading={isIsLoading}>
            {priceListDetails?.dateTimeStart}
          </Value>
        </Info>
        <Info>
          <Label>Término</Label>
          <Value isLoading={isIsLoading}>{priceListDetails?.dateTimeEnd}</Value>
        </Info>
      </Content>

      <Subtitle>Produtos</Subtitle>
      <Tables.Items>
        <PriceListItemDetails.Header />

        <ComponentLoadingList rows={6} show={priceListProductIsLoading} />

        <ComponentIsVisible when={!priceListProductIsLoading}>
          <ComponentIsVisible when={!isErrorProducts}>
            {priceListProducts.map((product, position) => (
              <PriceListItemDetails.Body
                key={product.id}
                position={position}
                priceList={product}
              />
            ))}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há produtos para serem exibidos"
            show={!priceListProducts.length && !isErrorProducts}
          />

          <ComponentIsVisible when={isErrorProducts}>
            <ComponentError
              message="Não foi possível carregar os produtos"
              onClick={loadPriceListProducts}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
      </Tables.Items>
      <ComponentPaginate
        currentPage={page}
        onPage={handlePagination}
        show={!!priceListProducts.length}
        totalPages={totalPages}
      />
    </Containers.Global>
  );
};

export default PriceListDetails;
