import { all, takeLatest } from 'redux-saga/effects';

import { listActions } from '../index';
import create from './create';
import createItem from './createItem';
import remove from './remove';
import removeItem from './removeItem';
import update from './update';
import updateItem from './updateItem';
import updateItemPosition from './updateItemPosition';
import updateListPosition from './updateListPosition';

const listSagas = all([
  takeLatest(listActions.createRequest, create),
  takeLatest(listActions.createItemRequest, createItem),
  takeLatest(listActions.removeRequest, remove),
  takeLatest(listActions.removeItemRequest, removeItem),
  takeLatest(listActions.updateRequest, update),
  takeLatest(listActions.updateItemRequest, updateItem),
  takeLatest(listActions.updateItemPositionRequest, updateItemPosition),
  takeLatest(listActions.updateListPositionRequest, updateListPosition),
]);

export default listSagas;
