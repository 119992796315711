import styled, { css } from 'styled-components';

import Tooltip from 'components/utils/Tooltip';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export interface IFieldContainerProps {
  isBorder?: boolean;
  isErrored?: boolean;
  isFilled: boolean;
  isFocused: boolean;
  isSecure?: boolean;
}

export const FieldContainer = styled.div<IFieldContainerProps>`
  align-items: center;
  border-bottom: 1px solid
    ${props => (props.isBorder ? colors.border : colors.transparent)};
  color: ${colors.label};
  display: grid;
  grid-template-columns: ${props => (props.isSecure ? '1fr 3rem' : '1fr')};
  height: 100%;
  min-width: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;
  &::before {
    border-radius: 0.5rem;
    content: '';
    height: 3rem;
    left: 0;
    margin-top: ${props => (props.isSecure ? '0' : '0.25rem')};
    position: absolute;
    width: 2px;
    z-index: 2;
  }
  ${props =>
    props.isErrored &&
    css`
      &::before {
        background-color: ${colors.red};
      }
      grid-template-columns: ${props.isSecure
        ? '1fr repeat(2, 2.5rem)'
        : ' 1fr 3rem'};
      padding-right: 0.5rem;
    `}
  ${props =>
    props.isFocused &&
    css`
      &::before {
        background-color: ${colors.primary};
      }
    `}
  ${props =>
    props.isFilled &&
    css`
      &::before {
        background-color: ${colors.input};
      }
      color: ${colors.input};
    `}
`;

export interface IInputProps {
  isLoading?: boolean;
  textAlign?: string;
  uppercase?: boolean;
}

export const Input = styled.input<IInputProps>`
  background: ${colors.transparent};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.input};
  flex: 1;
  font-size: 1rem;
  height: 100%;
  max-width: 100%;
  min-width: 0px;
  padding-left: 1.25rem;
  text-align: ${props => (props.textAlign ? props.textAlign : 'initial')};
  &:read-only {
    cursor: not-allowed;
  }
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      cursor: not-allowed;
    `}
`;

interface IToggleSecureProps {
  isErrored?: boolean;
}

export const ToggleSecure = styled.button<IToggleSecureProps>`
  align-items: center;
  background: ${colors.transparent};
  border: 0;
  color: ${colors.label};
  display: flex;
  font-size: 0;
  height: 2.25rem;
  justify-content: center;
  outline: none;
  padding-right: ${props => (props.isErrored ? 0 : '1rem')};
  width: 100%;
`;

export const Error = styled(Tooltip)``;
