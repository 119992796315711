import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  background-color: ${colors.tabBackground};
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 6rem;
  height: 2.15rem;
  overflow: hidden;
  position: relative;
`;

export const PathContainer = styled.div`
  align-items: center;
  border: 1px solid ${colors.border};
  border-radius: 0.5rem 0 0 0.5rem;
  display: grid;
  height: 100%;
  padding-left: 1rem;
  width: 100%;
`;

export const Path = styled.p`
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const Actions = styled.div`
  background-color: ${colors.primary};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
`;

export const Action = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  font-size: 0;
  justify-content: center;

  &:hover {
    opacity: 0.75;
  }
`;
