const pages = {
  admin: {
    list: '/administradores',
  },
  auth: {
    signIn: '/',
    resetPassword: '/recuperar-senha',
  },
  home: '/inicio',
  dashboard: '/dashboards',
  customer: {
    list: '/clientes',
    pedcoins: (id: number) => `/clientes/${id}/pedcoins`,
    nonPositive: '/clientes/nao-positivados',
    details: (id: number) => `/clientes/${id}`,
  },
  product: {
    list: '/produtos',
    request: '/produtos/solicitados',
    details: (id: number) => `/produtos/${id}`,
  },
  manufacturer: {
    list: '/fabricantes',
    details: (id: number) => `/fabricantes/${id}`,
  },
  priceList: {
    list: '/tabelas-de-preco',
    details: (id: number) => `/tabelas-de-preco/${id}`,
  },
  orders: {
    list: '/pedidos',
  },
  settings: '/configuracoes',
  company: '/empresa',
  feedback: {
    list: '/feedbacks',
  },
};

export default pages;
