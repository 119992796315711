import images from './images';
import thumbnails from './thumbnails';
import type from './type';
import uploadIsLoading from './updateIsLoading';

const bannerSelectors = {
  images,
  thumbnails,
  type,
  uploadIsLoading,
};

export default bannerSelectors;
