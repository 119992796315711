import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetPaymentMethodsResponse from 'services/httpClient/responses/customer/IGetPaymentMethodsResponse';

import { ICustomerInitialData } from '../../index';

function paymentMethodsSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IGetPaymentMethodsResponse>,
) {
  draft.paymentMethods.config.isLoading = false;
  draft.paymentMethods.data = action.payload.list.map(paymentMethod => ({
    active: paymentMethod.active,
    id: paymentMethod.id,
    name: paymentMethod.name,
    sku: paymentMethod.sku,
  }));
}

export default paymentMethodsSuccess;
