import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IDashboardInitialData } from '../../index';

function rankingCustomerFailure(
  draft: Draft<IDashboardInitialData>,
  _: PayloadAction,
) {
  draft.rankingCustomer.config.isLoading = false;
}

export default rankingCustomerFailure;
