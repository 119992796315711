import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface IImageInitialData {
  config: {
    isLoading: boolean;
  };
  faviconUrl?: string;
  url?: string;
}

const initialState: IImageInitialData = {
  faviconUrl: undefined,
  url: undefined,
  config: {
    isLoading: false,
  },
};

const imageSlice = createSlice({
  name: '@image',
  initialState,
  reducers,
});

export const imageActions = imageSlice.actions;
export const imageReducers = imageSlice.reducer;
