import Tables from 'styles/tables';

import { PriceListItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <PriceListItemsHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader center>SKU</Tables.ItemHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>Tipo</Tables.ItemHeader>
      <Tables.ItemHeader center>Início</Tables.ItemHeader>
      <Tables.ItemHeader center>Término</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </PriceListItemsHeader>
  );
};

export default ItemHeader;
