import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllManufacturerRequest from 'services/httpClient/request/manufacturer/IGetAllManufacturerRequest';
import IGetAllManufacturerResponse from 'services/httpClient/responses/manufacturer/IGetAllManufacturerResponse';

import { manufacturerActions } from '..';

import { IGetAllRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllRequestAction>) {
  try {
    const params: IGetAllManufacturerRequest = {
      id: action.payload.data.id ? action.payload.data.id : undefined,
      name: action.payload.data.name ? action.payload.data.name : undefined,
      page: action.payload.data.page,
      sku: action.payload.data.sku ? action.payload.data.sku : undefined,
      status: action.payload.data.status
        ? action.payload.data.status
        : undefined,
    };

    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetAllManufacturerResponse> = yield call(
      httpClient.get,
      'admin/manufacturers',
      { params },
    );

    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        manufacturerActions.getAllSuccess({
          data: {
            list: [],
            totalPages: 0,
          },
        }),
      );
      return;
    }

    yield put(
      manufacturerActions.getAllSuccess({ data: { list, totalPages } }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(manufacturerActions.getAllFailure());
  }
}

export default getAll;
