import { createSlice } from '@reduxjs/toolkit';
import IBanner from 'models/IBanner';
import IList from 'models/IList';
import ISettings from 'models/ISettings';

import reducers from './reducers';

export interface IAuthInitialData {
  authorization: {
    token: string | null;
  };
  forgotPassword: {
    config: {
      isLoading: boolean;
    };
  };
  hideUpdateNotes: boolean;
  profile?: {
    banners: IBanner;
    id: number;
    lists: IList[];
    settings: ISettings;
  };
  signIn: {
    config: {
      isLoading: boolean;
    };
  };
  updatePassword: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IAuthInitialData = {
  authorization: { token: null },
  hideUpdateNotes: false,
  profile: undefined,
  signIn: {
    config: {
      isLoading: false,
    },
  },
  forgotPassword: {
    config: {
      isLoading: false,
    },
  },
  updatePassword: {
    config: {
      isLoading: false,
    },
  },
};

const authSlice = createSlice({
  name: '@auth',
  initialState,
  reducers,
});

export const authActions = authSlice.actions;
export const authReducers = authSlice.reducer;
