import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { EExtractItemType, ICustomerInitialData } from '../../index';

export interface IPedcoinsExtractSuccessAction {
  data: {
    list: {
      date: string;
      description: string;
      id: number;
      quantity: number;
      reason: string;
      type: string;
    }[];
    totalPages: number;
  };
}

function pedcoinsExtractSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IPedcoinsExtractSuccessAction>,
) {
  const listParsed = action.payload.data.list.map(item => ({
    ...item,
    reason: item.reason,
    date: formatters.date(item.date),
    quantity:
      item.type === EExtractItemType.ENTRY
        ? formatters.money(item.quantity)
        : `- ${formatters.money(item.quantity)}`,
  }));
  draft.pedcoins.extract.config.totalPages = action.payload.data.totalPages;
  draft.pedcoins.extract.data = listParsed;
  draft.pedcoins.extract.config.isLoading = false;
}

export default pedcoinsExtractSuccess;
