import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IProductDetailsResponse from 'services/httpClient/responses/product/IGetProductResponse';

import { productActions } from '..';

import { IDetailsRequestAction } from '../reducers/details/request';

function* details(action: PayloadAction<IDetailsRequestAction>) {
  try {
    const { data }: AxiosResponse<IProductDetailsResponse> = yield call(
      httpClient.get,
      `admin/products/${action.payload.data.id}`,
    );

    yield put(productActions.detailsSuccess({ data }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(productActions.detailsFailure());
  }
}

export default details;
