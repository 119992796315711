import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IRankingCustomer from 'models/IRankingCustomer';

import { IDashboardInitialData } from '../../index';

export interface IRankingCustomerSuccessAction {
  data: {
    list: IRankingCustomer[];
  };
}

function rankingCustomerSuccess(
  draft: Draft<IDashboardInitialData>,
  action: PayloadAction<IRankingCustomerSuccessAction>,
) {
  draft.rankingCustomer.config.isLoading = false;
  draft.rankingCustomer.list = action.payload.data.list;
}

export default rankingCustomerSuccess;
