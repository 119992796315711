import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FaInfo } from 'react-icons/fa';

import EImageType from 'enums/imageType';
import ELinkType from 'enums/linkType';
import { Formik, FormikProps } from 'formik';
import helpers from 'helpers';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import { kitActions } from 'store/slices/kit';
import kitSelectors from 'store/slices/kit/selectors';
import { listActions } from 'store/slices/list';
import listSelectors from 'store/slices/list/selectors';
import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';
import { priceListActions } from 'store/slices/priceList';
import priceListSelectors from 'store/slices/priceList/selectors';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';
import * as Yup from 'yup';

import linkTypes from 'constants/linkTypes';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import { IOptionTypeBase } from 'components/input/select/Async';
import ComponentSelectSimple from 'components/input/select/Simple';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import colors from 'styles/colors';

import ItemImageCropper, { IItemImageCropperRefProps } from '../Cropper';
import {
  Content,
  FieldGrid,
  FormActions,
  FormikForm,
  ListItemInformation,
  Title,
} from './styles';

export interface IItemCreateRefProps {
  open: (id: number) => void;
}

interface ICreateItemFormData {
  link: {
    priceListId: IComponentSelectProps<number> | null;
    type: IComponentSelectProps | null;
    value: IComponentSelectProps<string> | null;
  };
  listId: number | null;
}

const itemCreateSchema = Yup.object().shape({
  link: Yup.object().shape({
    type: Yup.object()
      .shape({
        label: Yup.string().required('Selecione o tipo').nullable(false),
        value: Yup.string().required('Selecione o tipo').nullable(false),
      })
      .typeError('Selecione o tipo'),
    value: Yup.object().when('type', {
      is: (type: IComponentSelectProps) =>
        type?.label !== ELinkType.WithoutAction,
      otherwise: Yup.object().nullable(),
      then: Yup.object()
        .shape({
          label: Yup.string()
            .required('Selecione o link do item')
            .nullable(false),
          value: Yup.string()
            .required('Selecione o link do item')
            .nullable(false),
        })
        .typeError('Selecione o link do item'),
    }),
    priceListId: Yup.object().when('type', {
      is: (type: IComponentSelectProps) => type?.label === ELinkType.Product,
      otherwise: Yup.object().nullable(),
      then: Yup.object()
        .shape({
          label: Yup.string()
            .required('Selecione a tabela de preço')
            .nullable(false),
          value: Yup.string()
            .required('Selecione a tabela de preço')
            .nullable(false),
        })
        .typeError('Selecione a tabela de preço'),
    }),
  }),
});

const ItemCreate: React.ForwardRefRenderFunction<IItemCreateRefProps> = (
  _,
  ref,
) => {
  const reduxDispatch = useReduxDispatch();
  const [listId, setListId] = useState<number | undefined>(undefined);
  const [hasLinkOptions, setHasLinkOptions] = useState<boolean>(false);
  const [priceTableSelect, setPriceTableSelect] = useState<boolean>(false);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const itemImageCropperRef = useRef<IItemImageCropperRefProps>(null);
  const itemCreateFormRef = useRef<FormikProps<ICreateItemFormData>>(null);

  const kitsLoading = useReduxSelector(kitSelectors.listIsLoading);
  const manufacturersSelectLoading = useReduxSelector(
    manufacturerSelectors.selectIsLoading,
  );
  const productsLoading = useReduxSelector(productSelectors.selectIsLoading);
  const priceListLoading = useReduxSelector(priceListSelectors.selectIsLoading);
  const priceListOptions = useReduxSelector(priceListSelectors.select);
  const linkOptions = useReduxSelector(listSelectors.linkOptions);
  const createItemLoading = useReduxSelector(listSelectors.createItemIsLoading);

  const openModal = useCallback((id: number) => {
    document
      .getElementById('modal-list-create')
      ?.classList.add('active-scroll');
    setListId(id);
    componentModalBaseRef.current?.open();
    setPriceTableSelect(false);
    setHasLinkOptions(false);
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
    itemImageCropperRef.current?.removeImage();
    itemImageCropperRef.current?.removePreview();
    itemImageCropperRef.current?.setEmptyImage(false);

    document
      .getElementById('modal-list-create')
      ?.classList.remove('active-scroll');
  }, []);

  const handleOnChangeLinkType = useCallback(
    (option: IOptionTypeBase | null) => {
      itemCreateFormRef.current?.setFieldValue('link.type', option);
      if (option?.label === ELinkType.Product) {
        reduxDispatch(
          priceListActions.selectRequest({ data: { id: undefined } }),
        );
        setPriceTableSelect(true);
        setHasLinkOptions(true);
      }
      if (option?.label === ELinkType.Manufacturer) {
        reduxDispatch(
          manufacturerActions.selectRequest({
            data: {
              linkId: undefined,
            },
          }),
        );
        setPriceTableSelect(false);
        setHasLinkOptions(true);
        itemCreateFormRef.current?.setFieldValue('link.priceListId', null);
      }
      if (option?.label === ELinkType.Kit) {
        reduxDispatch(
          kitActions.listRequest({
            data: {
              linkId: undefined,
            },
          }),
        );
        setPriceTableSelect(false);
        setHasLinkOptions(true);
        itemCreateFormRef.current?.setFieldValue('link.priceListId', null);
      }
      if (option?.label === ELinkType.WithoutAction) {
        setPriceTableSelect(false);
        setHasLinkOptions(false);
        itemCreateFormRef.current?.setFieldValue('link.priceListId', null);
      }
    },
    [reduxDispatch],
  );

  const handleChangePriceListId = useCallback(
    (option: IOptionTypeBase | null) => {
      itemCreateFormRef.current?.setFieldValue('link.priceListId', option);
      if (option?.value) {
        reduxDispatch(
          productActions.selectRequest({ data: { priceListId: option.value } }),
        );
      }
    },
    [reduxDispatch],
  );

  const handleChangeLinkValue = useCallback(
    (option: IOptionTypeBase | null) => {
      itemCreateFormRef.current?.setFieldValue('link.value', option);
    },
    [],
  );

  const handleCreateItem = useCallback(
    async (data: ICreateItemFormData) => {
      try {
        if (!listId) {
          return;
        }

        if (!itemImageCropperRef.current?.imageSrc) {
          itemImageCropperRef.current?.setEmptyImage(true);
          return;
        }
        const blobFile = await helpers.dataUrlToFile({
          dataUrl: itemImageCropperRef.current?.imageSrc,
          fileName: 'file.jpg',
        });
        reduxDispatch(
          listActions.createItemRequest({
            data: {
              link: {
                type: data.link.type?.label as string,
                value:
                  data.link.type?.label &&
                  data.link.type?.label !== ELinkType.WithoutAction
                    ? Number(data.link.value?.value)
                    : 0,
                priceListId: data.link.priceListId?.value,
              },
              listId,
              file: blobFile,
              uploadType: EImageType.List,
            },
            functions: {
              success() {
                closeModal();
              },
            },
          }),
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = helpers.getValidationErrors(err);
          itemCreateFormRef.current?.setErrors(validationErrors);
        }
      }
    },
    [closeModal, reduxDispatch, listId],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  useEffect(() => {
    if (linkOptions.length) {
      itemCreateFormRef.current?.setFieldValue('link.value', linkOptions[0]);
    }
  }, [linkOptions]);

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content id="modal-list-create">
        <Title>
          Cadastrar novo item
          <ListItemInformation>
            <FaInfo size={10} />
          </ListItemInformation>
        </Title>

        <Formik
          initialValues={{
            link: {
              value: null,
              type: null,
              priceListId: null,
            },
            // file: null,
            listId: null,
          }}
          innerRef={itemCreateFormRef}
          onSubmit={handleCreateItem}
          validateOnChange={false}
          validationSchema={itemCreateSchema}
        >
          {({ errors, values }) => (
            <FormikForm>
              <FieldGrid>
                <ComponentSelectSimple
                  errorMessage={errors.link?.type}
                  hasError={!!errors.link?.type}
                  hideSelectedOptions
                  label="Escolha o tipo de link"
                  name="type"
                  onChange={handleOnChangeLinkType}
                  options={linkTypes}
                  placeholder="Selecione o tipo"
                  value={values.link.type}
                />
                <ComponentSelectSimple
                  errorMessage={errors.link?.priceListId}
                  hasError={!!errors.link?.priceListId}
                  isDisabled={!priceTableSelect}
                  isLoading={priceListLoading}
                  isSearchable
                  label="Escolha a tabela de preço"
                  name="priceListId"
                  onChange={handleChangePriceListId}
                  options={priceListOptions}
                  placeholder="Selecione a tabela de preço"
                  value={values.link.priceListId}
                />
                <ComponentSelectSimple
                  errorMessage={errors.link?.value}
                  hasError={!!errors.link?.value}
                  isDisabled={!hasLinkOptions}
                  isLoading={
                    kitsLoading ||
                    manufacturersSelectLoading ||
                    productsLoading ||
                    priceListLoading
                  }
                  isSearchable
                  label="Escolha o link do item"
                  name="value"
                  onChange={handleChangeLinkValue}
                  options={linkOptions}
                  placeholder="Selecione o link"
                  value={values.link.value}
                />
              </FieldGrid>
              <ItemImageCropper ref={itemImageCropperRef} />
              <FormActions>
                <ComponentButtonBase
                  backgroundColor={colors.red}
                  disabled={createItemLoading}
                  onClick={closeModal}
                  type="button"
                >
                  Cancelar
                </ComponentButtonBase>
                <ComponentButtonBase
                  disabled={createItemLoading}
                  isLoading={createItemLoading}
                  type="submit"
                >
                  Confirmar
                </ComponentButtonBase>
              </FormActions>
            </FormikForm>
          )}
        </Formik>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ItemCreate);
