import { createSlice } from '@reduxjs/toolkit';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import IProduct, { EProductDrugType } from 'models/IProduct';

import reducers from './reducers';

export interface IProductList {
  active: boolean;
  barcode: string | null;
  coinsPerReal: number;
  coinsPerRealFormatted: string;
  id: number;
  manufacturer: string;
  name: string;
  sku: string;
}

export interface IProductPriceScales {
  amountDiscount: string;
  amountFee: string;
  amountFinal: string;
  amountGross: string;
  amountNet: string;
  id: number;
  quantity: number;
  sku: string;
}
export interface IProductPriceList {
  amountDiscount: string;
  amountFee: string;
  amountFinal: string;
  amountGross: string;
  amountNet: string;
  expirationDate: string;
  id: number;
  name: string;
  quantityMax: string | number;
  quantityMin: string | number;
  sku: string;
}

export interface IProductDetails {
  active: boolean;
  barcode: string | null;
  coinsPerReal: number;
  coinsPerRealFormatted: string;
  drugActiveIngredient: string | null;
  drugControlled: string | boolean;
  drugIsFp: string | boolean;
  drugPackingBox: number | string | null;
  drugRegistrationNumberMs: string | null;
  drugType: EProductDrugType | string | null;
  id: number;
  manufacturer: string;
  minimumStock: number | null;
  name: string;
  priceList: IProductPriceList[];
  productPriceScales: IProductPriceScales[];
  sku: string;
  stock: number | string | null;
}

export interface IProductInitialData {
  coinsPerReal: {
    config: {
      isLoading: boolean;
    };
  };
  details: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: IProductDetails | null;
  };
  getAll: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    data: IProductList[];
  };
  getAllManufacturer: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    data: IProduct[];
  };
  select: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page?: number;
    };
    data: IComponentSelectProps<string>[];
  };
}

const initialState: IProductInitialData = {
  coinsPerReal: {
    config: {
      isLoading: false,
    },
  },
  details: {
    config: {
      isError: false,
      isLoading: false,
    },
    data: null,
  },
  getAll: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    data: [],
  },
  getAllManufacturer: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    data: [],
  },
  select: {
    config: {
      isError: false,
      isLoading: false,
      page: 0,
    },
    data: [],
  },
};

const productSlice = createSlice({
  name: '@product',
  initialState,
  reducers,
});

export const productActions = productSlice.actions;
export const productReducers = productSlice.reducer;
