import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IDashboardInitialData } from '../../index';

function rankingProductFailure(
  draft: Draft<IDashboardInitialData>,
  _: PayloadAction,
) {
  draft.rankingProduct.config.isLoading = false;
}

export default rankingProductFailure;
