import Tables from 'styles/tables';

import { AdminItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <AdminItemsHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>E-mail</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </AdminItemsHeader>
  );
};

export default ItemHeader;
