import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

export interface ISelectSuccessAction {
  data: {
    list: {
      id: number;
      name: string;
    }[];
  };
}

function selectSuccess(
  draft: Draft<IManufacturerInitialData>,
  action: PayloadAction<ISelectSuccessAction>,
) {
  const list = action.payload.data.list.map(listItem => ({
    label: listItem.name,
    value: listItem.id,
  }));
  draft.select.list = list;
  draft.select.config.isLoading = false;
}

export default selectSuccess;
