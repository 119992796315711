import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IPaymentMethodsRequestAction {
  data: {
    customerId: number;
  };
}

function paymentMethodsRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<IPaymentMethodsRequestAction>,
) {
  draft.paymentMethods.config.isLoading = true;
  draft.paymentMethods.config.isError = false;
}

export default paymentMethodsRequest;
