import styled from 'styled-components';

import { Form } from 'formik';

export const Grid = styled.div`
  align-items: flex-end;
  column-gap: 0.25rem;
  display: grid;
  grid-template-columns: repeat(2, 10rem) repeat(2, auto) 12rem 12rem repeat(
      2,
      2.25rem
    );
  margin-top: 1rem;
`;

export const FormikForm = styled(Form)``;
