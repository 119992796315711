import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IMeuCatalogoRequestAction {
  data: {
    email: string;
    password: string;
  };
}

function meuCatalogoRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IMeuCatalogoRequestAction>,
) {
  draft.meuCatalogo.config.isLoading = true;
}

export default meuCatalogoRequest;
