import Tables from 'styles/tables';

import { CustomerItemsHeader } from './styles';

const CustomerItemHeader: React.FC = () => {
  return (
    <CustomerItemsHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader center>Compras</Tables.ItemHeader>
    </CustomerItemsHeader>
  );
};

export default CustomerItemHeader;
