import { Draft } from '@reduxjs/toolkit';

import { IFeedbackInitialData } from '../../index';

function getAllFailure(draft: Draft<IFeedbackInitialData>) {
  draft.getAll.config.isLoading = false;
  draft.getAll.config.isError = true;
}

export default getAllFailure;
