import styled from 'styled-components';

import colors from 'styles/colors';

export const CheckTitleInformation = styled.div`
  align-items: center;
  background-color: ${colors.label};
  border-radius: 100%;
  color: ${colors.white};
  cursor: help;
  display: inline-flex;
  height: 1.15rem;
  justify-content: center;
  position: relative;
  width: 1.15rem;

  &:hover {
    &::before {
      border-bottom: 0.5rem solid transparent;
      border-right: 0.5rem solid ${colors.label};
      border-top: 0.5rem solid transparent;
      content: '';
      height: 0;
      position: absolute;
      right: -0.75rem;
      top: 0.25rem;
      width: 0;
      z-index: 2;
    }
    &::after {
      background-color: ${colors.label};
      border-radius: 0.5rem;
      color: ${colors.white};
      content: 'Marcando essa opção, o carregamento de produtos na tela ficará mais lento';
      font-size: 0.875rem;
      height: fit-content;
      left: calc(100% + 0.75rem);
      line-height: 1.15rem;
      padding: 0.5rem 0.75rem;
      position: absolute;
      top: -0.5rem;
      width: 10rem;
      z-index: 4;
    }
  }
`;
