import { PayloadAction } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { listActions } from '../index';
import { IUpdateListPositionRequestAction } from '../reducers/updateListPosition/request';

function* updateListPosition(
  action: PayloadAction<IUpdateListPositionRequestAction>,
) {
  try {
    const body = {
      ids: action.payload.data.ids,
    };
    yield call(httpClient.put, `admin/settings/lists/positions`, body);
    yield put(
      listActions.updateListPositionSuccess({
        data: { ids: action.payload.data.ids },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(listActions.updateListPositionFailure());
  }
}

export default updateListPosition;
