import { all, takeLatest } from 'redux-saga/effects';

import { customerActions } from '../index';
import changePassword from './changePassword';
import details from './details';
import drugs from './drugs';
import getAll from './getAll';
import launchPedcoins from './launchPedcoins';
import nonPositive from './nonPositive';
import nonPositivePdf from './nonPositivePdf';
import nonPositiveSpreadsheet from './nonPositiveSpreadsheet';
import orders from './orders';
import paymentMethods from './paymentMethods';
import pedcoinsExtract from './pedcoinsExtract';
import pedcoinsRedeem from './pedcoinsRedeem';
import pedcoinsSummary from './pedcoinsSummary';
import priceTables from './priceTables';

const customerSagas = all([
  takeLatest(customerActions.drugsRequest, drugs),
  takeLatest(customerActions.ordersRequest, orders),
  takeLatest(customerActions.priceTablesRequest, priceTables),
  takeLatest(customerActions.paymentMethodsRequest, paymentMethods),
  takeLatest(customerActions.detailsRequest, details),
  takeLatest(customerActions.getAllRequest, getAll),
  takeLatest(customerActions.changePasswordRequest, changePassword),
  takeLatest(customerActions.launchPedcoinsRequest, launchPedcoins),
  takeLatest(customerActions.nonPositiveRequest, nonPositive),
  takeLatest(customerActions.nonPositivePdfRequest, nonPositivePdf),
  takeLatest(customerActions.pedcoinsExtractRequest, pedcoinsExtract),
  takeLatest(customerActions.pedcoinsRedeemRequest, pedcoinsRedeem),
  takeLatest(customerActions.pedcoinsSummaryRequest, pedcoinsSummary),
  takeLatest(
    customerActions.nonPositiveSpreadsheetRequest,
    nonPositiveSpreadsheet,
  ),
]);

export default customerSagas;
