import list from './list';
import listIsError from './listIsError';
import listIsLoading from './listIsLoading';
import listPagination from './listPagination';
import pdfIsLoading from './pdfIsLoading';
import spreadsheetIsLoading from './spreadsheetIsLoading';

const orderSelectors = {
  list,
  listIsError,
  listIsLoading,
  listPagination,
  pdfIsLoading,
  spreadsheetIsLoading,
};

export default orderSelectors;
