import styled, { css } from 'styled-components';

import Tooltip from 'components/utils/Tooltip';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

export const Label = styled.label`
  color: ${colors.label};
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

export interface IFieldContainerProps {
  backgroundColor?: string;
  height?: string;
  isErrored?: boolean;
  isFilled: boolean;
  isFocused: boolean;
  isReadOnly?: boolean;
}

export const FieldContainer = styled.div<IFieldContainerProps>`
  align-items: center;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : colors.tabBackground};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  display: flex;
  height: ${props => (props.height ? props.height : '9rem')};
  overflow: hidden;
  position: relative;
  width: 100%;
  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.red};
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: ${colors.input};
      color: ${colors.input};
    `}
  ${props =>
    props.isFilled &&
    css`
      color: ${colors.input};
    `}
    ${props =>
    props.isReadOnly &&
    css`
      background-color: ${colors.background};
      border-color: ${colors.background};
    `}
`;

export const Textarea = styled.textarea`
  background: ${colors.transparent};
  border: 0;
  color: ${colors.input};
  flex: 1;
  font-size: 0.875rem;
  height: 9rem;
  max-width: 100%;
  min-width: 0;
  padding: 1rem;
  resize: none;
  &:read-only {
    animation: ${animations.shimmer} 2s infinite linear;
    background: linear-gradient(to right, #f2f3f5 4%, #ebeced 25%, #f2f3f5 36%);
    background-size: 1000px 100%;
    cursor: not-allowed;
  }
`;

export const Error = styled(Tooltip)``;
