import { Draft } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

function selectFailure(draft: Draft<IPriceListInitialData>) {
  draft.select.config.isError = true;
  draft.select.config.isLoading = false;
}

export default selectFailure;
