import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IDetailsRequestAction {
  data: {
    customerId: number;
  };
}

function detailsRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<IDetailsRequestAction>,
) {
  draft.details.config.isLoading = true;
}

export default detailsRequest;
