import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EImageType from 'enums/imageType';
import EUploadImageType from 'enums/uploadImageType';

import { IImageInitialData } from '../../index';

export interface IUploadRequestAction {
  data: {
    file: Blob;
    type: EImageType;
    uploadType: EUploadImageType;
  };
  functions: {
    success: () => void;
  };
}

function uploadRequest(
  draft: Draft<IImageInitialData>,
  _: PayloadAction<IUploadRequestAction>,
) {
  draft.config.isLoading = true;
}

export default uploadRequest;
