import React, { useCallback, useEffect } from 'react';

import { dashboardActions } from 'store/slices/dashboard';
import dashboardSelectors from 'store/slices/dashboard/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import CustomerItem from './CustomerItem';
import ProductItem from './ProductItem';
import { Container, ContainerList, MainContainer, Subtitle } from './styles';

const List: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const products = useReduxSelector(dashboardSelectors.rankingProductList);
  const customers = useReduxSelector(dashboardSelectors.rankingCustomerList);
  const rankingProductIsLoading = useReduxSelector(
    dashboardSelectors.rankingProductIsLoading,
  );
  const rankingCustomerIsLoading = useReduxSelector(
    dashboardSelectors.rankingCustomerIsLoading,
  );

  const handleProduct = useCallback(() => {
    reduxDispatch(dashboardActions.rankingProductRequest());
  }, [reduxDispatch]);

  const handleCustomer = useCallback(() => {
    reduxDispatch(dashboardActions.rankingCustomerRequest());
  }, [reduxDispatch]);

  useEffect(() => {
    handleProduct();
    handleCustomer();
  }, [handleCustomer, handleProduct]);
  return (
    <Container>
      <ContainerList>
        <Subtitle>Top 20 produtos mais vendidos</Subtitle>
        <MainContainer>
          <ProductItem.Header />
          <ComponentIsVisible when={!rankingProductIsLoading}>
            {products.map((product, position) => (
              <ProductItem.Body
                key={product.name}
                position={position}
                product={product}
              />
            ))}
          </ComponentIsVisible>
          <ComponentIsVisible when={rankingProductIsLoading}>
            <ComponentLoadingList rows={20} show={rankingProductIsLoading} />
          </ComponentIsVisible>
        </MainContainer>
      </ContainerList>

      <ContainerList>
        <Subtitle>Top 20 clientes que mais compraram</Subtitle>
        <MainContainer>
          <CustomerItem.Header />
          <ComponentIsVisible when={!rankingCustomerIsLoading}>
            {customers.map((customer, position) => (
              <CustomerItem.Body
                customer={customer}
                key={customer.name}
                position={position}
              />
            ))}
          </ComponentIsVisible>
          <ComponentIsVisible when={rankingCustomerIsLoading}>
            <ComponentLoadingList rows={20} show={rankingCustomerIsLoading} />
          </ComponentIsVisible>
        </MainContainer>
      </ContainerList>
    </Container>
  );
};

export default List;
