import { Draft } from '@reduxjs/toolkit';

import { ICustomerAccessInitialData } from '../..';

function spreadsheetCustomerAccessFailure(
  draft: Draft<ICustomerAccessInitialData>,
): void {
  draft.spreadsheet.config.isLoading = false;
}

export default spreadsheetCustomerAccessFailure;
