import styled from 'styled-components';

import colors from 'styles/colors';

export const Header = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.5rem;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Subtitle = styled.h4`
  color: ${colors.primary};
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0 1rem;
`;
