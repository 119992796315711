import { Draft } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

function drugsFailure(draft: Draft<ICustomerInitialData>) {
  draft.drugs.config.isLoading = false;
  draft.drugs.config.isError = true;
}

export default drugsFailure;
