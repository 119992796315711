import detailsFailure from './details/failure';
import detailsRequest from './details/request';
import detailsSuccess from './details/success';
import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccess from './getAll/success';
import getAllProductsFailure from './getAllProducts/failure';
import getAllProductsRequest from './getAllProducts/request';
import getAllProductsSuccess from './getAllProducts/success';
import selectFailure from './select/failure';
import selectRequest from './select/request';
import selectSuccess from './select/success';
import setPriceListOptionId from './set/priceListOptionId';

const priceListReducers = {
  detailsFailure,
  detailsRequest,
  detailsSuccess,
  getAllFailure,
  getAllRequest,
  getAllSuccess,
  getAllProductsFailure,
  getAllProductsRequest,
  getAllProductsSuccess,
  selectFailure,
  selectRequest,
  selectSuccess,
  setPriceListOptionId,
};

export default priceListReducers;
