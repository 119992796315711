import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IFiltersRequestAction {
  data: {
    showHasStock: boolean;
    showKit: boolean;
    showRedeem: boolean;
  };
}

function filtersRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IFiltersRequestAction>,
) {
  draft.filters.config.isLoading = true;
}

export default filtersRequest;
