import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';
import ICustomer from 'models/ICustomer';

import { ICustomerInitialData } from '../../index';

export interface IGetAllSuccessAction {
  data: {
    list: ICustomer[];
    totalPages: number;
    withPagination: boolean;
  };
}

function getAllSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IGetAllSuccessAction>,
) {
  const listFormatted = action.payload.data.list.map(customer => {
    const customerId = draft.getAll.customersConnected.find(
      customerId => customerId === customer.id,
    );
    if (customerId) {
      return {
        ...customer,
        phone: formatters.phone(customer.phone),
        document: formatters.cpfOrCnpj(customer.document),
        isConnected: true,
      };
    }
    return {
      ...customer,
      phone: formatters.phone(customer.phone),
      document: formatters.cpfOrCnpj(customer.document),
      isConnected: false,
    };
  });
  let listParsed = listFormatted;
  if (!action.payload.data.withPagination) {
    listParsed = listFormatted.filter(item => item.isConnected);
  }
  draft.getAll.config.totalPages = action.payload.data.totalPages;
  draft.getAll.data = listParsed;
  draft.getAll.config.isLoading = false;
}

export default getAllSuccess;
