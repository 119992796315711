import { Draft } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

function detailsFailure(draft: Draft<IPriceListInitialData>) {
  draft.details.config.isLoading = false;
  draft.details.config.isError = true;
}

export default detailsFailure;
