import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import helpers from 'helpers';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentInputMoney from 'components/input/Money';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import {
  ClientName,
  CloseButton,
  Content,
  FieldsGrid,
  FormActions,
  FormikForm,
  InputContainer,
  Label,
  Subtitle,
  Title,
} from './styles';

export interface IPedcoin {
  amount: number;
  productId: number;
  productName: string;
}
export interface IPedcoinsRefProps {
  open: (data: IPedcoin) => void;
}

interface IPedcoinsFormData {
  disabled: boolean;
  quantity: string;
}

const pedcoinsSchema = Yup.object().shape({
  quantity: Yup.string().when('disabled', {
    is: (disabled: boolean) => !disabled,
    otherwise: Yup.string(),
    then: Yup.string().required('Informe a quantidade'),
  }),
  disabled: Yup.boolean(),
});

const Pedcoins: React.ForwardRefRenderFunction<IPedcoinsRefProps> = (
  _,
  ref,
) => {
  const reduxDispatch = useReduxDispatch();
  const [pedcoinInfo, setPedcoinInfo] = useState<IPedcoin | undefined>(
    undefined,
  );
  const [disableField, setDisableField] = useState<boolean>(true);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const coinsPerRealFormRef = useRef<FormikProps<IPedcoinsFormData>>(null);

  const coinsPerRealLoading = useReduxSelector(
    productSelectors.coinsPerRealIsLoading,
  );
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  const openModal = useCallback((data: IPedcoin) => {
    setPedcoinInfo(data);
    setDisableField(data.amount === -1);
    setTimeout(() => {
      if (data.amount !== -1 && data.amount !== null) {
        coinsPerRealFormRef.current?.setFieldValue(
          'quantity',
          helpers.money(data.amount.toFixed(2)),
        );
      }
      if (data.amount === -1) {
        coinsPerRealFormRef.current?.setFieldValue('disabled', true);
      }
    }, 200);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleChangeCheckbox = useCallback((): void => {
    if (coinsPerRealFormRef.current?.values.disabled) {
      coinsPerRealFormRef.current?.setFieldValue('quantity', '');
    }
    if (!coinsPerRealFormRef.current?.values.disabled) {
      coinsPerRealFormRef.current?.setFieldValue('quantity', '0,00');
    }
    coinsPerRealFormRef.current?.setFieldValue(
      'disabled',
      !coinsPerRealFormRef.current?.values.disabled,
    );
    setDisableField(oldState => !oldState);
  }, []);

  const handlePedcoins = useCallback(
    (data: IPedcoinsFormData) => {
      if (!pedcoinInfo) {
        return;
      }
      reduxDispatch(
        productActions.coinsPerRealRequest({
          data: {
            id: pedcoinInfo.productId,
            value: data.disabled ? -1 : Number(data.quantity.replace(',', '.')),
          },
          functions: {
            success() {
              closeModal();
              reduxDispatch(
                productActions.getAllRequest({ data: { page: 1 } }),
              );
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch, pedcoinInfo],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>{`Definição de ${pedcoins.name} em produto`}</Title>
        <Subtitle>Preencha abaixo para concluir a ação</Subtitle>

        <CloseButton onClick={closeModal} type="button">
          <FiX size={24} />
        </CloseButton>

        <ClientName>
          <Label>Produto</Label>
          {pedcoinInfo?.productName}
        </ClientName>

        <Formik
          initialValues={{
            quantity: '',
            disabled: false,
          }}
          innerRef={coinsPerRealFormRef}
          onSubmit={handlePedcoins}
          validationSchema={pedcoinsSchema}
        >
          {({ errors, handleChange, values }) => (
            <FormikForm>
              <FieldsGrid>
                <ComponentInputMoney
                  disabled={disableField}
                  errorMessage={errors.quantity}
                  hasError={!!errors.quantity}
                  label={`Qtde. ${pedcoins.name}`}
                  name="quantity"
                  onChange={handleChange('quantity')}
                  textAlign="right"
                  value={values.quantity}
                />
                <InputContainer>
                  <ComponentInputCheckbox
                    isChecked={values.disabled}
                    label="Não definido"
                    name="disabled"
                    onChange={handleChangeCheckbox}
                  />
                </InputContainer>
              </FieldsGrid>

              <FormActions>
                <ComponentButtonBase
                  disabled={coinsPerRealLoading}
                  isLoading={coinsPerRealLoading}
                  type="submit"
                >
                  Confirmar
                </ComponentButtonBase>
              </FormActions>
            </FormikForm>
          )}
        </Formik>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(Pedcoins);
