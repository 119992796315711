import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IManufacturerInitialData } from '../../index';

export interface IDetailsSuccessAction {
  data: {
    active: boolean;
    coinsPerReal: number;
    id: number;
    name: string;
    products: number;
    sku: string;
  };
}

function detailsSuccess(
  draft: Draft<IManufacturerInitialData>,
  action: PayloadAction<IDetailsSuccessAction>,
) {
  draft.details.data = {
    active: action.payload.data.active,
    coinsPerReal: action.payload.data.coinsPerReal,
    id: action.payload.data.id,
    name: action.payload.data.name,
    sku: action.payload.data.sku,
    coinsPerRealFormatted: formatters.money(
      Number(action.payload.data.coinsPerReal.toFixed(2)),
    ),
  };
  draft.details.config.isLoading = false;
}

export default detailsSuccess;
