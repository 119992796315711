import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface IProductRequestList {
  customer: string;
  date: string;
  id: number;
  product: string;
}

export interface IProductRequestInitialData {
  getAll: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    data: IProductRequestList[];
  };
}

const initialState: IProductRequestInitialData = {
  getAll: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    data: [],
  },
};

const productRequestSlice = createSlice({
  name: '@productRequest',
  initialState,
  reducers,
});

export const productRequestActions = productRequestSlice.actions;
export const productRequestReducers = productRequestSlice.reducer;
