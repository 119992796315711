import styled, { css } from 'styled-components';

import Tooltip from 'components/utils/Tooltip';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  margin-top: 0.5rem;
  position: relative;
  width: 100%;
`;

export const Label = styled.label`
  color: ${colors.label};
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

export interface IFieldContainerProps {
  backgroundColor?: string;
  height?: string;
  isErrored?: boolean;
  isFilled: boolean;
  isFocused: boolean;
  isIcon?: boolean;
  isReadOnly?: boolean;
  isSecure?: boolean;
}

export const FieldContainer = styled.div<IFieldContainerProps>`
  align-items: center;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : colors.tabBackground};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  color: ${colors.label};
  display: grid;
  grid-template-columns: ${props => (props.isSecure ? '1fr 2.5rem' : '1fr')};
  height: ${props => (props.height ? props.height : '2.25rem')};
  min-width: 0;
  padding-left: ${props => (props.isIcon ? 0 : 0.7)}rem;
  position: relative;
  width: 100%;

  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.red};
      grid-template-columns: ${props.isSecure
        ? '1fr repeat(2, 2.5rem)'
        : ' 1fr 2.5rem'};
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: ${colors.input};
      color: ${colors.input};
    `}
  ${props =>
    props.isFilled &&
    css`
      color: ${colors.input};
    `}
  ${props =>
    props.isReadOnly &&
    css`
      background-color: ${colors.background};
      border-color: ${colors.background};
    `}
  ${props =>
    props.isIcon &&
    css`
      grid-template-columns: ${props.isSecure ? '3rem 1fr 2.5rem' : '3rem 1fr'};
      ${props.isErrored &&
      css`
        grid-template-columns: ${props.isSecure
          ? '3rem 1fr repeat(2, 2rem)'
          : '3rem 1fr 2rem'};
      `};
      & svg {
        margin: 0 auto;
      }
    `}
`;

export interface IInputProps {
  isLoading?: boolean;
  textAlign?: string;
  uppercase?: boolean;
}

export const Input = styled.input<IInputProps>`
  background: ${colors.transparent};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.input};
  flex: 1;
  font-size: 0.85rem;
  height: 100%;
  max-width: 100%;
  min-width: 0px;
  padding-right: ${props => (props.textAlign === 'right' ? '.7rem' : 0)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'initial')};
  &:read-only {
    cursor: not-allowed;
  }
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      cursor: not-allowed;
    `}
  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const ToggleSecure = styled.button`
  align-items: center;
  background: ${colors.transparent};
  border: 0;
  color: ${colors.label};
  display: flex;
  font-size: 0;
  height: 2.25rem;
  justify-content: center;
  outline: none;
  padding-right: 0.5rem;
`;

export const Error = styled(Tooltip)``;
