import favicon from './favicon';
import picture from './picture';
import uploadIsLoading from './uploadIsLoading';

const imageSelectors = {
  favicon,
  picture,
  uploadIsLoading,
};

export default imageSelectors;
