import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsTermsControlRequest from 'services/httpClient/request/settings/IPutSettingsTermsControlRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { PayloadAction } from '@reduxjs/toolkit';

import { ITermsControlRequestAction } from '../reducers/termsControl/request';

import { settingActions } from '..';

function* termsControl(action: PayloadAction<ITermsControlRequestAction>) {
  try {
    const body: IPutSettingsTermsControlRequest = {
      showTerms: action.payload.data.showTerms,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/terms',
      body,
    );
    ToastManager.show({
      title: response.data.message,
      type: 'success',
    });
    yield put(
      settingActions.termsControlSuccess({
        showTerms: action.payload.data.showTerms,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.termsControlFailure());
  }
}

export default termsControl;
