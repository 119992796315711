import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsLogosRequest from 'services/httpClient/request/settings/IPutSettingsLogosRequest';
import IGetProfileResponse from 'services/httpClient/responses/auth/IGetProfileResponse';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { ILogoRequestAction } from '../reducers/logo/request';

import { settingActions } from '..';

function* logo(action: PayloadAction<ILogoRequestAction>) {
  try {
    const body: IPutSettingsLogosRequest = {
      url: action.payload.data.url,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/logos',
      body,
    );
    const { data: profileData }: AxiosResponse<IGetProfileResponse> =
      yield call(httpClient.get, 'admin/sessions');
    yield put(settingActions.setData(profileData.settings));

    ToastManager.show({
      title: message,
      type: 'success',
    });

    yield put(
      settingActions.logoSuccess({
        url: action.payload.data.url,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.logoFailure());
  }
}

export default logo;
