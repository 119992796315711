import { ICustomerDrugData } from 'store/slices/customer';

import Tables from 'styles/tables';

import { DrugsBody } from '../styles';

interface IProps {
  drug: ICustomerDrugData;
  position: number;
}

const ItemBody = ({ drug, position }: IProps) => {
  return (
    <DrugsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{drug.code}</Tables.ItemBody>
      <Tables.ItemBody>{drug.description}</Tables.ItemBody>
    </DrugsBody>
  );
};

export default ItemBody;
