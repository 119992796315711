import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IContactRequestAction {
  data: {
    corporateName: string;
    email: string;
    phone: string;
  };
}

function contactRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IContactRequestAction>,
) {
  draft.contact.config.isLoading = true;
}

export default contactRequest;
