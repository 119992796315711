import { EPriceListType } from 'models/IPriceList';

function priceLIstType(value?: string | null): string {
  switch (value) {
    case EPriceListType.Base:
      return 'BASE';
    case EPriceListType.Offer:
      return 'OFERTA';
    case EPriceListType.SpecialPrice:
      return 'ESPECIAL';
    default:
      return 'NÃO INFORMADO';
  }
}

export default priceLIstType;
