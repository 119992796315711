import { Navigate, Outlet } from 'react-router-dom';

import authSelectors from 'store/slices/auth/selectors';

import pages from 'constants/pages';

import { useReduxSelector } from 'hooks/useReduxSelector';

const PrivateRouteWrapper = (): JSX.Element => {
  const authToken = useReduxSelector(authSelectors.authToken);

  return authToken ? <Outlet /> : <Navigate replace to={pages.auth.signIn} />;
};

export default PrivateRouteWrapper;
