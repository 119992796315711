import createIsLoading from './createIsLoading';
import list from './list';
import listIsError from './listIsError';
import listIsLoading from './listIsLoading';
import listPagination from './listPagination';
import removeIsLoading from './removeIsLoading';
import updateIsLoading from './updateIsLoading';

const adminSelectors = {
  createIsLoading,
  list,
  listIsError,
  listIsLoading,
  listPagination,
  updateIsLoading,
  removeIsLoading,
};

export default adminSelectors;
