import IPriceList from 'models/IPriceList';

import Tables from 'styles/tables';

import { PriceListItemsBody } from '../styles';

interface IItemBodyProps {
  position: number;
  priceList: IPriceList;
}
const ItemBody = ({ position, priceList }: IItemBodyProps): JSX.Element => {
  return (
    <PriceListItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{priceList.id}</Tables.ItemBody>
      <Tables.ItemBody>{priceList.name}</Tables.ItemBody>
      <Tables.ItemBody>{priceList.manufacturer}</Tables.ItemBody>
      <Tables.ItemBody center>{priceList.sku}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountGross}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountDiscount}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountNet}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountFee}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountFinal}</Tables.ItemBody>
    </PriceListItemsBody>
  );
};

export default ItemBody;
