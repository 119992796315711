import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutCustomerChangePasswordRequest from 'services/httpClient/request/customer/IPutCustomerChangePasswordRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { PayloadAction } from '@reduxjs/toolkit';

import { IChangePasswordRequestAction } from '../reducers/changePassword/request';

import { customerActions } from '..';

function* changePassword(action: PayloadAction<IChangePasswordRequestAction>) {
  try {
    const body: IPutCustomerChangePasswordRequest = {
      newPassword: action.payload.data.newPassword,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      `admin/customers/${action.payload.data.customerId}/password`,
      body,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });
    yield put(customerActions.changePasswordSuccess());
    action.payload.functions.success();
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.changePasswordFailure());
  }
}

export default changePassword;
