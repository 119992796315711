import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { EDashboardBilling, IDashboardInitialData } from '../../index';

export interface IBillingRequestAction {
  data: {
    type: EDashboardBilling;
  };
}

function billingRequest(
  draft: Draft<IDashboardInitialData>,
  action: PayloadAction<IBillingRequestAction>,
) {
  if (action.payload.data.type === EDashboardBilling.Daily) {
    draft.billing.daily.config.isLoading = true;
    draft.billing.daily.amount = 0;
    draft.billing.daily.value = '0,00';
  }
  if (action.payload.data.type === EDashboardBilling.Monthly) {
    draft.billing.monthly.config.isLoading = true;
    draft.billing.monthly.amount = 0;
    draft.billing.monthly.value = '0,00';
  }
  if (action.payload.data.type === EDashboardBilling.Semiannual) {
    draft.billing.semiannual.config.isLoading = true;
    draft.billing.semiannual.amount = 0;
    draft.billing.semiannual.value = '0,00';
  }
  if (action.payload.data.type === EDashboardBilling.Weekly) {
    draft.billing.weekly.config.isLoading = true;
    draft.billing.weekly.amount = 0;
    draft.billing.weekly.value = '0,00';
  }
  if (action.payload.data.type === EDashboardBilling.Yearly) {
    draft.billing.yearly.config.isLoading = true;
    draft.billing.yearly.amount = 0;
    draft.billing.yearly.value = '0,00';
  }
}

export default billingRequest;
