import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllManufacturerProductsRequest from 'services/httpClient/request/manufacturer/IGetAllManufacturerProductsRequest';
import IGetAllManufacturerProductsResponse from 'services/httpClient/responses/manufacturer/IGetAllManufacturerProductsResponse';

import { manufacturerActions } from '..';

import { IGetAllProductsRequestAction } from '../reducers/getAllProducts/request';

function* getAllProducts(action: PayloadAction<IGetAllProductsRequestAction>) {
  try {
    const params: IGetAllManufacturerProductsRequest = {
      page: action.payload.data.page,
    };
    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetAllManufacturerProductsResponse> = yield call(
      httpClient.get,
      `admin/manufacturers/${action.payload.data.id}/products`,
      { params },
    );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        manufacturerActions.getAllProductsSuccess({
          data: {
            list: [],
            totalPages: 0,
          },
        }),
      );
      return;
    }
    yield put(
      manufacturerActions.getAllProductsSuccess({
        data: {
          list,
          totalPages,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(manufacturerActions.getAllProductsFailure());
  }
}

export default getAllProducts;
