import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface ICompanyRequestAction {
  data: {
    corporateName: string;
    email: string;
    phone: string;
  };
}

function companyRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<ICompanyRequestAction>,
) {
  draft.company.config.isLoading = true;
}

export default companyRequest;
