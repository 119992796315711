import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

export interface IGetAllRequestAction {
  data: {
    id?: string;
    name?: string;
    page: number;
    perPage?: number;
    sku?: string;
    status?: string;
  };
}

function getAllRequest(
  draft: Draft<IManufacturerInitialData>,
  action: PayloadAction<IGetAllRequestAction>,
) {
  draft.getAll.list = [];
  draft.getAll.config.isError = false;
  draft.getAll.config.isLoading = true;
  draft.getAll.config.page = action.payload.data.page;
}

export default getAllRequest;
