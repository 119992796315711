import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import ComponentButtonBase from 'components/button/Base';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import bannerTutorial from 'assets/utils/banner-tutorial.jpg';

import colors from 'styles/colors';

import {
  Actions,
  Content,
  HelperImage,
  Thumbnail,
  Title,
  TitleContainer,
} from './styles';

export interface IHelperBannerRefProps {
  open: () => void;
}

const HelperBanner: React.ForwardRefRenderFunction<IHelperBannerRefProps> = (
  _,
  ref,
) => {
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const openModal = useCallback(() => {
    componentModalBaseRef.current?.open();
  }, []);
  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <TitleContainer>
          <Title>Modelos de exibição dos banners</Title>
        </TitleContainer>

        <Thumbnail>
          <HelperImage src={bannerTutorial} />
        </Thumbnail>

        <Actions>
          <ComponentButtonBase
            backgroundColor={colors.subtitle}
            onClick={closeModal}
          >
            Fechar
          </ComponentButtonBase>
        </Actions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(HelperBanner);
