import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EListTypes from 'enums/listType';

import { ISettingInitialData } from '../../index';

interface IProductsViewModeSuccessAction {
  productsViewMode: EListTypes;
}

function productsViewModeSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IProductsViewModeSuccessAction>,
) {
  draft.data.products.productsViewMode = action.payload.productsViewMode;
  draft.productsViewMode.config.isLoading = false;
}

export default productsViewModeSuccess;
