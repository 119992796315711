import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IAddressRequestAction {
  data: {
    city: string;
    complement?: string;
    neighborhood: string;
    number: string;
    postalCode: string;
    state: string;
    street: string;
  };
}

function addressRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IAddressRequestAction>,
) {
  draft.address.config.isLoading = true;
}

export default addressRequest;
