import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { orderActions } from '..';

import { ISpreadsheetOrderRequestAction } from '../reducers/spreadsheet/request';

function* spreadsheet(action: PayloadAction<ISpreadsheetOrderRequestAction>) {
  try {
    const params = {
      status: action.payload.data.status
        ? action.payload.data.status
        : undefined,
      origin: action.payload.data.origin
        ? action.payload.data.origin
        : undefined,
      name: action.payload.data.name ? action.payload.data.name : undefined,
      dateEnd: action.payload.data.dateEnd
        ? action.payload.data.dateEnd
        : undefined,
      dateStart: action.payload.data.dateStart
        ? action.payload.data.dateStart
        : undefined,
    };
    const { data: ordersSpreadsheet } = yield call(
      httpClient.get,
      `admin/orders/spreadsheet`,
      {
        params,
        responseType: 'blob',
      },
    );
    const url = window.URL.createObjectURL(
      new Blob([ordersSpreadsheet], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      }),
    );

    const link = document.createElement('a');
    link.href = url;
    link.download = `pedido-eletronico_${format(new Date(), 'dd-MM-yyyy')}`;
    document.body.appendChild(link);
    link.click();
    yield put(orderActions.spreadsheetOrderSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(orderActions.spreadsheetOrderFailure());
  }
}

export default spreadsheet;
