import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm, Options } from '../styles';

interface IFiltersFormData {
  hasStock: boolean;
  kit: boolean;
  redeem: boolean;
}

const FiltersControl: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const filtersConfigFormRef = useRef<FormikProps<IFiltersFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.filtersIsLoading);
  const filters = useReduxSelector(settingSelectors.filters);

  const handleColumnsConfig = useCallback(
    (data: IFiltersFormData) => {
      reduxDispatch(
        settingActions.filtersRequest({
          data: {
            showHasStock: data.hasStock,
            showKit: data.kit,
            showRedeem: data.redeem,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChange = useCallback((field: string, value: boolean) => {
    filtersConfigFormRef.current?.setFieldValue(field, !value);
    filtersConfigFormRef.current?.submitForm();
  }, []);

  return (
    <Formik
      initialValues={{
        hasStock: filters.hasStock,
        kit: filters.kit,
        redeem: filters.redeem,
      }}
      innerRef={filtersConfigFormRef}
      onSubmit={handleColumnsConfig}
    >
      {({ values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Controle de exibição de filtros
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>
            <CardSubtitle>
              Com base na disponibilidade abaixo, escolha os filtros que serão
              habilitados para seus clientes
            </CardSubtitle>

            <Options>
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.hasStock}
                label="Com estoque"
                name="hasStock"
                onChange={() => handleOnChange('hasStock', values.hasStock)}
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.redeem}
                label="Resgate"
                name="redeem"
                onChange={() => handleOnChange('redeem', values.redeem)}
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.kit}
                label="Kits"
                name="kit"
                onChange={() => handleOnChange('kit', values.kit)}
              />
            </Options>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default FiltersControl;
