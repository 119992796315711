import { Draggable } from 'react-beautiful-dnd';
import { FiEdit, FiTrash2 } from 'react-icons/fi';

import IListItem from 'models/IListItem';

import { Action, Actions, Container, Image, Thumbnail } from './styles';

interface IListItemProps {
  index: number;
  isClearDnD: boolean;
  item: IListItem;
  openEdit: (item: IListItem) => void;
  openRemoveAlert: (item: IListItem) => void;
}

const ListItem: React.FC<IListItemProps> = ({
  index,
  isClearDnD,
  item,
  openEdit,
  openRemoveAlert,
}) => {
  return (
    <Draggable
      draggableId={String(item.id)}
      index={index}
      isDragDisabled={!isClearDnD}
      key={item.id}
    >
      {(provided, snapshot) => (
        <Container
          isDragging={snapshot.isDragging}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Thumbnail isDragging={snapshot.isDragging}>
            <Image src={item.url} />
          </Thumbnail>
          <Actions>
            <Action onClick={() => openEdit(item)}>
              Editar
              <FiEdit size={18} />
            </Action>
            <Action onClick={() => openRemoveAlert(item)} remove>
              Remover
              <FiTrash2 size={18} />
            </Action>
          </Actions>
        </Container>
      )}
    </Draggable>
  );
};

export default ListItem;
