import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IListItem from 'models/IListItem';

import { IListInitialData } from '../../index';

export interface ICreateItemSuccessAction {
  data: {
    listId: number;
    listItem: IListItem;
  };
}

function createItemRequest(
  draft: Draft<IListInitialData>,
  action: PayloadAction<ICreateItemSuccessAction>,
) {
  const listItems = draft.data.map(list => {
    if (list.id === action.payload.data.listId) {
      return {
        items: list.items.concat(action.payload.data.listItem),
        active: list.active,
        id: list.id,
        isCollapsed: list.isCollapsed,
        position: list.position,
        quantityColumns: list.quantityColumns,
        subtitle: list.subtitle,
        title: list.title,
        typeView: list.typeView,
      };
    }
    return list;
  });
  draft.data = listItems;
  draft.createItem.config.isLoading = false;
}

export default createItemRequest;
