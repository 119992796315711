import { Draft } from '@reduxjs/toolkit';

import { IProductInitialData } from '../../index';

function detailsFailure(draft: Draft<IProductInitialData>) {
  draft.details.config.isLoading = false;
  draft.details.config.isError = true;
}

export default detailsFailure;
