import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetBillingResponse from 'services/httpClient/responses/dashboard/IGetBillingResponse';

import { dashboardActions } from '../index';
import { IBillingRequestAction } from '../reducers/billing/request';

function* billing(action: PayloadAction<IBillingRequestAction>) {
  try {
    const {
      data: { amount, value },
    }: AxiosResponse<IGetBillingResponse> = yield call(
      httpClient.get,
      `admin/dashboard/billing`,
      {
        params: {
          type: action.payload.data.type,
        },
      },
    );

    yield put(
      dashboardActions.billingSuccess({
        data: { amount, value, type: action.payload.data.type },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(
      dashboardActions.billingFailure({
        data: { type: action.payload.data.type },
      }),
    );
  }
}

export default billing;
