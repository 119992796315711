import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetCustomerDetailsResponse from 'services/httpClient/responses/customer/IGetCustomerDetailsResponse';

import { ICustomerInitialData } from '../../index';

function detailsSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IGetCustomerDetailsResponse>,
) {
  draft.details.data = {
    companyName: action.payload.company_name,
    creditLimit: action.payload.credit_limit,
    document: action.payload.document,
    email: action.payload.email,
    hasAvisa: action.payload.has_avisa,
    hasCrf: action.payload.has_crf,
    hasSivisa: action.payload.has_avisa,
    id: action.payload.id,
    name: action.payload.name,
    network: action.payload.network,
    phone: action.payload.phone,
    situation: action.payload.situation,
    sku: action.payload.sku,
    state: action.payload.state,
  };
  draft.details.config.isLoading = false;
}

export default detailsSuccess;
