import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import {
  CustomerPedcoinsItemHeader,
  CustomerPedcoinsItemsHeader,
} from 'pages/Customers/Pedcoins/styles';

function ExtractItemHeader(): JSX.Element {
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  return (
    <CustomerPedcoinsItemsHeader>
      <CustomerPedcoinsItemHeader>Tipo de transação</CustomerPedcoinsItemHeader>
      <CustomerPedcoinsItemHeader>Descrição</CustomerPedcoinsItemHeader>
      <CustomerPedcoinsItemHeader right>
        {pedcoins.name}
      </CustomerPedcoinsItemHeader>
      <CustomerPedcoinsItemHeader />
      <CustomerPedcoinsItemHeader>Data</CustomerPedcoinsItemHeader>
      <CustomerPedcoinsItemHeader center>Motivo</CustomerPedcoinsItemHeader>
    </CustomerPedcoinsItemsHeader>
  );
}

export default ExtractItemHeader;
