import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IInternalControlSuccessAction {
  hasPreferenceCompanyName: boolean;
}

function internalControlSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IInternalControlSuccessAction>,
) {
  draft.internalControl.config.isLoading = false;
  draft.data.internal.hasPreferenceCompanyName =
    action.payload.hasPreferenceCompanyName;
}

export default internalControlSuccess;
