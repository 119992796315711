import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { ICoinsPerRealRequestAction } from '../reducers/coinsPerReal/request';

import { manufacturerActions } from '..';

function* coinsPerReal(action: PayloadAction<ICoinsPerRealRequestAction>) {
  try {
    const { status }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      `admin/manufacturers/${action.payload.data.id}/coins-per-real`,
      {
        value: action.payload.data.value,
      },
    );

    if (status === EHttpStatusCodes.OK) {
      yield put(manufacturerActions.coinsPerRealSuccess());
      ToastManager.show({
        title: 'Pontos definidos com sucesso',
        type: 'success',
      });
      action.payload.functions.success();
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(manufacturerActions.coinsPerRealFailure());
  }
}

export default coinsPerReal;
