import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import { formatters } from 'racsys-js-tools';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import states from 'constants/states';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPostalCode from 'components/input/PostalCode';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm } from '../styles';
import { ContainerButton, Fields } from './styles';

interface ICompanyAddressFormData {
  city: string;
  complement?: string;
  neighborhood: string;
  number: string;
  postalCode: string;
  state: IComponentSelectProps<string>;
  street: string;
}

const companyAddressSchema = Yup.object().shape({
  city: Yup.string().required('Informe a cidade'),
  neighborhood: Yup.string().required('Informe o bairro'),
  number: Yup.string().required('Informe o nº'),
  postalCode: Yup.string()
    .max(9, "Tamanho máximo do campo 'CEP' deve ser 9 dígitos")
    .required('Informe o CEP'),
  state: Yup.object()
    .shape({
      value: Yup.string().required('Selecione a UF'),
    })
    .typeError('Selecione a UF'),
  street: Yup.string().required('Informe o logradouro'),
});

function CompanyAddress(): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const companyAddressFormRef =
    useRef<FormikProps<ICompanyAddressFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.addressIsLoading);
  const address = useReduxSelector(settingSelectors.address);

  const handleSelectChange = useCallback((option: IOptionTypeBase | null) => {
    companyAddressFormRef.current?.setFieldValue(
      'state',
      option ?? {
        label: '',
        value: '',
      },
    );
  }, []);

  const handleAddress = useCallback(
    (data: ICompanyAddressFormData) => {
      reduxDispatch(
        settingActions.addressRequest({
          data: {
            city: data.city,
            neighborhood: data.neighborhood,
            number: data.number,
            postalCode: data.postalCode,
            state: data.state.value,
            street: data.street,
            complement: data.complement,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        city: address.city ?? '',
        postalCode: address?.postalCode
          ? formatters.postalCode(address?.postalCode)
          : '',
        complement: address?.complement ?? '',
        neighborhood: address?.neighborhood ?? '',
        number: address?.number ?? '',
        state: {
          label: address?.state ?? '',
          value: address?.state ?? '',
        },
        street: address?.street ?? '',
      }}
      innerRef={companyAddressFormRef}
      onSubmit={handleAddress}
      validationSchema={companyAddressSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Endereço
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>

            <CardSubtitle>
              Preencha os campos abaixo com o endereço da empresa
            </CardSubtitle>

            <Fields>
              <ComponentInputPostalCode
                errorMessage={errors.postalCode}
                hasError={!!errors.postalCode}
                label="CEP"
                name="postalCode"
                onChange={handleChange('postalCode')}
                value={values.postalCode}
              />
              <ComponentInputSimple
                errorMessage={errors.street}
                hasError={!!errors.street}
                label="Logradouro"
                name="street"
                onChange={handleChange('street')}
                value={values.street}
              />
              <ComponentInputSimple
                errorMessage={errors.number}
                hasError={!!errors.number}
                label="Nº"
                name="number"
                onChange={handleChange('number')}
                value={values.number}
              />
              <ComponentInputSimple
                errorMessage={errors.complement}
                hasError={!!errors.complement}
                label="Complemento"
                name="complement"
                onChange={handleChange('complement')}
                value={values.complement}
              />
            </Fields>

            <Fields
              gridTemplateColumns="12rem 1fr 6rem"
              gridTemplateColumnsSmall="10rem 1fr 5.5rem"
            >
              <ComponentInputSimple
                errorMessage={errors.neighborhood}
                hasError={!!errors.neighborhood}
                label="Bairro"
                name="neighborhood"
                onChange={handleChange('neighborhood')}
                value={values.neighborhood}
              />
              <ComponentInputSimple
                errorMessage={errors.city}
                hasError={!!errors.city}
                label="Cidade"
                name="city"
                onChange={handleChange('city')}
                value={values.city}
              />
              <ComponentSelectSimple
                errorMessage={errors.state?.value}
                hasError={!!errors.state?.value}
                label="UF"
                name="state"
                onChange={handleSelectChange}
                options={states}
                placeholder=""
                value={values.state}
              />
            </Fields>

            <ContainerButton>
              <ComponentButtonBase
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                width="8rem"
              >
                Salvar
              </ComponentButtonBase>
            </ContainerButton>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
}

export default CompanyAddress;
