import { useCallback, useEffect, useRef, useState } from 'react';

import { FaInfo } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentInputMoney from 'components/input/Money';
import ComponentInputNumber from 'components/input/Number';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm, Options } from '../styles';
import { CustomField, FieldInformation, Fields, Label } from './styles';

interface IFormData {
  expirationTerm: string;
  isEnable: boolean;
  name: string;
  pointsPerReal: string;
}

const schemaValidation = Yup.object().shape({
  name: Yup.string().required('Informe o nome da moeda'),
  pointsPerReal: Yup.string().required('Informe os pontos por real'),
  expirationTerm: Yup.string().required('Informe o prazo de expiração'),
});

function SettingsPedcoins(): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const formRef = useRef<FormikProps<IFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.pedcoinsIsLoading);
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  const [disableFields, setDisableFields] = useState<boolean>(
    pedcoins.isEnable,
  );

  const handlePedcoinsRules = useCallback(
    async (data: IFormData) => {
      reduxDispatch(
        settingActions.pedcoinsRequest({
          data: {
            expirationTermInMonths: data.expirationTerm,
            name: data.name,
            pointsPerReal: data.pointsPerReal,
            isEnable: data.isEnable,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleChangeCheckbox = useCallback((): void => {
    if (formRef.current?.values.isEnable) {
      formRef.current?.setFieldValue('expirationTerm', '');
      formRef.current?.setFieldValue('name', '');
      formRef.current?.setFieldValue('pointsPerReal', '');
    }
    formRef.current?.setFieldValue(
      'isEnable',
      !formRef.current?.values.isEnable,
    );
    setDisableFields(oldState => !oldState);
  }, []);

  useEffect((): void => {
    if (pedcoins) {
      setDisableFields(pedcoins.isEnable);
    }
  }, [pedcoins]);

  return (
    <Formik
      initialValues={{
        expirationTerm: pedcoins.expirationTerm.toString(),
        isEnable: pedcoins.isEnable,
        name: pedcoins.name,
        pointsPerReal: pedcoins.pointsPerReal.toString(),
      }}
      innerRef={formRef}
      onSubmit={handlePedcoinsRules}
      validationSchema={schemaValidation}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Regras Pedcoins
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>
            <CardSubtitle>
              Defina as regras de nomenclatura, controle e valores para os
              Pedcoins
            </CardSubtitle>

            <Options>
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.isEnable}
                label="Habilitar"
                name="isEnable"
                onChange={handleChangeCheckbox}
              />
            </Options>

            <Fields>
              <ComponentInputSimple
                disabled={disableFields}
                errorMessage={errors.name}
                hasError={!!errors.name}
                label="Nome da moeda"
                name="name"
                onChange={handleChange('name')}
                value={values.name}
              />
              <CustomField>
                <Label>
                  Prazo de expiração
                  <FieldInformation>
                    <FaInfo size={12} />
                  </FieldInformation>
                </Label>
                <ComponentInputNumber
                  disabled={disableFields}
                  errorMessage={errors.expirationTerm}
                  hasError={!!errors.expirationTerm}
                  name="expirationTerm"
                  onChange={handleChange('expirationTerm')}
                  value={values.expirationTerm}
                />
              </CustomField>
              <ComponentInputMoney
                disabled={disableFields}
                errorMessage={errors.pointsPerReal}
                hasError={!!errors.pointsPerReal}
                label="Pontos por real"
                name="pointsPerReal"
                onChange={handleChange('pointsPerReal')}
                textAlign="right"
                value={values.pointsPerReal}
              />
              <ComponentButtonBase
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
              >
                Salvar
              </ComponentButtonBase>
            </Fields>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
}

export default SettingsPedcoins;
