import styled from 'styled-components';

import colors from 'styles/colors';

export const FilterContainer = styled.div`
  align-items: flex-end;
  display: grid;
  grid-template-columns: 1fr 8.5rem;
`;

export const FilterContainerAccesses = styled.div`
  align-items: flex-end;
  display: grid;
  grid-template-columns: 1fr 20rem 8.5rem;
`;

export const ConnectedCustomers = styled.span`
  color: ${colors.primary};
  display: contents;
  font-size: 0.8rem;
  font-weight: normal;
`;

export const FileButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-right: 0.5rem;
`;
