import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IUpdateItemPositionSuccessAction {
  data: {
    ids: number[];
    listId: number;
  };
}

function updateItemPositionRequest(
  draft: Draft<IListInitialData>,
  action: PayloadAction<IUpdateItemPositionSuccessAction>,
) {
  const newListItems = draft.data.map(list => {
    if (list.id === action.payload.data.listId) {
      list.items.forEach(item => {
        action.payload.data.ids.forEach((id, index) => {
          if (item.id === id) {
            // eslint-disable-next-line no-param-reassign
            item.position = index;
          }
        });
      });
      return {
        active: list.active,
        id: list.id,
        isCollapsed: list.isCollapsed,
        position: list.position,
        quantityColumns: list.quantityColumns,
        subtitle: list.subtitle,
        title: list.title,
        typeView: list.typeView,
        items: list.items.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        }),
      };
    }
    return list;
  });
  draft.data = newListItems;
}

export default updateItemPositionRequest;
