import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCustomerPedcoinsRedeemRequest from 'services/httpClient/request/customer/IGetCustomerPedcoinsRedeemRequest';
import IGetCustomerPedcoinsRedeemResponse from 'services/httpClient/responses/customer/IGetCustomerPedcoinsRedeemResponse';

import { customerActions } from '..';

import { IPedcoinsRedeemRequestAction } from '../reducers/pedcoinsRedeem/request';

function* pedcoinsRedeem(action: PayloadAction<IPedcoinsRedeemRequestAction>) {
  try {
    const params: IGetCustomerPedcoinsRedeemRequest = {
      page: action.payload.data.page,
    };

    const {
      data: { list, totalPages },
      status: httpStatusCode,
    }: AxiosResponse<IGetCustomerPedcoinsRedeemResponse> = yield call(
      httpClient.get,
      `admin/customers/${action.payload.data.customerId}/pedcoins/redeem`,
      { params },
    );
    if (httpStatusCode === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        customerActions.pedcoinsRedeemSuccess({
          data: { list: [], totalPages: 0 },
        }),
      );
      return;
    }
    yield put(
      customerActions.pedcoinsRedeemSuccess({
        data: { list, totalPages },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.pedcoinsRedeemFailure());
  }
}

export default pedcoinsRedeem;
