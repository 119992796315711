import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';
import { listActions } from 'store/slices/list';

import httpClient from 'services/httpClient';
import IGetManufacturerSelectResponse from 'services/httpClient/responses/manufacturer/IGetManufacturerSelectResponse';

import { ISelectRequestAction } from '../reducers/select/request';

import { manufacturerActions } from '..';

function* select(action: PayloadAction<ISelectRequestAction>) {
  try {
    const {
      data: { list },
      status,
    }: AxiosResponse<IGetManufacturerSelectResponse> = yield call(
      httpClient.get,
      'admin/manufacturers/select',
    );

    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(manufacturerActions.selectSuccess({ data: { list: [] } }));
      yield put(
        listActions.setLinkOptions({
          data: {
            option: [],
          },
        }),
      );
      return;
    }
    yield put(manufacturerActions.selectSuccess({ data: { list } }));
    const listFormatted = list.map(item => {
      return {
        id: String(item.id),
        name: item.name,
      };
    });
    yield put(
      listActions.setLinkOptions({
        data: {
          option: listFormatted,
        },
      }),
    );

    if (action.payload.data.linkId) {
      yield put(
        listActions.setLinkOptionId({
          data: {
            id: action.payload.data.linkId,
          },
        }),
      );
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(manufacturerActions.selectFailure());
  }
}

export default select;
