import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 35rem;
  justify-content: space-between;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h5`
  color: ${colors.primary};
  font-weight: 500;
  margin-bottom: 0.25rem;
  transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  &.isOpen {
    font-size: 1rem;
  }
  &.isClosed {
    font-size: 1.5rem;
  }
`;

export const Status = styled.small`
  color: ${colors.red};
  font-size: 0.75rem;
  margin-left: 0.25rem;
`;

export const Subtitle = styled.p`
  color: ${colors.subtitle};
  font-size: 0.875rem;
  transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  &.isOpen {
    font-size: 0.875rem;
  }
  &.isClosed {
    font-size: 1.2rem;
  }
`;

export const Actions = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: flex;
  justify-content: flex-end;
`;

interface IActionProps {
  center?: boolean;
  isRemove?: boolean;
  isSettings?: boolean;
}

export const Action = styled.button<IActionProps>`
  align-items: center;
  background-color: ${colors.primary};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.white};
  column-gap: 1rem;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2rem;
  padding: 0 1rem;
  transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    opacity: 0.8;
  }

  &.isOpen {
    opacity: 1;
    transform: scale(1);
  }
  &.isClosed {
    opacity: 0;
    transform: scale(0);
  }

  ${props =>
    props.isSettings &&
    css`
      background-color: ${colors.transparent};
      border: 1px solid ${colors.orange};
      color: ${colors.orange};
    `};

  ${props =>
    props.isRemove &&
    css`
      background-color: ${colors.transparent};
      border: 1px solid ${colors.red};
      color: ${colors.red};
      padding: 0 0.5rem;
    `};

  ${props =>
    props.center &&
    css`
      margin: 0 auto;
    `}
`;

export const Body = styled.div`
  display: flex;
  grid-gap: 1rem;
  margin: 2rem 0 0;
  max-width: 100%;
  overflow-x: auto;
  transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 100%;
  &.isOpen {
    margin: 2rem 0 0;
    opacity: 1;
    transform: scaleY(1);
  }
  &.isClosed {
    height: 0;
    margin: 0;
    opacity: 0;
    transform: scaleY(0);
  }

  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      background: ${colors.background};
      height: 0.5rem;
      width: 0.4rem;
      border-radius: 0.25rem;
    }

    ::-webkit-scrollbar-button {
      height: 0;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background: ${colors.background};
      height: 0.5rem;
      border-radius: 0.25rem;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.placeholder};
      height: 0.5rem;
      width: 0.4rem;
      border-radius: 0.25rem;
    }
  }
`;

export const ListEmpty = styled.div`
  margin: 0 auto;
`;
