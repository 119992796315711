import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IChangePasswordRequestAction {
  data: {
    customerId: number;
    newPassword: string;
  };
  functions: {
    success: () => void;
  };
}

function changePasswordRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<IChangePasswordRequestAction>,
) {
  draft.changePassword.config.isLoading = true;
}

export default changePasswordRequest;
