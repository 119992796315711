import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import ICreateAdminRequest from 'services/httpClient/request/admin/ICreateAdminRequest';
import ICreateAdminResponse from 'services/httpClient/responses/admin/ICreateAdminResponse';

import { ICreateAdminRequestAction } from '../reducers/create/request';

import { adminActions } from '..';

function* createAdmin(action: PayloadAction<ICreateAdminRequestAction>) {
  try {
    const body: ICreateAdminRequest = {
      email: action.payload.data.email.trim(),
      name: action.payload.data.name.trim(),
      password: action.payload.data.password.trim(),
    };
    const response: AxiosResponse<ICreateAdminResponse> = yield call(
      httpClient.post,
      'admin',
      body,
    );

    if (response.status === EHttpStatusCodes.CREATED) {
      ToastManager.show({
        title: response.data.message,
        type: 'success',
      });
      action.payload.functions.success();
      yield put(
        adminActions.createSuccess({
          email: response.data.admin.email,
          id: response.data.admin.id,
          name: response.data.admin.name,
        }),
      );
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(adminActions.createFailure());
  }
}

export default createAdmin;
