import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllAdminRequest from 'services/httpClient/request/admin/IGetAllAdminRequest';
import IGetAllAdminResponse from 'services/httpClient/responses/admin/IGetAllAdminResponse';

import { IListAdminRequestAction } from '../reducers/list/request';

import { adminActions } from '..';

function* listAdmin(action: PayloadAction<IListAdminRequestAction>) {
  try {
    const params: IGetAllAdminRequest = {
      page: action.payload.data.page,
    };
    const response: AxiosResponse<IGetAllAdminResponse> = yield call(
      httpClient.get,
      'admin',
      { params },
    );
    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        adminActions.listSuccess({
          list: [],
          totalPages: 1,
        }),
      );
      return;
    }
    yield put(
      adminActions.listSuccess({
        list: response.data.list,
        totalPages: response.data.totalPages,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(adminActions.listFailure());
  }
}

export default listAdmin;
