import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAdminInitialData } from '../../index';

interface IListAdminSuccessAction {
  list: {
    email: string;
    id: number;
    name: string;
  }[];
  totalPages: number;
}

function listAdminSuccess(
  draft: Draft<IAdminInitialData>,
  action: PayloadAction<IListAdminSuccessAction>,
) {
  draft.list.config.isLoading = false;
  draft.list.config.pagination.totalPages = action.payload.totalPages;
  draft.list.data = action.payload.list;
}

export default listAdminSuccess;
