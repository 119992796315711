import BodyProductPriceList from './PriceList/Body/index';
import HeaderProductPriceList from './PriceList/Header/index';
import BodyProductPriceScales from './PriceScales/Body/index';
import HeaderProductPriceScales from './PriceScales/Header/index';

const ProductItem = {
  BodyProductPriceList,
  BodyProductPriceScales,
  HeaderProductPriceList,
  HeaderProductPriceScales,
};

export default ProductItem;
