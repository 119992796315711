import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

export interface ISelectRequestAction {
  data: {
    id?: number;
  };
}

function selectRequest(
  draft: Draft<IPriceListInitialData>,
  _: PayloadAction<ISelectRequestAction>,
) {
  draft.select.data = [];
  draft.select.config.isError = false;
  draft.select.config.isLoading = true;
}

export default selectRequest;
