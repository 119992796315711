import { createSlice } from '@reduxjs/toolkit';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import IList from 'models/IList';

import reducers from './reducers';

export interface IListInitialData {
  create: {
    config: {
      isLoading: boolean;
    };
  };
  createItem: {
    config: {
      isLoading: boolean;
    };
  };
  data: IList[];
  linkOptionSelected: IComponentSelectProps<string> | null;
  linkOptions: {
    data: IComponentSelectProps<string>[];
  };
  remove: {
    config: {
      isLoading: boolean;
    };
  };
  removeItem: {
    config: {
      isLoading: boolean;
    };
  };
  update: {
    config: {
      isLoading: boolean;
    };
  };
  updateItem: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IListInitialData = {
  create: {
    config: {
      isLoading: false,
    },
  },
  createItem: {
    config: {
      isLoading: false,
    },
  },
  data: [],
  linkOptionSelected: null,
  linkOptions: {
    data: [],
  },
  remove: {
    config: {
      isLoading: false,
    },
  },
  removeItem: {
    config: {
      isLoading: false,
    },
  },
  update: {
    config: {
      isLoading: false,
    },
  },
  updateItem: {
    config: {
      isLoading: false,
    },
  },
};

const listSlice = createSlice({
  name: '@list',
  initialState,
  reducers,
});

export const listActions = listSlice.actions;
export const listReducers = listSlice.reducer;
