import Tables from 'styles/tables';

import { CustomerItemsHeader } from './styles';

const CustomerItemHeader: React.FC = () => {
  return (
    <CustomerItemsHeader>
      <Tables.ItemHeader>ID</Tables.ItemHeader>
      <Tables.ItemHeader>SKU</Tables.ItemHeader>
      <Tables.ItemHeader>Nome do cliente</Tables.ItemHeader>
      <Tables.ItemHeader>E-mail</Tables.ItemHeader>
      <Tables.ItemHeader>Acesso</Tables.ItemHeader>
      <Tables.ItemHeader>Telefone</Tables.ItemHeader>
      <Tables.ItemHeader>UF</Tables.ItemHeader>
    </CustomerItemsHeader>
  );
};

export default CustomerItemHeader;
