import { Draft } from '@reduxjs/toolkit';

import { ICustomerAccessInitialData } from '../..';

function pdfCustomerAccessFailure(
  draft: Draft<ICustomerAccessInitialData>,
): void {
  draft.pdf.config.isLoading = false;
}

export default pdfCustomerAccessFailure;
