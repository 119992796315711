import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetRankingCustomerResponse from 'services/httpClient/responses/dashboard/IGetRankingCustomerResponse';

import { dashboardActions } from '../index';

function* rankingCustomer() {
  try {
    const {
      data: { list },
    }: AxiosResponse<IGetRankingCustomerResponse> = yield call(
      httpClient.get,
      `admin/dashboard/customers-ranking`,
    );

    yield put(
      dashboardActions.rankingCustomerSuccess({
        data: { list },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(dashboardActions.rankingCustomerFailure());
  }
}

export default rankingCustomer;
