import styled from 'styled-components';

import Tables from 'styles/tables';

export const OrdersItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 7rem 1fr repeat(2, 8rem) 9rem 6rem 10rem;
  padding: 1.25rem 0;
`;

export const OrdersItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 7rem 1fr repeat(2, 8rem) 9rem 6rem 10rem;
  padding: 1rem 0;
`;
