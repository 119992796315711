import styled from 'styled-components';

import { Form } from 'formik';

import colors from 'styles/colors';

export const Content = styled.div`
  background-color: ${colors.white};
  border-radius: 1rem;
  max-height: 80%;
  max-width: 30rem;
  padding: 2rem;
  position: relative;
  width: 100%;
  z-index: 4;
`;

export const Title = styled.h4`
  color: ${colors.primary};
  font-size: 1.1rem;
  font-weight: 500;
`;

export const Subtitle = styled.p`
  color: ${colors.subtitle};
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

export const CloseButton = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.subtitle};
  display: flex;
  font-size: 0;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 1rem;

  &:hover {
    opacity: 0.75;
  }
`;

export const ClientName = styled.strong`
  color: ${colors.orange};
  display: grid;
  font-size: 0.925rem;
  font-weight: 500;
  margin: 1.5rem 0 1rem;
`;

export const Label = styled.label`
  color: ${colors.label};
  font-size: 0.875rem;
  font-weight: 400;
  grid-column-end: 3;
  grid-column-start: 1;
  margin-bottom: 0.5rem;
`;

export const FormikForm = styled(Form)``;

export const FieldsGrid = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 8rem 1fr;
`;

export const InputContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-bottom: 0.4rem;
  margin-left: 1rem;
`;

export const FormActions = styled.div`
  margin: 1rem auto 0;
  max-width: 15rem;
`;
