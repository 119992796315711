import createFailure from './create/failure';
import createRequest from './create/request';
import createSuccess from './create/success';
import createItemFailure from './createItem/failure';
import createItemRequest from './createItem/request';
import createItemSuccess from './createItem/success';
import removeFailure from './remove/failure';
import removeRequest from './remove/request';
import removeSuccess from './remove/success';
import removeItemFailure from './removeItem/failure';
import removeItemRequest from './removeItem/request';
import removeItemSuccess from './removeItem/success';
import setChangeDropdown from './set/changeDropdown';
import setLinkOptionId from './set/linkOptionId';
import setLinkOptions from './set/linkOptions';
import setList from './set/list';
import updateFailure from './update/failure';
import updateRequest from './update/request';
import updateSuccess from './update/success';
import updateItemFailure from './updateItem/failure';
import updateItemRequest from './updateItem/request';
import updateItemSuccess from './updateItem/success';
import updateItemPositionFailure from './updateItemPosition/failure';
import updateItemPositionRequest from './updateItemPosition/request';
import updateItemPositionSuccess from './updateItemPosition/success';
import updateListPositionFailure from './updateListPosition/failure';
import updateListPositionRequest from './updateListPosition/request';
import updateListPositionSuccess from './updateListPosition/success';

const listReducers = {
  createFailure,
  createRequest,
  createSuccess,
  createItemFailure,
  createItemRequest,
  createItemSuccess,
  removeFailure,
  removeRequest,
  removeSuccess,
  removeItemFailure,
  removeItemRequest,
  removeItemSuccess,
  setChangeDropdown,
  setLinkOptionId,
  setLinkOptions,
  setList,
  updateFailure,
  updateRequest,
  updateSuccess,
  updateItemFailure,
  updateItemRequest,
  updateItemSuccess,
  updateItemPositionFailure,
  updateItemPositionRequest,
  updateItemPositionSuccess,
  updateListPositionFailure,
  updateListPositionRequest,
  updateListPositionSuccess,
};

export default listReducers;
