import { all, takeLatest } from 'redux-saga/effects';

import { adminActions } from '../index';
import createAdmin from './create';
import listAdmin from './list';
import removeAdmin from './remove';
import updateAdmin from './update';

const adminSagas = all([
  takeLatest(adminActions.createRequest, createAdmin),
  takeLatest(adminActions.listRequest, listAdmin),
  takeLatest(adminActions.updateRequest, updateAdmin),
  takeLatest(adminActions.removeRequest, removeAdmin),
]);

export default adminSagas;
