import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IConfigInitialData } from '../../index';

interface ISetPedTokenAction {
  data: { pedToken: string };
}

function setPedToken(
  draft: Draft<IConfigInitialData>,
  action: PayloadAction<ISetPedTokenAction>,
) {
  draft.pedToken = action.payload.data.pedToken;
}

export default setPedToken;
