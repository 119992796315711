export default {
  background: '#EFEFEF',
  tabBackground: '#F5F5F5',
  backgroundDark: '#d9dadb',

  boxShadow: 'rgba(0, 0, 0, 0.25)',

  primary: '#363F5F',

  title: '#363F5F',
  subtitle: '#7B7B7B',
  text: '#666',
  secondaryText: '#6C7A89',

  label: '#666C81',
  input: '#49506A',
  placeholder: '#AAAAAA',
  border: '#DEDEDE',

  red: '#D05959',
  orange: '#DB940A',
  white: '#FFFFFF',
  green: '#49AA26',
  yellow: '#DEB41D',
  blue: '#298CD4',
  blueDark: '#100DA9',

  transparent: 'transparent',
};
