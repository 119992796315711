import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { EOrderOrigin, EOrderStatus } from 'models/IOrder';

import { IOrderInitialData } from '../../index';

export interface ISpreadsheetOrderRequestAction {
  data: {
    dateEnd?: string;
    dateStart?: string;
    name?: string;
    origin?: EOrderOrigin;
    status?: EOrderStatus;
  };
}

function spreadsheetOrderRequest(
  draft: Draft<IOrderInitialData>,
  _: PayloadAction<ISpreadsheetOrderRequestAction>,
) {
  draft.spreadsheet.config.isLoading = true;
}

export default spreadsheetOrderRequest;
