import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetPriceListResponse from 'services/httpClient/responses/priceList/IIGetPriceListResponse';

import { priceListActions } from '..';

import { ISelectRequestAction } from '../reducers/select/request';

function* select(action: PayloadAction<ISelectRequestAction>) {
  try {
    const {
      data: { list },
      status,
    }: AxiosResponse<IGetPriceListResponse> = yield call(
      httpClient.get,
      'admin/price-lists-select',
    );

    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(priceListActions.selectSuccess({ data: { list: [] } }));
      return;
    }
    yield put(priceListActions.selectSuccess({ data: { list } }));
    if (action.payload.data.id) {
      yield put(
        priceListActions.setPriceListOptionId({
          data: {
            id: action.payload.data.id,
          },
        }),
      );
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(priceListActions.selectFailure());
  }
}

export default select;
