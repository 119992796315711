import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IExternalScriptRequestAction {
  data: {
    externalScript: string;
  };
}

function externalScriptRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IExternalScriptRequestAction>,
) {
  draft.externalScript.config.isLoading = true;
}

export default externalScriptRequest;
