import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface ICustomerAccessStateData {
  companyName: string;
  customerId: number;
  customerName: string;
  dateAccess: string;
  document: string;
  id: number;
}
export interface ICustomerAccessInitialData {
  getAll: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    data: ICustomerAccessStateData[];
  };
  pdf: {
    config: {
      isLoading: boolean;
    };
  };
  spreadsheet: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: ICustomerAccessInitialData = {
  getAll: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    data: [],
  },
  pdf: {
    config: {
      isLoading: false,
    },
  },
  spreadsheet: {
    config: {
      isLoading: false,
    },
  },
};

const customerAccessSlice = createSlice({
  name: '@customerAccess',
  initialState,
  reducers,
});

export const customerAccessActions = customerAccessSlice.actions;
export const customerAccessReducers = customerAccessSlice.reducer;
