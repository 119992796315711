import ECustomerSituation from 'enums/customersSituation';

const customerSituations = [
  {
    label: ECustomerSituation.Active,
    value: ECustomerSituation.Active,
  },
  {
    label: ECustomerSituation.Inactive,
    value: ECustomerSituation.Inactive,
  },
  {
    label: ECustomerSituation.Defaulting,
    value: ECustomerSituation.Defaulting,
  },
];

export default customerSituations;
