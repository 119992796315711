import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IAdmin from 'models/IAdmin';

import { IAdminInitialData } from '../../index';

interface IUpdateAdminSuccessAction {
  email: string;
  id: number;
  name: string;
}

function updateAdminSuccess(
  draft: Draft<IAdminInitialData>,
  action: PayloadAction<IUpdateAdminSuccessAction>,
) {
  draft.list.data = draft.list.data.map(admin => {
    if (admin.id === action.payload.id) {
      const adminToUpdate: IAdmin = {
        ...admin,
        email: action.payload.email,
        name: action.payload.name,
      };
      return adminToUpdate;
    }
    return admin;
  });
  draft.update.config.isLoading = false;
}

export default updateAdminSuccess;
