import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetAllCustomerDrugsResponse from 'services/httpClient/responses/customer/IGetAllCustomerDrugsResponse';

import { ICustomerInitialData } from '../../index';

function drugsSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IGetAllCustomerDrugsResponse>,
) {
  draft.drugs.data = action.payload.list.map(drug => ({
    code: drug.code,
    description: drug.description,
  }));
  draft.drugs.config.isLoading = false;
}

export default drugsSuccess;
