import { Draft, PayloadAction } from '@reduxjs/toolkit';
import {
  ISettingsAddress,
  ISettingsColumns,
  ISettingsCompany,
  ISettingsDesign,
  ISettingsFilters,
  ISettingsInternal,
  ISettingsMeuCatalogo,
  ISettingsOthers,
  ISettingsPedcoins,
  ISettingsProducts,
  ISettingsPurchase,
  ISettingsShipping,
  ISettingsSocialMedia,
} from 'models/ISettings';
import { formatters } from 'racsys-js-tools';

import { ISettingInitialData } from '../../index';

interface ISetDataAction {
  address: ISettingsAddress;
  columns: ISettingsColumns;
  company: ISettingsCompany;
  design: ISettingsDesign;
  filters: ISettingsFilters;
  internal: ISettingsInternal;
  meuCatalogo: ISettingsMeuCatalogo;
  others: ISettingsOthers;
  pedcoins: ISettingsPedcoins;
  products: ISettingsProducts;
  purchase: ISettingsPurchase;
  shipping: ISettingsShipping;
  socialMedia: ISettingsSocialMedia;
}

function setData(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<ISetDataAction>,
) {
  draft.data = {
    internal: action.payload.internal,
    address: action.payload.address,
    columns: action.payload.columns,
    company: action.payload.company,
    design: action.payload.design,
    filters: action.payload.filters,
    meuCatalogo: action.payload.meuCatalogo,
    others: action.payload.others,
    pedcoins: action.payload.pedcoins,
    products: action.payload.products,
    purchase: action.payload.purchase,
    shipping: action.payload.shipping,
    socialMedia: {
      facebook: action.payload.socialMedia.facebook ?? '',
      instagram: action.payload.socialMedia.instagram ?? '',
      linkedin: action.payload.socialMedia.linkedin ?? '',
      whatsapp: action.payload.socialMedia.whatsapp
        ? formatters.phone(action.payload.socialMedia.whatsapp, {
            defaultValue: '',
          })
        : '',
    },
  };
}

export default setData;
