import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IColumnsRequestAction {
  data: {
    displayColorsStock: boolean;
    showOnlyPriceTotal: boolean;
    showProductsWithoutLimit: boolean;
  };
}

function columnsRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IColumnsRequestAction>,
) {
  draft.columns.config.isLoading = true;
}

export default columnsRequest;
