import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import formatters from 'formatters';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCustomerRequest from 'services/httpClient/request/customer/IGetCustomerRequest';
import IGetCustomerResponse from 'services/httpClient/responses/customer/IGetCustomerResponse';

import { customerActions } from '..';

import { IGetAllRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllRequestAction>) {
  try {
    const documentFormatted = action.payload.data.document
      ? formatters.removeMask(action.payload.data.document)
      : undefined;
    const params: IGetCustomerRequest = {
      page: action.payload.data.page as number,
      document: documentFormatted,
      name: action.payload.data.name ? action.payload.data.name : undefined,
      situation: action.payload.data.situation
        ? action.payload.data.situation
        : undefined,
    };
    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetCustomerResponse> = yield call(
      httpClient.get,
      'admin/customers',
      { params: action.payload.data.withPagination ? params : undefined },
    );

    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        customerActions.getAllSuccess({
          data: {
            list: [],
            totalPages: 0,
            withPagination: action.payload.data.withPagination,
          },
        }),
      );
      return;
    }
    yield put(
      customerActions.getAllSuccess({
        data: {
          list,
          totalPages,
          withPagination: action.payload.data.withPagination,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.getAllFailure());
  }
}

export default getAll;
