import listFailure from './list/failure';
import listRequest from './list/request';
import listSuccess from './list/success';

const kitReducers = {
  listFailure,
  listRequest,
  listSuccess,
};

export default kitReducers;
