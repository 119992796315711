import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IKitInitialData } from '../../index';

export interface IListSuccessAction {
  data: {
    list: {
      id: number;
      name: string;
    }[];
  };
}

function listSuccess(
  draft: Draft<IKitInitialData>,
  action: PayloadAction<IListSuccessAction>,
) {
  draft.data.config.isLoading = false;
  const list = action.payload.data.list.map(item => ({
    label: item.name,
    value: item.id,
  }));

  draft.data.list = list;
}

export default listSuccess;
