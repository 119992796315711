import styled from 'styled-components';

import colors from 'styles/colors';

export const ExtractCards = styled.div`
  column-gap: 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 1.5rem;
`;

export const Warning = styled.div`
  color: ${colors.yellow};
  column-gap: 0.25rem;
  display: flex;
  font-size: 0.75rem;
  font-weight: 600;
  grid-column: 1 / 4;
  justify-content: flex-end;
  margin-top: 0.75rem;
`;

export const WarningMessage = styled.p`
  color: ${colors.text};
  font-weight: 400;
`;
