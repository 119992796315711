import { createSlice } from '@reduxjs/toolkit';
import IPagination from 'interfaces/IPagination';
import IAdmin from 'models/IAdmin';

import reducers from './reducers';

export interface IAdminInitialData {
  create: {
    config: {
      isLoading: boolean;
    };
  };
  list: {
    config: {
      isError: boolean;
      isLoading: boolean;
      pagination: IPagination;
    };
    data: IAdmin[];
  };
  remove: {
    config: {
      isLoading: boolean;
    };
  };
  update: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IAdminInitialData = {
  list: {
    config: {
      isError: false,
      isLoading: false,
      pagination: {
        page: 1,
        totalPages: 1,
      },
    },
    data: [],
  },
  create: {
    config: {
      isLoading: false,
    },
  },
  update: {
    config: {
      isLoading: false,
    },
  },
  remove: {
    config: {
      isLoading: false,
    },
  },
};

const adminSlice = createSlice({
  name: '@admin',
  initialState,
  reducers,
});

export const adminActions = adminSlice.actions;
export const adminReducers = adminSlice.reducer;
