import forgotPasswordFailure from './forgotPassword/failure';
import forgotPasswordRequest from './forgotPassword/request';
import forgotPasswordSuccess from './forgotPassword/success';
import setHideUpdateNotes from './set/hideUpdateNotes';
import setProfile from './set/profile';
import signInFailure from './signIn/failure';
import signInRequest from './signIn/request';
import signInSuccess from './signIn/success';
import signOutRequest from './signOut/request';
import updatePasswordFailure from './updatePassword/failure';
import updatePasswordRequest from './updatePassword/request';
import updatePasswordSuccess from './updatePassword/success';

const authReducers = {
  forgotPasswordFailure,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  setHideUpdateNotes,
  setProfile,
  signInFailure,
  signInRequest,
  signInSuccess,
  signOutRequest,
  updatePasswordFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
};

export default authReducers;
