import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import { EOrderOrigin, EOrderStatus } from 'models/IOrder';
import { orderActions } from 'store/slices/order';
import orderSelectors from 'store/slices/order/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputDate from 'components/input/Date';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';

import { FormikForm } from './styles';

export interface IOrderFilterFormData {
  dateEnd: string;
  dateStart: string;
  name: string;
  origin: IComponentSelectProps<EOrderOrigin> | null;
  status: IComponentSelectProps<EOrderStatus> | null;
}

const orderStatusOptions = [
  {
    label: 'Pedido cancelado',
    value: 'CANCELADO',
  },
  {
    label: 'Pedido faturado',
    value: 'FATURADO',
  },
  {
    label: 'Pedido enviado',
    value: 'FECHADO',
  },
  {
    label: 'Pedido sendo processado',
    value: 'PROCESSANDO',
  },
];

const orderOriginOptions = [
  {
    label: 'APP',
    value: 'APP',
  },
  {
    label: 'SITE',
    value: 'SITE',
  },
];

const schema = Yup.object().shape(
  {
    dateEnd: Yup.date().when('dateStart', {
      is: (val: string) => !!val,
      then: Yup.date()
        .required('Informe uma data final')
        .min(
          Yup.ref('dateStart'),
          'Data final não pode ser inferior a data inicial',
        ),
      otherwise: Yup.date().nullable(),
    }),
    dateStart: Yup.date().when('dateEnd', {
      is: (val: string) => !!val,
      then: Yup.date().required('Informe uma data inicial'),
      otherwise: Yup.date().nullable(),
    }),
  },
  [['dateEnd', 'dateStart']],
);

const OrdersFilter: React.ForwardRefRenderFunction<
  FormikProps<IOrderFilterFormData>
> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();

  const filterOrdersFormRef = useRef<FormikProps<IOrderFilterFormData>>(
    {} as FormikProps<IOrderFilterFormData>,
  );

  const isLoading = useReduxSelector(orderSelectors.listIsLoading);

  const handleSearchOrder = useCallback(
    (data: IOrderFilterFormData) => {
      reduxDispatch(
        orderActions.listRequest({
          data: {
            page: 1,
            status: data.status?.value,
            origin: data.origin?.value,
            name: data.name,
            dateStart: data.dateStart,
            dateEnd: data.dateEnd,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleSelectChangeStatus = useCallback(
    (option: IOptionTypeBase | null) => {
      filterOrdersFormRef.current?.setFieldValue('status', option);
    },
    [],
  );

  const handleSelectChangeOriginEnd = useCallback(
    (option: IOptionTypeBase | null) => {
      filterOrdersFormRef.current?.setFieldValue('origin', option);
    },
    [],
  );

  const handleClear = useCallback(() => {
    filterOrdersFormRef.current.resetForm();
    reduxDispatch(
      orderActions.listRequest({
        data: { page: 1 },
      }),
    );
  }, [reduxDispatch]);

  useImperativeHandle(ref, () => ({
    ...filterOrdersFormRef.current,
  }));

  return (
    <Formik
      initialValues={{
        status: null,
        origin: null,
        name: '',
        dateEnd: '',
        dateStart: '',
      }}
      innerRef={filterOrdersFormRef}
      onSubmit={handleSearchOrder}
      validationSchema={schema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <ComponentInputSimple
            backgroundColor={colors.white}
            label="Cliente"
            name="name"
            onChange={handleChange('name')}
            placeholder="Pesquisar por cliente"
            value={values.name}
          />
          <ComponentSelectSimple
            backgroundColor={colors.white}
            isClearable
            label="Status"
            name="status"
            onChange={handleSelectChangeStatus}
            options={orderStatusOptions}
            placeholder="Selecione"
            value={values.status}
          />
          <ComponentSelectSimple
            backgroundColor={colors.white}
            isClearable
            label="Origem"
            name="originEnd"
            onChange={handleSelectChangeOriginEnd}
            options={orderOriginOptions}
            placeholder="Selecione"
            value={values.origin}
          />
          <ComponentInputDate
            backgroundColor={colors.white}
            errorMessage={errors.dateStart}
            hasError={!!errors.dateStart}
            label="Data início"
            max={values.dateEnd}
            name="dateStart"
            onChange={handleChange('dateStart')}
            value={values.dateStart}
          />
          <ComponentInputDate
            backgroundColor={colors.white}
            errorMessage={errors.dateEnd}
            hasError={!!errors.dateEnd}
            label="Data fim"
            min={values.dateStart}
            name="dateEnd"
            onChange={handleChange('dateEnd')}
            value={values.dateEnd}
          />
          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={isLoading}
            onClick={handleClear}
            title="Limpar pesquisa"
            type="button"
          >
            <FiX size={18} />
          </ComponentButtonBase>
          <ComponentButtonBase
            disabled={isLoading}
            title="Pesquisar"
            type="submit"
          >
            <FiSearch size={18} />
          </ComponentButtonBase>
        </FormikForm>
      )}
    </Formik>
  );
};

export default forwardRef(OrdersFilter);
