import { Draft } from '@reduxjs/toolkit';

import { IAuthInitialData } from '../../index';

function signOutRequest(draft: Draft<IAuthInitialData>) {
  draft.authorization.token = null;
  draft.profile = undefined;
}

export default signOutRequest;
