import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import EListTypes from 'enums/listType';
import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm, Options } from '../styles';

interface IFormData {
  productsViewMode: EListTypes;
}

const ProductViewMode = () => {
  const reduxDispatch = useReduxDispatch();

  const formikRef = useRef<FormikProps<IFormData>>(null);

  const isLoading = useReduxSelector(
    settingSelectors.productsViewModeIsLoading,
  );
  const products = useReduxSelector(settingSelectors.products);

  const handleSubmit = useCallback(
    (data: IFormData) => {
      reduxDispatch(
        settingActions.productsViewModeRequest({
          data: {
            productsViewMode: data.productsViewMode,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChange = useCallback((value: EListTypes) => {
    formikRef.current?.setFieldValue('productsViewMode', value);
    formikRef.current?.submitForm();
  }, []);

  return (
    <Formik
      initialValues={{
        productsViewMode: products.productsViewMode,
      }}
      innerRef={formikRef}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Controle de exibição do tipo da listagem de produtos
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>

            <CardSubtitle>Define modo de visualização principal</CardSubtitle>

            <Options gridTemplateColumns="1fr_1fr_1fr">
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={EListTypes.Grade === values.productsViewMode}
                label="Exibir modo grade"
                name="productsViewMode"
                onChange={() => handleOnChange(EListTypes.Grade)}
              />
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={EListTypes.List === values.productsViewMode}
                label="Exibir modo lista"
                name="productsViewMode"
                onChange={() => handleOnChange(EListTypes.List)}
              />
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={EListTypes.Table === values.productsViewMode}
                label="Exibir modo tabela"
                name="productsViewMode"
                onChange={() => handleOnChange(EListTypes.Table)}
              />
            </Options>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ProductViewMode;
