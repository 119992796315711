import styled from 'styled-components';

import Tables from 'styles/tables';

export const PriceListItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 5rem 1fr 10rem 3rem repeat(5, 6rem);
  padding: 1.25rem 1rem;
`;

export const PriceListItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 5rem 1fr 10rem 3rem repeat(5, 6rem);
  padding: 1rem;
`;
