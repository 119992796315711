import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EImageType from 'enums/imageType';

import { IListInitialData } from '../../index';

export interface ICreateItemRequestAction {
  data: {
    file: Blob;
    link: {
      priceListId?: number;
      type: string;
      value: number;
    };
    listId: number;
    uploadType: EImageType;
  };
  functions: {
    success: () => void;
  };
}

function createItemRequest(
  draft: Draft<IListInitialData>,
  _: PayloadAction<ICreateItemRequestAction>,
) {
  draft.createItem.config.isLoading = true;
}

export default createItemRequest;
