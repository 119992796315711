import { createSlice } from '@reduxjs/toolkit';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import IManufacturer from 'models/IManufacturer';
import IProduct from 'models/IProduct';

import reducers from './reducers';

export interface IManufacturerInitialData {
  coinsPerReal: {
    config: {
      isLoading: boolean;
    };
  };
  details: {
    config: {
      isLoading: boolean;
    };
    data: IManufacturer | null;
  };
  getAll: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    list: IManufacturer[];
  };
  getAllProducts: {
    config: {
      isError: boolean;
      isLoading: boolean;
      page: number;
      totalPages: number;
    };
    list: IProduct[];
  };
  select: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    list: IComponentSelectProps[];
  };
}

const initialState: IManufacturerInitialData = {
  coinsPerReal: {
    config: {
      isLoading: false,
    },
  },
  details: {
    config: {
      isLoading: false,
    },
    data: null,
  },
  getAll: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    list: [],
  },
  getAllProducts: {
    config: {
      isError: false,
      isLoading: false,
      page: 1,
      totalPages: 0,
    },
    list: [],
  },
  select: {
    config: {
      isError: false,
      isLoading: false,
    },
    list: [],
  },
};

const manufacturerSlice = createSlice({
  name: '@manufacturer',
  initialState,
  reducers,
});

export const manufacturerActions = manufacturerSlice.actions;
export const manufacturerReducers = manufacturerSlice.reducer;
