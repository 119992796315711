import { Draft } from '@reduxjs/toolkit';

import { IAdminInitialData } from '../../index';

function listAdminFailure(draft: Draft<IAdminInitialData>) {
  draft.list.config.isError = true;
  draft.list.config.isLoading = false;
}

export default listAdminFailure;
