import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IThemesRequestAction {
  data: {
    mainColor: string;
  };
}

function themesRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IThemesRequestAction>,
) {
  draft.themes.config.isLoading = true;
}

export default themesRequest;
