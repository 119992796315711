import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IUpdateListPositionRequestAction {
  data: {
    ids: number[];
  };
}

function updateListPositionRequest(
  draft: Draft<IListInitialData>,
  action: PayloadAction<IUpdateListPositionRequestAction>,
) {
  draft.data.forEach(list => {
    action.payload.data.ids.forEach((id, index) => {
      if (list.id === id) {
        // eslint-disable-next-line no-param-reassign
        list.position = index;
      }
    });
  });
  draft.data = draft.data.sort((a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  });
}

export default updateListPositionRequest;
