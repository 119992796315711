import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';
import { bannerActions } from 'store/slices/banner';
import { listActions } from 'store/slices/list';
import { settingActions } from 'store/slices/setting';

import httpClient, { httpClientSetAuthorization } from 'services/httpClient';
import IPostSignInRequest from 'services/httpClient/request/auth/IPostSignInRequest';
import IGetProfileResponse from 'services/httpClient/responses/auth/IGetProfileResponse';
import IPostSignInResponse from 'services/httpClient/responses/auth/IPostSignInResponse';

import { authActions } from '../index';
import { ISignInRequestAction } from '../reducers/signIn/request';

function* signIn({
  payload: { data, functions },
}: PayloadAction<ISignInRequestAction>) {
  try {
    const body: IPostSignInRequest = {
      email: data.email,
      password: data.password,
    };

    const {
      data: { token },
    }: AxiosResponse<IPostSignInResponse> = yield call(
      httpClient.post,
      'admin/sessions',
      body,
    );
    httpClientSetAuthorization(token);

    const { data: profileData }: AxiosResponse<IGetProfileResponse> =
      yield call(httpClient.get, 'admin/sessions');

    yield put(authActions.signInSuccess({ token }));
    yield put(authActions.setProfile(profileData));
    yield put(bannerActions.setData({ data: profileData.banners }));
    yield put(
      listActions.setList({
        data: {
          list: profileData.lists,
        },
      }),
    );
    yield put(settingActions.setData(profileData.settings));
    functions.navigateTo();
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(authActions.signInFailure());
  }
}

export default signIn;
