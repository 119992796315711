import { Draft } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

function getAllFailure(draft: Draft<IPriceListInitialData>) {
  draft.getAll.config.isLoading = false;
  draft.getAll.config.isError = true;
}

export default getAllFailure;
