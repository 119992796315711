import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAdminInitialData } from '../../index';

export interface IRemoveAdminRequestAction {
  data: {
    id: number;
  };
  functions: {
    success: () => void;
  };
}

function removeAdmin(
  draft: Draft<IAdminInitialData>,
  _: PayloadAction<IRemoveAdminRequestAction>,
) {
  draft.remove.config.isLoading = true;
}

export default removeAdmin;
