import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPostCreateListItemRequest from 'services/httpClient/request/list/IPostCreateListItemRequest';
import IUploadImageResponse from 'services/httpClient/responses/image/IUploadImageResponse';
import IPostCreateListItemResponse from 'services/httpClient/responses/list/IPostCreateListItemResponse';

import { listActions } from '../index';
import { ICreateItemRequestAction } from '../reducers/createItem/request';

function* createItem(action: PayloadAction<ICreateItemRequestAction>) {
  try {
    const formData = new FormData();

    formData.append('file', action.payload.data.file);
    formData.append('type', action.payload.data.uploadType);

    const {
      data: { url: imageUrl },
    }: AxiosResponse<IUploadImageResponse> = yield call(
      httpClient.post,
      'admin/pictures',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const body: IPostCreateListItemRequest = {
      link: {
        type: action.payload.data.link.type,
        value: action.payload.data.link.value,
        priceListId: action.payload.data.link.priceListId ?? undefined,
      },
      url: imageUrl,
    };

    const {
      data: { listItem, message },
    }: AxiosResponse<IPostCreateListItemResponse> = yield call(
      httpClient.post,
      `admin/settings/lists/${action.payload.data.listId}/items`,
      body,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });
    action.payload.functions.success();
    yield put(
      listActions.createItemSuccess({
        data: {
          listId: action.payload.data.listId,
          listItem,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(listActions.createItemFailure());
  }
}

export default createItem;
