import { createSlice } from '@reduxjs/toolkit';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';

import reducers from './reducers';

export interface IKitInitialData {
  data: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    list: IComponentSelectProps[];
  };
}

const initialState: IKitInitialData = {
  data: {
    config: {
      isLoading: false,
      isError: false,
    },
    list: [],
  },
};

const kitSlice = createSlice({
  name: '@kit',
  initialState,
  reducers,
});

export const kitActions = kitSlice.actions;
export const kitReducers = kitSlice.reducer;
