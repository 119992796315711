import styled from 'styled-components';

import colors from 'styles/colors';

interface IContainerProps {
  total?: boolean;
}

export const Container = styled.div<IContainerProps>`
  background-color: ${props => (!props.total ? colors.white : colors.title)};
  border-radius: 0.5rem;
  padding: 1.5rem;
`;

export const Header = styled.header`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
`;

interface INameProps {
  total?: boolean;
}

export const Name = styled.label<INameProps>`
  color: ${props => (!props.total ? colors.label : colors.white)};
  font-size: 1rem;
`;

export const PedcoinIcon = styled.div`
  align-items: center;
  border: 2px solid ${colors.white};
  border-radius: 50%;
  color: ${colors.white};
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
`;

interface IPointsProps {
  total?: boolean;
}

export const Points = styled.div<IPointsProps>`
  color: ${props => (!props.total ? colors.text : colors.white)};
  margin-top: 1.75rem;
`;

export const Value = styled.span`
  font-size: 1.75rem;
  font-weight: 500;
`;
