import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAdminInitialData } from '../../index';

export interface IListAdminRequestAction {
  data: {
    page: number;
  };
}

function listAdmin(
  draft: Draft<IAdminInitialData>,
  action: PayloadAction<IListAdminRequestAction>,
) {
  draft.list.config.isError = false;
  draft.list.config.isLoading = true;
  draft.list.config.pagination.page = action.payload.data.page;
  draft.list.data = [];
}

export default listAdmin;
