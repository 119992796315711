/* eslint-disable no-console */
import io, { Socket } from 'socket.io-client';

import EServerWsChannels from './channels';

class WsClient {
  private wsClient: Socket | undefined;

  public start({
    callback,
    pedToken,
    token,
  }: {
    callback: (customerIds: string[]) => void;
    pedToken: string;
    token: string;
  }): void {
    this.wsClient = io(process.env.REACT_APP_WS_URL ?? '', {
      transports: ['websocket'],
      auth: {
        token,
        pedToken,
      },
    });
    this.wsClient.on('connect', () => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Conectado com o back-end');
      }
    });

    this.wsClient.on(EServerWsChannels.Online, (customerIds: string[]) => {
      callback(customerIds);
    });

    this.wsClient.on(EServerWsChannels.Offline, (customerIds: string[]) => {
      callback(customerIds);
    });
  }

  public stop(): void {
    if (!this.wsClient) {
      return;
    }
    this.wsClient.disconnect();
  }
}

export default new WsClient();
