import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IUpdateListPositionRequestAction {
  data: {
    ids: number[];
  };
}

function updateListPositionRequest(
  _: Draft<IListInitialData>,
  __: PayloadAction<IUpdateListPositionRequestAction>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {}

export default updateListPositionRequest;
