import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccess from './getAll/success';

const productRequestReducers = {
  getAllFailure,
  getAllRequest,
  getAllSuccess,
};

export default productRequestReducers;
