import styled from 'styled-components';

import colors from 'styles/colors';

export const SubtitleContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 12rem;
  justify-content: space-between;
  margin: 1.5rem 0 0.5rem;
  width: 100%;
`;

export const Subtitle = styled.h3`
  color: ${colors.subtitle};
  font-size: 1.115rem;
  font-weight: 500;
`;

export const BannersConfiguration = styled.div`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  padding: 1.5rem 2rem 1.5rem;
`;

export const TitleSeparator = styled.h2`
  color: ${colors.title};
  font-size: 1rem;
  font-weight: 400;
  margin: 0 0 0.5rem;
`;

export const BannerInformation = styled.div`
  align-items: center;
  background-color: ${colors.label};
  border-radius: 100%;
  color: ${colors.white};
  cursor: help;
  display: inline-flex;
  height: 1.15rem;
  justify-content: center;
  margin-left: 0.5rem;
  position: relative;
  width: 1.15rem;

  &:hover {
    &::before {
      border-bottom: 0.5rem solid transparent;
      border-right: 0.5rem solid ${colors.label};
      border-top: 0.5rem solid transparent;
      content: '';
      height: 0;
      position: absolute;
      right: -0.75rem;
      top: 0.25rem;
      width: 0;
      z-index: 2;
    }
    &::after {
      background-color: ${colors.label};
      border-radius: 0.5rem;
      color: ${colors.white};
      content: 'Para uma melhor visualização adicione os banners na proporção 12:5 (ex: 1200x500). Recomendamos o tamanho 1200x500';
      height: fit-content;
      left: calc(100% + 0.75rem);
      line-height: 1.15rem;
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
      position: absolute;
      top: -0.5rem;
      width: 10rem;
      z-index: 4;
    }
    &.smallBanner {
      &::after {
        content: 'Para uma melhor visualização adicione os banners menores na proporção 12:5 (ex: 1200x500). Aconselhamos o tamanho de 350x145 para os banners menores';
      }
    }
  }
`;

export const Label = styled.label`
  font-size: 0.875rem;
`;

export const Options = styled.div`
  column-gap: 1rem;
  display: flex;
  margin: 0.5rem 0;
`;

interface IOptionProps {
  optionSelected?: boolean;
}

export const Option = styled.button<IOptionProps>`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  font-size: 0.875rem;

  &::before {
    background-color: ${props =>
      props.optionSelected ? colors.white : colors.transparent};
    border: ${props =>
      props.optionSelected
        ? `4px solid ${colors.orange}`
        : `2px solid ${colors.border}`};
    border-radius: 0.5rem;
    box-sizing: border-box;
    content: '';
    height: 1rem;
    margin-right: 0.5rem;
    width: 1rem;
  }
`;

export const Helper = styled.p`
  color: ${colors.text};
  font-size: 0.75rem;
`;

export const HelperAction = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.orange};
  font-size: 0.75rem;
  margin: 0 0.25rem;
  &:hover {
    opacity: 0.75;
  }
`;

export const RotativeBanners = styled.div`
  margin-top: 2rem;
`;

export const Uploads = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.5rem;

  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const UploadButton = styled.button`
  align-items: center;
  background-color: ${colors.primary};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.white};
  column-gap: 0.5rem;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2.25rem;
  justify-content: center;
  padding: 0 1.5rem;
  width: fit-content;
  &:hover {
    opacity: 0.8;
  }
`;

export const UploadFile = styled.input`
  display: none;
`;

export const MiniatureBanners = styled.div`
  margin-top: 2rem;
`;

export const ErrorMessage = styled.p`
  color: ${colors.red};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

export const BannersActions = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 0.5rem;
  max-width: 12rem;
`;

export const NewList = styled.button`
  align-items: center;
  background-color: ${colors.primary};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.white};
  column-gap: 1rem;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 2rem;
  justify-content: center;

  &:hover {
    opacity: 0.8;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
