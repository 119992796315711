import getAll from './getAll';
import getAllIsError from './getAllIsError';
import getAllIsLoading from './getAllIsLoading';
import getAllPage from './getAllPage';
import getAllTotalPages from './getAllTotalPages';
import pdfIsLoading from './pdfIsLoading';
import spreadsheetIsLoading from './spreadsheetIsLoading';

const customerAccessSelectors = {
  getAll,
  getAllIsError,
  getAllIsLoading,
  getAllPage,
  getAllTotalPages,
  pdfIsLoading,
  spreadsheetIsLoading,
};

export default customerAccessSelectors;
