import { ButtonHTMLAttributes } from 'react';

import { ClipLoader } from 'react-spinners';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Container } from './styles';

interface IAuthButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

function AuthButton({
  children,
  isLoading = false,
  ...props
}: IAuthButtonProps): JSX.Element {
  return (
    <Container disabled={isLoading} {...props}>
      <ComponentIsVisible when={isLoading}>
        <ClipLoader color={colors.white} size={20} />
      </ComponentIsVisible>
      <ComponentIsVisible when={!isLoading}>{children}</ComponentIsVisible>
    </Container>
  );
}

AuthButton.defaultProps = {
  isLoading: false,
};

export default AuthButton;
