import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { call, put } from 'redux-saga/effects';

import { ISpreadsheetCustomerAccessRequestAction } from '../reducers/spreadsheet/request';

import httpClient from 'services/httpClient';

import helpers from 'helpers';

import { customerAccessActions } from '..';

function* spreadsheet(
  action: PayloadAction<ISpreadsheetCustomerAccessRequestAction>,
) {
  try {
    const params = {
      date: action.payload.data.date ? action.payload.data.date : undefined,
      page: action.payload.data.page ? action.payload.data.page : undefined,
    };
    const { data: customersAccessSpreadsheet } = yield call(
      httpClient.get,
      'admin/customers-access/spreadsheet',
      { params, responseType: 'blob' },
    );
    const url = window.URL.createObjectURL(
      new Blob([customersAccessSpreadsheet], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      }),
    );

    const link = document.createElement('a');
    link.href = url;
    link.download = `pedido-eletronico_${format(new Date(), 'dd-MM-yyyy')}`;
    document.body.appendChild(link);
    link.click();
    yield put(customerAccessActions.spreadsheetCustomerAccessSucess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerAccessActions.spreadsheetCustomerAccessFailure());
  }
}

export default spreadsheet;
