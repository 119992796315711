import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import { customerActions } from '..';

import httpClient from 'services/httpClient';
import IGetPaymentMethodsResponse from 'services/httpClient/responses/customer/IGetPaymentMethodsResponse';

import { IPaymentMethodsRequestAction } from '../reducers/paymentMethods/request';

function* paymentMethods(action: PayloadAction<IPaymentMethodsRequestAction>) {
  try {
    const { data, status }: AxiosResponse<IGetPaymentMethodsResponse> =
      yield call(
        httpClient.get,
        `admin/customers/${action.payload.data.customerId}/payment_methods`,
      );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(customerActions.paymentMethodsSuccess({ list: [] }));
      return;
    }
    yield put(customerActions.paymentMethodsSuccess({ list: data.list }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.paymentMethodsFailure());
  }
}

export default paymentMethods;
