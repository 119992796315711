import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface IConfigInitialData {
  pedToken?: string;
}

const initialState: IConfigInitialData = {
  pedToken: undefined,
};

const configSlice = createSlice({
  name: '@config',
  initialState,
  reducers,
});

export const configActions = configSlice.actions;
export const configReducers = configSlice.reducer;
