import Tables from 'styles/tables';

import { CustomerOrdersHeader } from '../styles';

const ItemHeader = () => {
  return (
    <CustomerOrdersHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader>Data de abertura</Tables.ItemHeader>
      <Tables.ItemHeader>Data de cancelamento</Tables.ItemHeader>
      <Tables.ItemHeader>Data de encerramento</Tables.ItemHeader>
      <Tables.ItemHeader>Data de processamento</Tables.ItemHeader>
      <Tables.ItemHeader>Data de faturamento</Tables.ItemHeader>
      <Tables.ItemHeader>ID do pagamento</Tables.ItemHeader>
      <Tables.ItemHeader>Nome do pagamento</Tables.ItemHeader>
    </CustomerOrdersHeader>
  );
};

export default ItemHeader;
