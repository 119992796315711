import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPostCreateListRequest from 'services/httpClient/request/list/IPostCreateListRequest';
import IPostCreateListResponse from 'services/httpClient/responses/list/IPostCreateListResponse';

import { listActions } from '../index';
import { ICreateRequestAction } from '../reducers/create/request';

function* create(action: PayloadAction<ICreateRequestAction>) {
  try {
    const body: IPostCreateListRequest = {
      quantityColumns: action.payload.data.quantityColumns,
      subtitle: action.payload.data.subtitle.trim(),
      title: action.payload.data.title.trim(),
      typeView: action.payload.data.typeView.value,
    };

    const {
      data: { list, message },
    }: AxiosResponse<IPostCreateListResponse> = yield call(
      httpClient.post,
      'admin/settings/lists',
      body,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });
    action.payload.functions.success();
    yield put(listActions.createSuccess({ data: list }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(listActions.createFailure());
  }
}

export default create;
