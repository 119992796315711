import React, { useCallback, useRef, useState } from 'react';

import { ColorResult, SketchPicker } from 'react-color';
import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import validators from 'validators';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  Card,
  CardContent,
  CardSubtitle,
  CardTitle,
  FormikForm,
} from '../styles';
import {
  ColorField,
  ColorPicker,
  SketchPickerCover,
  SketchPickerPopover,
} from './styles';

interface IColorControlFormData {
  colorHex: string;
}

const colorHexSchema = Yup.object().shape({
  colorHex: Yup.string()
    .required('Informe a cor')
    .min(7, 'Informe o hexadecimal com 7 caracteres (ex: "#abc123")')
    .max(7, 'Informe o hexadecimal com 7 caracteres (ex: "#abc123")')
    .test({
      test: value => validators.hexadecimal(value ?? ''),
      message: 'Informe um hexadecimal válido (ex: "#abc123")',
    }),
});

const ColorControl: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const [showPicker, setShowPicker] = useState<boolean>(false);
  const colorControlFormRef = useRef<FormikProps<IColorControlFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.themesIsLoading);
  const designer = useReduxSelector(settingSelectors.designer);

  const handleSetPicker = () => {
    setShowPicker(currentValue => !currentValue);
  };

  const handleChangeColor = (color: ColorResult) => {
    colorControlFormRef.current?.setFieldValue('colorHex', color.hex);
  };

  const handleColorControl = useCallback(
    (data: IColorControlFormData) => {
      reduxDispatch(
        settingActions.themesRequest({
          data: {
            mainColor: data.colorHex,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        colorHex: designer.mainColor,
      }}
      innerRef={colorControlFormRef}
      onSubmit={handleColorControl}
      validationSchema={colorHexSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardContent>
              <CardTitle>
                Configurar a cor principal da aplicação
                <ComponentIsVisible when={!isLoading}>
                  <FiCheckCircle color={colors.green} size={18} />
                </ComponentIsVisible>
                <ComponentIsVisible when={isLoading}>
                  <ClipLoader size={18} />
                </ComponentIsVisible>
              </CardTitle>
              <CardSubtitle>
                Escolha uma cor de sua preferência para ser a cor primária de
                sua aplicação (ex: #abc123)
              </CardSubtitle>

              <ColorPicker>
                <ColorField
                  color={values.colorHex}
                  onClick={handleSetPicker}
                  type="button"
                />
                <SketchPickerPopover showPicker={showPicker}>
                  <SketchPickerCover onClick={handleSetPicker} />
                  <SketchPicker
                    color={values.colorHex}
                    onChange={handleChangeColor}
                  />
                </SketchPickerPopover>

                <ComponentInputSimple
                  errorMessage={errors.colorHex}
                  hasError={!!errors.colorHex}
                  maxLength={7}
                  name="colorHex"
                  onChange={handleChange('colorHex')}
                  value={values.colorHex}
                />

                <ComponentButtonBase
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                >
                  Salvar
                </ComponentButtonBase>
              </ColorPicker>
            </CardContent>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ColorControl;
