import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import formatters from 'formatters';
import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import { Content, Info, Label, Subtitle, Value } from './styles';

interface ICustomerDetailsParams extends Record<string, string | undefined> {
  id: string;
}

const GeneralData = () => {
  const params = useParams<ICustomerDetailsParams>();
  const reduxDispatch = useReduxDispatch();

  const customerDetails = useReduxSelector(customerSelectors.details);
  const isLoading = useReduxSelector(customerSelectors.detailsIsLoading);

  const loadCustomerDetails = useCallback(() => {
    if (!params.id) {
      return;
    }
    reduxDispatch(
      customerActions.detailsRequest({
        data: {
          customerId: Number(params.id),
        },
      }),
    );
  }, [params.id, reduxDispatch]);

  useEffect(() => {
    loadCustomerDetails();
  }, [loadCustomerDetails]);

  return (
    <>
      <Subtitle>Dados gerais</Subtitle>
      <Content>
        <Info>
          <Label>Nome</Label>
          <Value isLoading={isLoading}>{customerDetails?.name}</Value>
        </Info>
        <Info>
          <Label>Código</Label>
          <Value isLoading={isLoading}>{customerDetails?.id}</Value>
        </Info>
        <Info>
          <Label>SKU</Label>
          <Value isLoading={isLoading}>{customerDetails?.sku}</Value>
        </Info>
        <Info>
          <Label>SKU da Rede</Label>
          <Value isLoading={isLoading}>
            {customerDetails?.network ?? 'Não informado'}
          </Value>
        </Info>
        <Info>
          <Label>Razão Social</Label>
          <Value isLoading={isLoading}>
            {customerDetails?.companyName ?? 'Não informado'}
          </Value>
        </Info>
        <Info>
          <Label>Documento</Label>
          <Value isLoading={isLoading}>
            {formatters.cpfOrCnpj(customerDetails?.document)}
          </Value>
        </Info>
        <Info>
          <Label>Email</Label>
          <Value isLoading={isLoading}>{customerDetails?.email}</Value>
        </Info>
        <Info>
          <Label>Estado</Label>
          <Value isLoading={isLoading}>{customerDetails?.state}</Value>
        </Info>
        <Info>
          <Label>Telefone</Label>
          <Value isLoading={isLoading}>
            {customerDetails?.phone
              ? formatters.phone(customerDetails?.phone)
              : 'Não informado'}
          </Value>
        </Info>
        <Info>
          <Label>Limite de Crédito</Label>
          <Value isLoading={isLoading}>{customerDetails?.creditLimit}</Value>
        </Info>
        <Info>
          <Label>Situação</Label>
          <Value isLoading={isLoading}>{customerDetails?.situation}</Value>
        </Info>
        <Info>
          <Label>Anvisa</Label>
          <Value isLoading={isLoading}>
            {customerDetails?.hasAvisa === true ? 'Sim' : 'Não'}
          </Value>
        </Info>
        <Info>
          <Label>Sivisa</Label>
          <Value isLoading={isLoading}>
            {customerDetails?.hasSivisa === true ? 'Sim' : 'Não'}
          </Value>
        </Info>
        <Info>
          <Label>CRF</Label>
          <Value isLoading={isLoading}>
            {customerDetails?.hasCrf === true ? 'Sim' : 'Não'}
          </Value>
        </Info>
      </Content>
    </>
  );
};

export default GeneralData;
