import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAdminInitialData } from '../../index';

interface IRemoveAdminSuccessAction {
  id: number;
}

function removeAdminSuccess(
  draft: Draft<IAdminInitialData>,
  action: PayloadAction<IRemoveAdminSuccessAction>,
) {
  draft.list.data = draft.list.data.filter(
    item => item.id !== action.payload.id,
  );
  draft.remove.config.isLoading = false;
}

export default removeAdminSuccess;
