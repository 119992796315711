import Tables from 'styles/tables';

import { CustomerItemsHeader } from './styles';

const CustomerItemHeader: React.FC = () => {
  return (
    <CustomerItemsHeader>
      <Tables.ItemHeader center>Online</Tables.ItemHeader>
      <Tables.ItemHeader>Nome do cliente</Tables.ItemHeader>
      <Tables.ItemHeader>CPF/CNPJ</Tables.ItemHeader>
      <Tables.ItemHeader>E-mail</Tables.ItemHeader>
      <Tables.ItemHeader>Telefone</Tables.ItemHeader>
      <Tables.ItemHeader>Status</Tables.ItemHeader>
      <Tables.ItemHeader center>Ações</Tables.ItemHeader>
    </CustomerItemsHeader>
  );
};

export default CustomerItemHeader;
