import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IPedcoinsSummaryRequestAction {
  data: {
    customerId: number;
  };
}

function pedcoinsSummaryRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<IPedcoinsSummaryRequestAction>,
) {
  draft.pedcoins.summary.config.isLoading = true;
}

export default pedcoinsSummaryRequest;
