import styled from 'styled-components';

export const Fields = styled.div`
  align-items: flex-end;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 10rem 6.5rem;
  justify-content: flex-end;
  row-gap: 0.5rem;

  @media screen and (min-width: 1366px) {
    grid-template-columns: 1fr 12rem 8rem;
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: 1fr 15rem 10rem;
  }
`;
