import { createSlice } from '@reduxjs/toolkit';
import EBannersType from 'enums/bannersType';

import reducers from './reducers';

export interface IBannerInitialData {
  config: {
    isLoading: boolean;
  };
  images: {
    id: number | string;
    url: string;
  }[];
  thumbnails: {
    id: number | string;
    url: string;
  }[];
  type?: EBannersType;
}

const initialState: IBannerInitialData = {
  images: [],
  thumbnails: [],
  type: undefined,
  config: {
    isLoading: false,
  },
};

const bannerSlice = createSlice({
  name: '@banner',
  initialState,
  reducers,
});

export const bannerActions = bannerSlice.actions;
export const bannerReducers = bannerSlice.reducer;
