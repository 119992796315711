import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface ILogoSuccessAction {
  url: string;
}

function logoSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<ILogoSuccessAction>,
) {
  draft.logo.config.isLoading = false;
  draft.data.design.logo = action.payload.url;
}

export default logoSuccess;
