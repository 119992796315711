import IRankingCustomer from 'models/IRankingCustomer';

import Tables from 'styles/tables';

import { CustomerItemsBody } from './styles';

interface ICustomerItemBodyProps {
  customer: IRankingCustomer;
  position: number;
}

const CustomerItemBody: React.FC<ICustomerItemBodyProps> = ({
  customer,
  position,
}) => {
  return (
    <CustomerItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{customer.name}</Tables.ItemBody>
      <Tables.ItemBody center>{customer.purchases}</Tables.ItemBody>
    </CustomerItemsBody>
  );
};

export default CustomerItemBody;
