import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IFeedbackInitialData } from '../../index';

function getAllRequest(
  draft: Draft<IFeedbackInitialData>,
  _: PayloadAction<void>,
) {
  draft.getAll.data = [];
  draft.getAll.config.isLoading = true;
  draft.getAll.config.isError = false;
}

export default getAllRequest;
