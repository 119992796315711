import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IInternalControlRequestAction {
  data: {
    hasPreferenceCompanyName: boolean;
  };
}

function internalControlRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IInternalControlRequestAction>,
) {
  draft.internalControl.config.isLoading = true;
}

export default internalControlRequest;
