import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetAllPriceTablesResponse from 'services/httpClient/responses/customer/IGetAllPriceTablesResponse';

import { ICustomerInitialData } from '../../index';

function priceTablesSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IGetAllPriceTablesResponse>,
) {
  draft.priceTables.data = action.payload.list.map(priceList => ({
    id: priceList.id,
    name: priceList.name,
    sku: priceList.sku,
    type: priceList.type,
  }));
  draft.priceTables.config.isLoading = false;
}

export default priceTablesSuccess;
