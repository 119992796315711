import { createSlice } from '@reduxjs/toolkit';
import EListTypes from 'enums/listType';
import ISettings from 'models/ISettings';

import reducers from './reducers';

export interface ISettingInitialData {
  address: {
    config: {
      isLoading: boolean;
    };
  };
  columns: {
    config: {
      isLoading: boolean;
    };
  };
  company: {
    config: {
      isLoading: boolean;
    };
  };
  contact: {
    config: {
      isLoading: boolean;
    };
  };
  data: ISettings;
  externalScript: {
    config: {
      isLoading: boolean;
    };
  };
  favicon: {
    config: {
      isLoading: boolean;
    };
  };
  filters: {
    config: {
      isLoading: boolean;
    };
  };
  homeControl: {
    config: {
      isLoading: boolean;
    };
  };
  internalControl: {
    config: {
      isLoading: boolean;
    };
  };
  isMaintenance: {
    config: {
      isLoading: boolean;
    };
  };
  logo: {
    config: {
      isLoading: boolean;
    };
  };
  meuCatalogo: {
    config: {
      isLoading: boolean;
    };
  };
  pedcoins: {
    config: {
      isLoading: boolean;
    };
  };
  productsViewMode: {
    config: {
      isLoading: boolean;
    };
  };
  purchases: {
    config: {
      isLoading: boolean;
    };
  };
  shipping: {
    config: {
      isLoading: boolean;
    };
  };
  socialMedia: {
    config: {
      isLoading: boolean;
    };
  };
  terms: {
    config: {
      isLoading: boolean;
    };
  };
  termsControl: {
    config: {
      isLoading: boolean;
    };
  };
  themes: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: ISettingInitialData = {
  data: {
    internal: {
      hasPreferenceCompanyName: false,
    },
    address: {
      city: '',
      complement: '',
      neighborhood: '',
      number: '',
      postalCode: '',
      state: '',
      street: '',
    },
    columns: {
      onlyAmountFinal: false,
      showProductsWithoutLimit: false,
      displayColorsStock: false,
    },
    company: {
      corporateName: '',
      document: '',
      email: '',
      name: '',
      phone: '',
    },
    design: {
      favicon: '',
      logo: '',
      mainColor: '',
    },
    filters: {
      hasStock: false,
      kit: false,
      redeem: false,
    },
    meuCatalogo: {
      email: '',
      password: '',
    },
    others: {
      externalScript: '',
      showHome: false,
      showTerms: false,
      terms: null,
      isMaintenance: false,
    },

    pedcoins: {
      expirationTerm: 0,
      isEnable: false,
      name: '',
      pointsPerReal: 0,
    },
    products: {
      sellOutOfStock: false,
      productsViewMode: EListTypes.Table,
      showExpiryWarning: false,
      showStockQuantity: false,
      showWithoutStock: false,
    },
    purchase: {
      blockCredit: false,
      blockDrugList: false,
      blockSituation: false,
    },
    shipping: {
      timeLimitMessage: '',
    },
    socialMedia: {
      facebook: '',
      instagram: '',
      linkedin: '',
      whatsapp: '',
    },
  },
  address: {
    config: {
      isLoading: false,
    },
  },
  productsViewMode: {
    config: {
      isLoading: false,
    },
  },
  isMaintenance: {
    config: {
      isLoading: false,
    },
  },
  columns: {
    config: {
      isLoading: false,
    },
  },
  company: {
    config: {
      isLoading: false,
    },
  },
  contact: {
    config: {
      isLoading: false,
    },
  },
  externalScript: {
    config: {
      isLoading: false,
    },
  },
  favicon: {
    config: {
      isLoading: false,
    },
  },
  filters: {
    config: {
      isLoading: false,
    },
  },
  homeControl: {
    config: {
      isLoading: false,
    },
  },
  logo: {
    config: {
      isLoading: false,
    },
  },
  internalControl: {
    config: {
      isLoading: false,
    },
  },
  meuCatalogo: {
    config: {
      isLoading: false,
    },
  },
  pedcoins: {
    config: {
      isLoading: false,
    },
  },
  purchases: {
    config: {
      isLoading: false,
    },
  },
  shipping: {
    config: {
      isLoading: false,
    },
  },
  socialMedia: {
    config: {
      isLoading: false,
    },
  },
  terms: {
    config: {
      isLoading: false,
    },
  },
  termsControl: {
    config: {
      isLoading: false,
    },
  },
  themes: {
    config: {
      isLoading: false,
    },
  },
};

const settingSlice = createSlice({
  name: '@setting',
  initialState,
  reducers,
});

export const settingActions = settingSlice.actions;
export const settingReducers = settingSlice.reducer;
