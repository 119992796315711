import styled from 'styled-components';

export const Subheader = styled.div`
  display: grid;
  padding-bottom: 0.5rem;
  width: 100%;
`;

export const EmptyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FilterContainer = styled.div`
  align-items: flex-end;
  column-gap: 8px;
  display: grid;
  grid-template-columns: 1fr 8.5rem 8.5rem;
`;
