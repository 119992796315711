import { all, takeLatest } from 'redux-saga/effects';

import { productActions } from '../index';
import coinsPerReal from './coinsPerReal';
import details from './details';
import getAll from './getAll';
import select from './select';

const productSagas = all([
  takeLatest(productActions.coinsPerRealRequest, coinsPerReal),
  takeLatest(productActions.detailsRequest, details),
  takeLatest(productActions.getAllRequest, getAll),
  takeLatest(productActions.selectRequest, select),
]);

export default productSagas;
