import styled, { css } from 'styled-components';

import { Form } from 'formik';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
`;

interface IContentProps {
  blueBackground?: boolean;
}

export const Content = styled.div<IContentProps>`
  align-items: center;
  background-color: ${props =>
    props.blueBackground ? colors.primary : colors.background};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 2.5rem;
`;

export const AnimatedContent = styled.div.attrs({
  id: 'animatedContent',
})`
  align-items: center;
  animation-delay: 0.1s;
  animation-duration: 0.4s;
  animation-fill-mode: backwards;
  animation-name: ${animations.appear};
  animation-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  &.hide {
    animation-name: ${animations.disappear};
  }
`;

interface ITitleProps {
  forgotPassword?: boolean;
  formTitle?: boolean;
}

export const Title = styled.h1<ITitleProps>`
  align-items: flex-start;
  color: ${colors.yellow};
  display: grid;
  font-size: 2.25rem;
  max-width: 40rem;
  width: 100%;

  ${props =>
    props.formTitle &&
    css`
      color: ${colors.primary};
      max-width: 22.5rem;
    `}

  ${props =>
    props.forgotPassword &&
    css`
      color: ${colors.primary};
      font-size: 1.875rem;
      max-width: 22.5rem;
    `}
`;

export const Small = styled.small`
  color: ${colors.white};
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
`;

export const Subtitle = styled.p`
  color: ${colors.white};
  font-size: 1.25rem;
  margin: 0.5rem 0 2.5rem 0;
  max-width: 40rem;
  width: 100%;
`;

export const ContentSubtitle = styled.p`
  color: ${colors.subtitle};
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.25rem;
  max-width: 22.5rem;
`;

export const Image = styled.img`
  max-width: 40rem;
  width: 80%;
`;

export const FormikForm = styled(Form)`
  margin-top: 2.5rem;
  max-width: 22.5rem;
  width: 100%;
`;

interface IAuthFieldsProps {
  forgotPassword?: boolean;
}

export const AuthFields = styled.div<IAuthFieldsProps>`
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  display: grid;
  grid-template-rows: repeat(2, 4.5rem);

  ${props =>
    props.forgotPassword &&
    css`
      grid-template-rows: 4.5rem;
    `}
`;

export const ForgotPassword = styled.button`
  align-items: flex-end;
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.subtitle};
  display: flex;
  font-size: 0.875rem;
  margin-left: auto;
  margin-top: 1rem;

  &:hover {
    opacity: 0.7;
  }
`;

export const BackButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.subtitle};
  display: flex;
  font-size: 0.875rem;
  margin: 1rem auto 0;
  padding: 0.5rem 1.25rem;
  width: fit-content;

  &:hover {
    opacity: 0.7;
  }
`;
