import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

export interface ISelectRequestAction {
  data: {
    linkId?: string;
  };
}

function selectRequest(
  draft: Draft<IManufacturerInitialData>,
  _: PayloadAction<ISelectRequestAction>,
) {
  draft.select.list = [];
  draft.select.config.isLoading = true;
  draft.select.config.isError = false;
}

export default selectRequest;
