import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface ISetLinkOptionIdAction {
  data: {
    id: string;
  };
}

function setLinkOptionId(
  draft: Draft<IListInitialData>,
  action: PayloadAction<ISetLinkOptionIdAction>,
) {
  if (draft.linkOptions.data.length >= 1) {
    const linkSelected = draft.linkOptions.data.find(
      linkOption => linkOption.value === action.payload.data.id,
    );
    draft.linkOptionSelected = linkSelected ?? null;
  }
}

export default setLinkOptionId;
