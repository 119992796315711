import { Route, Routes } from 'react-router-dom';

import AdminList from 'pages/Admin/List';
import SignIn from 'pages/Auth/SignIn';
import Company from 'pages/Company';
import Customers from 'pages/Customers';
import CustomerDetails from 'pages/Customers/Details';
import CustomerPedcoins from 'pages/Customers/Pedcoins';
import CustomersNonPositive from 'pages/CustomersNonPositive';
import Dashboard from 'pages/Dashboard';
import FeedbackList from 'pages/Feedback/List';
import Home from 'pages/Home';
import ManufacturerDetails from 'pages/Manufacturer/Details';
import ManufacturerList from 'pages/Manufacturer/List';
import OrderList from 'pages/Orders/List';
import PriceListDetails from 'pages/PriceList/Details';
import PriceList from 'pages/PriceList/List';
import ProductDetails from 'pages/Product/Details';
import ProductList from 'pages/Product/List';
import ProductRequestList from 'pages/Product/ProductRequest/List';
import Settings from 'pages/Settings';
import Status from 'pages/Status';

import NotFound from './NotFound';
import PrivateRouteWrapper from './PrivateRouteWrapper';

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<PrivateRouteWrapper />}>
        <Route element={<Dashboard />} path="/dashboards" />
        <Route element={<Home />} path="/inicio" />
        <Route element={<Customers />} path="/clientes" />
        <Route
          element={<CustomersNonPositive />}
          path="/clientes/nao-positivados"
        />
        <Route element={<CustomerPedcoins />} path="/clientes/:id/pedcoins" />
        <Route element={<ProductList />} path="/produtos" />
        <Route element={<ProductRequestList />} path="/produtos/solicitados" />
        <Route element={<ProductDetails />} path="/produtos/:id" />
        <Route element={<ManufacturerList />} path="/fabricantes" />
        <Route element={<ManufacturerDetails />} path="/fabricantes/:id" />
        <Route element={<PriceList />} path="/tabelas-de-preco" />
        <Route element={<PriceListDetails />} path="/tabelas-de-preco/:id" />
        <Route element={<Settings />} path="/configuracoes" />
        <Route element={<AdminList />} path="/administradores" />
        <Route element={<Company />} path="/empresa" />
        <Route element={<OrderList />} path="/pedidos" />
        <Route element={<CustomerDetails />} path="/clientes/:id" />
        <Route element={<FeedbackList />} path="/feedbacks" />
      </Route>
      <Route element={<SignIn />} path="/" />
      <Route element={<Status />} path="/status" />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MainRoutes;
