import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import { customerActions } from '..';

import httpClient from 'services/httpClient';
import IGetCustomerDetailsResponse from 'services/httpClient/responses/customer/IGetCustomerDetailsResponse';

import { IDetailsRequestAction } from '../reducers/details/request';

function* details(action: PayloadAction<IDetailsRequestAction>) {
  try {
    const { data }: AxiosResponse<IGetCustomerDetailsResponse> = yield call(
      httpClient.get,
      `admin/customers/${action.payload.data.customerId}`,
    );
    yield put(customerActions.detailsSuccess(data));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.detailsFailure());
  }
}

export default details;
