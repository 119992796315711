import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IUpdateRequestAction {
  data: {
    active: boolean;
    id: number;
    isCollapsed: boolean;
    position: number;
    quantityColumns: number;
    subtitle: string;
    title: string;
    typeView: {
      label: string;
      value: string;
    };
  };
  functions: {
    success: () => void;
  };
}

function updateRequest(
  draft: Draft<IListInitialData>,
  _: PayloadAction<IUpdateRequestAction>,
) {
  draft.update.config.isLoading = true;
}

export default updateRequest;
