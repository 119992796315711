import billingFailure from './billing/failure';
import billingRequest from './billing/request';
import billingSuccess from './billing/success';
import customerFailure from './customer/failure';
import customerRequest from './customer/request';
import customerSuccess from './customer/success';
import orderFailure from './order/failure';
import orderRequest from './order/request';
import orderSuccess from './order/success';
import rankingCustomerFailure from './rankingCustomer/failure';
import rankingCustomerRequest from './rankingCustomer/request';
import rankingCustomerSuccess from './rankingCustomer/success';
import rankingProductFailure from './rankingProduct/failure';
import rankingProductRequest from './rankingProduct/request';
import rankingProductSuccess from './rankingProduct/success';

const dashboardReducers = {
  billingFailure,
  billingRequest,
  billingSuccess,
  customerFailure,
  customerRequest,
  customerSuccess,
  orderFailure,
  orderRequest,
  orderSuccess,
  rankingCustomerFailure,
  rankingCustomerRequest,
  rankingCustomerSuccess,
  rankingProductFailure,
  rankingProductRequest,
  rankingProductSuccess,
};

export default dashboardReducers;
