import Tables from 'styles/tables';

import { FeedbackItemsHeader } from '../styles';

const ItemHeader = () => {
  return (
    <FeedbackItemsHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader>Cliente</Tables.ItemHeader>
      <Tables.ItemHeader>Mensagem</Tables.ItemHeader>
      <Tables.ItemHeader>Data</Tables.ItemHeader>
      <Tables.ItemHeader center>Nota</Tables.ItemHeader>
    </FeedbackItemsHeader>
  );
};

export default ItemHeader;
