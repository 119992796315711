import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import EListTypes from 'enums/listType';
import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentInputCheckbox from 'components/input/Checkbox';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm, Options } from '../styles';

interface IPurchasesFormData {
  block: {
    blockCredit: boolean;
    blockDrugList: boolean;
    blockSituation: boolean;
  };
  product: {
    productsViewMode: EListTypes;
    sellOutOfStock: boolean;
    showExpiryWarning: boolean;
    showStockQuantity: boolean;
    showWithoutStock: boolean;
  };
}

function Loading({ isLoading }: { isLoading: boolean }): JSX.Element {
  if (!isLoading) {
    return <FiCheckCircle color={colors.green} size={18} />;
  }
  return <ClipLoader size={18} />;
}

function PurchasesControl(): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const formRef = useRef<FormikProps<IPurchasesFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.purchasesIsLoading);
  const products = useReduxSelector(settingSelectors.products);
  const purchases = useReduxSelector(settingSelectors.purchases);

  const handlePurchasesConfig = useCallback(
    (data: IPurchasesFormData) => {
      reduxDispatch(
        settingActions.purchasesRequest({
          data: {
            block: {
              creditLimit: data.block.blockCredit,
              situation: data.block.blockSituation,
              drugList: data.block.blockDrugList,
            },
            product: {
              sellWithoutStock: data.product.sellOutOfStock,
              showExpirationWarning: data.product.showExpiryWarning,
              showWithoutStock: data.product.showWithoutStock,
              showStockQuantity: data.product.showStockQuantity,
              productsViewMode: data.product.productsViewMode,
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChange = useCallback((field: string, value: boolean): void => {
    formRef.current?.setFieldValue(field, !value);
    formRef.current?.submitForm();
  }, []);

  return (
    <Formik
      initialValues={{
        block: {
          blockCredit: purchases.blockCredit,
          blockDrugList: purchases.blockDrugList,
          blockSituation: purchases.blockSituation,
        },
        product: {
          sellOutOfStock: products.sellOutOfStock,
          showExpiryWarning: products.showExpiryWarning,
          showStockQuantity: products.showStockQuantity,
          showWithoutStock: products.showWithoutStock,
          productsViewMode: products.productsViewMode,
        },
      }}
      innerRef={formRef}
      onSubmit={handlePurchasesConfig}
    >
      {({ values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Controle de dados
              <Loading isLoading={isLoading} />
            </CardTitle>

            <CardSubtitle>
              Defina algumas regras que devem ser aplicadas aos clientes
            </CardSubtitle>

            <Options gridTemplateColumns="repeat(2, 1fr)">
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.block.blockCredit}
                label="Bloqueio por crédito"
                name="blockCredit"
                onChange={() =>
                  handleOnChange('block.blockCredit', values.block.blockCredit)
                }
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.product.showWithoutStock}
                label="Exibir item sem estoque"
                name="showWithoutStock"
                onChange={() =>
                  handleOnChange(
                    'product.showWithoutStock',
                    values.product.showWithoutStock,
                  )
                }
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.block.blockSituation}
                label="Bloqueio por inadimplência"
                name="blockSituation"
                onChange={() =>
                  handleOnChange(
                    'block.blockSituation',
                    values.block.blockSituation,
                  )
                }
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.product.showStockQuantity}
                label="Exibir quantidade em estoque"
                name="showStockQuantity"
                onChange={() =>
                  handleOnChange(
                    'product.showStockQuantity',
                    values.product.showStockQuantity,
                  )
                }
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.block.blockDrugList}
                label="Bloqueio por medicamentos controlados"
                name="blockDrugList"
                onChange={() =>
                  handleOnChange(
                    'block.blockDrugList',
                    values.block.blockDrugList,
                  )
                }
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.product.sellOutOfStock}
                label="Venda de item sem estoque"
                name="sellOutOfStock"
                onChange={() =>
                  handleOnChange(
                    'product.sellOutOfStock',
                    values.product.sellOutOfStock,
                  )
                }
              />

              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.product.showExpiryWarning}
                label="Exibir aviso de validade"
                name="showExpiryWarning"
                onChange={() =>
                  handleOnChange(
                    'product.showExpiryWarning',
                    values.product.showExpiryWarning,
                  )
                }
              />
            </Options>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
}

export default PurchasesControl;
