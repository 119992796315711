import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllPriceListRequest from 'services/httpClient/request/priceList/IGetAllPriceListRequest';
import IGetAllPriceListResponse from 'services/httpClient/responses/priceList/IGetAllPriceListResponse';

import { priceListActions } from '..';

import { IGetAllRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllRequestAction>) {
  try {
    const params: IGetAllPriceListRequest = {
      id: action.payload.data.id ? action.payload.data.id : undefined,
      name: action.payload.data.name ? action.payload.data.name : undefined,
      dateTimeEnd: action.payload.data.dateTimeEnd
        ? action.payload.data.dateTimeEnd
        : undefined,
      page: action.payload.data.page,
      dateTimeStart: action.payload.data.dateTimeStart
        ? action.payload.data.dateTimeStart
        : undefined,
      sku: action.payload.data.sku ? action.payload.data.sku : undefined,
      type: action.payload.data.type ? action.payload.data.type : undefined,
    };

    const {
      data: { list, totalPages },
      status,
    }: AxiosResponse<IGetAllPriceListResponse> = yield call(
      httpClient.get,
      'admin/price-lists',
      { params },
    );

    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        priceListActions.getAllSuccess({
          data: {
            list: [],
            totalPages: 0,
          },
        }),
      );
      return;
    }

    yield put(
      priceListActions.getAllSuccess({
        data: {
          list,
          totalPages,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(priceListActions.getAllFailure());
  }
}

export default getAll;
