import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsInternalControlRequest from 'services/httpClient/request/settings/IPutSettingsInternalControlRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IInternalControlRequestAction } from '../reducers/internalControl/request';

import { settingActions } from '..';

function* internalControl(
  action: PayloadAction<IInternalControlRequestAction>,
) {
  try {
    const body: IPutSettingsInternalControlRequest = {
      hasPreferenceCompanyName: action.payload.data.hasPreferenceCompanyName,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/internal',
      body,
    );
    ToastManager.show({
      title: response.data.message,
      type: 'success',
    });
    yield put(
      settingActions.internalControlSuccess({
        hasPreferenceCompanyName: action.payload.data.hasPreferenceCompanyName,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.internalControlFailure());
  }
}

export default internalControl;
