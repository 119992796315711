import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { ListContainer, Subtitle } from '../styles';
import PaymentMethodItem from './Item';

interface IPaymentMethodsParams extends Record<string, string | undefined> {
  id: string;
}

const PaymentMethodsList = () => {
  const params = useParams<IPaymentMethodsParams>();
  const reduxDispatch = useReduxDispatch();
  const paymentMethods = useReduxSelector(customerSelectors.paymentMethods);
  const isLoading = useReduxSelector(customerSelectors.paymentMethodsIsLoading);
  const isError = useReduxSelector(customerSelectors.paymentMethodIsError);

  const handlePaymentMethods = useCallback(() => {
    if (!params.id) {
      return;
    }
    reduxDispatch(
      customerActions.paymentMethodsRequest({
        data: {
          customerId: Number(params.id),
        },
      }),
    );
  }, [params.id, reduxDispatch]);

  useEffect(() => {
    handlePaymentMethods();
  }, [handlePaymentMethods]);

  return (
    <>
      <Subtitle>Formas de Pagamento</Subtitle>
      <ListContainer>
        <ComponentIsVisible when={!isLoading && paymentMethods.length !== 0}>
          <PaymentMethodItem.Header />
          {paymentMethods.map((paymentMethod, position) => (
            <PaymentMethodItem.Body
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              position={position}
            />
          ))}
        </ComponentIsVisible>
        <ComponentEmpty
          message="Nenhuma forma de pagamento encontrada"
          show={!isError && paymentMethods.length === 0 && !isLoading}
        />
        <ComponentIsVisible when={isError}>
          <ComponentError
            message="Erro ao buscar formas de pagamento"
            onClick={handlePaymentMethods}
          />
        </ComponentIsVisible>
        <ComponentLoadingList rows={8} show={isLoading} />
      </ListContainer>
    </>
  );
};

export default PaymentMethodsList;
