import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ReactCropperElement, ReactCropperProps } from 'react-cropper';

import EImageType from 'enums/imageType';
import EUploadImageType from 'enums/uploadImageType';
import helpers from 'helpers';
import { imageActions } from 'store/slices/image';
import imageSelectors from 'store/slices/image/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';

import colors from 'styles/colors';

import ComponentModalBase, { IComponentModalBaseRefProps } from '../Base';
import {
  Actions,
  Content,
  ContentPreview,
  PreviewImage,
  Title,
} from './styles';

interface IOpenCropperData {
  imageType: EUploadImageType;
  imageUrl: string;
}

export interface IComponentImageCropperRefProps {
  open: (data: IOpenCropperData) => void;
}

type IComponentImageCropperProps = ReactCropperProps;

const ComponentImageCropper: React.ForwardRefRenderFunction<
  IComponentImageCropperRefProps,
  IComponentImageCropperProps
> = ({ aspectRatio, initialAspectRatio = 16 / 9 }, ref) => {
  const reduxDispatch = useReduxDispatch();

  const [imageSrc, setImageSrc] = useState<string>('');
  const [uploadType, setUploadType] = useState<EUploadImageType>(
    EUploadImageType.banner,
  );
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const previewImageRef = useRef<ReactCropperElement>(
    {} as ReactCropperElement,
  );

  const isLoading = useReduxSelector(imageSelectors.uploadIsLoading);

  const openModal = useCallback((data: IOpenCropperData) => {
    componentModalBaseRef.current?.open();
    setImageSrc(data.imageUrl);
    setUploadType(data.imageType);
  }, []);

  const closeModal = useCallback(() => {
    setImageSrc('');
    componentModalBaseRef.current?.close();
  }, []);

  const handleCropImage = useCallback(() => {
    const { cropper } = previewImageRef.current;

    if (cropper) {
      const imgFile = cropper.getCroppedCanvas().toDataURL();
      setImageSrc(imgFile);
    }
  }, []);

  const handleUploadImage = useCallback(async () => {
    const blobImage = await helpers.dataUrlToFile({
      dataUrl: imageSrc,
      fileName: 'file.png',
    });

    reduxDispatch(
      imageActions.uploadRequest({
        data: {
          file: blobImage,
          type: EImageType.Banner,
          uploadType,
        },
        functions: {
          success() {
            closeModal();
          },
        },
      }),
    );
  }, [closeModal, imageSrc, reduxDispatch, uploadType]);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Recortar imagem</Title>

        <ContentPreview>
          <PreviewImage
            aspectRatio={aspectRatio}
            background={false}
            draggable={false}
            guides={false}
            initialAspectRatio={initialAspectRatio}
            modal={false}
            ref={previewImageRef}
            src={imageSrc}
            toggleDragModeOnDblclick={false}
            viewMode={2}
          />
        </ContentPreview>

        <Actions>
          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={isLoading}
            onClick={closeModal}
          >
            Cancelar
          </ComponentButtonBase>

          <ComponentButtonBase
            backgroundColor={colors.orange}
            disabled={isLoading}
            onClick={handleCropImage}
          >
            Cortar imagem
          </ComponentButtonBase>

          <ComponentButtonBase
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleUploadImage}
          >
            Confirmar
          </ComponentButtonBase>
        </Actions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ComponentImageCropper);
