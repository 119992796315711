import authToken from './authToken';
import forgotPasswordIsLoading from './forgotPasswordIsLoading';
import hideUpdateNotes from './hideUpdateNotes';
import signInIsLoading from './signInIsLoading';
import updatePasswordIsLoading from './updatePasswordIsLoading';

const authSelectors = {
  authToken,
  forgotPasswordIsLoading,
  hideUpdateNotes,
  signInIsLoading,
  updatePasswordIsLoading,
};

export default authSelectors;
