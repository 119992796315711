import ComponentIsVisible from 'components/utils/IsVisible';

import empty from 'assets/utils/empty.svg';

import { Container, EmptyImage, EmptyMessage, EmptyThumbnail } from './styles';

interface IComponentEmptyProps {
  message: string;
  show: boolean;
}

const ComponentEmpty: React.FC<IComponentEmptyProps> = ({ message, show }) => {
  return (
    <ComponentIsVisible when={show}>
      <Container>
        <EmptyThumbnail>
          <EmptyImage src={empty} />
        </EmptyThumbnail>
        <EmptyMessage>{message}</EmptyMessage>
      </Container>
    </ComponentIsVisible>
  );
};

export default ComponentEmpty;
