import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IPriceListInitialData } from '../../index';

export interface IGetAllProductsSuccessAction {
  data: {
    list: {
      amountDiscount: number;
      amountFee: number;
      amountFinal: number;
      amountGross: number;
      amountNet: number;
      id: number;
      manufacturer: string;
      name: string;
      sku: string;
    }[];
    totalPages: number;
  };
}

function getAllProductsRequest(
  draft: Draft<IPriceListInitialData>,
  action: PayloadAction<IGetAllProductsSuccessAction>,
) {
  draft.getAllProducts.data = action.payload.data.list.map(product => ({
    amountGross: formatters.money(product.amountGross),
    amountFinal: formatters.money(product.amountFinal),
    amountNet: formatters.money(product.amountNet),
    amountFee: formatters.money(product.amountFee),
    amountDiscount: `${formatters.money(product.amountDiscount)}%`,
    id: product.id,
    name: product.name,
    sku: product.sku,
    manufacturer: product.manufacturer,
  }));
  draft.getAllProducts.config.isLoading = false;
  draft.getAllProducts.config.totalPages = action.payload.data.totalPages;
}

export default getAllProductsRequest;
