import { createGlobalStyle } from 'styled-components';

import 'suneditor/dist/css/suneditor.min.css';
import 'cropperjs/dist/cropper.css';
import colors from './colors';

export default createGlobalStyle`
  * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      scroll-behavior: smooth;
      outline: none;
      -webkit-font-smoothing: antialiased !important;
    }

  html {
    @media screen and (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media screen and (max-width: 720px) {
      font-size: 87.5%;
    }

    @media (min-width: 900px) {
      ::-webkit-scrollbar {
        background: ${colors.primary};
        height: 0.5rem;
        width: 0.4rem;
      }

      ::-webkit-scrollbar-button {
        height: 0;
        width: 0;
      }

      ::-webkit-scrollbar-track {
        background: ${colors.background};
        height: 0.5rem;
      }

      ::-webkit-scrollbar-thumb {
        background: ${colors.primary};
        height: 0.5rem;
        width: 0.4rem;
        border-radius: 0 0 0.25rem 0.25rem;
      }
    }
  }

  html, body, #root {
    min-height: 100vh;
  }

  body {
    background-color: ${colors.white};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-rendering: optimizeLegibility;
    line-height: 1;
  }

  body, input, button, textarea {
    font-family: 'Archivo', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.text};
  }

  input, textarea {
    color: ${colors.text};

    &::placeholder {
      color: ${colors.secondaryText};
      font-size: 0.875rem;
      font-weight: 300;
    }
  }

  button {
    cursor: pointer;
    transition: all .3s ease;
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
  }

  [disabled] {
    opacity: .6;
    cursor: not-allowed;
  }

  .ulPaginate {
    display: flex;
    list-style: none;
    margin: .5rem 0 0;
    padding: 0;
    align-items: center;
    column-gap: .25rem;
  }
  .ulPaginate li {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.primary};
      border-radius: .5rem;
    color: ${colors.primary};
    cursor: pointer;
    font-size: .75rem;
    font-weight: 500;
    height: 1.75rem;
    transition: all .3s ease-in-out;
    width: 1.75rem;
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    &.previous {
      font-size: 1.5rem;
      font-weight: 400;
      width: 1.75rem;
      & a {
        width: 100%;
      }
    }
    &.next {
      font-size: 1.5rem;
      font-weight: 400;
      width: 1.75rem;
    }
    :hover {
      opacity: .8
    }
    &.active {
      background: ${colors.primary};
      color: ${colors.white};
      border-radius: .5rem;
      font-size: .825rem;
      font-weight: 600;
    }
  }
  .disable-scroll {
    overflow: hidden;
  }
  .active-scroll {
    overflow-y: scroll !important;
  }

  .sun-editor .se-list-layer.se-list-align .se-list-inner {
    width: 155px;
  }
`;
