import changePasswordIsLoading from './changePasswordIsLoading';
import details from './details';
import detailsIsLoading from './detailsIsLoading';
import drugs from './drugs';
import drugsIsError from './drugsIsError';
import drugsIsLoading from './drugsIsLoading';
import getAll from './getAll';
import getAllIsError from './getAllIsError';
import getAllIsLoading from './getAllIsLoading';
import getAllPage from './getAllPage';
import getAllTotalPages from './getAllTotalPages';
import getPriceTables from './getPriceTables';
import getPriceTablesIsError from './getPriceTablesIsError';
import getPriceTablesIsLoading from './getPriceTablesIsLoading';
import launchPedcoinsIsLoading from './launchPedcoinsIsLoading';
import nonPositive from './nonPositive';
import nonPositiveIsError from './nonPositiveIsError';
import nonPositiveIsLoading from './nonPositiveIsLoading';
import nonPositivePage from './nonPositivePage';
import nonPositivePdfIsLoading from './nonPositivePdfIsLoading';
import nonPositiveSpreadsheetIsLoading from './nonPositiveSpreadsheetIsLoading';
import nonPositiveTotalPages from './nonPositiveTotalPages';
import onlyConnected from './onlyConnected';
import orders from './orders';
import ordersIsError from './ordersIsError';
import ordersIsLoading from './ordersIsLoading';
import paymentMethodIsError from './paymentMethodIsError';
import paymentMethods from './paymentMethods';
import paymentMethodsIsLoading from './paymentMethodsIsLoading';
import pedcoinsExtract from './pedcoinsExtract';
import pedcoinsExtractIsError from './pedcoinsExtractIsError';
import pedcoinsExtractIsLoading from './pedcoinsExtractIsLoading';
import pedcoinsExtractPage from './pedcoinsExtractPage';
import pedcoinsExtractTotalPages from './pedcoinsExtractTotalPages';
import pedcoinsRedeem from './pedcoinsRedeem';
import pedcoinsRedeemIsError from './pedcoinsRedeemIsError';
import pedcoinsRedeemIsLoading from './pedcoinsRedeemIsLoading';
import pedcoinsRedeemPage from './pedcoinsRedeemPage';
import pedcoinsRedeemTotalPages from './pedcoinsRedeemTotalPages';
import pedcoinsSummaryAvailable from './pedcoinsSummaryAvailable';
import pedcoinsSummaryEntries from './pedcoinsSummaryEntries';
import pedcoinsSummaryExpiresToday from './pedcoinsSummaryExpiresToday';
import pedcoinsSummaryIsLoading from './pedcoinsSummaryIsLoading';
import pedcoinsSummaryOutputs from './pedcoinsSummaryOutputs';

const customerSelectors = {
  drugsIsError,
  drugsIsLoading,
  drugs,
  ordersIsError,
  ordersIsLoading,
  orders,
  paymentMethodIsError,
  getPriceTablesIsError,
  getPriceTablesIsLoading,
  getPriceTables,
  paymentMethods,
  paymentMethodsIsLoading,
  detailsIsLoading,
  details,
  nonPositiveSpreadsheetIsLoading,
  changePasswordIsLoading,
  getAll,
  getAllIsError,
  getAllIsLoading,
  getAllPage,
  getAllTotalPages,
  launchPedcoinsIsLoading,
  nonPositive,
  nonPositiveIsError,
  nonPositiveIsLoading,
  nonPositivePage,
  nonPositivePdfIsLoading,
  nonPositiveTotalPages,
  onlyConnected,
  pedcoinsExtract,
  pedcoinsExtractIsError,
  pedcoinsExtractIsLoading,
  pedcoinsExtractPage,
  pedcoinsExtractTotalPages,
  pedcoinsRedeem,
  pedcoinsRedeemIsError,
  pedcoinsRedeemIsLoading,
  pedcoinsRedeemPage,
  pedcoinsRedeemTotalPages,
  pedcoinsSummaryAvailable,
  pedcoinsSummaryEntries,
  pedcoinsSummaryExpiresToday,
  pedcoinsSummaryIsLoading,
  pedcoinsSummaryOutputs,
};

export default customerSelectors;
