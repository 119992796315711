import coinsPerRealIsLoading from './coinsPerRealIsLoading';
import details from './details';
import detailsIsError from './detailsIsError';
import detailsIsLoading from './detailsIsLoading';
import getAll from './getAll';
import getAllIsError from './getAllIsError';
import getAllIsLoading from './getAllIsLoading';
import getAllPage from './getAllPage';
import getAllTotalPages from './getAllTotalPages';
import select from './select';
import selectIsError from './selectIsError';
import selectIsLoading from './selectIsLoading';

const productSelectors = {
  coinsPerRealIsLoading,
  details,
  detailsIsLoading,
  detailsIsError,
  getAll,
  getAllIsError,
  getAllIsLoading,
  getAllPage,
  getAllTotalPages,
  select,
  selectIsError,
  selectIsLoading,
};

export default productSelectors;
