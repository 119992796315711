import { Draft } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

function selectFailure(draft: Draft<IManufacturerInitialData>) {
  draft.select.config.isLoading = false;
  draft.select.config.isError = true;
}

export default selectFailure;
