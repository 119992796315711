import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentTextarea from 'components/input/Textarea';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  Card,
  CardSubtitle,
  CardTitle,
  FormActions,
  FormikForm,
} from '../styles';

interface IShippingTimeLimitFormData {
  timeLimitMessage: string;
}

const shippingTimeLimitSchema = Yup.object().shape({
  timeLimitMessage: Yup.string(),
});

const ShippingTimeLimit: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const shippingTimeLimitFormRef =
    useRef<FormikProps<IShippingTimeLimitFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.shippingIsLoading);
  const shipping = useReduxSelector(settingSelectors.shipping);

  const handleShippingTimeLimit = useCallback(
    (data: IShippingTimeLimitFormData) => {
      reduxDispatch(
        settingActions.shippingRequest({
          data: {
            timeLimitMessage: data.timeLimitMessage,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        timeLimitMessage: shipping.timeLimitMessage,
      }}
      innerRef={shippingTimeLimitFormRef}
      onSubmit={handleShippingTimeLimit}
      validationSchema={shippingTimeLimitSchema}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Horário limite para envios
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>
            <CardSubtitle>
              Insira abaixo uma mensagem informando o horário limite para envios
            </CardSubtitle>

            <ComponentTextarea
              label="Mensagem"
              name="timeLimitMessage"
              onChange={handleChange('timeLimitMessage')}
              value={values.timeLimitMessage}
            />

            <FormActions>
              <ComponentButtonBase
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
              >
                Salvar
              </ComponentButtonBase>
            </FormActions>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ShippingTimeLimit;
