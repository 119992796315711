import { Column, Columns, Container, Name } from './styles';

interface IColumnOptionProps {
  columns?: number;
  name?: string;
  onClick?: () => void;
  selected?: boolean;
}

const ColumnOption: React.FC<IColumnOptionProps> = ({
  columns = 2,
  name = 'Duas',
  onClick,
  selected,
}) => {
  const columnsItems = new Array(columns);

  for (let i = 0; i < columnsItems.length; i += 1) {
    columnsItems[i] = i;
  }

  return (
    <Container onClick={onClick} selected={selected} type="button">
      <Name>{`${name} por linha`}</Name>
      <Columns columns={columns}>
        {columnsItems.map(index => (
          <Column key={index} selected={selected} />
        ))}
      </Columns>
    </Container>
  );
};

export default ColumnOption;
