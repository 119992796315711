import list from './list';
import listIsError from './listIsError';
import listIsLoading from './listIsLoading';

const kitSelectors = {
  list,
  listIsError,
  listIsLoading,
};

export default kitSelectors;
