import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';
import ICustomerNonPositive from 'models/ICustomerNonPositive';

import { ICustomerInitialData } from '../../index';

export interface INonPositiveSuccessAction {
  data: {
    list: {
      companyName: string;
      dateAccess: string;
      email: string;
      id: number;
      name: string;
      phone: string | null;
      sku: string;
      state: string;
    }[];
    totalPages: number;
  };
}

function nonPositiveSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<INonPositiveSuccessAction>,
) {
  const listParsed: ICustomerNonPositive[] = action.payload.data.list.map(
    customer => ({
      phone: customer.phone
        ? formatters.phone(customer.phone)
        : 'Não informado',
      dateAccess: formatters.date(customer.dateAccess),
      email: customer.email,
      id: customer.id,
      name: customer.name,
      state: customer.state,
      companyName: customer.companyName,
      sku: customer.sku,
    }),
  );
  draft.nonPositive.config.totalPages = action.payload.data.totalPages;
  draft.nonPositive.data = listParsed;
  draft.nonPositive.config.isLoading = false;
}

export default nonPositiveSuccess;
