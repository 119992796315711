import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllCustomerOrdersResponse from 'services/httpClient/responses/customer/IGetAllCustomerOrdersResponse';

import { customerActions } from '..';

import { IOrdersRequestAction } from '../reducers/orders/request';

function* orders(action: PayloadAction<IOrdersRequestAction>) {
  try {
    const { data, status }: AxiosResponse<IGetAllCustomerOrdersResponse> =
      yield call(
        httpClient.get,
        `admin/customers/${action.payload.data.customerId}/orders`,
      );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(customerActions.ordersSuccess({ list: [] }));
      return;
    }
    yield put(customerActions.ordersSuccess({ list: data.list }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.ordersFailure());
  }
}

export default orders;
