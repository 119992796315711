import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthInitialData } from '../../index';

interface ISetHideUpdateNotesAction {
  data: { status: boolean };
}

function setHideUpdateNotes(
  draft: Draft<IAuthInitialData>,
  action: PayloadAction<ISetHideUpdateNotesAction>,
) {
  draft.hideUpdateNotes = action.payload.data.status;
}

export default setHideUpdateNotes;
