import { useCallback, useEffect } from 'react';

import { feedbackActions } from 'store/slices/feedback';
import feedbackSelectors from 'store/slices/feedback/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import FeedbackItem from './Item';

const FeedbackList = () => {
  const reduxDispatch = useReduxDispatch();
  const feedbacks = useReduxSelector(feedbackSelectors.getAll);
  const isLoading = useReduxSelector(feedbackSelectors.getAllIsLoading);
  const isError = useReduxSelector(feedbackSelectors.getAllIsError);

  const handleFeedbackList = useCallback(() => {
    reduxDispatch(feedbackActions.getAllRequest());
  }, [reduxDispatch]);

  useEffect(() => {
    handleFeedbackList();
  }, [handleFeedbackList]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle>Feedbacks</General.PageTitle>
      </General.PageTitleContainer>
      <Tables.Items>
        <ComponentIsVisible when={!isLoading && feedbacks.length !== 0}>
          <FeedbackItem.Header />
          {feedbacks.map(feedback => (
            <FeedbackItem.Body feedback={feedback} key={feedback.id} />
          ))}
        </ComponentIsVisible>
        <ComponentLoadingList rows={8} show={isLoading} />
        <ComponentIsVisible when={isError}>
          <ComponentError
            message="Falha ao buscar os feedbacks"
            onClick={handleFeedbackList}
          />
        </ComponentIsVisible>
        <ComponentEmpty
          message="Não há feedbacks para serem exibidos"
          show={!isLoading && !isError && feedbacks.length === 0}
        />
      </Tables.Items>
    </Containers.Global>
  );
};

export default FeedbackList;
