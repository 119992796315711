import {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import ComponentIsVisible from 'components/utils/IsVisible';

import { Backdrop, Container } from './styles';

export interface IComponentModalBaseRefProps {
  close: () => void;
  open: () => void;
}

interface IComponentModalBaseProps {
  children: ReactNode;
}

const ComponentModalBase: React.ForwardRefRenderFunction<
  IComponentModalBaseRefProps,
  IComponentModalBaseProps
> = ({ children }, ref) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const openModal = useCallback(() => {
    document.body.classList.add('disable-scroll');
    setIsOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    document.body.classList.remove('disable-scroll');
    setIsOpened(false);
  }, []);

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  useHotkeys('esc', () => closeModal());

  return (
    <ComponentIsVisible when={isOpened}>
      <Container>
        <Backdrop onClick={closeModal} />
        {children}
      </Container>
    </ComponentIsVisible>
  );
};

export default forwardRef(ComponentModalBase);
