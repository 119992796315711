import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetAllCustomerOrdersResponse from 'services/httpClient/responses/customer/IGetAllCustomerOrdersResponse';

import { ICustomerInitialData } from '../../index';

function ordersSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IGetAllCustomerOrdersResponse>,
) {
  draft.orders.data = action.payload.list.map(order => ({
    dateBilling: order.dateBilling,
    dateCancellation: order.dateCancellation,
    dateClosing: order.dateClosing,
    dateOpening: order.dateOpening,
    dateProcessing: order.dateProcessing,
    id: order.id,
    paymentMethod: {
      id: order.paymentMethod.id,
      name: order.paymentMethod.name,
    },
  }));
  draft.orders.config.isLoading = false;
}

export default ordersSuccess;
