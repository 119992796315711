import styled from 'styled-components';

import { Form } from 'formik';

import colors from 'styles/colors';

export const Content = styled.div`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  max-width: 68rem;
  padding: 2rem;
  width: 100%;
  z-index: 4;
`;

export const Title = styled.h4`
  color: ${colors.primary};
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const FormikForm = styled(Form)``;

export const Fields = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 15rem 1fr;
  margin-bottom: 1rem;
`;

export const ColumnOptions = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
`;

export const Label = styled.span`
  color: ${colors.label};
  font-size: 0.875rem;
  grid-column-end: 4;
  grid-column-start: 1;
`;

export const ActiveField = styled.div`
  display: flex;
  margin: 1rem 0.5rem 0 auto;
  width: fit-content;
`;

export const Actions = styled.div`
  align-items: flex-end;
  column-gap: 0.5rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;
