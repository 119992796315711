import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { ListContainer, Subtitle } from '../styles';
import CustomerOrdersItem from './Item';

interface ICustomerOrdersParams extends Record<string, string | undefined> {
  id: string;
}

const CustomerOrdersList = () => {
  const params = useParams<ICustomerOrdersParams>();
  const reduxDispatch = useReduxDispatch();
  const customerOrders = useReduxSelector(customerSelectors.orders);
  const isLoading = useReduxSelector(customerSelectors.ordersIsLoading);
  const isError = useReduxSelector(customerSelectors.ordersIsError);

  const handleCustomerOrders = useCallback(() => {
    if (!params.id) {
      return;
    }
    reduxDispatch(
      customerActions.ordersRequest({
        data: {
          customerId: Number(params.id),
        },
      }),
    );
  }, [params.id, reduxDispatch]);

  useEffect(() => {
    handleCustomerOrders();
  }, [handleCustomerOrders]);

  return (
    <>
      <Subtitle>Pedidos</Subtitle>
      <ListContainer>
        <ComponentIsVisible
          when={!isLoading && !isError && customerOrders.length !== 0}
        >
          <CustomerOrdersItem.Header />
          {customerOrders.map((customerOrder, position) => (
            <CustomerOrdersItem.Body
              customerOrder={customerOrder}
              key={customerOrder.id}
              position={position}
            />
          ))}
        </ComponentIsVisible>
        <ComponentEmpty
          message="Nenhum pedido encontrado"
          show={!isError && customerOrders.length === 0 && !isLoading}
        />
        <ComponentIsVisible when={isError}>
          <ComponentError
            message="Erro na busca pelas tabelas"
            onClick={handleCustomerOrders}
          />
        </ComponentIsVisible>
        <ComponentLoadingList rows={8} show={isLoading} />
      </ListContainer>
    </>
  );
};

export default CustomerOrdersList;
