import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IDashboardInitialData } from '../../index';

function rankingCustomerRequest(
  draft: Draft<IDashboardInitialData>,
  _: PayloadAction,
) {
  draft.rankingCustomer.config.isLoading = true;
}

export default rankingCustomerRequest;
