import IRankingProduct from 'models/IRankingProduct';

import Tables from 'styles/tables';

import { ProductItemsBody } from '../styles';

interface IItemBodyProps {
  position: number;
  product: IRankingProduct;
}

const ItemBody = ({ position, product }: IItemBodyProps): JSX.Element => {
  return (
    <ProductItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{product.name}</Tables.ItemBody>
      <Tables.ItemBody center>{product.sales}</Tables.ItemBody>
    </ProductItemsBody>
  );
};

export default ItemBody;
