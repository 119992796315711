import { useCallback, useEffect, useRef, useState } from 'react';

import { FiAlertCircle } from 'react-icons/fi';
import ReactSelect, {
  SelectInstance,
  Props as SimpleProps,
} from 'react-select';

import IComponentSelectProps from 'interfaces/IComponentSimpleProps';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Container, Error, Label, SelectContainer } from '../styles';
import { customStyles } from './styles';

export interface IOptionTypeBase {
  [key: string]: any;
}
interface IComponentSelectSimpleProps
  extends SimpleProps<IOptionTypeBase, false> {
  backgroundColor?: string;
  errorMessage?: string;
  hasError?: boolean;
  label?: string;
  name: string;
  onLoadOptions?: (
    setLoading: (stateLoading: boolean) => void,
  ) => Promise<void>;
  options?: IComponentSelectProps<number | string>[];
  placeholder?: string;
  title?: string;
}

function ComponentSelectSimple({
  backgroundColor,
  errorMessage,
  hasError = false,
  isDisabled,
  label,
  name,
  onLoadOptions,
  options,
  placeholder,
  title,
  ...rest
}: IComponentSelectSimpleProps): JSX.Element {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectRef = useRef<SelectInstance<IOptionTypeBase> | null>(null);

  const onSetLoading = useCallback((stateLoading: boolean) => {
    setIsLoading(stateLoading);
  }, []);

  const handleSelectFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleSelectBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!selectRef.current?.hasValue());
  }, []);

  useEffect(() => {
    if (onLoadOptions) {
      onLoadOptions(onSetLoading);
    }
  }, [onLoadOptions, onSetLoading]);

  return (
    <Container>
      <ComponentIsVisible when={!!label}>
        <Label>{label}</Label>
      </ComponentIsVisible>

      <SelectContainer
        backgroundColor={backgroundColor}
        isDisabled={isDisabled}
        isErrored={hasError}
        isFilled={isFilled}
        isFocused={isFocused}
        title={title}
      >
        <ReactSelect
          isDisabled={isDisabled}
          isLoading={isLoading}
          loadingMessage={() => 'Buscando'}
          name={name}
          noOptionsMessage={() => 'Sem opções'}
          onBlur={handleSelectBlur}
          onFocus={handleSelectFocus}
          options={options}
          placeholder={isLoading ? 'BUSCANDO' : placeholder}
          ref={selectRef}
          styles={customStyles}
          {...rest}
        />

        <ComponentIsVisible when={hasError}>
          <Error title={errorMessage as string}>
            <FiAlertCircle color={colors.red} size={18} />
          </Error>
        </ComponentIsVisible>
      </SelectContainer>
    </Container>
  );
}

export default ComponentSelectSimple;
