import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface ILogoRequestAction {
  data: {
    url: string;
  };
}

function logoRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<ILogoRequestAction>,
) {
  draft.logo.config.isLoading = true;
}

export default logoRequest;
