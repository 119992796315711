import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import companyConfig from 'config/company';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';
import { IApplicationState } from 'store/rootReducers';
import { bannerActions } from 'store/slices/banner';
import { configActions } from 'store/slices/config';
import { listActions } from 'store/slices/list';
import { settingActions } from 'store/slices/setting';

import httpClient, {
  httpClientSetAuthorization,
  httpClientSetPedToken,
} from 'services/httpClient';
import IGetProfileResponse from 'services/httpClient/responses/auth/IGetProfileResponse';

import { authActions } from '..';

function* setToken({ payload }: PayloadAction<IApplicationState>) {
  try {
    httpClientSetPedToken(companyConfig.getToken());
    yield put(
      configActions.setPedToken({
        data: {
          pedToken: companyConfig.getToken(),
        },
      }),
    );
    if (!payload) {
      return;
    }
    const { authorization } = payload.auth;
    if (authorization?.token) {
      httpClientSetAuthorization(authorization?.token);
      const { data: profileData }: AxiosResponse<IGetProfileResponse> =
        yield call(httpClient.get, 'admin/sessions');
      yield put(authActions.setProfile(profileData));
      yield put(bannerActions.setData({ data: profileData.banners }));
      yield put(
        listActions.setList({
          data: {
            list: profileData.lists,
          },
        }),
      );
      yield put(settingActions.setData(profileData.settings));
    }
  } catch (err: any) {
    helpers.errorHandling(err);
  }
}

export default setToken;
