import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutUpdateListItemRequest from 'services/httpClient/request/list/IPutUpdateListItemRequest';
import IUploadImageResponse from 'services/httpClient/responses/image/IUploadImageResponse';
import IPutUpdateListItemResponse from 'services/httpClient/responses/list/IPutUpdateListItemResponse';

import { IUpdateItemRequestAction } from '../reducers/updateItem/request';

import { listActions } from '..';

function* updateItem(action: PayloadAction<IUpdateItemRequestAction>) {
  try {
    let imageUrl = '';
    if (action.payload.data.image) {
      const formData = new FormData();
      formData.append('file', action.payload.data.image.file);
      formData.append('type', action.payload.data.image.uploadType);
      const {
        data: { url },
      }: AxiosResponse<IUploadImageResponse> = yield call(
        httpClient.post,
        'admin/pictures',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      imageUrl = url;
    }
    const body: IPutUpdateListItemRequest = {
      link: {
        type: action.payload.data.link.type,
        value: action.payload.data.link.value,
        priceListId: action.payload.data.link.priceListId ?? undefined,
      },
      url: imageUrl || action.payload.data.url,
    };
    const {
      data: { listItem, message },
    }: AxiosResponse<IPutUpdateListItemResponse> = yield call(
      httpClient.put,
      `admin/settings/lists/${action.payload.data.listId}/items/${action.payload.data.id}`,
      body,
    );
    ToastManager.show({
      title: message,
      type: 'success',
    });
    action.payload.functions.success();
    yield put(
      listActions.updateItemSuccess({
        data: { listId: action.payload.data.listId, listItem },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(listActions.updateItemFailure());
  }
}

export default updateItem;
