import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IListItem from 'models/IListItem';

import { IListInitialData } from '../../index';

export interface IUpdateItemSuccessAction {
  data: {
    listId: number;
    listItem: IListItem;
  };
}

function updateItemSuccess(
  draft: Draft<IListInitialData>,
  action: PayloadAction<IUpdateItemSuccessAction>,
) {
  const updatedListItems = draft.data.map(list => {
    if (list.id === action.payload.data.listId) {
      return {
        active: list.active,
        id: list.id,
        isCollapsed: list.isCollapsed,
        position: list.position,
        quantityColumns: list.quantityColumns,
        subtitle: list.subtitle,
        title: list.title,
        typeView: list.typeView,
        items: list.items.map(listItem => {
          if (listItem.id === action.payload.data.listItem.id) {
            return {
              ...listItem,
              link: {
                priceListId: action.payload.data.listItem.link.priceListId,
                type: action.payload.data.listItem.link.type,
                value: action.payload.data.listItem.link.value,
              },
              url: action.payload.data.listItem.url,
            };
          }
          return listItem;
        }),
      };
    }
    return list;
  });

  draft.data = updatedListItems;
  draft.updateItem.config.isLoading = false;
}

export default updateItemSuccess;
