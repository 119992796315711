import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCustomerPedcoinsSummaryResponse from 'services/httpClient/responses/customer/IGetCustomerPedcoinsSummaryResponse';

import { customerActions } from '..';

import { IPedcoinsSummaryRequestAction } from '../reducers/pedcoinsSummary/request';

function* pedcoinsSummary(
  action: PayloadAction<IPedcoinsSummaryRequestAction>,
) {
  try {
    const response: AxiosResponse<IGetCustomerPedcoinsSummaryResponse> =
      yield call(
        httpClient.get,
        `admin/customers/${action.payload.data.customerId}/pedcoins/summary`,
      );

    yield put(customerActions.pedcoinsSummarySuccess({ data: response.data }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.pedcoinsSummaryFailure());
  }
}

export default pedcoinsSummary;
