import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerAccessInitialData } from '../..';

export interface ISpreadsheetCustomerAccessRequestAction {
  data: {
    date?: string;
    page: number;
  };
}

function spreadsheetCustomerAccessRequest(
  draft: Draft<ICustomerAccessInitialData>,
  _: PayloadAction<ISpreadsheetCustomerAccessRequestAction>,
) {
  draft.spreadsheet.config.isLoading = true;
}

export default spreadsheetCustomerAccessRequest;
