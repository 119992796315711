import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm } from '../styles';
import { Fields } from './styles';

interface IMeuCatalogoCredentialsFormData {
  email: string;
  password: string;
}

const meuCatalogoCredentialsSchema = Yup.object().shape({
  email: Yup.string().required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
});

const MeuCatalogoCredentials: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const meuCatalogoCredentialsFormRef =
    useRef<FormikProps<IMeuCatalogoCredentialsFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.meuCatalogoIsLoading);
  const meuCatalogo = useReduxSelector(settingSelectors.meuCatalogo);

  const handleMeuCatalogoCredentials = useCallback(
    (data: IMeuCatalogoCredentialsFormData) => {
      reduxDispatch(
        settingActions.meuCatalogoRequest({
          data: {
            email: data.email,
            password: data.password,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        email: meuCatalogo.email,
        password: meuCatalogo.password,
      }}
      innerRef={meuCatalogoCredentialsFormRef}
      onSubmit={handleMeuCatalogoCredentials}
      validationSchema={meuCatalogoCredentialsSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Conta Meu Catálogo
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>
            <CardSubtitle>
              Preencha os campos abaixo para vincular sua conta do Meu Catálogo
            </CardSubtitle>

            <Fields>
              <ComponentInputSimple
                errorMessage={errors.email}
                hasError={!!errors.email}
                label="E-mail"
                name="email"
                onChange={handleChange('email')}
                value={values.email}
              />
              <ComponentInputPassword
                errorMessage={errors.password}
                hasError={!!errors.password}
                label="Senha"
                name="password"
                onChange={handleChange('password')}
                value={values.password}
              />
              <ComponentButtonBase
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
              >
                Salvar
              </ComponentButtonBase>
            </Fields>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default MeuCatalogoCredentials;
