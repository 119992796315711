import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IUpdateSuccessAction {
  data: {
    active: boolean;
    id: number;
    isCollapsed: boolean;
    position: number;
    quantityColumns: number;
    subtitle: string;
    title: string;
    typeView: {
      label: string;
      value: string;
    };
  };
}

function updateSuccess(
  draft: Draft<IListInitialData>,
  action: PayloadAction<IUpdateSuccessAction>,
) {
  const list = draft.data.map(list => {
    if (list.id === action.payload.data.id) {
      return {
        active: action.payload.data.active,
        quantityColumns: action.payload.data.quantityColumns,
        title: action.payload.data.title,
        subtitle: action.payload.data.subtitle,
        typeView: action.payload.data.typeView,
        id: list.id,
        isCollapsed: list.isCollapsed,
        items: list.items,
        position: list.position,
      };
    }
    return list;
  });

  draft.data = list;
  draft.update.config.isLoading = false;
}

export default updateSuccess;
