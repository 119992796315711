import { useCallback, useEffect, useRef, useState } from 'react';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { FaInfo } from 'react-icons/fa';
import { FiCamera, FiPlus } from 'react-icons/fi';

import EBannersType from 'enums/bannersType';
import EUploadImageType from 'enums/uploadImageType';
import IList from 'models/IList';
import authSelectors from 'store/slices/auth/selectors';
import { bannerActions } from 'store/slices/banner';
import bannerSelectors from 'store/slices/banner/selectors';
import { listActions } from 'store/slices/list';
import listSelectors from 'store/slices/list/selectors';
import { v4 as uuidV4 } from 'uuid';
import validators from 'validators';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentImageCropper, {
  IComponentImageCropperRefProps,
} from 'components/modal/Cropper';
import ComponentUpdateNotes, {
  IComponentUpdateNotesRefProps,
} from 'components/modal/UpdateNotes';
import ComponentIsVisible from 'components/utils/IsVisible';

import Containers from 'styles/containers';
import General from 'styles/general';

import HelperBanner, { IHelperBannerRefProps } from './banners/Helper';
import UploadedItem from './banners/Item';
import PreviewBanner, { IPreviewBannerRefProps } from './banners/Preview';
import ListConfigCreate, {
  IListConfigCreateRefProps,
} from './list/Config/Create';
import ListConfigEdit, { IListConfigEditRefProps } from './list/Config/Edit';
import ListContent from './list/Content';
import {
  BannerInformation,
  BannersActions,
  BannersConfiguration,
  Body,
  ErrorMessage,
  Helper,
  HelperAction,
  Label,
  MiniatureBanners,
  NewList,
  Option,
  Options,
  RotativeBanners,
  Subtitle,
  SubtitleContainer,
  TitleSeparator,
  UploadButton,
  UploadFile,
  Uploads,
} from './styles';

interface IUploadImageData {
  imageType: EUploadImageType;
  uploadRef: React.MutableRefObject<HTMLInputElement>;
}
interface IDeleteImageData {
  id: string | number;
  imageType: EUploadImageType;
}
interface IErroredData {
  banners: boolean;
  thumbnails: boolean;
}

const Home: React.FC = () => {
  const reduxDispatch = useReduxDispatch();
  const { show: toastShow } = useToast();
  const componentUpdateNotesRef = useRef<IComponentUpdateNotesRefProps>(null);
  const uploadBannerRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const uploadThumbnailRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const helperBannerRef = useRef<IHelperBannerRefProps>(null);
  const previewBannerRef = useRef<IPreviewBannerRefProps>(null);
  const componentImageCropperRef = useRef<IComponentImageCropperRefProps>(null);
  const listConfigCreateRef = useRef<IListConfigCreateRefProps>(null);
  const listConfigEditRef = useRef<IListConfigEditRefProps>(null);
  const [isErrored, setIsErrored] = useState<IErroredData>({
    banners: false,
    thumbnails: false,
  });
  const [currentLists, setCurrentLists] = useState<IList[]>([]);

  const images = useReduxSelector(bannerSelectors.images);
  const thumbnails = useReduxSelector(bannerSelectors.thumbnails);
  const type = useReduxSelector(bannerSelectors.type);
  const isLoading = useReduxSelector(bannerSelectors.uploadIsLoading);
  const lists = useReduxSelector(listSelectors.list);
  const hideUpdateNotes = useReduxSelector(authSelectors.hideUpdateNotes);

  const [bannersType, setBannersType] = useState<EBannersType | undefined>(
    type,
  );

  const openHelperBanner = () => {
    helperBannerRef.current?.open();
  };

  const handleUploadImage = (data: IUploadImageData) => {
    data.uploadRef.current.click();

    data.uploadRef.current.addEventListener('change', () => {
      if (data.uploadRef.current.files) {
        const file = data.uploadRef.current.files.item(0);

        if (file) {
          const fileVerified = validators.fileType(file?.type);

          if (!fileVerified) {
            toastShow({
              title: 'Formato de arquivo inválido',
              description: 'Formatos permitidos: png, jpg e jpeg',
              type: 'error',
            });
            return;
          }

          const reader = new FileReader();

          reader.readAsDataURL(file);

          reader.onload = event => {
            componentImageCropperRef.current?.open({
              imageUrl: event.target?.result as string,
              imageType: data.imageType,
            });
          };
        }
      }
    });
  };

  const handleRemoveImage = useCallback(
    (data: IDeleteImageData) => {
      reduxDispatch(
        bannerActions.remove({
          data: {
            id: data.id,
            type: data.imageType,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleSaveBannerSettings = useCallback(() => {
    if (
      !images.length &&
      bannersType === EBannersType.RotatingAndThumbnail &&
      thumbnails.length < 2
    ) {
      setIsErrored({
        banners: true,
        thumbnails: true,
      });
    }
    if (!images.length) {
      setIsErrored(currentErrored => ({
        ...currentErrored,
        banners: true,
      }));
      return;
    }
    if (
      bannersType === EBannersType.RotatingAndThumbnail &&
      thumbnails.length < 2
    ) {
      setIsErrored(currentErrored => ({
        ...currentErrored,
        thumbnails: true,
      }));
      return;
    }
    setIsErrored({
      banners: false,
      thumbnails: false,
    });

    const bannersImagesFormatted = images.map(newBannerImage => {
      return newBannerImage.url;
    });

    reduxDispatch(
      bannerActions.updateRequest({
        data: {
          images: bannersImagesFormatted,
          type: bannersType as EBannersType,
          thumbnails:
            bannersType === EBannersType.RotatingAndThumbnail
              ? {
                  one: thumbnails[0].url,
                  two: thumbnails[1].url,
                }
              : undefined,
        },
      }),
    );
  }, [images, thumbnails, bannersType, reduxDispatch]);

  const openPreviewImage = useCallback((url: string) => {
    previewBannerRef.current?.open(url);
  }, []);

  const openListConfigCreate = () => {
    listConfigCreateRef.current?.open();
  };
  const openListConfigEdit = (list: IList) => {
    listConfigEditRef.current?.open(list);
  };
  const handleUpdateListPosition = useCallback(
    (lists: IList[]) => {
      const ids = lists.map(list => list.id);
      reduxDispatch(
        listActions.updateListPositionRequest({
          data: {
            ids,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const reorder = useCallback(
    (list: IList[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    },
    [],
  );
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const listsReorder = reorder(
        currentLists,
        result.source.index,
        result.destination.index,
      );
      setCurrentLists(listsReorder);
      handleUpdateListPosition(listsReorder);
    },
    [currentLists, handleUpdateListPosition, reorder],
  );

  useEffect(() => {
    setBannersType(type);
  }, [type]);

  useEffect(() => {
    setCurrentLists(lists);
  }, [lists]);

  useEffect(() => {
    if (!hideUpdateNotes) {
      componentUpdateNotesRef.current?.open();
    }
  }, [hideUpdateNotes]);

  return (
    <Containers.Global>
      <General.PageTitle>Configuração página inicial</General.PageTitle>

      <SubtitleContainer>
        <Subtitle>Banner Principal</Subtitle>
      </SubtitleContainer>
      <BannersConfiguration>
        <TitleSeparator>Modelo de exibição do banner principal</TitleSeparator>
        <Label>Escolha o modelo de exibição desejado</Label>
        <Options>
          <Option
            onClick={() => setBannersType(EBannersType.JustRotating)}
            optionSelected={bannersType === EBannersType.JustRotating}
          >
            Apenas banner rotativo
          </Option>
          <Option
            onClick={() => setBannersType(EBannersType.RotatingAndThumbnail)}
            optionSelected={bannersType === EBannersType.RotatingAndThumbnail}
          >
            Banner rotativo com 2 banners menores fixos
          </Option>
        </Options>
        <Helper>
          Está com dúvidas?
          <HelperAction onClick={openHelperBanner}> Clique aqui</HelperAction>e
          veja a diferença entre as duas opções disponíveis.
        </Helper>

        <RotativeBanners>
          <TitleSeparator>
            Insira as imagens que serão exibidas no banner rotativo
            <BannerInformation>
              <FaInfo size={10} />
            </BannerInformation>
            <ComponentIsVisible when={!images?.length && isErrored.banners}>
              <ErrorMessage>
                Adicione pelo menos um banner rotativo
              </ErrorMessage>
            </ComponentIsVisible>
          </TitleSeparator>
          <Uploads>
            <ComponentIsVisible when={!!images?.length}>
              {images?.map(image => (
                <UploadedItem
                  image={image}
                  key={image.id}
                  openPreview={openPreviewImage}
                  removeImage={() =>
                    handleRemoveImage({
                      imageType: EUploadImageType.banner,
                      id: image.id,
                    })
                  }
                />
              ))}
            </ComponentIsVisible>
            <UploadButton
              disabled={isLoading}
              onClick={() =>
                handleUploadImage({
                  imageType: EUploadImageType.banner,
                  uploadRef: uploadBannerRef,
                })
              }
            >
              <UploadFile
                accept="image/png, image/jpg, image/jpeg"
                ref={uploadBannerRef}
                type="file"
              />
              Nova imagem
              <FiCamera size={18} />
            </UploadButton>
          </Uploads>
        </RotativeBanners>

        <ComponentIsVisible
          when={bannersType === EBannersType.RotatingAndThumbnail}
        >
          <MiniatureBanners>
            <TitleSeparator>
              {`Insira as imagens dos banners menores (${thumbnails.length}/2)`}
              <BannerInformation className="smallBanner">
                <FaInfo size={10} />
              </BannerInformation>
              <ComponentIsVisible
                when={thumbnails.length < 2 && isErrored.thumbnails}
              >
                <ErrorMessage>
                  Na opção com 2 miniaturas, ambas são obrigatórias
                </ErrorMessage>
              </ComponentIsVisible>
            </TitleSeparator>
            <Uploads>
              <ComponentIsVisible when={!!thumbnails.length}>
                {thumbnails.map(image => (
                  <UploadedItem
                    image={image}
                    key={image.id}
                    openPreview={openPreviewImage}
                    removeImage={() =>
                      handleRemoveImage({
                        imageType: EUploadImageType.thumbnail,
                        id: image.id,
                      })
                    }
                  />
                ))}
              </ComponentIsVisible>
              <ComponentIsVisible when={thumbnails.length < 2}>
                <UploadButton
                  disabled={isLoading}
                  onClick={() =>
                    handleUploadImage({
                      imageType: EUploadImageType.thumbnail,
                      uploadRef: uploadThumbnailRef,
                    })
                  }
                >
                  <UploadFile
                    accept="image/png, image/jpg, image/jpeg"
                    ref={uploadThumbnailRef}
                    type="file"
                  />
                  Nova imagem
                  <FiCamera size={18} />
                </UploadButton>
              </ComponentIsVisible>
            </Uploads>
          </MiniatureBanners>
        </ComponentIsVisible>

        <BannersActions>
          <ComponentButtonBase
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleSaveBannerSettings}
          >
            Salvar
          </ComponentButtonBase>
        </BannersActions>
      </BannersConfiguration>

      <SubtitleContainer>
        <Subtitle>Controle da listagem</Subtitle>

        <NewList disabled={isLoading} onClick={openListConfigCreate}>
          Nova listagem
          <FiPlus size={18} />
        </NewList>
      </SubtitleContainer>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable direction="vertical" droppableId={uuidV4()}>
          {provided => (
            <Body ref={provided.innerRef} {...provided.droppableProps}>
              {currentLists.map((list, index) => (
                <ListContent
                  index={index}
                  key={list.id}
                  list={list}
                  openConfigEdit={openListConfigEdit}
                />
              ))}
              {provided.placeholder}
            </Body>
          )}
        </Droppable>
      </DragDropContext>

      <HelperBanner ref={helperBannerRef} />
      <PreviewBanner ref={previewBannerRef} />
      <ComponentImageCropper ref={componentImageCropperRef} />
      <ListConfigCreate ref={listConfigCreateRef} />
      <ListConfigEdit ref={listConfigEditRef} />
      <ComponentUpdateNotes ref={componentUpdateNotesRef} />
    </Containers.Global>
  );
};

export default Home;
