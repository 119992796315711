import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IKitInitialData } from '../../index';

export interface IListRequestAction {
  data: {
    linkId?: string;
  };
}

function listRequest(
  draft: Draft<IKitInitialData>,
  _: PayloadAction<IListRequestAction>,
) {
  draft.data.list = [];
  draft.data.config.isError = false;
  draft.data.config.isLoading = true;
}

export default listRequest;
