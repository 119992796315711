import { createSlice } from '@reduxjs/toolkit';
import IPagination from 'interfaces/IPagination';
import IOrder from 'models/IOrder';

import reducers from './reducers';

export interface IOrderInitialData {
  list: {
    config: {
      isError: boolean;
      isLoading: boolean;
      pagination: IPagination;
    };
    data: IOrder[];
  };
  pdf: {
    config: {
      isLoading: boolean;
    };
  };
  spreadsheet: {
    config: {
      isLoading: boolean;
    };
  };
}

const initialState: IOrderInitialData = {
  list: {
    config: {
      isError: false,
      isLoading: false,
      pagination: {
        page: 1,
        totalPages: 1,
      },
    },
    data: [],
  },
  pdf: {
    config: {
      isLoading: false,
    },
  },
  spreadsheet: {
    config: {
      isLoading: false,
    },
  },
};

const orderSlice = createSlice({
  name: '@order',
  initialState,
  reducers,
});

export const orderActions = orderSlice.actions;
export const orderReducers = orderSlice.reducer;
