import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IFaviconSuccessAction {
  url: string;
}

function faviconSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IFaviconSuccessAction>,
) {
  draft.favicon.config.isLoading = false;
  draft.data.design.favicon = action.payload.url;
}

export default faviconSuccess;
