import styled from 'styled-components';

import colors from 'styles/colors';

export const Fields = styled.div`
  align-items: flex-end;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 8rem;
  row-gap: 0.5rem;

  @media screen and (min-width: 1366px) {
    grid-template-columns: 10rem 10rem 7rem 8rem;
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: 15rem 12rem 8rem 8rem;
  }
`;

export const CustomField = styled.div``;

export const Label = styled.label`
  color: ${colors.label};
  font-size: 0.875rem;
`;

export const FieldInformation = styled.div`
  align-items: center;
  background-color: ${colors.label};
  border-radius: 100%;
  color: ${colors.white};
  cursor: help;
  display: inline-flex;
  height: 1.15rem;
  justify-content: center;
  margin-left: 0.5rem;
  position: relative;
  width: 1.15rem;

  &:hover {
    &::before {
      height: 0;
      width: 0;
      content: '';
      position: absolute;
      right: -0.75rem;
      top: 0.25rem;
      border-bottom: 0.5rem solid transparent;
      border-top: 0.5rem solid transparent;
      border-right: 0.5rem solid ${colors.label};
      z-index: 2;
    }
    &::after {
      background-color: ${colors.label};
      border-radius: 0.5rem;
      color: ${colors.white};
      content: 'Digite o prazo de expiração dos pontos em meses usando números de 1 a 12, por exemplo: 1 = um mês, 2 = dois meses e etc.';
      height: fit-content;
      left: calc(100% + 0.75rem);
      line-height: 1.15rem;
      padding: 0.5rem 0.75rem;
      position: absolute;
      top: -0.5rem;
      width: 10rem;
      z-index: 4;
    }
  }
`;
