import IListItem from './IListItem';

export enum EListTypeView {
  Authenticated = 'AUTENTICADO',
  Both = 'AMBOS',
  NotAuthenticated = 'NAO_AUTENTICADO',
}

interface IList {
  active: boolean;
  id: number;
  isCollapsed: boolean;
  items: IListItem[];
  position: number;
  quantityColumns: number;
  subtitle: string;
  title: string;
  typeView: {
    label: string;
    value: string;
  };
}

export default IList;
