import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IRemoveRequestAction } from '../reducers/remove/request';

import { listActions } from '..';

function* remove(action: PayloadAction<IRemoveRequestAction>) {
  try {
    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.delete,
      `admin/settings/lists/${action.payload.data.id}`,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });

    action.payload.functions.success();

    yield put(
      listActions.removeSuccess({ data: { id: action.payload.data.id } }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(listActions.removeFailure());
  }
}

export default remove;
