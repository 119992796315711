import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IPedcoinsExtractRequestAction {
  data: {
    customerId: number;
    page: number;
  };
}

function pedcoinsExtractRequest(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IPedcoinsExtractRequestAction>,
) {
  draft.pedcoins.extract.data = [];
  draft.pedcoins.extract.config.isLoading = true;
  draft.pedcoins.extract.config.isError = false;
  draft.pedcoins.extract.config.page = action.payload.data.page;
}

export default pedcoinsExtractRequest;
