import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPhone from 'components/input/Phone';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm } from '../styles';
import { ContainerButton, Fields } from './styles';

interface ISocialMediaFormData {
  facebook: string;
  instagram: string;
  linkedin: string;
  whatsapp: string;
}

const socialMediaSchema = Yup.object().shape({
  facebook: Yup.string(),
  instagram: Yup.string(),
  linkedin: Yup.string(),
  whatsapp: Yup.string(),
});

function SocialMedia(): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const socialMediaFormRef = useRef<FormikProps<ISocialMediaFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.socialMediaIsLoading);
  const socialMedia = useReduxSelector(settingSelectors.socialMedia);

  const handleSocialMedias = useCallback(
    (data: ISocialMediaFormData) => {
      reduxDispatch(
        settingActions.socialMediaRequest({
          data: {
            facebook: data.facebook,
            instagram: data.instagram,
            linkedin: data.linkedin,
            whatsapp: data.whatsapp,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        facebook: socialMedia.facebook,
        instagram: socialMedia.instagram,
        linkedin: socialMedia.linkedin,
        whatsapp: socialMedia.whatsapp,
      }}
      innerRef={socialMediaFormRef}
      onSubmit={handleSocialMedias}
      validationSchema={socialMediaSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Redes sociais
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>

            <CardSubtitle>
              Preencha os campos abaixo com os links das suas redes sociais
            </CardSubtitle>

            <Fields>
              <ComponentInputSimple
                errorMessage={errors.facebook}
                hasError={!!errors.facebook}
                label="Facebook"
                name="facebook"
                onChange={handleChange('facebook')}
                value={values.facebook}
              />
              <ComponentInputSimple
                errorMessage={errors.instagram}
                hasError={!!errors.instagram}
                label="Instagram"
                name="instagram"
                onChange={handleChange('instagram')}
                value={values.instagram}
              />
              <ComponentInputSimple
                errorMessage={errors.linkedin}
                hasError={!!errors.linkedin}
                label="Linkedin"
                name="linkedin"
                onChange={handleChange('linkedin')}
                value={values.linkedin}
              />
              <ComponentInputPhone
                errorMessage={errors.whatsapp}
                hasError={!!errors.whatsapp}
                label="WhatsApp"
                name="whatsapp"
                onChange={handleChange('whatsapp')}
                value={values.whatsapp}
              />
            </Fields>

            <ContainerButton>
              <ComponentButtonBase
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
                width="8rem"
              >
                Salvar
              </ComponentButtonBase>
            </ContainerButton>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
}

export default SocialMedia;
