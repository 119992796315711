import { useRef } from 'react';

import formatters from 'formatters';

import ComponentInputBase, {
  IComponentInputBaseProps,
  IComponentInputBaseRefProps,
} from '../Base';

type IComponentInputNumberProps = IComponentInputBaseProps;

const ComponentInputNumber: React.FC<IComponentInputNumberProps> = props => {
  const componentInputBaseRef = useRef<IComponentInputBaseRefProps>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    const textFormatted = formatters.onlyNumbers(text);
    componentInputBaseRef.current?.setValueRef(textFormatted);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <ComponentInputBase
      {...props}
      onChange={handleOnChange}
      ref={componentInputBaseRef}
    />
  );
};

export default ComponentInputNumber;
