import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Aside = styled.aside`
  background-color: ${colors.tabBackground};
  display: grid;
  height: 100%;
  height: 100vh;
  left: 0;
  max-width: 6rem;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      background: ${colors.background};
      height: 0.5rem;
      width: 0.4rem;
      border-radius: 0.25rem;
    }

    ::-webkit-scrollbar-button {
      height: 0;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background: ${colors.background};
      height: 0.5rem;
      border-radius: 0.25rem;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.placeholder};
      height: 0.5rem;
      width: 0.4rem;
      border-radius: 0.25rem;
    }
  }
`;

export const Content = styled.nav`
  align-items: center;
  display: grid;
  grid-template-rows: 5rem 1fr 5rem;
  justify-content: center;
  max-width: 6rem;
  padding: 1rem;
  position: relative;
  width: 100%;
`;

export const Thumbnail = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 4rem;
  text-decoration: none;
`;

interface ILogoProps {
  isLoading?: boolean;
}

export const Logo = styled.img<ILogoProps>`
  display: none;
  height: 100%;
  width: 100%;

  ${props =>
    props.isLoading &&
    css`
      display: block;
    `}
`;

interface ILogoLoadingProps {
  isLoading?: boolean;
}

export const LogoLoading = styled.div<ILogoLoadingProps>`
  animation: ${animations.shimmer} 2s infinite linear;
  background: linear-gradient(to right, #dedede 4%, #fafafa 25%, #dedede 36%);
  background-size: 1000px 100%;
  border-radius: 0.25rem;
  display: none;
  height: 4rem;
  width: 4rem;

  ${props =>
    props.isLoading &&
    css`
      display: block;
    `}
`;

export const Menu = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

interface IItemProps {
  selected?: boolean;
}

export const Item = styled(Link)<IItemProps>`
  align-items: center;
  background-color: ${colors.transparent};
  border: 1px solid ${colors.transparent};
  border-radius: 0.5rem;
  color: ${colors.primary};
  display: flex;
  font-size: 0;
  height: 2.25rem;
  justify-content: center;
  text-decoration: none;
  width: 2.25rem;

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${colors.white};
      border: 1px solid ${colors.border};
    `};
`;

export const LogOut = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.red};
  display: flex;
  font-size: 0;
  height: 2.25rem;
  justify-content: center;
  margin: 0 auto;
  width: 2.25rem;

  &:hover {
    filter: brightness(0.85);
  }
`;
