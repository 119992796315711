import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  align-items: center;
  color: ${colors.text};
  column-gap: 0.25rem;
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
`;

export const Checkbox = styled.label`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  color: ${colors.primary};
  cursor: pointer;
  display: flex;
  height: 1.5rem;
  justify-content: flex-start;
  margin-top: 0;
  position: relative;
  user-select: none;
  width: 1.5rem;
  &:hover .checkboxInput ~ .checkmark {
    background-color: ${colors.primary};
    opacity: 0.7;
  }
`;

export const Input = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 0;
  &:checked ~ .checkmark {
    background-color: ${colors.primary};
  }
  &:checked ~ .checkmark:after {
    opacity: 1;
  }
`;

export const Mark = styled.span`
  background-color: ${colors.transparent};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 1.35rem;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 1.35rem;
  &:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid ${colors.white};
    border-width: 0 1px 1px 0;
    content: '';
    height: 0.7rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(45deg) translateX(-60%);
    width: 0.25rem;
  }
`;
