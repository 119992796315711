import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetRankingProductResponse from 'services/httpClient/responses/dashboard/IGetRankingProductResponse';

import { dashboardActions } from '../index';

function* rankingProduct() {
  try {
    const {
      data: { list },
    }: AxiosResponse<IGetRankingProductResponse> = yield call(
      httpClient.get,
      `admin/dashboard/products-ranking`,
    );

    yield put(
      dashboardActions.rankingProductSuccess({
        data: { list },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(dashboardActions.rankingProductFailure());
  }
}

export default rankingProduct;
