import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IPriceListInitialData } from '../../index';

export interface IGetAllSuccessAction {
  data: {
    list: {
      dateTimeEnd: string;
      dateTimeStart: string;
      id: number;
      name: string;
      sku: string;
      type: string;
    }[];
    totalPages: number;
  };
}

function getAllSuccess(
  draft: Draft<IPriceListInitialData>,
  action: PayloadAction<IGetAllSuccessAction>,
) {
  draft.getAll.config.isLoading = false;
  draft.getAll.config.totalPages = action.payload.data.totalPages;
  draft.getAll.data = action.payload.data.list.map(item => ({
    ...item,
    dateTimeEnd: formatters.date(item.dateTimeEnd, {
      mask: 'dd/MM/yyyy HH:mm',
    }),
    dateTimeStart: formatters.date(item.dateTimeStart, {
      mask: 'dd/MM/yyyy HH:mm',
    }),
    type: formatters.priceListType(item.type),
  }));
}

export default getAllSuccess;
