import { Draft } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

function paymentMethodsFailure(draft: Draft<ICustomerInitialData>) {
  draft.paymentMethods.config.isLoading = false;
  draft.paymentMethods.config.isError = true;
}

export default paymentMethodsFailure;
