import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputNumber from 'components/input/Number';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';

import { FormikForm } from './styles';

const statusOptions: IComponentSelectProps<string>[] = [
  {
    label: 'Ativo',
    value: '1',
  },
  {
    label: 'Inativo',
    value: '0',
  },
];

export interface IManufacturerFilterFormData {
  id: string;
  name: string;
  sku: string;
  status: IComponentSelectProps<string> | null;
}

interface IManufacturerFilterProps {
  isLoading: boolean;
  onClear: () => void;
  onSubmit: (data: IManufacturerFilterFormData) => void;
}

const ManufacturerFilter: React.ForwardRefRenderFunction<
  FormikProps<IManufacturerFilterFormData>,
  IManufacturerFilterProps
> = ({ isLoading, onClear, onSubmit }, ref) => {
  const filterFormRef = useRef<FormikProps<IManufacturerFilterFormData>>(
    {} as FormikProps<IManufacturerFilterFormData>,
  );

  const handleSelectChange = useCallback((option: IOptionTypeBase | null) => {
    filterFormRef.current?.setFieldValue('status', option);
  }, []);

  useImperativeHandle(ref, () => ({
    ...filterFormRef.current,
  }));

  return (
    <Formik
      initialValues={{
        id: '',
        name: '',
        sku: '',
        status: null,
      }}
      innerRef={filterFormRef}
      onSubmit={onSubmit}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <ComponentInputNumber
            backgroundColor={colors.white}
            label="ID"
            name="id"
            onChange={handleChange('id')}
            placeholder="Pesquisar por ID"
            value={values.id}
          />
          <ComponentInputSimple
            backgroundColor={colors.white}
            label="Nome"
            name="name"
            onChange={handleChange('name')}
            placeholder="Pesquisar por nome"
            value={values.name}
          />
          <ComponentInputSimple
            backgroundColor={colors.white}
            label="SKU"
            name="sku"
            onChange={handleChange('sku')}
            placeholder="Pesquisar por SKU"
            value={values.sku}
          />
          <ComponentSelectSimple
            backgroundColor={colors.white}
            isClearable
            label="Status"
            name="status"
            onChange={handleSelectChange}
            options={statusOptions}
            placeholder="Selecione um status"
            value={values.status}
          />
          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={isLoading}
            onClick={onClear}
            type="button"
          >
            <FiX size={18} />
          </ComponentButtonBase>
          <ComponentButtonBase disabled={isLoading} type="submit">
            <FiSearch size={18} />
          </ComponentButtonBase>
        </FormikForm>
      )}
    </Formik>
  );
};

export default forwardRef(ManufacturerFilter);
