import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthInitialData } from '../../index';

export interface ISignInSuccessAction {
  token: string;
}

function signInSuccess(
  draft: Draft<IAuthInitialData>,
  action: PayloadAction<ISignInSuccessAction>,
) {
  draft.signIn.config.isLoading = false;
  draft.authorization.token = action.payload.token;
}

export default signInSuccess;
