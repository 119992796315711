import addressFailure from './address/failure';
import addressRequest from './address/request';
import addressSuccess from './address/success';
import columnsFailure from './columns/failure';
import columnsRequest from './columns/request';
import columnsSuccess from './columns/success';
import companyFailure from './company/failure';
import companyRequest from './company/request';
import companySuccess from './company/success';
import contactsFailure from './contacts/failure';
import contactsRequest from './contacts/request';
import contactsSuccess from './contacts/success';
import externalScriptFailure from './externalScript/failure';
import externalScriptRequest from './externalScript/request';
import externalScriptSuccess from './externalScript/success';
import faviconFailure from './favicon/failure';
import faviconRequest from './favicon/request';
import faviconSuccess from './favicon/success';
import filtersFailure from './filters/failure';
import filtersRequest from './filters/request';
import filtersSuccess from './filters/success';
import homeControlFailure from './homeControl/failure';
import homeControlRequest from './homeControl/request';
import homeControlSuccess from './homeControl/success';
import internalControlFailure from './internalControl/failure';
import internalControlRequest from './internalControl/request';
import internalControlSuccess from './internalControl/success';
import logoFailure from './logo/failure';
import logoRequest from './logo/request';
import logoSuccess from './logo/success';
import maintenanceFailure from './maintenance/failure';
import maintenanceRequest from './maintenance/request';
import maintenanceSuccess from './maintenance/success';
import meuCatalogoFailure from './meuCatalogo/failure';
import meuCatalogoRequest from './meuCatalogo/request';
import meuCatalogoSuccess from './meuCatalogo/success';
import pedcoinsFailure from './pedcoins/failure';
import pedcoinsRequest from './pedcoins/request';
import pedcoinsSuccess from './pedcoins/success';
import productsViewModeFailure from './productModeView/failure';
import productsViewModeRequest from './productModeView/request';
import productsViewModeSuccess from './productModeView/success';
import purchasesFailure from './purchases/failure';
import purchasesRequest from './purchases/request';
import purchasesSuccess from './purchases/success';
import setData from './set/data';
import shippingFailure from './shipping/failure';
import shippingRequest from './shipping/request';
import shippingSuccess from './shipping/success';
import socialMediaFailure from './socialMedia/failure';
import socialMediaRequest from './socialMedia/request';
import socialMediaSuccess from './socialMedia/success';
import termsFailure from './terms/failure';
import termsRequest from './terms/request';
import termsSuccess from './terms/success';
import termsControlFailure from './termsControl/failure';
import termsControlRequest from './termsControl/request';
import termsControlSuccess from './termsControl/success';
import themesFailure from './themes/failure';
import themesRequest from './themes/request';
import themesSuccess from './themes/success';

const settingReducers = {
  internalControlFailure,
  internalControlRequest,
  internalControlSuccess,
  addressFailure,
  addressRequest,
  addressSuccess,
  columnsFailure,
  columnsRequest,
  columnsSuccess,
  companyFailure,
  companyRequest,
  companySuccess,
  contactsFailure,
  contactsRequest,
  contactsSuccess,
  externalScriptFailure,
  externalScriptRequest,
  externalScriptSuccess,
  faviconFailure,
  faviconRequest,
  faviconSuccess,
  filtersFailure,
  filtersRequest,
  filtersSuccess,
  homeControlFailure,
  homeControlRequest,
  homeControlSuccess,
  logoFailure,
  logoRequest,
  logoSuccess,
  meuCatalogoFailure,
  meuCatalogoRequest,
  meuCatalogoSuccess,
  maintenanceFailure,
  maintenanceRequest,
  maintenanceSuccess,
  pedcoinsFailure,
  pedcoinsRequest,
  pedcoinsSuccess,
  purchasesFailure,
  purchasesRequest,
  purchasesSuccess,
  productsViewModeFailure,
  productsViewModeRequest,
  productsViewModeSuccess,
  shippingFailure,
  shippingRequest,
  shippingSuccess,
  socialMediaFailure,
  socialMediaRequest,
  socialMediaSuccess,
  termsFailure,
  termsRequest,
  termsSuccess,
  termsControlFailure,
  termsControlRequest,
  termsControlSuccess,
  themesFailure,
  themesRequest,
  themesSuccess,
  setData,
};

export default settingReducers;
