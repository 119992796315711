import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetAllFeedbackResponse from 'services/httpClient/responses/feedback/IGetAllFeedbackResponse';

import { IFeedbackInitialData } from '../../index';

function getAllSuccess(
  draft: Draft<IFeedbackInitialData>,
  action: PayloadAction<IGetAllFeedbackResponse>,
) {
  draft.getAll.data = action.payload.list.map(feedback => ({
    id: feedback.id,
    message: feedback.message,
    rating: feedback.rating,
    createdAt: feedback.createdAt,
    customer: {
      id: feedback.customer.id,
      name: feedback.customer.name,
    },
  }));
  draft.getAll.config.isLoading = false;
}

export default getAllSuccess;
