import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { Formik, FormikProps } from 'formik';
import { EListTypeView } from 'models/IList';
import listSelectors from 'store/slices/list/selectors';
import * as Yup from 'yup';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import ColumnOption from '../ColumnOption';
import {
  Actions,
  ActiveField,
  ColumnOptions,
  Fields,
  FormikForm,
  Label,
} from '../styles';

enum EColumnOption {
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export interface IListConfigFormData {
  active: boolean;
  quantityColumns: number;
  subtitle: string;
  title: string;
  typeView: {
    label: string;
    value: string;
  };
}

interface IListConfigFormProps {
  closeModal: () => void;
  hideActive?: boolean;
  initialValues?: IListConfigFormData;
  onSubmit: (data: IListConfigFormData) => void;
}

export const listConfigSchema = Yup.object().shape({
  title: Yup.string().required('Informe o título'),
  quantityColumns: Yup.string().required('Informe a quantidade de colunas'),
  typeView: Yup.object().shape({
    value: Yup.string().required('Informe a quantidade de colunas'),
  }),
});

const ListConfigForm: React.ForwardRefRenderFunction<
  FormikProps<IListConfigFormData>,
  IListConfigFormProps
> = ({ closeModal, hideActive = false, initialValues, onSubmit }, ref) => {
  const formRef = useRef<FormikProps<IListConfigFormData>>(
    {} as FormikProps<IListConfigFormData>,
  );

  const listUpdateLoading = useReduxSelector(listSelectors.updateIsLoading);
  const listCreateLoading = useReduxSelector(listSelectors.createIsLoading);

  const handleSelectChange = useCallback((option: IOptionTypeBase | null) => {
    formRef.current?.setFieldValue(
      'typeView',
      option ?? {
        value: EListTypeView.Both,
        label: 'Ambos',
      },
    );
  }, []);

  const handleOnChangeCheckbox = useCallback(() => {
    formRef.current?.setFieldValue('active', !formRef.current?.values.active);
  }, []);

  const chooseColumnOption = (columns: EColumnOption) => {
    formRef.current?.setFieldValue('quantityColumns', columns);
  };

  useImperativeHandle(ref, () => ({
    ...formRef.current,
  }));

  return (
    <Formik
      initialValues={{
        quantityColumns: initialValues?.quantityColumns ?? EColumnOption.TWO,
        subtitle: initialValues?.subtitle ?? '',
        title: initialValues?.title ?? '',
        typeView: initialValues?.typeView ?? {
          value: EListTypeView.Both,
          label: 'Ambos',
        },
        active: initialValues?.active ?? false,
      }}
      innerRef={formRef}
      onSubmit={onSubmit}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Fields>
            <ComponentInputSimple
              errorMessage={errors.title}
              hasError={!!errors.title}
              label="Título"
              name="title"
              onChange={handleChange('title')}
              value={values.title}
            />
            <ComponentInputSimple
              label="Subtítulo"
              name="subtitle"
              onChange={handleChange('subtitle')}
              value={values.subtitle}
            />
            <ComponentSelectSimple
              errorMessage={errors.typeView?.value}
              hasError={!!errors.typeView?.value}
              hideSelectedOptions
              label="Escolha a visibilidade para os clientes"
              name="typeView"
              onChange={handleSelectChange}
              options={[
                {
                  value: EListTypeView.Both,
                  label: 'Ambos',
                },
                {
                  value: EListTypeView.Authenticated,
                  label: 'Autenticado',
                },
                {
                  value: EListTypeView.NotAuthenticated,
                  label: 'Não autenticado',
                },
              ]}
              placeholder="Selecione um tipo"
              value={values.typeView}
            />
          </Fields>

          <ColumnOptions>
            <Label>Escolha a quantidade de colunas da listagem</Label>
            <ColumnOption
              onClick={() => chooseColumnOption(EColumnOption.TWO)}
              selected={values.quantityColumns === EColumnOption.TWO}
            />
            <ColumnOption
              columns={EColumnOption.THREE}
              name="Três"
              onClick={() => chooseColumnOption(EColumnOption.THREE)}
              selected={values.quantityColumns === EColumnOption.THREE}
            />
            <ColumnOption
              columns={EColumnOption.FOUR}
              name="Quatro"
              onClick={() => chooseColumnOption(EColumnOption.FOUR)}
              selected={values.quantityColumns === EColumnOption.FOUR}
            />
          </ColumnOptions>

          <ComponentIsVisible when={!hideActive}>
            <ActiveField>
              <ComponentInputCheckbox
                isChecked={values.active}
                label="Ativa"
                name="active"
                onChange={handleOnChangeCheckbox}
              />
            </ActiveField>
          </ComponentIsVisible>

          <Actions>
            <ComponentButtonBase
              backgroundColor={colors.red}
              disabled={listCreateLoading || listUpdateLoading}
              onClick={closeModal}
              type="button"
              width="10rem"
            >
              Cancelar
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={listCreateLoading || listUpdateLoading}
              isLoading={listCreateLoading || listUpdateLoading}
              type="submit"
              width="15rem"
            >
              Salvar configuração
            </ComponentButtonBase>
          </Actions>
        </FormikForm>
      )}
    </Formik>
  );
};

export default forwardRef(ListConfigForm);
