import cnpj from './cnpj';
import cpf from './cpf';
import cpfOrCnpj from './cpfOrCnpj';
import date from './date';
import dragType from './dragType';
import money from './money';
import onlyNumbers from './onlyNumbers';
import percent from './percent';
import phone from './phone';
import postalCode from './postalCode';
import priceListType from './priceListType';
import removeMask from './removeMask';

export default {
  cnpj,
  cpf,
  cpfOrCnpj,
  date,
  money,
  onlyNumbers,
  phone,
  postalCode,
  removeMask,
  dragType,
  priceListType,
  percent,
};
