import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';
import { listActions } from 'store/slices/list';

import httpClient from 'services/httpClient';
import IGetKitResponse from 'services/httpClient/responses/kit/IGetKitResponse';

import { IListRequestAction } from '../reducers/list/request';

import { kitActions } from '..';

function* list(action: PayloadAction<IListRequestAction>) {
  try {
    const {
      data: { list },
      status,
    }: AxiosResponse<IGetKitResponse> = yield call(
      httpClient.get,
      'admin/kits',
    );
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(kitActions.listSuccess({ data: { list: [] } }));
      yield put(
        listActions.setLinkOptions({
          data: {
            option: [],
          },
        }),
      );
      return;
    }
    yield put(kitActions.listSuccess({ data: { list } }));
    const listFormatted = list.map(item => {
      return {
        id: String(item.id),
        name: item.name,
      };
    });
    yield put(
      listActions.setLinkOptions({
        data: {
          option: listFormatted,
        },
      }),
    );

    if (action.payload.data.linkId) {
      yield put(
        listActions.setLinkOptionId({
          data: {
            id: action.payload.data.linkId,
          },
        }),
      );
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(kitActions.listFailure());
  }
}

export default list;
