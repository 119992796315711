import { Draft, PayloadAction } from '@reduxjs/toolkit';
import ECustomerSituation from 'enums/customersSituation';

import { ICustomerInitialData } from '../../index';

export interface IGetAllRequestAction {
  data: {
    document?: string;
    name?: string;
    page?: number;
    situation?: ECustomerSituation;
    withPagination: boolean;
  };
}

function getAllRequest(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IGetAllRequestAction>,
) {
  draft.getAll.data = [];
  draft.getAll.config.isLoading = true;
  draft.getAll.config.isError = false;
  draft.getAll.config.page = action.payload.data.page ?? 1;
}

export default getAllRequest;
