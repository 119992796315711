import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface ISetCustomersConnectedAction {
  data: {
    customerIds: string[];
  };
}

function setCustomersConnected(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<ISetCustomersConnectedAction>,
) {
  draft.getAll.customersConnected = action.payload.data.customerIds.map(
    customerId => Number(customerId),
  );

  draft.getAll.data = draft.getAll.data.map(customer => {
    const customerId = draft.getAll.customersConnected.find(
      customerId => customerId === customer.id,
    );

    if (customerId) {
      return {
        ...customer,
        isConnected: true,
      };
    }
    return {
      ...customer,
      isConnected: false,
    };
  });
}

export default setCustomersConnected;
