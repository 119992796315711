import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface ISocialMediaSuccessAction {
  facebook: string | null;
  instagram: string | null;
  linkedin: string | null;
  whatsapp: string | null;
}

function socialMediaSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<ISocialMediaSuccessAction>,
) {
  draft.socialMedia.config.isLoading = false;
  draft.data.socialMedia = {
    facebook: action.payload.facebook ?? '',
    instagram: action.payload.instagram ?? '',
    linkedin: action.payload.linkedin ?? '',
    whatsapp: action.payload.whatsapp ?? '',
  };
}

export default socialMediaSuccess;
