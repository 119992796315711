import styled, { css } from 'styled-components';

import { Form } from 'formik';

import colors from 'styles/colors';

export const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1.5rem;
`;

export const FormikForm = styled(Form)``;

export const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1.5rem;
`;

export const CardMaintenance = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.yellow};
  border-radius: 0.5rem;
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 8rem;
  justify-content: space-between;
  margin: 1.5rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  width: 100%;
`;

export const CardTitleMaintenance = styled.h4`
  align-items: center;
  color: ${colors.yellow};
  column-gap: 1rem;
  display: flex;
  font-size: 1.05rem;
  font-weight: 500;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h4`
  align-items: center;
  color: ${colors.primary};
  column-gap: 1rem;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

export const CardSubtitle = styled.p`
  color: ${colors.subtitle};
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

interface IOptionsProps {
  gridTemplateColumns?: string;
}
export const Options = styled.div<IOptionsProps>`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
    ${({ gridTemplateColumns }) =>
      gridTemplateColumns &&
      css`
        grid-template-columns: ${gridTemplateColumns};
      `}
  }
`;

export const Option = styled.div`
  column-gap: 1rem;
  display: flex;
  width: 100%;
`;

export const FormActions = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 1rem;
  width: 8rem;
`;
