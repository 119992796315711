import styled, { css } from 'styled-components';

import { Cropper } from 'react-cropper';

import { Form } from 'formik';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Content = styled.div`
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: ${animations.appear};
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${colors.white};
  border-radius: 0.5rem;
  max-height: 95%;
  max-width: 50rem;
  overflow-x: hide;
  overflow-y: scroll;
  padding: 1.75rem 2rem;
  width: 100%;
  z-index: 12;
  @media (min-width: 900px) {
    ::-webkit-scrollbar {
      background: ${colors.background};
      height: 0.5rem;
      width: 0.4rem;
      border-radius: 0.25rem;
    }

    ::-webkit-scrollbar-button {
      height: 0;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background: ${colors.background};
      height: 0.5rem;
      border-radius: 0.25rem;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.placeholder};
      height: 0.5rem;
      width: 0.4rem;
      border-radius: 0.25rem;
    }
  }
`;

export const Title = styled.h5`
  color: ${colors.primary};
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

interface IThumbnailProps {
  emptyImage?: boolean;
}

export const Thumbnail = styled.div<IThumbnailProps>`
  align-items: center;
  display: flex;
  height: 25rem;
  justify-content: center;
  margin: 1rem auto;
  position: relative;
  width: 100%;

  ${props =>
    props.emptyImage &&
    css`
      display: grid;
      height: 5rem;
      margin: 3rem auto 1rem;
    `}
`;

interface IActuallyImageProps {
  isVisible?: boolean;
}

export const ActuallyImage = styled.img<IActuallyImageProps>`
  border-radius: 0.5rem;
  height: 25rem;
  width: 25rem;

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};
`;

interface IActuallyImageLoadingProps {
  isVisible?: boolean;
}

export const ActuallyImageLoading = styled.div<IActuallyImageLoadingProps>`
  animation: ${animations.shimmer} 2s infinite linear;
  background: linear-gradient(to right, #f1f1f1 4%, #fafafa 25%, #f1f1f1 36%);
  background-size: 1000px 100%;
  border-radius: 0.5rem;
  display: none;
  height: 25rem;
  width: 25rem;

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};
`;

export const ContentPreview = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  position: relative;
  width: 100%;
`;

export const PreviewImage = styled(Cropper)`
  display: block;
  height: 100%;
  max-height: 25rem;
  max-width: 25rem;
  width: 100%;
`;

interface IActionsProps {
  hasPreview: boolean;
}

export const Actions = styled.div<IActionsProps>`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 12rem;
  justify-content: center;
  margin: 0.5rem auto 0;
  max-width: 25rem;

  ${props =>
    props.hasPreview &&
    css`
      grid-template-columns: 0.7fr 1fr;
    `};
`;

interface IActionImageButtonProps {
  crop?: boolean;
  remove?: boolean;
}

export const ActionImageButton = styled.button<IActionImageButtonProps>`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  color: ${props => (props.remove ? colors.red : colors.primary)};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin: 0 auto;
  row-gap: 0.5rem;
  text-decoration: underline;
  width: fit-content;

  &:hover {
    opacity: 0.75;
  }
  ${props =>
    props.crop &&
    css`
      color: ${colors.orange};
    `}
`;

export const FileInput = styled.input`
  display: none;
`;

export const EmptyImageMessage = styled.p`
  color: ${colors.red};
  display: flex;
  font-size: 0.875rem;
`;

export const FormikForm = styled(Form)`
  margin-top: 2rem;
`;

export const FieldGrid = styled.div`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 10.5rem 1fr 0.75fr;
`;

export const FormActions = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 10rem 12rem;
  justify-content: flex-end;
  margin-top: 1rem;
`;
