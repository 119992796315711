import { IPriceTablesData } from 'store/slices/customer';

import Tables from 'styles/tables';

import { PriceTableBody } from '../styles';

interface IProps {
  paymentMethod: IPriceTablesData;
  position: number;
}

const ItemBody = ({ paymentMethod, position }: IProps) => {
  return (
    <PriceTableBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{paymentMethod.id}</Tables.ItemBody>
      <Tables.ItemBody center>{paymentMethod.sku}</Tables.ItemBody>
      <Tables.ItemBody>{paymentMethod.type}</Tables.ItemBody>
      <Tables.ItemBody>{paymentMethod.name}</Tables.ItemBody>
    </PriceTableBody>
  );
};

export default ItemBody;
