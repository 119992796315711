import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPostUpdatePasswordRequest from 'services/httpClient/request/auth/IPostUpdatePasswordRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IUpdatePasswordRequestAction } from '../reducers/updatePassword/request';

import { authActions } from '..';

function* updatePassword(action: PayloadAction<IUpdatePasswordRequestAction>) {
  try {
    const body: IPostUpdatePasswordRequest = {
      current: action.payload.data.current,
      newPassword: action.payload.data.newPassword,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'admin/sessions/update-password',
      body,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });
    action.payload.functions.close();
    yield put(authActions.updatePasswordSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(authActions.updatePasswordFailure());
  }
}

export default updatePassword;
