import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import SunEditor from 'suneditor-react';

import { useHotkeys } from 'react-hotkeys-hook';

import 'suneditor/dist/css/suneditor.min.css';

import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import colors from 'styles/colors';

import { Actions, Content, EditorContainer, Title } from './styles';

export interface IUpdatePasswordRefProps {
  open: () => void;
}

const UpdateTerms: React.ForwardRefRenderFunction<IUpdatePasswordRefProps> = (
  _,
  ref,
) => {
  const reduxDispatch = useReduxDispatch();

  const [currentTerms, setCurrentTerms] = useState<string>('');

  const isLoading = useReduxSelector(settingSelectors.termsIsLoading);
  const others = useReduxSelector(settingSelectors.others);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const openModal = useCallback(() => {
    componentModalBaseRef.current?.open();
  }, []);

  const handleCloseModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleTerms = useCallback((text: string) => {
    setCurrentTerms(text);
  }, []);

  const handleUpdateTerms = useCallback(() => {
    reduxDispatch(
      settingActions.termsRequest({
        data: {
          terms: currentTerms,
        },
        functions: {
          closeModal: handleCloseModal,
        },
      }),
    );
  }, [currentTerms, handleCloseModal, reduxDispatch]);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  useHotkeys('esc', () => handleCloseModal());

  useEffect(() => {
    setCurrentTerms(others.terms ?? '');
  }, [others?.terms]);

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Editar termos de uso</Title>

        <EditorContainer>
          <SunEditor
            lang="pt_br"
            onChange={handleTerms}
            setOptions={{
              value: currentTerms,
              mode: 'classic',
              maxHeight: '25rem',
              minHeight: '25rem',
              defaultStyle: 'font-family: Archivo, sans-serif; font-size: 14px',
              fontSize: [
                8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72,
              ],
              formats: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
              buttonList: [
                ['fontSize', 'formatBlock'],
                [
                  'bold',
                  'underline',
                  'italic',
                  'strike',
                  'removeFormat',
                  'undo',
                  'redo',
                ],
                '/', // Line break,
                [
                  'fontColor',
                  'hiliteColor',
                  'outdent',
                  'indent',
                  'horizontalRule',
                  'list',
                  'table',
                  'link',
                ],
                ['align', 'list', 'fullScreen', 'codeView'],
              ],
            }}
          />
        </EditorContainer>
        <Actions>
          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={isLoading}
            onClick={handleCloseModal}
            type="button"
          >
            Cancelar
          </ComponentButtonBase>

          <ComponentButtonBase
            backgroundColor={colors.orange}
            isLoading={isLoading}
            onClick={handleUpdateTerms}
            type="button"
          >
            Confirmar
          </ComponentButtonBase>
        </Actions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(UpdateTerms);
