import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IRemoveAdminRequestAction } from '../reducers/remove/request';

import { adminActions } from '..';

function* removeAdmin(action: PayloadAction<IRemoveAdminRequestAction>) {
  try {
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.delete,
      `admin/${action.payload.data.id}`,
    );

    if (response.status === EHttpStatusCodes.OK) {
      ToastManager.show({
        title: response.data.message,
        type: 'success',
      });
      action.payload.functions.success();
      yield put(
        adminActions.removeSuccess({
          id: action.payload.data.id,
        }),
      );
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(adminActions.removeFailure());
  }
}

export default removeAdmin;
