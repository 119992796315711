import concat from './concat';
import remove from './remove';
import setData from './set/data';
import updateFailure from './update/failure';
import updateRequest from './update/request';
import updateSuccess from './update/success';

const bannerReducers = {
  concat,
  remove,
  updateFailure,
  updateRequest,
  updateSuccess,
  setData,
};

export default bannerReducers;
