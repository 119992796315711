import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerAccessInitialData } from '../..';

export interface IPdfCustomerAccessRequestAction {
  data: {
    date?: string;
    page: number;
  };
}

function pdfCustomerAccessRequest(
  draft: Draft<ICustomerAccessInitialData>,
  _: PayloadAction<IPdfCustomerAccessRequestAction>,
) {
  draft.pdf.config.isLoading = true;
}

export default pdfCustomerAccessRequest;
