import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Subtitle = styled.h4`
  color: ${colors.primary};
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 1rem;
`;

export const MainContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  display: block;
  padding: 1.5rem;
`;
