import Tables from 'styles/tables';

import { PaymentMethodHeader } from '../styles';

const ItemHeader = () => {
  return (
    <PaymentMethodHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader center>SKU</Tables.ItemHeader>
      <Tables.ItemHeader>STATUS</Tables.ItemHeader>
      <Tables.ItemHeader>NOME</Tables.ItemHeader>
    </PaymentMethodHeader>
  );
};

export default ItemHeader;
