import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsPedcoinsRequest from 'services/httpClient/request/settings/IPutSettingsPedcoinsRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IPedcoinsRequestAction } from '../reducers/pedcoins/request';

import { settingActions } from '..';

function* pedcoins(action: PayloadAction<IPedcoinsRequestAction>) {
  try {
    const expirationTermInMonthsFormatted = Number(
      action.payload.data.expirationTermInMonths,
    );
    const pointsPerRealFormatted = Number(
      action.payload.data.pointsPerReal.replace(',', '.'),
    );
    const body: IPutSettingsPedcoinsRequest = {
      expirationTermInMonths: expirationTermInMonthsFormatted,
      name: action.payload.data.name,
      pointsPerReal: pointsPerRealFormatted,
      isEnable: action.payload.data.isEnable,
    };
    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/pedcoins',
      body,
    );
    ToastManager.show({
      title: message,
      type: 'success',
    });
    yield put(
      settingActions.pedcoinsSuccess({
        expirationTermInMonths: expirationTermInMonthsFormatted,
        name: action.payload.data.name,
        pointsPerReal: pointsPerRealFormatted,
        isEnable: action.payload.data.isEnable,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.pedcoinsFailure());
  }
}

export default pedcoins;
