import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface ISetChangeDropdownAction {
  data: {
    id: number;
  };
}

function setChangeDropdown(
  draft: Draft<IListInitialData>,
  action: PayloadAction<ISetChangeDropdownAction>,
) {
  draft.data = draft.data.map(list => {
    if (list.id === action.payload.data.id) {
      return {
        isCollapsed: !list.isCollapsed,
        active: list.active,
        id: list.id,
        items: list.items,
        position: list.position,
        quantityColumns: list.quantityColumns,
        subtitle: list.subtitle,
        title: list.title,
        typeView: list.typeView,
      };
    }
    return {
      isCollapsed: list.isCollapsed,
      active: list.active,
      id: list.id,
      items: list.items,
      position: list.position,
      quantityColumns: list.quantityColumns,
      subtitle: list.subtitle,
      title: list.title,
      typeView: list.typeView,
    };
  });
}

export default setChangeDropdown;
