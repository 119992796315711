import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { FormikProps } from 'formik';
import { listActions } from 'store/slices/list';

import { useReduxDispatch } from 'hooks/useReduxDispatch';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import ListConfigForm, { IListConfigFormData } from '../Form';
import { Content, Title } from '../styles';

export interface IListConfigCreateRefProps {
  open: () => void;
}

const ListConfigCreate: React.ForwardRefRenderFunction<
  IListConfigCreateRefProps
> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const listConfigFormRef = useRef<FormikProps<IListConfigFormData>>(null);

  const openModal = useCallback(() => {
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleCreateList = useCallback(
    (data: IListConfigFormData) => {
      reduxDispatch(
        listActions.createRequest({
          data: {
            quantityColumns: data.quantityColumns,
            subtitle: data.subtitle,
            title: data.title,
            typeView: data.typeView,
          },
          functions: {
            success() {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Cadastrar nova listagem</Title>

        <ListConfigForm
          closeModal={closeModal}
          hideActive
          onSubmit={handleCreateList}
          ref={listConfigFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ListConfigCreate);
