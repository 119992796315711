import styled from 'styled-components';

import { Form } from 'formik';

export const FormikForm = styled(Form)`
  align-items: flex-end;
  column-gap: 0.25rem;
  display: grid;
  grid-template-columns: 10rem repeat(3, 15rem) repeat(2, 2.25rem);
  margin-top: 1rem;
  max-width: 70%;
`;
