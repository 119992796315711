import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPostForgotPasswordRequest from 'services/httpClient/request/auth/IPostForgotPasswordRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IForgotPasswordRequestAction } from '../reducers/forgotPassword/request';

import { authActions } from '..';

function* forgotPassword(action: PayloadAction<IForgotPasswordRequestAction>) {
  try {
    const body: IPostForgotPasswordRequest = {
      email: action.payload.data.email,
    };
    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.post,
      'admin/sessions/forgot-password',
      body,
    );
    ToastManager.show({
      title: message,
      type: 'success',
    });
    action.payload.functions.goBack();
    yield put(authActions.forgotPasswordSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(authActions.forgotPasswordFailure());
  }
}

export default forgotPassword;
