import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { ReactCropperElement } from 'react-cropper';
import { IoCamera } from 'react-icons/io5';
import { BarLoader } from 'react-spinners';

import validators from 'validators';

import useToast from 'hooks/useToast';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  ActionImageButton,
  Actions,
  ActuallyImage,
  ActuallyImageLoading,
  Container,
  ContentPreview,
  EmptyImageMessage,
  FileInput,
  PreviewImage,
  Thumbnail,
} from './styles';

interface IItemImageCropperProps {
  imageUrl?: string;
  isLoading?: boolean;
}

export interface IItemImageCropperRefProps {
  hasPreview: boolean;
  imageSrc: string;
  isNewImage: boolean;
  removeImage: () => void;
  removePreview: () => void;
  setEmptyImage: (value: boolean) => void;
  setIsLoadingImage: (value: boolean) => void;
}

const ItemImageCropper: React.ForwardRefRenderFunction<
  IItemImageCropperRefProps,
  IItemImageCropperProps
> = ({ imageUrl, isLoading }, ref) => {
  const { show: toastShow } = useToast();

  const [emptyImage, setEmptyImage] = useState<boolean>(false);
  const [hasPreview, setHasPreview] = useState<boolean>(false);
  const [isCropLoading, setIsCropLoading] = useState<boolean>(false);
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);
  const [isNewImage, setIsNewImage] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>(imageUrl ?? '');

  const fileInputRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const previewImageRef = useRef<ReactCropperElement>(
    {} as ReactCropperElement,
  );

  const handleSelectImage = (): void => {
    fileInputRef.current.click();
  };

  const handleOnChangeFileInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.item(0);
    if (!file) {
      return;
    }
    const fileVerified = validators.fileType(file?.type);
    if (!fileVerified) {
      toastShow({
        title: 'Formato de arquivo inválido',
        description: 'Formatos permitidos: png, jpg e jpeg',
        type: 'error',
      });
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      setImageSrc(event.target?.result as string);
      setEmptyImage(false);
      setIsNewImage(true);
      setHasPreview(true);
    };
  };

  const handleRemoveImage = (): void => {
    setImageSrc('');
    setHasPreview(false);
  };

  const handleCropImage = useCallback(async (): Promise<void> => {
    const { cropper } = previewImageRef.current;
    if (cropper) {
      setIsCropLoading(true);
      const imgFile = cropper.getCroppedCanvas().toDataURL();

      const newImage = new Image();

      newImage.src = imgFile;
      newImage.onload = () => {
        setHasPreview(false);
        setIsLoadingImage(false);
        setIsCropLoading(false);
      };

      setImageSrc(imgFile);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    hasPreview,
    imageSrc,
    isNewImage,
    removePreview() {
      setHasPreview(false);
    },
    removeImage() {
      setImageSrc('');
    },
    setEmptyImage(value: boolean) {
      setEmptyImage(value);
    },
    setIsLoadingImage(value: boolean) {
      setIsLoadingImage(value);
    },
  }));

  return (
    <Container>
      <ComponentIsVisible when={!hasPreview}>
        <Thumbnail emptyImage={!imageSrc}>
          <ComponentIsVisible when={!!imageSrc}>
            <ActuallyImage isVisible={!isLoadingImage} src={imageSrc} />
            <ActuallyImageLoading isVisible={isLoadingImage} />
          </ComponentIsVisible>
          <ComponentIsVisible when={!imageSrc}>
            <ActionImageButton onClick={handleSelectImage} type="button">
              <IoCamera color={colors.primary} size={24} />
              <FileInput
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleOnChangeFileInput}
                ref={fileInputRef}
                type="file"
              />
              Adicionar imagem
            </ActionImageButton>
            <ComponentIsVisible when={emptyImage}>
              <EmptyImageMessage>A imagem é obrigatória</EmptyImageMessage>
            </ComponentIsVisible>
          </ComponentIsVisible>
        </Thumbnail>
      </ComponentIsVisible>

      <ComponentIsVisible when={hasPreview}>
        <ContentPreview>
          <PreviewImage
            background={false}
            draggable={false}
            guides={false}
            height="25rem"
            initialAspectRatio={1 / 1}
            modal={false}
            ref={previewImageRef}
            src={imageSrc}
            toggleDragModeOnDblclick={false}
            viewMode={2}
            width="25rem"
          />
        </ContentPreview>
      </ComponentIsVisible>

      <Actions hasPreview={hasPreview}>
        <ComponentIsVisible when={!hasPreview}>
          <ComponentIsVisible when={!!imageSrc}>
            <ActionImageButton
              disabled={isLoading}
              onClick={handleRemoveImage}
              remove
              type="button"
            >
              Excluir imagem
            </ActionImageButton>
          </ComponentIsVisible>
        </ComponentIsVisible>

        <ComponentIsVisible when={hasPreview}>
          <ActionImageButton
            color={colors.white}
            onClick={handleRemoveImage}
            remove
            type="button"
          >
            Remover imagem
          </ActionImageButton>
          <ActionImageButton crop onClick={handleCropImage} type="button">
            <ComponentIsVisible when={isCropLoading}>
              <BarLoader color={colors.orange} height={4} width={80} />
            </ComponentIsVisible>
            <ComponentIsVisible when={!isCropLoading}>
              Cortar imagem
            </ComponentIsVisible>
          </ActionImageButton>
        </ComponentIsVisible>
      </Actions>
    </Container>
  );
};

export default forwardRef(ItemImageCropper);
