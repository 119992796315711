import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IUpdateAdminRequest from 'services/httpClient/request/admin/IUpdateAdminRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IUpdateAdminRequestAction } from '../reducers/update/request';

import { adminActions } from '..';

function* updateAdmin(action: PayloadAction<IUpdateAdminRequestAction>) {
  try {
    const body: IUpdateAdminRequest = {
      email: action.payload.data.email.trim(),
      name: action.payload.data.name.trim(),
      password:
        action.payload.data.password !== ''
          ? action.payload.data.password?.trim()
          : undefined,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      `admin/${action.payload.data.id}`,
      body,
    );
    if (response.status === EHttpStatusCodes.OK) {
      ToastManager.show({
        title: response.data.message,
        type: 'success',
      });
      action.payload.functions.success();
      yield put(
        adminActions.updateSuccess({
          email: action.payload.data.email,
          id: action.payload.data.id,
          name: action.payload.data.name,
        }),
      );
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(adminActions.updateFailure());
  }
}

export default updateAdmin;
