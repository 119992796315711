import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { ICustomerInitialData } from '../../index';

export interface IPedcoinsRedeemSuccessAction {
  data: {
    list: {
      date: string;
      invoice: string;
      orderId: number;
      pedcoins: number;
    }[];
    totalPages: number;
  };
}

function pedcoinsRedeemSuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IPedcoinsRedeemSuccessAction>,
) {
  const listParsed = action.payload.data.list.map(item => ({
    ...item,
    date: formatters.date(item.date),
  }));
  draft.pedcoins.redeem.data = listParsed;
  draft.pedcoins.redeem.config.totalPages = action.payload.data.totalPages;
  draft.pedcoins.redeem.data = listParsed;
  draft.pedcoins.redeem.config.isLoading = false;
}

export default pedcoinsRedeemSuccess;
