import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IPedcoinsSummarySuccessAction {
  data: {
    available: number;
    entries: number;
    expiresToday: number;
    outputs: number;
  };
}

function pedcoinsSummarySuccess(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IPedcoinsSummarySuccessAction>,
) {
  draft.pedcoins.summary.available = action.payload.data.available;
  draft.pedcoins.summary.entries = action.payload.data.entries;
  draft.pedcoins.summary.expiresToday = action.payload.data.expiresToday;
  draft.pedcoins.summary.outputs = action.payload.data.outputs;
  draft.pedcoins.summary.config.isLoading = false;
}

export default pedcoinsSummarySuccess;
