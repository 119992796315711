import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EUploadImageType from 'enums/uploadImageType';

import { IBannerInitialData } from '../index';

interface IRemoveRequestAction {
  data: {
    id: number | string;
    type: EUploadImageType;
  };
}

function remove(
  draft: Draft<IBannerInitialData>,
  action: PayloadAction<IRemoveRequestAction>,
) {
  if (action.payload.data.type === EUploadImageType.thumbnail) {
    const newThumbnailImages = draft.thumbnails.filter(
      thumbnailImage => thumbnailImage.id !== action.payload.data.id,
    );
    draft.thumbnails = newThumbnailImages;
  } else {
    const newBannerImages = draft.images.filter(
      bannerImage => bannerImage.id !== action.payload.data.id,
    );
    draft.images = newBannerImages;
  }
}

export default remove;
