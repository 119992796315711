import { useCallback, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  FaBuilding,
  FaClipboard,
  FaCogs,
  FaCube,
  FaCubes,
  FaUserTimes,
} from 'react-icons/fa';
import { GoSignOut } from 'react-icons/go';
import { IoBriefcase, IoCart, IoHome, IoPeople } from 'react-icons/io5';
import { MdDashboard, MdRateReview } from 'react-icons/md';

import { authActions } from 'store/slices/auth';
import settingSelectors from 'store/slices/setting/selectors';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import WsClient from 'services/webSocket';

import {
  Aside,
  Content,
  Item,
  Logo,
  LogoLoading,
  LogOut,
  Menu,
  Thumbnail,
} from './styles';

const Sidebar: React.FC = () => {
  const router = useLocation();
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();

  const designer = useReduxSelector(settingSelectors.designer);

  const [imageSrc, setImageSrc] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const pathName = router.pathname;

  const handleSignOut = useCallback(() => {
    reduxDispatch(authActions.signOutRequest());
    WsClient.stop();
    navigate(pages.auth.signIn, { replace: true });
  }, [navigate, reduxDispatch]);

  useEffect(() => {
    setIsLoading(true);
    const newImage = new Image();

    newImage.src = designer.logo;
    newImage.onload = () => {
      setIsLoading(false);
    };

    setImageSrc(newImage.src);
  }, [designer.logo]);

  return (
    <Aside>
      <Content>
        <Thumbnail to={pages.home}>
          <Logo isLoading={!isLoading} src={imageSrc} />
          <LogoLoading isLoading={isLoading} />
        </Thumbnail>

        <Menu>
          <Item
            selected={pathName.startsWith('/dashboards')}
            title="Dashboards"
            to={pages.dashboard}
          >
            <MdDashboard size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/inicio')}
            title="Início"
            to={pages.home}
          >
            <IoHome size={18} />
          </Item>
          <Item
            selected={
              pathName.startsWith('/clientes') &&
              !pathName.endsWith('/nao-positivados')
            }
            title="Clientes"
            to={pages.customer.list}
          >
            <IoPeople size={18} />
          </Item>
          <Item
            selected={pathName.endsWith('/nao-positivados')}
            title="Clientes não positivados"
            to={pages.customer.nonPositive}
          >
            <FaUserTimes size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/produtos')}
            title="Produtos"
            to={pages.product.list}
          >
            <FaCube size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/fabricantes')}
            title="Fabricantes"
            to={pages.manufacturer.list}
          >
            <FaCubes size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/tabelas-de-preco')}
            title="Tabelas de preço"
            to={pages.priceList.list}
          >
            <FaClipboard size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/pedidos')}
            title="Pedidos"
            to={pages.orders.list}
          >
            <IoCart size={24} />
          </Item>
          <Item
            selected={pathName.startsWith('/administradores')}
            title="Administradores"
            to={pages.admin.list}
          >
            <IoBriefcase size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/feedbacks')}
            title="Feedbacks"
            to={pages.feedback.list}
          >
            <MdRateReview size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/configuracoes')}
            title="Configurações"
            to={pages.settings}
          >
            <FaCogs size={18} />
          </Item>
          <Item
            selected={pathName.startsWith('/empresa')}
            title="Empresa"
            to={pages.company}
          >
            <FaBuilding size={18} />
          </Item>
        </Menu>

        <LogOut onClick={handleSignOut} title="Sair">
          <GoSignOut size={18} />
        </LogOut>
      </Content>
    </Aside>
  );
};

export default Sidebar;
