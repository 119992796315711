import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import IListItem from 'models/IListItem';
import { listActions } from 'store/slices/list';
import listSelectors from 'store/slices/list/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import colors from 'styles/colors';

import { Actions, Content, ItemImage, Message, Title } from './styles';

interface IItemRemoveData extends IListItem {
  listId: number;
}

export interface IItemRemoveRefProps {
  open: (data: IItemRemoveData) => void;
}

const ItemRemove: React.ForwardRefRenderFunction<IItemRemoveRefProps> = (
  _,
  ref,
) => {
  const reduxDispatch = useReduxDispatch();
  const [item, setItem] = useState<IItemRemoveData>({} as IItemRemoveData);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const removeItemLoading = useReduxSelector(listSelectors.removeItemIsLoading);

  const openModal = useCallback((data: IItemRemoveData) => {
    setItem(data);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleRemoveItem = useCallback(() => {
    reduxDispatch(
      listActions.removeItemRequest({
        data: {
          id: item.id,
          listId: item.listId,
        },
        functions: {
          success() {
            closeModal();
          },
        },
      }),
    );
  }, [closeModal, reduxDispatch, item.id, item.listId]);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Atenção</Title>

        <Message>Tem certeza que deseja remover este item?</Message>

        <ItemImage src={item.url} />

        <Actions>
          <ComponentButtonBase
            backgroundColor={colors.subtitle}
            disabled={removeItemLoading}
            onClick={closeModal}
          >
            Cancelar
          </ComponentButtonBase>

          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={removeItemLoading}
            isLoading={removeItemLoading}
            onClick={handleRemoveItem}
          >
            Confirmar
          </ComponentButtonBase>
        </Actions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ItemRemove);
