import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsShippingRequest from 'services/httpClient/request/settings/IPutSettingsShippingRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IShippingRequestAction } from '../reducers/shipping/request';

import { settingActions } from '..';

function* shipping(action: PayloadAction<IShippingRequestAction>) {
  try {
    const body: IPutSettingsShippingRequest = {
      timeLimitMessage: action.payload.data.timeLimitMessage,
    };
    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/shipping',
      body,
    );
    ToastManager.show({
      title: response.data.message,
      type: 'success',
    });
    yield put(
      settingActions.shippingSuccess({
        timeLimitMessage: action.payload.data.timeLimitMessage,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.shippingFailure());
  }
}

export default shipping;
