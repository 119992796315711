import { useState } from 'react';

import { FiInfo } from 'react-icons/fi';

import { EExtractItemType } from 'store/slices/customer';

import {
  CustomerPedcoinsItemBody,
  CustomerPedcoinsItemsBody,
  ReasonInfo,
  ReasonInfoMessage,
} from 'pages/Customers/Pedcoins/styles';

interface IExtractItem {
  date: string;
  description: string;
  id: number;
  quantity: string;
  reason: string;
  type: string;
}

interface IExtractItemBodyProps {
  extractItem: IExtractItem;
}

function ExtractItemBody({ extractItem }: IExtractItemBodyProps): JSX.Element {
  const [showReason, setShowReason] = useState<boolean>(false);

  const quantityCoins =
    extractItem.type === EExtractItemType.CANCELLED
      ? '--'
      : extractItem.quantity;

  const handleShowReason = (value: boolean) => {
    setShowReason(value);
  };

  return (
    <CustomerPedcoinsItemsBody>
      <CustomerPedcoinsItemBody
        cancelled={extractItem.type === EExtractItemType.CANCELLED}
        entry={extractItem.type === EExtractItemType.ENTRY}
        output={extractItem.type === EExtractItemType.OUTPUT}
      >
        {extractItem.type}
      </CustomerPedcoinsItemBody>
      <CustomerPedcoinsItemBody>
        {extractItem.description}
      </CustomerPedcoinsItemBody>
      <CustomerPedcoinsItemBody right>{quantityCoins}</CustomerPedcoinsItemBody>
      <CustomerPedcoinsItemBody />
      <CustomerPedcoinsItemBody>{extractItem.date}</CustomerPedcoinsItemBody>
      <CustomerPedcoinsItemBody center overflowVisible>
        <ReasonInfo
          onMouseEnter={() => handleShowReason(true)}
          onMouseLeave={() => handleShowReason(false)}
        >
          <FiInfo size={20} />
          <ReasonInfoMessage show={showReason}>
            {extractItem.reason}
          </ReasonInfoMessage>
        </ReasonInfo>
      </CustomerPedcoinsItemBody>
    </CustomerPedcoinsItemsBody>
  );
}

export default ExtractItemBody;
