import { useCallback, useRef } from 'react';

import { FaInfo } from 'react-icons/fa';
import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm, Options } from '../styles';
import { CheckTitleInformation } from './styles';

interface IColumnsFormData {
  displayColorsStock: boolean;
  showJustFinalValue: boolean;
  showProductsWithoutLimit: boolean;
}

const ColumnsControl: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const columnsConfigFormRef = useRef<FormikProps<IColumnsFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.columnsIsLoading);
  const columns = useReduxSelector(settingSelectors.columns);

  const handleColumnsConfig = useCallback(
    (data: IColumnsFormData) => {
      reduxDispatch(
        settingActions.columnsRequest({
          data: {
            showOnlyPriceTotal: data.showJustFinalValue,
            showProductsWithoutLimit: data.showProductsWithoutLimit,
            displayColorsStock: data.displayColorsStock,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChangeShowJustFinalValue = useCallback(() => {
    columnsConfigFormRef.current?.setFieldValue(
      'showJustFinalValue',
      !columnsConfigFormRef.current?.values.showJustFinalValue,
    );
    columnsConfigFormRef.current?.submitForm();
  }, []);

  const handleOnChangeProductsWithoutLimit = useCallback(() => {
    columnsConfigFormRef.current?.setFieldValue(
      'showProductsWithoutLimit',
      !columnsConfigFormRef.current?.values.showProductsWithoutLimit,
    );
    columnsConfigFormRef.current?.submitForm();
  }, []);

  const handleOnChangeDisplayColorsByStock = useCallback(() => {
    columnsConfigFormRef.current?.setFieldValue(
      'displayColorsStock',
      !columnsConfigFormRef.current?.values.displayColorsStock,
    );
    columnsConfigFormRef.current?.submitForm();
  }, []);

  return (
    <Formik
      initialValues={{
        showJustFinalValue: columns.onlyAmountFinal,
        showProductsWithoutLimit: columns.showProductsWithoutLimit,
        displayColorsStock: columns.displayColorsStock,
      }}
      innerRef={columnsConfigFormRef}
      onSubmit={handleColumnsConfig}
    >
      {({ values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Controle de exibição da listagem de produtos
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>
            <CardSubtitle>
              Defina a maneira que seus clientes enxergarão a listagem de
              produtos
            </CardSubtitle>

            <Options gridTemplateColumns="repeat(2, 1fr)">
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.showJustFinalValue}
                label='Exibir apenas "Valor final"'
                name="showJustFinalValue"
                onChange={handleOnChangeShowJustFinalValue}
              />
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: 8,
                }}
              >
                <ComponentInputCheckbox
                  disabled={isLoading}
                  isChecked={values.showProductsWithoutLimit}
                  label="Exibir sem limite de paginação"
                  name="showProductsWithoutLimit"
                  onChange={handleOnChangeProductsWithoutLimit}
                />
                <CheckTitleInformation>
                  <FaInfo size={10} />
                </CheckTitleInformation>
              </div>
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.displayColorsStock}
                label="Exibir cores de acordo com estoque do produtos"
                name="displayColorsStock"
                onChange={handleOnChangeDisplayColorsByStock}
              />
            </Options>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ColumnsControl;
