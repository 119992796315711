import { useRef } from 'react';

import { Formik, FormikProps } from 'formik';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';
import Form from 'styles/form';

import { Actions, FormikForm } from './styles';

export interface IAdminFormData {
  email: string;
  name: string;
  password: string;
}

interface IAdminFormProps {
  close: () => void;
  initialValues: {
    email: string;
    name: string;
    password: string;
  };
  isLoading?: boolean;
  onSubmit: (data: IAdminFormData) => void;
  validationSchema: any;
}

const AdminForm: React.FC<IAdminFormProps> = ({
  close,
  initialValues,
  isLoading = false,
  onSubmit,
  validationSchema,
}) => {
  const adminFormRef = useRef<FormikProps<IAdminFormData>>(null);

  return (
    <Formik
      initialValues={{
        email: initialValues.email,
        name: initialValues.name,
        password: initialValues.password,
      }}
      innerRef={adminFormRef}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Form.FieldGrid gridTemplateColumns="1fr">
            <ComponentInputSimple
              errorMessage={errors.name}
              hasError={!!errors.name}
              label="Nome"
              name="name"
              onChange={handleChange('name')}
              value={values.name}
            />
            <ComponentInputSimple
              errorMessage={errors.email}
              hasError={!!errors.email}
              label="E-mail"
              name="email"
              onChange={handleChange('email')}
              value={values.email}
            />
            <ComponentInputPassword
              errorMessage={errors.password}
              hasError={!!errors.password}
              label="Senha"
              name="password"
              onChange={handleChange('password')}
              value={values.password}
            />
          </Form.FieldGrid>

          <Actions>
            <ComponentButtonBase
              backgroundColor={colors.red}
              disabled={isLoading}
              onClick={close}
              type="button"
            >
              Cancelar
            </ComponentButtonBase>
            <ComponentButtonBase
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
            >
              Confirmar
            </ComponentButtonBase>
          </Actions>
        </FormikForm>
      )}
    </Formik>
  );
};

export default AdminForm;
