interface IPriceList {
  amountDiscount: string;
  amountFee: string;
  amountFinal: string;
  amountGross: string;
  amountNet: string;
  id: number;
  manufacturer: string;
  name: string;
  sku: string;
}

export enum EPriceListType {
  Base = 'BASE',
  Offer = 'OFERTA',
  SpecialPrice = 'PRECO_ESPECIAL',
}

export default IPriceList;
