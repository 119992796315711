import { Draft } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

function ordersFailure(draft: Draft<ICustomerInitialData>) {
  draft.orders.config.isLoading = false;
  draft.orders.config.isError = true;
}

export default ordersFailure;
