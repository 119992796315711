import { useCallback, useEffect, useState } from 'react';

import { ClipLoader } from 'react-spinners';

import { dashboardActions, EDashboardBilling } from 'store/slices/dashboard';
import dashboardSelectors from 'store/slices/dashboard/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  BodyBilling,
  Card,
  ContainerBody,
  ContainerLoading,
  Item,
  ItemsHeader,
  ItemText,
  Main,
} from './styles';

enum ETitleType {
  Daily = 'Diário',
  Monthly = 'Mensal',
  Semiannual = 'Semestral',
  Weekly = 'Semanal',
  Yearly = 'Anual',
}

const Billing = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();
  const [title, setTitle] = useState<ETitleType>(ETitleType.Daily);

  const daily = useReduxSelector(dashboardSelectors.billingDayValue);
  const weekly = useReduxSelector(dashboardSelectors.billingWeeklyValue);
  const monthly = useReduxSelector(dashboardSelectors.billingMonthlyValue);
  const semiannual = useReduxSelector(
    dashboardSelectors.billingSemiannualValue,
  );
  const yearly = useReduxSelector(dashboardSelectors.billingYearlyValue);
  const billingDayIsLoading = useReduxSelector(
    dashboardSelectors.billingDayIsLoading,
  );
  const billingWeeklyIsLoading = useReduxSelector(
    dashboardSelectors.billingWeeklyIsLoading,
  );
  const billingMonthlyIsLoading = useReduxSelector(
    dashboardSelectors.billingMonthlyIsLoading,
  );
  const billingSemiannualIsLoading = useReduxSelector(
    dashboardSelectors.billingSemiannualIsLoading,
  );
  const billingYearlyIsLoading = useReduxSelector(
    dashboardSelectors.billingYearlyIsLoading,
  );

  const handleBillingDaily = useCallback(() => {
    reduxDispatch(
      dashboardActions.billingRequest({
        data: {
          type: EDashboardBilling.Daily,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleBillingWeekly = useCallback(() => {
    reduxDispatch(
      dashboardActions.billingRequest({
        data: {
          type: EDashboardBilling.Weekly,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleBillingMonthly = useCallback(() => {
    reduxDispatch(
      dashboardActions.billingRequest({
        data: {
          type: EDashboardBilling.Monthly,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleBillingSemiannual = useCallback(() => {
    reduxDispatch(
      dashboardActions.billingRequest({
        data: {
          type: EDashboardBilling.Semiannual,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleBillingYearly = useCallback(() => {
    reduxDispatch(
      dashboardActions.billingRequest({
        data: {
          type: EDashboardBilling.Yearly,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleSetTitle = useCallback((title: ETitleType) => {
    setTitle(title);
  }, []);

  useEffect(() => {
    handleBillingDaily();
    handleBillingWeekly();
    handleBillingMonthly();
    handleBillingSemiannual();
    handleBillingYearly();
  }, [
    handleBillingDaily,
    handleBillingMonthly,
    handleBillingSemiannual,
    handleBillingWeekly,
    handleBillingYearly,
  ]);

  useEffect(() => {
    const id = setInterval(() => {
      if (title === ETitleType.Daily) {
        setTitle(ETitleType.Weekly);
      }
      if (title === ETitleType.Weekly) {
        setTitle(ETitleType.Monthly);
      }
      if (title === ETitleType.Monthly) {
        setTitle(ETitleType.Semiannual);
      }
      if (title === ETitleType.Semiannual) {
        setTitle(ETitleType.Yearly);
      }
      if (title === ETitleType.Yearly) {
        setTitle(ETitleType.Daily);
      }
    }, 10000);
    return () => {
      clearInterval(id);
    };
  }, [title]);

  return (
    <Card>
      <Main>
        <ItemsHeader>
          <Item
            isFocused={title === ETitleType.Daily}
            onClick={() => {
              handleSetTitle(ETitleType.Daily);
            }}
          >
            <ItemText isFocused={title === ETitleType.Daily}>Diário</ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Weekly}
            onClick={() => {
              handleSetTitle(ETitleType.Weekly);
            }}
          >
            <ItemText isFocused={title === ETitleType.Weekly}>Semanal</ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Monthly}
            onClick={() => {
              handleSetTitle(ETitleType.Monthly);
            }}
          >
            <ItemText isFocused={title === ETitleType.Monthly}>Mensal</ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Semiannual}
            onClick={() => {
              handleSetTitle(ETitleType.Semiannual);
            }}
          >
            <ItemText isFocused={title === ETitleType.Semiannual}>
              Semestral
            </ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Yearly}
            onClick={() => {
              handleSetTitle(ETitleType.Yearly);
            }}
          >
            <ItemText isFocused={title === ETitleType.Yearly}>Anual</ItemText>
          </Item>
        </ItemsHeader>

        <ContainerBody>
          <ComponentIsVisible when={title === ETitleType.Daily}>
            <ComponentIsVisible when={!billingDayIsLoading}>
              <BodyBilling>{title}</BodyBilling>
              <BodyBilling>{daily}</BodyBilling>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingDayIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Weekly}>
            <ComponentIsVisible when={!billingWeeklyIsLoading}>
              <BodyBilling>{title}</BodyBilling>
              <BodyBilling>{weekly}</BodyBilling>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingWeeklyIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Monthly}>
            <ComponentIsVisible when={!billingMonthlyIsLoading}>
              <BodyBilling>{title}</BodyBilling>
              <BodyBilling>{monthly}</BodyBilling>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingMonthlyIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Semiannual}>
            <ComponentIsVisible when={!billingSemiannualIsLoading}>
              <BodyBilling>{title}</BodyBilling>
              <BodyBilling>{semiannual}</BodyBilling>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingSemiannualIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Yearly}>
            <ComponentIsVisible when={!billingYearlyIsLoading}>
              <BodyBilling>{title}</BodyBilling>
              <BodyBilling>{yearly}</BodyBilling>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingYearlyIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>
        </ContainerBody>
      </Main>
    </Card>
  );
};

export default Billing;
