export enum EProductType {
  Kit = 'KIT',
  Product = 'PRODUTO',
}

interface IProduct {
  barcode: string;
  hasStock: boolean;
  id: number;
  name: string;
  sku: string;
  type: EProductType;
}

export enum EProductDrugType {
  Ethical = 'ETICO',
  Generic = 'GENERICO',
  NoDrug = 'NAO_MEDICAMENTO',
  Others = 'OUTROS',
  Similar = 'SIMILAR',
}

export default IProduct;
