import { EOrderStatus } from 'models/IOrder';

function formatterOrderStatus(status: EOrderStatus): string {
  switch (status) {
    case EOrderStatus.Cancellation:
      return 'Pedido cancelado';
    case EOrderStatus.Billing:
      return 'Pedido faturado';
    case EOrderStatus.Processing:
      return 'Pedido processando';
    case EOrderStatus.Closing:
      return 'Pedido enviado';
    default:
      return 'Pedido enviado';
  }
}

export default formatterOrderStatus;
