import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IUpdateItemPositionRequestAction {
  data: {
    ids: number[];
    listId: number;
  };
}

function updateItemPositionRequest(
  _: Draft<IListInitialData>,
  __: PayloadAction<IUpdateItemPositionRequestAction>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {}

export default updateItemPositionRequest;
