import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentTextarea from 'components/input/Textarea';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  Card,
  CardSubtitle,
  CardTitle,
  FormActions,
  FormikForm,
} from '../styles';

interface IScriptsFormData {
  externalScript: string;
}

const scriptsControlSchema = Yup.object().shape({
  timeLimitMessage: Yup.string(),
});

const ScriptsControl: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const scriptsControlFormRef = useRef<FormikProps<IScriptsFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.externalScriptIsLoading);
  const others = useReduxSelector(settingSelectors.others);

  const handleScriptControl = useCallback(
    (data: IScriptsFormData) => {
      reduxDispatch(
        settingActions.externalScriptRequest({
          data: {
            externalScript: data.externalScript,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        externalScript: others.externalScript,
      }}
      innerRef={scriptsControlFormRef}
      onSubmit={handleScriptControl}
      validationSchema={scriptsControlSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Inserção de script
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>
            <CardSubtitle>
              Insira abaixo o script que estará presente em seu site
            </CardSubtitle>

            <ComponentTextarea
              errorMessage={errors.externalScript}
              hasError={!!errors.externalScript}
              label="Script"
              name="externalScript"
              onChange={handleChange('externalScript')}
              value={values.externalScript}
            />

            <FormActions>
              <ComponentButtonBase
                disabled={isLoading}
                isLoading={isLoading}
                type="submit"
              >
                Salvar
              </ComponentButtonBase>
            </FormActions>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ScriptsControl;
