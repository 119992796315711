import styled from 'styled-components';

import Tables from 'styles/tables';

export const PriceListItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 4rem 5rem 1fr 8rem repeat(2, 5rem) repeat(5, 8rem);
  padding: 1.25rem 1rem;
`;

export const PriceListItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 4rem 5rem 1fr 8rem repeat(2, 5rem) repeat(5, 8rem);
  padding: 1rem;
`;
