import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { EListTypeView } from 'models/IList';
import IListItem from 'models/IListItem';

import { IListInitialData } from '../../index';

export interface ISetListAction {
  data: {
    list: {
      active: boolean;
      id: number;
      items: IListItem[];
      position: number;
      quantityColumns: number;
      subtitle: string;
      title: string;
      typeView: string;
    }[];
  };
}

function setList(
  draft: Draft<IListInitialData>,
  action: PayloadAction<ISetListAction>,
) {
  const list = action.payload.data.list.map(list => {
    let label = 'Ambos';
    switch (list.typeView) {
      case EListTypeView.Authenticated:
        label = 'Autenticado';
        break;
      case EListTypeView.NotAuthenticated:
        label = 'Não autenticado';
        break;
      default:
        label = 'Ambos';
        break;
    }
    return {
      active: list.active,
      id: list.id,
      items: list.items,
      position: list.position,
      quantityColumns: list.quantityColumns,
      subtitle: list.subtitle,
      title: list.title,
      isCollapsed: true,
      typeView: {
        value: list.typeView,
        label,
      },
    };
  });
  draft.data = list;
}

export default setList;
