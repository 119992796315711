import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { EOrderOrigin, EOrderStatus } from 'models/IOrder';

import { IOrderInitialData } from '../../index';

export interface IListOrderRequestAction {
  data: {
    dateEnd?: string;
    dateStart?: string;
    name?: string;
    origin?: EOrderOrigin;
    page: number;
    status?: EOrderStatus;
  };
}

function listOrder(
  draft: Draft<IOrderInitialData>,
  action: PayloadAction<IListOrderRequestAction>,
) {
  draft.list.config.isError = false;
  draft.list.config.isLoading = true;
  draft.list.config.pagination.page = action.payload.data.page;
  draft.list.data = [];
}

export default listOrder;
