import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IManufacturerDetailsResponse from 'services/httpClient/responses/manufacturer/IManufacturerDetailsResponse';

import { manufacturerActions } from '..';

import { IDetailsRequestAction } from '../reducers/details/request';

function* details(action: PayloadAction<IDetailsRequestAction>) {
  try {
    const { data }: AxiosResponse<IManufacturerDetailsResponse> = yield call(
      httpClient.get,
      `admin/manufacturers/${action.payload.data.id}`,
    );

    yield put(manufacturerActions.detailsSuccess({ data }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(manufacturerActions.detailsFailure());
  }
}

export default details;
