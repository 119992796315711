import React, { useCallback, useEffect } from 'react';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentPaginate from 'components/utils/Paginate';

import CustomerPedcoinsItemsLoading from '../Loading';
import { Content } from '../styles';
import PedcoinsRedeemItems from './Item';

interface ICustomerPedcoinsRedeemProps {
  customerId: number;
}

function CustomerPedcoinsRedeem({
  customerId,
}: ICustomerPedcoinsRedeemProps): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const redeemLoading = useReduxSelector(
    customerSelectors.pedcoinsRedeemIsLoading,
  );
  const redeemList = useReduxSelector(customerSelectors.pedcoinsRedeem);
  const page = useReduxSelector(customerSelectors.pedcoinsRedeemPage);
  const totalPages = useReduxSelector(
    customerSelectors.pedcoinsRedeemTotalPages,
  );
  const isError = useReduxSelector(customerSelectors.pedcoinsRedeemIsError);

  const loadPedcoinsRedeem = useCallback(() => {
    reduxDispatch(
      customerActions.pedcoinsRedeemRequest({
        data: {
          customerId,
          page: 1,
        },
      }),
    );
  }, [customerId, reduxDispatch]);

  const handlePagination = useCallback(
    (page: number) => {
      reduxDispatch(
        customerActions.pedcoinsRedeemRequest({
          data: {
            customerId,
            page,
          },
        }),
      );
    },
    [customerId, reduxDispatch],
  );

  useEffect(() => {
    loadPedcoinsRedeem();
  }, [loadPedcoinsRedeem]);
  return (
    <Content>
      <ComponentIsVisible when={!redeemLoading}>
        <ComponentIsVisible when={!!redeemList.length && !isError}>
          <PedcoinsRedeemItems.Header />

          {redeemList.map(redeemItem => (
            <PedcoinsRedeemItems.Body
              key={redeemItem.orderId}
              redeemItem={redeemItem}
            />
          ))}
        </ComponentIsVisible>

        <ComponentEmpty
          message="Não existem resgates"
          show={!redeemList.length && !isError}
        />

        <ComponentIsVisible when={isError}>
          <ComponentError
            message="Não foi possível carregar os resgates"
            onClick={loadPedcoinsRedeem}
          />
        </ComponentIsVisible>
      </ComponentIsVisible>
      <ComponentIsVisible when={redeemLoading}>
        <CustomerPedcoinsItemsLoading show={redeemLoading} />
      </ComponentIsVisible>

      <ComponentPaginate
        currentPage={page}
        onPage={handlePagination}
        show={!!redeemList.length}
        totalPages={totalPages}
      />
    </Content>
  );
}

export default CustomerPedcoinsRedeem;
