import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IList, { EListTypeView } from 'models/IList';
import IListItem from 'models/IListItem';

import { IListInitialData } from '../../index';

export interface ICreateSuccessAction {
  data: {
    active: boolean;
    id: number;
    items: IListItem[];
    position: number;
    quantityColumns: number;
    subtitle: string;
    title: string;
    typeView: string;
  };
}

function createSuccess(
  draft: Draft<IListInitialData>,
  action: PayloadAction<ICreateSuccessAction>,
) {
  let label = 'Ambos';
  switch (action.payload.data.typeView) {
    case EListTypeView.Authenticated:
      label = 'Autenticado';
      break;
    case EListTypeView.NotAuthenticated:
      label = 'Não autenticado';
      break;
    default:
      label = 'Ambos';
      break;
  }
  const listParsed: IList = {
    isCollapsed: true,
    typeView: {
      value: action.payload.data.typeView,
      label,
    },
    active: action.payload.data.active,
    id: action.payload.data.id,
    items: action.payload.data.items,
    position: action.payload.data.position,
    quantityColumns: action.payload.data.quantityColumns,
    subtitle: action.payload.data.subtitle,
    title: action.payload.data.title,
  };
  const newList = draft.data.concat(listParsed);
  draft.data = newList;
  draft.create.config.isLoading = true;
}

export default createSuccess;
