import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsFaviconsRequest from 'services/httpClient/request/settings/IPutSettingsFaviconsRequest';
import IGetProfileResponse from 'services/httpClient/responses/auth/IGetProfileResponse';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IFaviconRequestAction } from '../reducers/favicon/request';

import { settingActions } from '..';

function* favicon(action: PayloadAction<IFaviconRequestAction>) {
  try {
    const body: IPutSettingsFaviconsRequest = {
      url: action.payload.data.url,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/favicons',
      body,
    );
    const { data: profileData }: AxiosResponse<IGetProfileResponse> =
      yield call(httpClient.get, 'admin/sessions');
    yield put(settingActions.setData(profileData.settings));

    ToastManager.show({
      title: message,
      type: 'success',
    });

    yield put(
      settingActions.faviconSuccess({
        url: action.payload.data.url,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.faviconFailure());
  }
}

export default favicon;
