import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import EImageType from 'enums/imageType';
import ELinkType from 'enums/linkType';
import { Formik, FormikProps } from 'formik';
import helpers from 'helpers';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import IListItem from 'models/IListItem';
import { kitActions } from 'store/slices/kit';
import kitSelectors from 'store/slices/kit/selectors';
import { listActions } from 'store/slices/list';
import listSelectors from 'store/slices/list/selectors';
import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';
import { priceListActions } from 'store/slices/priceList';
import priceListSelectors from 'store/slices/priceList/selectors';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';
import * as Yup from 'yup';

import linkTypes from 'constants/linkTypes';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import { IOptionTypeBase } from 'components/input/select/Async';
import ComponentSelectSimple from 'components/input/select/Simple';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import colors from 'styles/colors';

import ItemImageCropper, { IItemImageCropperRefProps } from '../Cropper';
import { Content, FieldGrid, FormActions, FormikForm, Title } from './styles';

interface IItemEditData extends Omit<IListItem, 'link'> {
  link: {
    priceListId?: number;
    type: {
      label: string;
      value: number;
    };
    value: string;
  };
  listId: number;
}

export interface IItemEditRefProps {
  open: (data: IItemEditData) => void;
}

interface IEditItemFormData {
  file: Blob | null;
  link: {
    priceListId: IComponentSelectProps<number> | null;
    type: IComponentSelectProps | null;
    value: IComponentSelectProps<string> | null;
  };
  listId: number;
}

const itemEditSchema = Yup.object().shape({
  link: Yup.object().shape({
    type: Yup.object()
      .shape({
        label: Yup.string().required('Selecione o tipo').nullable(false),
        value: Yup.string().required('Selecione o tipo').nullable(false),
      })
      .typeError('Selecione o tipo'),
    value: Yup.object().when('type', {
      is: (type: IComponentSelectProps) =>
        type.label !== ELinkType.WithoutAction,
      otherwise: Yup.object().nullable(),
      then: Yup.object()
        .shape({
          label: Yup.string()
            .required('Selecione o link do item')
            .nullable(false),
          value: Yup.string()
            .required('Selecione o link do item')
            .nullable(false),
        })
        .typeError('Selecione o link do item'),
    }),
    priceListId: Yup.object().when('type', {
      is: (type: IComponentSelectProps) => type.label === ELinkType.Product,
      otherwise: Yup.object().nullable(),
      then: Yup.object()
        .shape({
          label: Yup.string()
            .required('Selecione a tabela de preço')
            .nullable(false),
          value: Yup.string()
            .required('Selecione a tabela de preço')
            .nullable(false),
        })
        .typeError('Selecione a tabela de preço'),
    }),
  }),
});

const ItemEdit: React.ForwardRefRenderFunction<IItemEditRefProps> = (
  _,
  ref,
) => {
  const reduxDispatch = useReduxDispatch();
  const [item, setItem] = useState<IItemEditData>({} as IItemEditData);
  const [priceTableSelect, setPriceTableSelect] = useState<boolean>(false);
  const [isWithoutAction, setIsWithoutAction] = useState<boolean>(false);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const itemImageCropperRef = useRef<IItemImageCropperRefProps>(null);
  const itemEditFormRef = useRef<FormikProps<IEditItemFormData>>(null);

  const kitsLoading = useReduxSelector(kitSelectors.listIsLoading);
  const manufacturersLoading = useReduxSelector(
    manufacturerSelectors.selectIsLoading,
  );
  const productsLoading = useReduxSelector(productSelectors.selectIsLoading);
  const priceListLoading = useReduxSelector(priceListSelectors.selectIsLoading);
  const linkOptions = useReduxSelector(listSelectors.linkOptions);
  const linkOptionSelected = useReduxSelector(listSelectors.linkOptionSelected);
  const priceListOptions = useReduxSelector(priceListSelectors.select);
  const priceListSelected = useReduxSelector(priceListSelectors.selectSelected);
  const updateItemLoading = useReduxSelector(listSelectors.updateItemIsLoading);

  const openModal = useCallback(
    (data: IItemEditData) => {
      document
        .getElementById('modal-list-edit')
        ?.classList.add('active-scroll');
      componentModalBaseRef.current?.open();
      itemImageCropperRef.current?.setIsLoadingImage(true);
      const newImage = new Image();

      newImage.src = data.url;
      newImage.onload = () => {
        itemImageCropperRef.current?.setIsLoadingImage(false);
      };

      if (data.link?.type.label === ELinkType.Product) {
        reduxDispatch(
          productActions.selectRequest({ data: { linkId: data.link.value } }),
        );
        reduxDispatch(
          priceListActions.selectRequest({
            data: { id: data.link?.priceListId },
          }),
        );
        setPriceTableSelect(true);
      }
      if (data.link?.type.label === ELinkType.Manufacturer) {
        reduxDispatch(
          manufacturerActions.selectRequest({
            data: {
              linkId: data.link.value,
            },
          }),
        );
      }
      if (data.link?.type.label === ELinkType.Kit) {
        reduxDispatch(
          kitActions.listRequest({
            data: {
              linkId: data.link.value,
            },
          }),
        );
      }
      setItem(data);
    },
    [reduxDispatch],
  );

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
    itemImageCropperRef.current?.removeImage();
    itemImageCropperRef.current?.removePreview();
    itemImageCropperRef.current?.setEmptyImage(false);
    setPriceTableSelect(false);
    document
      .getElementById('modal-list-edit')
      ?.classList.remove('active-scroll');
  }, []);

  const handleOnChangeLinkType = useCallback(
    (option: IOptionTypeBase | null) => {
      itemEditFormRef.current?.setFieldValue('link.type', option);
      if (option?.label === ELinkType.Product) {
        reduxDispatch(
          priceListActions.selectRequest({ data: { id: undefined } }),
        );
        setPriceTableSelect(true);
        setIsWithoutAction(false);
      }
      if (option?.label === ELinkType.Manufacturer) {
        reduxDispatch(
          manufacturerActions.selectRequest({
            data: {
              linkId: undefined,
            },
          }),
        );
        setPriceTableSelect(false);
        setIsWithoutAction(false);
        itemEditFormRef.current?.setFieldValue('link.priceListId', null);
      }
      if (option?.label === ELinkType.Kit) {
        reduxDispatch(
          kitActions.listRequest({
            data: {
              linkId: undefined,
            },
          }),
        );
        setPriceTableSelect(false);
        setIsWithoutAction(false);
        itemEditFormRef.current?.setFieldValue('link.priceListId', null);
      }
      if (option?.label === ELinkType.WithoutAction) {
        setPriceTableSelect(false);
        setIsWithoutAction(true);
        itemEditFormRef.current?.setFieldValue('link.priceListId', null);
      }
      itemEditFormRef.current?.setFieldValue('link.value', null);
    },
    [reduxDispatch],
  );

  const handleChangePriceListId = useCallback(
    (option: IOptionTypeBase | null) => {
      itemEditFormRef.current?.setFieldValue('link.priceListId', option);
      if (option?.value) {
        reduxDispatch(
          productActions.selectRequest({ data: { priceListId: option.value } }),
        );
      }
    },
    [reduxDispatch],
  );

  const handleChangeLinkValue = useCallback(
    (option: IOptionTypeBase | null) => {
      itemEditFormRef.current?.setFieldValue('link.value', option);
    },
    [],
  );

  const handleEditItem = useCallback(
    async (data: IEditItemFormData) => {
      try {
        if (!item.listId) {
          return;
        }
        if (!itemImageCropperRef.current?.imageSrc) {
          itemImageCropperRef.current?.setEmptyImage(true);
          return;
        }
        let blobFile: File = {} as File;
        if (itemImageCropperRef.current?.isNewImage) {
          blobFile = await helpers.dataUrlToFile({
            dataUrl: itemImageCropperRef.current?.imageSrc,
            fileName: 'file.jpg',
          });
        }
        reduxDispatch(
          listActions.updateItemRequest({
            data: {
              id: item.id,
              link: {
                type: data.link.type?.label as string,
                value:
                  data.link.type?.label &&
                  data.link.type?.label !== ELinkType.WithoutAction
                    ? Number(data.link.value?.value)
                    : 0,
                priceListId: data.link.priceListId?.value,
              },
              listId: item.listId,
              url: item.url,
              image: itemImageCropperRef.current?.isNewImage
                ? {
                    file: blobFile,
                    uploadType: EImageType.List,
                  }
                : undefined,
            },
            functions: {
              success() {
                closeModal();
              },
            },
          }),
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = helpers.getValidationErrors(err);
          itemEditFormRef.current?.setErrors(validationErrors);
        }
      }
    },
    [closeModal, reduxDispatch, item],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  useEffect(() => {
    if (linkOptionSelected) {
      itemEditFormRef.current?.setFieldValue('link.value', linkOptionSelected);
    }
  }, [linkOptionSelected]);

  useEffect(() => {
    if (priceListSelected) {
      itemEditFormRef.current?.setFieldValue(
        'link.priceListId',
        priceListSelected,
      );
    }
  }, [priceListSelected]);

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content id="modal-list-edit">
        <Title>Editar item</Title>

        <Formik
          initialValues={{
            file: null,
            link: {
              type: item.link?.type,
              priceListId:
                priceListOptions.find(
                  priceList => priceList.value === item.link?.priceListId,
                ) ?? null,
              value:
                linkOptions.find(
                  linkOption => linkOption.value === item.link?.value,
                ) ?? null,
            },
            listId: item.listId,
          }}
          innerRef={itemEditFormRef}
          onSubmit={handleEditItem}
          validation={itemEditSchema}
        >
          {({ errors, values }) => (
            <FormikForm>
              <FieldGrid>
                <ComponentSelectSimple
                  errorMessage={errors.link?.type}
                  hasError={!!errors.link?.type}
                  hideSelectedOptions
                  label="Escolha o tipo de link"
                  name="type"
                  onChange={handleOnChangeLinkType}
                  options={linkTypes}
                  placeholder="Selecione o tipo"
                  value={values.link.type}
                />
                <ComponentSelectSimple
                  errorMessage={errors.link?.priceListId}
                  hasError={!!errors.link?.priceListId}
                  isDisabled={!priceListOptions.length || !priceTableSelect}
                  isLoading={priceListLoading}
                  isSearchable
                  label="Escolha a tabela de preço"
                  name="priceListId"
                  onChange={handleChangePriceListId}
                  options={priceListOptions}
                  placeholder="Selecione a tabela de preço"
                  value={values.link.priceListId}
                />
                <ComponentSelectSimple
                  errorMessage={errors.link?.value}
                  hasError={!!errors.link?.value}
                  isDisabled={isWithoutAction}
                  isLoading={
                    kitsLoading || manufacturersLoading || productsLoading
                  }
                  isSearchable
                  label="Escolha o link do item"
                  name="value"
                  onChange={handleChangeLinkValue}
                  options={linkOptions}
                  placeholder="Selecione o link"
                  value={values.link.value}
                />
              </FieldGrid>

              <ItemImageCropper
                imageUrl={item.url}
                isLoading={updateItemLoading}
                ref={itemImageCropperRef}
              />

              <FormActions>
                <ComponentButtonBase
                  backgroundColor={colors.red}
                  disabled={updateItemLoading}
                  onClick={closeModal}
                  type="button"
                >
                  Cancelar
                </ComponentButtonBase>
                <ComponentButtonBase
                  disabled={updateItemLoading}
                  isLoading={updateItemLoading}
                  type="submit"
                >
                  Confirmar
                </ComponentButtonBase>
              </FormActions>
            </FormikForm>
          )}
        </Formik>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ItemEdit);
