import styled from 'styled-components';

import { Cropper } from 'react-cropper';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Content = styled.div`
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: ${animations.appear};
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${colors.white};
  border-radius: 0.5rem;
  max-height: 95%;
  max-width: 40rem;
  overflow: visible;
  padding: 1.75rem 2rem;
  width: 100%;
  z-index: 20;
`;

export const Title = styled.h5`
  color: ${colors.primary};
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const ContentPreview = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  position: relative;
  width: 100%;
`;

export const PreviewImage = styled(Cropper)`
  display: block;
  height: 100%;
  max-height: 25rem;
  max-height: 25rem;
  width: 100%;
`;

export const Actions = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 6.5rem 10rem 8rem;
  justify-content: flex-end;
  margin-top: 1rem;
`;
