import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsPurchaseRequest from 'services/httpClient/request/settings/IPutSettingsPurchaseRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IPurchasesRequestAction } from '../reducers/purchases/request';

import { settingActions } from '..';

function* purchases(action: PayloadAction<IPurchasesRequestAction>) {
  try {
    const body: IPutSettingsPurchaseRequest = {
      block: {
        creditLimit: action.payload.data.block.creditLimit,
        situation: action.payload.data.block.situation,
        drugList: action.payload.data.block.drugList,
      },
      product: {
        sellWithoutStock: action.payload.data.product.sellWithoutStock,
        showExpirationWarning:
          action.payload.data.product.showExpirationWarning,
        showWithoutStock: action.payload.data.product.showWithoutStock,
        showStockQuantity: action.payload.data.product.showStockQuantity,
        productsViewMode: action.payload.data.product.productsViewMode,
      },
    };

    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/purchases',
      body,
    );

    ToastManager.show({
      title: response.data.message,
      type: 'success',
    });

    yield put(
      settingActions.purchasesSuccess({
        block: {
          creditLimit: action.payload.data.block.creditLimit,
          situation: action.payload.data.block.situation,
          drugList: action.payload.data.block.drugList,
        },
        product: {
          sellWithoutStock: action.payload.data.product.sellWithoutStock,
          showExpirationWarning:
            action.payload.data.product.showExpirationWarning,
          showWithoutStock: action.payload.data.product.showWithoutStock,
          showStockQuantity: action.payload.data.product.showStockQuantity,
          productsViewMode: action.payload.data.product.productsViewMode,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.purchasesFailure());
  }
}

export default purchases;
