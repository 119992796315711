import uploadFailure from './upload/failure';
import uploadRequest from './upload/request';
import uploadSuccess from './upload/success';

const imageReducers = {
  uploadFailure,
  uploadRequest,
  uploadSuccess,
};

export default imageReducers;
