import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { formatters } from 'racsys-js-tools';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsSocialMediaRequest from 'services/httpClient/request/settings/IPutSettingsSocialMediaRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { ISocialMediaRequestAction } from '../reducers/socialMedia/request';

import { settingActions } from '..';

function* socialMedia(action: PayloadAction<ISocialMediaRequestAction>) {
  try {
    const body: IPutSettingsSocialMediaRequest = {
      facebook: action.payload.data.facebook,
      instagram: action.payload.data.instagram,
      linkedin: action.payload.data.linkedin,
      whatsapp: formatters.removeMask(action.payload.data.whatsapp, {
        defaultValue: '',
      }),
    };

    const response: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/social-media',
      body,
    );

    ToastManager.show({
      title: response.data.message,
      type: 'success',
    });

    yield put(
      settingActions.socialMediaSuccess({
        facebook: action.payload.data.facebook,
        instagram: action.payload.data.instagram,
        linkedin: action.payload.data.linkedin,
        whatsapp: action.payload.data.whatsapp,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.socialMediaFailure());
  }
}

export default socialMedia;
