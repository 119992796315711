import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface IRemoveItemSuccessAction {
  data: {
    id: number;
    listId: number;
  };
}

function removeItemSuccess(
  draft: Draft<IListInitialData>,
  action: PayloadAction<IRemoveItemSuccessAction>,
) {
  const listItems = draft.data.map(list => {
    if (list.id === action.payload.data.listId) {
      return {
        items: list.items.filter(item => item.id !== action.payload.data.id),
        active: list.active,
        id: list.id,
        isCollapsed: list.isCollapsed,
        position: list.position,
        quantityColumns: list.quantityColumns,
        subtitle: list.subtitle,
        title: list.title,
        typeView: list.typeView,
      };
    }
    return list;
  });
  draft.data = listItems;
  draft.removeItem.config.isLoading = false;
}

export default removeItemSuccess;
