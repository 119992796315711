import coinsPerRealIsLoading from './coinsPerRealIsLoading';
import details from './details';
import detailsIsLoading from './detailsIsLoading';
import getAllIsError from './getAllIsError';
import getAllIsLoading from './getAllIsLoading';
import getAllList from './getAllList';
import getAllPage from './getAllPage';
import getAllProductsIsError from './getAllProductsIsError';
import getAllProductsIsLoading from './getAllProductsIsLoading';
import getAllProductsList from './getAllProductsList';
import getAllProductsPage from './getAllProductsPage';
import getAllProductsTotalPages from './getAllProductsTotalPages';
import getAllTotalPages from './getAllTotalPages';
import selectIsLoading from './selectIsLoading';

const manufacturerSelectors = {
  coinsPerRealIsLoading,
  details,
  detailsIsLoading,
  getAllIsError,
  getAllIsLoading,
  getAllList,
  getAllPage,
  getAllProductsIsError,
  getAllProductsIsLoading,
  getAllProductsList,
  getAllProductsPage,
  getAllProductsTotalPages,
  getAllTotalPages,
  selectIsLoading,
};

export default manufacturerSelectors;
