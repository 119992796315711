import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAdminInitialData } from '../../index';

export interface IUpdateAdminRequestAction {
  data: {
    email: string;
    id: number;
    name: string;
    password?: string;
  };
  functions: {
    success: () => void;
  };
}

function updateAdmin(
  draft: Draft<IAdminInitialData>,
  _: PayloadAction<IUpdateAdminRequestAction>,
) {
  draft.update.config.isLoading = true;
}

export default updateAdmin;
