import IProduct from 'models/IProduct';

import ComponentIsVisible from 'components/utils/IsVisible';

import Tables from 'styles/tables';

import { ManufacturerProductItemsBody } from '../styles';

interface IItemBodyProps {
  position: number;
  product: IProduct;
}

const ItemBody = ({ position, product }: IItemBodyProps): JSX.Element => {
  return (
    <ManufacturerProductItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{product.id}</Tables.ItemBody>
      <Tables.ItemBody>{product.name}</Tables.ItemBody>
      <Tables.ItemBody>{product.sku}</Tables.ItemBody>
      <Tables.ItemBody>{product.barcode}</Tables.ItemBody>
      <Tables.ItemBody>{product.type}</Tables.ItemBody>
      <Tables.ItemBody center>
        <ComponentIsVisible when={product.hasStock}>Sim</ComponentIsVisible>
        <ComponentIsVisible when={!product.hasStock}>Não</ComponentIsVisible>
      </Tables.ItemBody>
    </ManufacturerProductItemsBody>
  );
};

export default ItemBody;
