import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IHomeControlRequestAction {
  data: {
    showHome: boolean;
  };
}

function homeControlRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IHomeControlRequestAction>,
) {
  draft.homeControl.config.isLoading = true;
}

export default homeControlRequest;
