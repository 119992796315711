import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { EDashboardBilling, IDashboardInitialData } from '../../index';

export interface IBillingSuccessAction {
  data: {
    amount: number;
    type: EDashboardBilling;
    value: number;
  };
}

function billingSuccess(
  draft: Draft<IDashboardInitialData>,
  action: PayloadAction<IBillingSuccessAction>,
) {
  if (action.payload.data.type === EDashboardBilling.Daily) {
    draft.billing.daily.config.isLoading = false;
    draft.billing.daily.amount = action.payload.data.amount;
    draft.billing.daily.value = formatters.money(action.payload.data.value, {
      addPrefix: true,
    });
  }
  if (action.payload.data.type === EDashboardBilling.Monthly) {
    draft.billing.monthly.config.isLoading = false;
    draft.billing.monthly.amount = action.payload.data.amount;
    draft.billing.monthly.value = formatters.money(action.payload.data.value, {
      addPrefix: true,
    });
  }
  if (action.payload.data.type === EDashboardBilling.Semiannual) {
    draft.billing.semiannual.config.isLoading = false;
    draft.billing.semiannual.amount = action.payload.data.amount;
    draft.billing.semiannual.value = formatters.money(
      action.payload.data.value,
      {
        addPrefix: true,
      },
    );
  }
  if (action.payload.data.type === EDashboardBilling.Weekly) {
    draft.billing.weekly.config.isLoading = false;
    draft.billing.weekly.amount = action.payload.data.amount;
    draft.billing.weekly.value = formatters.money(action.payload.data.value, {
      addPrefix: true,
    });
  }
  if (action.payload.data.type === EDashboardBilling.Yearly) {
    draft.billing.yearly.config.isLoading = false;
    draft.billing.yearly.amount = action.payload.data.amount;
    draft.billing.yearly.value = formatters.money(action.payload.data.value, {
      addPrefix: true,
    });
  }
}

export default billingSuccess;
