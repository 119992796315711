import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetCustomerPedcoinsExtractRequest from 'services/httpClient/request/customer/IGetCustomerPedcoinsExtractRequest';
import IGetCustomerPedcoinsExtractResponse from 'services/httpClient/responses/customer/IGetCustomerPedcoinsExtractResponse';

import { customerActions } from '..';

import { IPedcoinsExtractRequestAction } from '../reducers/pedcoinsExtract/request';

function* pedcoinsExtract(
  action: PayloadAction<IPedcoinsExtractRequestAction>,
) {
  try {
    const params: IGetCustomerPedcoinsExtractRequest = {
      page: action.payload.data.page,
    };

    const {
      data: { list, totalPages },
      status: httpStatusCode,
    }: AxiosResponse<IGetCustomerPedcoinsExtractResponse> = yield call(
      httpClient.get,
      `admin/customers/${action.payload.data.customerId}/pedcoins`,
      { params },
    );
    if (httpStatusCode === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        customerActions.pedcoinsExtractSuccess({
          data: { list: [], totalPages: 0 },
        }),
      );
      return;
    }
    yield put(
      customerActions.pedcoinsExtractSuccess({ data: { list, totalPages } }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.pedcoinsExtractFailure());
  }
}

export default pedcoinsExtract;
