import styled, { css } from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Content = styled.div`
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: ${animations.appear};
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  background-color: ${colors.white};
  border-radius: 0.5rem;
  max-height: 95%;
  max-width: 35rem;
  overflow: visible;
  padding: 1.75rem 2rem;
  width: 100%;
  z-index: 12;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Title = styled.h5`
  color: ${colors.primary};
  font-size: 1.25rem;
  font-weight: 500;
`;

export const BannerName = styled.div`
  align-items: flex-end;
  color: ${colors.label};
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 1rem;
`;

export const BannerNameLabel = styled.label`
  color: ${colors.orange};
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
`;

export const Thumbnail = styled.div`
  align-items: center;

  display: flex;
  height: 16rem;
  justify-content: center;
  margin: 1rem auto;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

interface IImagePreviewProps {
  isVisible?: boolean;
}

export const ImagePreview = styled.img<IImagePreviewProps>`
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  border-radius: 0.5rem;
  display: none;
  max-height: 15rem;
  width: 100%;

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};
`;

interface IImagePreviewLoadingProps {
  isVisible?: boolean;
}

export const ImagePreviewLoading = styled.div<IImagePreviewLoadingProps>`
  animation: ${animations.shimmer} 2s infinite linear;
  background: linear-gradient(to right, #f1f1f1 4%, #fafafa 25%, #f1f1f1 36%);
  background-size: 1000px 100%;
  border-radius: 0.5rem;
  display: none;
  height: 16rem;
  width: 100%;

  ${props =>
    props.isVisible &&
    css`
      display: block;
    `};
`;

export const Actions = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 10rem;
  justify-content: center;
  margin: 1rem auto 0;
`;
