import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';
import { listActions } from 'store/slices/list';

import httpClient from 'services/httpClient';
import IGetProductSelectResponse from 'services/httpClient/responses/product/IGetProductSelectResponse';

import { ISelectRequestAction } from '../reducers/select/request';

import { productActions } from '..';

function* select(action: PayloadAction<ISelectRequestAction>) {
  try {
    let list = [];
    let status: number | undefined;
    if (action.payload.data.priceListId) {
      const {
        data: { list: dataResponse },
        status: statusResponse,
      }: AxiosResponse<IGetProductSelectResponse> = yield call(
        httpClient.get,
        `admin/price-lists/${action.payload.data.priceListId}/products-select`,
      );
      list = dataResponse;
      status = statusResponse;
    } else {
      const {
        data: { list: dataResponse },
        status: statusResponse,
      }: AxiosResponse<IGetProductSelectResponse> = yield call(
        httpClient.get,
        'admin/products-select',
      );
      list = dataResponse;
      status = statusResponse;
    }
    if (status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        productActions.selectSuccess({
          data: {
            list: [],
          },
        }),
      );
      yield put(
        listActions.setLinkOptions({
          data: {
            option: [],
          },
        }),
      );
      return;
    }
    yield put(
      productActions.selectSuccess({
        data: {
          list,
        },
      }),
    );
    const listFormatted = list.map(item => {
      return {
        id: item.sku,
        name: item.name,
        sku: item.sku,
      };
    });
    yield put(
      listActions.setLinkOptions({
        data: {
          option: listFormatted,
        },
      }),
    );
    if (action.payload.data.linkId) {
      yield put(
        listActions.setLinkOptionId({
          data: {
            id: action.payload.data.linkId,
          },
        }),
      );
    }
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(productActions.selectFailure());
  }
}

export default select;
