import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

export interface IGetAllRequestAction {
  data: {
    dateTimeEnd?: string;
    dateTimeStart?: string;
    id?: string;
    name?: string;
    page: number;
    perPage?: number;
    sku?: string;
    type?: string;
  };
}

function getAllRequest(
  draft: Draft<IPriceListInitialData>,
  action: PayloadAction<IGetAllRequestAction>,
) {
  draft.details.data = null;
  draft.getAll.data = [];
  draft.getAll.config.isError = false;
  draft.getAll.config.isLoading = true;
  draft.getAll.config.page = action.payload.data.page;
}

export default getAllRequest;
