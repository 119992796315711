import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { EOrderOrigin, EOrderStatus } from 'models/IOrder';

import { IOrderInitialData } from '../../index';

export interface IPdfOrderRequestAction {
  data: {
    dateEnd?: string;
    dateStart?: string;
    name?: string;
    origin?: EOrderOrigin;
    status?: EOrderStatus;
  };
}

function pdfOrderRequest(
  draft: Draft<IOrderInitialData>,
  _: PayloadAction<IPdfOrderRequestAction>,
) {
  draft.pdf.config.isLoading = true;
}

export default pdfOrderRequest;
