import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IAddressSuccessAction {
  city: string;
  complement?: string;
  neighborhood: string;
  number: string;
  postalCode: string;
  state: string;
  street: string;
}

function addressSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IAddressSuccessAction>,
) {
  draft.address.config.isLoading = false;
  draft.data.address = {
    city: action.payload.city,
    complement: action.payload.complement ?? null,
    neighborhood: action.payload.neighborhood,
    number: action.payload.number,
    postalCode: action.payload.postalCode,
    state: action.payload.state,
    street: action.payload.street,
  };
}

export default addressSuccess;
