import React, { useMemo } from 'react';

import CountUp from 'react-countup';
import { IconBaseProps } from 'react-icons/lib';
import { ScaleLoader } from 'react-spinners';

import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Container, Header, Name, PedcoinIcon, Points, Value } from './styles';

interface IExtractCardProps {
  icon?: React.ComponentType<IconBaseProps>;
  iconColor?: string;
  isLoading: boolean;
  pedcoinsIcon?: boolean;
  points: number;
  title: string;
  total?: boolean;
}

function ExtractCard({
  icon: IconInput,
  iconColor,
  isLoading,
  pedcoinsIcon = false,
  points,
  title,
  total,
}: IExtractCardProps): JSX.Element {
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  const coinIconName = useMemo(() => {
    return pedcoins.name.toUpperCase().split('')[0];
  }, [pedcoins.name]);

  return (
    <Container total={total}>
      <Header>
        <Name total={total}>{title}</Name>
        <ComponentIsVisible when={pedcoinsIcon}>
          <PedcoinIcon>{coinIconName}</PedcoinIcon>
        </ComponentIsVisible>
        {IconInput && <IconInput color={iconColor} size={26} />}
      </Header>
      <Points total={total}>
        <ComponentIsVisible when={!isLoading}>
          <CountUp delay={0} duration={3} end={points} start={0}>
            {({ countUpRef }) => <Value ref={countUpRef} />}
          </CountUp>
        </ComponentIsVisible>
        <ComponentIsVisible when={isLoading}>
          <ScaleLoader
            color={total ? colors.white : colors.label}
            height={18}
            margin={2}
            radius={2}
            speedMultiplier={1}
            width={2}
          />
        </ComponentIsVisible>
      </Points>
    </Container>
  );
}

export default ExtractCard;
