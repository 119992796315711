import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { dashboardActions, EDashboardCustomer } from '../index';

function* customer() {
  try {
    const {
      data: { value: activeValue },
    }: AxiosResponse<{ value: number }> = yield call(
      httpClient.get,
      `admin/dashboard/customers`,
      {
        params: {
          situation: EDashboardCustomer.Active,
        },
      },
    );

    const {
      data: { value: inactiveValue },
    }: AxiosResponse<{ value: number }> = yield call(
      httpClient.get,
      `admin/dashboard/customers`,
      {
        params: {
          situation: EDashboardCustomer.Inactive,
        },
      },
    );

    const {
      data: { value: defaulterValue },
    }: AxiosResponse<{ value: number }> = yield call(
      httpClient.get,
      `admin/dashboard/customers`,
      {
        params: {
          situation: EDashboardCustomer.Defaulter,
        },
      },
    );

    const {
      data: { value: nonPositive },
    }: AxiosResponse<{ value: number }> = yield call(
      httpClient.get,
      `admin/dashboard/customers`,
      {
        params: {
          situation: EDashboardCustomer.NotPositive,
        },
      },
    );

    yield put(
      dashboardActions.customerSuccess({
        data: {
          active: activeValue,
          inactive: inactiveValue,
          nonPositive,
          defaulter: defaulterValue,
        },
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(dashboardActions.customerFailure());
  }
}

export default customer;
