import billingDayAmount from './billingDayAmount';
import billingDayIsLoading from './billingDayIsLoading';
import billingDayValue from './billingDayValue';
import billingMonthlyAmount from './billingMonthlyAmount';
import billingMonthlyIsLoading from './billingMonthlyIsLoading';
import billingMonthlyValue from './billingMonthlyValue';
import billingSemiannualAmount from './billingSemiannualAmount';
import billingSemiannualIsLoading from './billingSemiannualIsLoading';
import billingSemiannualValue from './billingSemiannualValue';
import billingWeeklyAmount from './billingWeeklyAmount';
import billingWeeklyIsLoading from './billingWeeklyIsLoading';
import billingWeeklyValue from './billingWeeklyValue';
import billingYearlyAmount from './billingYearlyAmount';
import billingYearlyIsLoading from './billingYearlyIsLoading';
import billingYearlyValue from './billingYearlyValue';
import customerActive from './customerActive';
import customerDefaulter from './customerDefaulter';
import customerInactive from './customerInactive';
import customerIsLoading from './customerIsLoading';
import customerNonPositive from './customerNonPositive';
import orderBilling from './orderBilling';
import orderCancellation from './orderCancellation';
import orderClosing from './orderClosing';
import orderIsLoading from './orderIsLoading';
import orderOpening from './orderOpening';
import orderProcessing from './orderProcessing';
import rankingCustomerIsLoading from './rankingCustomerIsLoading';
import rankingCustomerList from './rankingCustomerList';
import rankingProductIsLoading from './rankingProductIsLoading';
import rankingProductList from './rankingProductList';

const dashboardSelectors = {
  billingDayAmount,
  billingDayIsLoading,
  billingDayValue,
  billingMonthlyAmount,
  billingMonthlyIsLoading,
  billingMonthlyValue,
  billingSemiannualAmount,
  billingSemiannualIsLoading,
  billingSemiannualValue,
  billingWeeklyAmount,
  billingWeeklyIsLoading,
  billingWeeklyValue,
  billingYearlyAmount,
  billingYearlyIsLoading,
  billingYearlyValue,
  customerActive,
  customerDefaulter,
  customerInactive,
  customerIsLoading,
  customerNonPositive,
  orderBilling,
  orderCancellation,
  orderClosing,
  orderIsLoading,
  orderOpening,
  orderProcessing,
  rankingCustomerIsLoading,
  rankingCustomerList,
  rankingProductIsLoading,
  rankingProductList,
};

export default dashboardSelectors;
