import { all, takeLatest } from 'redux-saga/effects';

import { productRequestActions } from '../index';
import getAll from './getAll';

const productRequestSagas = all([
  takeLatest(productRequestActions.getAllRequest, getAll),
]);

export default productRequestSagas;
