import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetPriceListDetailsResponse from 'services/httpClient/responses/priceList/IGetPriceListDetailsResponse';

import { priceListActions } from '..';

import { IDetailsRequestAction } from '../reducers/details/request';

function* details(action: PayloadAction<IDetailsRequestAction>) {
  try {
    const {
      data: priceListDetails,
    }: AxiosResponse<IGetPriceListDetailsResponse> = yield call(
      httpClient.get,
      `admin/price-lists/${action.payload.data.id}`,
    );

    yield put(priceListActions.detailsSuccess({ data: priceListDetails }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(priceListActions.detailsFailure());
  }
}

export default details;
