import { PayloadAction } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutUpdateListRequest from 'services/httpClient/request/list/IPutUpdateListRequest';

import { IUpdateRequestAction } from '../reducers/update/request';

import { listActions } from '..';

function* update(action: PayloadAction<IUpdateRequestAction>) {
  try {
    const body: IPutUpdateListRequest = {
      active: action.payload.data.active,
      quantityColumns: action.payload.data.quantityColumns,
      subtitle: action.payload.data.subtitle.trim(),
      title: action.payload.data.title.trim(),
      typeView: action.payload.data.typeView.value,
    };
    yield call(
      httpClient.put,
      `admin/settings/lists/${action.payload.data.id}`,
      body,
    );
    ToastManager.show({
      title: 'Lista editada com sucesso',
      type: 'success',
    });
    action.payload.functions.success();
    yield put(listActions.updateSuccess({ data: action.payload.data }));
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(listActions.updateFailure());
  }
}

export default update;
