import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface ITermsRequestAction {
  data: {
    terms: string;
  };
  functions: {
    closeModal: () => void;
  };
}

function termsRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<ITermsRequestAction>,
) {
  draft.terms.config.isLoading = true;
}

export default termsRequest;
