import { useCallback, useEffect } from 'react';

import { productRequestActions } from 'store/slices/productRequest';
import productRequestSelectors from 'store/slices/productRequest/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentBackButton from 'components/button/BackButton';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import ProductItem from './Item';

const ProductRequestList = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(productRequestSelectors.getAllIsLoading);
  const products = useReduxSelector(productRequestSelectors.getAll);
  const productPage = useReduxSelector(productRequestSelectors.getAllPage);
  const productTotalPages = useReduxSelector(
    productRequestSelectors.getAllTotalPages,
  );
  const isError = useReduxSelector(productRequestSelectors.getAllIsError);

  const loadProductsRequest = useCallback(() => {
    reduxDispatch(
      productRequestActions.getAllRequest({
        data: {
          page: 1,
        },
      }),
    );
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    (page: number) => {
      reduxDispatch(
        productRequestActions.getAllRequest({
          data: {
            page,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  useEffect(() => {
    loadProductsRequest();
  }, [loadProductsRequest]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle hasBackButton>
          <ComponentBackButton />
          Produtos solicitados
        </General.PageTitle>
      </General.PageTitleContainer>

      <Tables.Items hasBorder marginTop=".5rem">
        <ProductItem.Header />

        <ComponentIsVisible when={!isLoading}>
          <ComponentIsVisible when={!!products.length && !isError}>
            {products.map((product, position) => (
              <ProductItem.Body
                key={product.id}
                position={position}
                product={product}
              />
            ))}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há produtos solicitados para serem exibidos"
            show={!products.length && !isError}
          />

          <ComponentIsVisible when={isError}>
            <ComponentError
              message="Não foi possível carregar os produtos solicitados"
              onClick={loadProductsRequest}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
        <ComponentIsVisible when={isLoading}>
          <ComponentLoadingList rows={8} show={isLoading} />
        </ComponentIsVisible>
      </Tables.Items>
      <ComponentIsVisible when={!isLoading}>
        <ComponentPaginate
          currentPage={productPage}
          onPage={handlePagination}
          show={!!products.length}
          totalPages={productTotalPages}
        />
      </ComponentIsVisible>
    </Containers.Global>
  );
};

export default ProductRequestList;
