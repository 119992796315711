import { Draft } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

function getAllProductsFailure(draft: Draft<IManufacturerInitialData>) {
  draft.getAllProducts.config.isError = true;
  draft.getAllProducts.config.isLoading = false;
}

export default getAllProductsFailure;
