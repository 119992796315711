import { IProductPriceScales } from 'store/slices/product';

import Tables from 'styles/tables';

import { PriceScaleItemsBody } from '../styles';

interface IItemBodyProps {
  position: number;
  productPriceScales: IProductPriceScales;
}
const ItemBody = ({
  position,
  productPriceScales,
}: IItemBodyProps): JSX.Element => {
  return (
    <PriceScaleItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{productPriceScales.id}</Tables.ItemBody>
      <Tables.ItemBody center>{productPriceScales.sku}</Tables.ItemBody>
      <Tables.ItemBody center>{productPriceScales.quantity}</Tables.ItemBody>
      <Tables.ItemBody right>{productPriceScales.amountGross}</Tables.ItemBody>
      <Tables.ItemBody right>
        {productPriceScales.amountDiscount}
      </Tables.ItemBody>
      <Tables.ItemBody right>{productPriceScales.amountNet}</Tables.ItemBody>
      <Tables.ItemBody right>{productPriceScales.amountFee}</Tables.ItemBody>
      <Tables.ItemBody right>{productPriceScales.amountFinal}</Tables.ItemBody>
    </PriceScaleItemsBody>
  );
};

export default ItemBody;
