import { useCallback, useEffect, useRef } from 'react';

import { FormikProps } from 'formik';
import { orderActions } from 'store/slices/order';
import orderSelectors from 'store/slices/order/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import OrdersFilter, { IOrderFilterFormData } from './Filter';
import OrderItem from './Item';
import { EmptyContainer, FilterContainer, Subheader } from './styles';

const OrderList: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const ordersFilterRef = useRef<FormikProps<IOrderFilterFormData>>(null);

  const isError = useReduxSelector(orderSelectors.listIsError);
  const isLoading = useReduxSelector(orderSelectors.listIsLoading);
  const orders = useReduxSelector(orderSelectors.list);
  const pagination = useReduxSelector(orderSelectors.listPagination);

  const pdfIsLoading = useReduxSelector(orderSelectors.pdfIsLoading);
  const spreadsheetIsLoading = useReduxSelector(
    orderSelectors.spreadsheetIsLoading,
  );

  const handleOrders = useCallback(() => {
    reduxDispatch(orderActions.listRequest({ data: { page: 1 } }));
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    (page: number) => {
      const status = ordersFilterRef.current?.values.status;
      reduxDispatch(
        orderActions.listRequest({
          data: {
            page,
            status: status?.value ?? undefined,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleGeneratePdf = useCallback(() => {
    const name = ordersFilterRef.current?.values.name;
    const origin = ordersFilterRef.current?.values.origin?.value;
    const status = ordersFilterRef.current?.values.status?.value;
    const dateEnd = ordersFilterRef.current?.values.dateEnd;
    const dateStart = ordersFilterRef.current?.values.dateStart;
    reduxDispatch(
      orderActions.pdfOrderRequest({
        data: {
          name,
          origin,
          status,
          dateEnd,
          dateStart,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleGenerateSpreadsheet = useCallback(() => {
    const name = ordersFilterRef.current?.values.name;
    const origin = ordersFilterRef.current?.values.origin?.value;
    const status = ordersFilterRef.current?.values.status?.value;
    const dateEnd = ordersFilterRef.current?.values.dateEnd;
    const dateStart = ordersFilterRef.current?.values.dateStart;
    reduxDispatch(
      orderActions.spreadsheetOrderRequest({
        data: {
          name,
          origin,
          status,
          dateEnd,
          dateStart,
        },
      }),
    );
  }, [reduxDispatch]);

  useEffect(() => {
    handleOrders();
  }, [handleOrders]);

  return (
    <Containers.Global>
      <Subheader>
        <General.PageTitle>Pedidos</General.PageTitle>
      </Subheader>
      <FilterContainer>
        <OrdersFilter ref={ordersFilterRef} />
        <ComponentButtonBase
          disabled={spreadsheetIsLoading}
          isLoading={spreadsheetIsLoading}
          onClick={handleGenerateSpreadsheet}
          title="Gerar Planilha"
        >
          Gerar Planilha
        </ComponentButtonBase>
        <ComponentButtonBase
          disabled={pdfIsLoading}
          isLoading={pdfIsLoading}
          onClick={handleGeneratePdf}
          title="Gerar PDF"
          type="button"
        >
          Gerar PDF
        </ComponentButtonBase>
      </FilterContainer>
      <Tables.Items hasBorder marginTop=".5rem">
        <OrderItem.Header />
        <ComponentIsVisible when={!isLoading}>
          <ComponentIsVisible when={!isError}>
            <ComponentIsVisible when={!!orders.length}>
              {orders.map((order, position) => (
                <OrderItem.Body
                  key={order.id}
                  order={order}
                  position={position}
                />
              ))}
            </ComponentIsVisible>
            <ComponentIsVisible when={!orders?.length}>
              <EmptyContainer>
                <ComponentEmpty
                  message="Nenhum pedido encontrado"
                  show={!orders.length}
                />
              </EmptyContainer>
            </ComponentIsVisible>
          </ComponentIsVisible>
          <ComponentIsVisible when={isError}>
            <ComponentError
              message="Não foi possível carregar os pedidos"
              onClick={handleOrders}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
        <ComponentIsVisible when={isLoading}>
          <ComponentLoadingList rows={8} show={isLoading} />
        </ComponentIsVisible>
      </Tables.Items>
      <ComponentIsVisible when={!isLoading}>
        <ComponentPaginate
          currentPage={pagination.page}
          onPage={handlePagination}
          show
          totalPages={pagination.totalPages}
        />
      </ComponentIsVisible>
    </Containers.Global>
  );
};

export default OrderList;
