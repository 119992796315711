import { all, takeEvery } from 'redux-saga/effects';

import { dashboardActions } from '../index';
import billing from './billing';
import customer from './customer';
import order from './order';
import rankingCustomer from './rankingCustomer';
import rankingProduct from './rankingProduct';

const dashboardSagas = all([
  takeEvery(dashboardActions.billingRequest, billing),
  takeEvery(dashboardActions.customerRequest, customer),
  takeEvery(dashboardActions.orderRequest, order),
  takeEvery(dashboardActions.rankingProductRequest, rankingProduct),
  takeEvery(dashboardActions.rankingCustomerRequest, rankingCustomer),
]);

export default dashboardSagas;
