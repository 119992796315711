import { FaRegStar, FaStar } from 'react-icons/fa';

import formatters from 'formatters';
import { IFeedbackData } from 'store/slices/feedback';

import colors from 'styles/colors';
import Tables from 'styles/tables';

import { FeedbackItemsBody } from '../styles';

interface IProps {
  feedback: IFeedbackData;
}

const ItemBody = ({ feedback }: IProps) => {
  const starsArray = [
    <FaStar color={colors.yellow} key={1} size={18} />,
    <FaStar color={colors.yellow} key={2} size={18} />,
    <FaStar color={colors.yellow} key={3} size={18} />,
    <FaStar color={colors.yellow} key={4} size={18} />,
    <FaStar color={colors.yellow} key={5} size={18} />,
  ];
  const createdAtFormatted = formatters.date(feedback.createdAt);

  return (
    <FeedbackItemsBody>
      <Tables.ItemBody center>{feedback.id}</Tables.ItemBody>
      <Tables.ItemBody overflowVisible>
        {feedback.customer.name}
      </Tables.ItemBody>
      <Tables.ItemBody overflowVisible>{feedback.message}</Tables.ItemBody>
      <Tables.ItemBody overflowVisible>{createdAtFormatted}</Tables.ItemBody>
      <Tables.ItemBody center>
        {starsArray.map((star, index) => {
          const position = index + 1;
          if (position <= feedback.rating) {
            return star;
          }
          return <FaRegStar key={position} size={18} />;
        })}
      </Tables.ItemBody>
    </FeedbackItemsBody>
  );
};

export default ItemBody;
