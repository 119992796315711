import { InputHTMLAttributes, useRef, useState } from 'react';

import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';

import ComponentIsVisible from 'components/utils/IsVisible';

import {
  Container,
  Error,
  FieldContainer,
  IFieldContainerProps,
  IInputProps,
  Input,
  ToggleSecure,
} from './styles';

export interface IComponentInputBaseProps
  extends Omit<IFieldContainerProps, 'isErrored' | 'isFocused' | 'isFilled'>,
    IInputProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'height'> {
  errorMessage?: string;
  hasError?: boolean;
  isBorder?: boolean;
  isLoading?: boolean;
  name: string;
  secure?: boolean;
  title?: string;
}

const AuthField: React.FC<IComponentInputBaseProps> = ({
  errorMessage,
  hasError = false,
  isBorder = false,
  isLoading = false,
  name,
  secure = false,
  textAlign,
  title,
  type = 'text',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputType, setInputType] = useState<string>(type);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };
  const handleInputBlur = () => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  };
  const handleToggleSecure = () => {
    setInputType(currentType => {
      if (currentType === 'password') {
        return 'text';
      }
      return 'password';
    });
  };

  return (
    <Container>
      <FieldContainer
        isBorder={isBorder}
        isErrored={hasError}
        isFilled={isFilled}
        isFocused={isFocused}
        isSecure={secure}
      >
        <Input
          isLoading={isLoading}
          name={name}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          ref={inputRef}
          textAlign={textAlign}
          title={title}
          type={inputType}
          {...rest}
        />

        <ComponentIsVisible when={secure}>
          <ComponentIsVisible when={inputType === 'password'}>
            <ToggleSecure
              isErrored={hasError}
              onClick={handleToggleSecure}
              type="button"
            >
              <FiEye size={18} />
            </ToggleSecure>
          </ComponentIsVisible>
          <ComponentIsVisible when={inputType !== 'password'}>
            <ToggleSecure
              isErrored={hasError}
              onClick={handleToggleSecure}
              type="button"
            >
              <FiEyeOff size={18} />
            </ToggleSecure>
          </ComponentIsVisible>
        </ComponentIsVisible>

        <ComponentIsVisible when={hasError}>
          <Error title={errorMessage as string}>
            <FiAlertCircle size={18} />
          </Error>
        </ComponentIsVisible>
      </FieldContainer>
    </Container>
  );
};

export default AuthField;
