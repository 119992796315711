import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EListTypes from 'enums/listType';

import { ISettingInitialData } from '../../index';

export interface IPurchasesRequestAction {
  data: {
    block: {
      creditLimit: boolean;
      drugList: boolean;
      situation: boolean;
    };
    product: {
      productsViewMode: EListTypes;
      sellWithoutStock: boolean;
      showExpirationWarning: boolean;
      showStockQuantity: boolean;
      showWithoutStock: boolean;
    };
  };
}

function purchasesRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IPurchasesRequestAction>,
) {
  draft.purchases.config.isLoading = true;
}

export default purchasesRequest;
