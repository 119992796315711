import styled from 'styled-components';

import { Form } from 'formik';

export const FormikForm = styled(Form)`
  margin-top: 1rem;
`;

export const Actions = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 8rem 1fr;
  justify-content: flex-end;
  margin-top: 1.5rem;
`;
