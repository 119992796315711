import { Draft } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

function getAllProductsFailure(draft: Draft<IPriceListInitialData>) {
  draft.getAllProducts.config.isError = true;
  draft.getAllProducts.config.isLoading = false;
}

export default getAllProductsFailure;
