import styled, { css } from 'styled-components';

import Tooltip from 'components/utils/Tooltip';

import colors from 'styles/colors';

export const Container = styled.div`
  margin-top: 0.5rem;
  width: 100%;
`;

export const Label = styled.label`
  color: ${colors.label};
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

interface ISelectContainerProps {
  backgroundColor?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isFilled: boolean;
  isFocused: boolean;
}

export const SelectContainer = styled.div<ISelectContainerProps>`
  align-items: center;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : colors.tabBackground};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  display: grid;
  height: 2.25rem;
  position: relative;
  width: 100%;
  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.red};
      grid-template-columns: 1fr 2rem;
    `}
  ${props =>
    props.isDisabled &&
    css`
      border-color: ${colors.border};
    `}
    ${props =>
    props.isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Error = styled(Tooltip)``;
