import { Draft } from '@reduxjs/toolkit';

import { IManufacturerInitialData } from '../../index';

function getAllFailure(draft: Draft<IManufacturerInitialData>) {
  draft.getAll.config.isLoading = false;
  draft.getAll.config.isError = true;
}

export default getAllFailure;
