import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IShippingSuccessAction {
  timeLimitMessage: string;
}

function shippingSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IShippingSuccessAction>,
) {
  draft.shipping.config.isLoading = false;
  draft.data.shipping = {
    timeLimitMessage: action.payload.timeLimitMessage,
  };
}

export default shippingSuccess;
