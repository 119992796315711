import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import { Formik, FormikProps } from 'formik';
import { authActions } from 'store/slices/auth';
import authSelectors from 'store/slices/auth/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPassword from 'components/input/Password';
import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import colors from 'styles/colors';

import { Actions, Content, FormikForm, Title } from './styles';

interface IUpdatePasswordFormData {
  confirmNewPassword: string;
  currentPassword: string;
  newPassword: string;
}

const updatePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().trim().required('Informe a senha atual'),
  newPassword: Yup.string().trim().required('Informe a nova senha'),
  confirmNewPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('newPassword'), null], 'Senhas são diferentes')
    .required('Informe a confirmação da nova senha'),
});

export interface IUpdatePasswordRefProps {
  open: () => void;
}

const UpdatePassword: React.ForwardRefRenderFunction<
  IUpdatePasswordRefProps
> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();

  const formRef = useRef<FormikProps<IUpdatePasswordFormData>>(null);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const updatePasswordLoading = useReduxSelector(
    authSelectors.updatePasswordIsLoading,
  );

  const openModal = useCallback(() => {
    componentModalBaseRef.current?.open();
  }, []);

  const handleCloseModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleUpdatePassword = useCallback(
    (data: IUpdatePasswordFormData) => {
      reduxDispatch(
        authActions.updatePasswordRequest({
          data: {
            current: data.currentPassword,
            newPassword: data.newPassword,
          },
          functions: {
            close: () => {
              handleCloseModal();
            },
          },
        }),
      );
    },
    [reduxDispatch, handleCloseModal],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  useHotkeys('esc', () => handleCloseModal());

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Alterar senha</Title>

        <Formik
          initialValues={{
            confirmNewPassword: '',
            currentPassword: '',
            newPassword: '',
          }}
          innerRef={formRef}
          onSubmit={handleUpdatePassword}
          validationSchema={updatePasswordSchema}
        >
          {({ errors, handleChange, values }) => (
            <FormikForm>
              <ComponentInputPassword
                errorMessage={errors.currentPassword}
                hasError={!!errors.currentPassword}
                label="Senha atual"
                name="currentPassword"
                onChange={handleChange('currentPassword')}
                value={values.currentPassword}
              />
              <ComponentInputPassword
                errorMessage={errors.newPassword}
                hasError={!!errors.newPassword}
                label="Nova senha"
                name="newPassword"
                onChange={handleChange('newPassword')}
                value={values.newPassword}
              />
              <ComponentInputPassword
                errorMessage={errors.confirmNewPassword}
                hasError={!!errors.confirmNewPassword}
                label="Confirmar nova senha"
                name="confirmNewPassword"
                onChange={handleChange('confirmNewPassword')}
                value={values.confirmNewPassword}
              />

              <Actions>
                <ComponentButtonBase
                  backgroundColor={colors.red}
                  disabled={updatePasswordLoading}
                  onClick={handleCloseModal}
                  type="button"
                >
                  Cancelar
                </ComponentButtonBase>

                <ComponentButtonBase
                  backgroundColor={colors.orange}
                  isLoading={updatePasswordLoading}
                  type="submit"
                >
                  Confirmar
                </ComponentButtonBase>
              </Actions>
            </FormikForm>
          )}
        </Formik>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(UpdatePassword);
