import { all, takeLatest } from 'redux-saga/effects';

import { customerAccessActions } from '../index';
import getAll from './getAll';
import pdf from './pdf';
import spreadsheet from './spreadsheet';

const customerAccessSagas = all([
  takeLatest(customerAccessActions.getAllRequest, getAll),
  takeLatest(customerAccessActions.pdfCustomerAccessRequest, pdf),
  takeLatest(
    customerAccessActions.spreadsheetCustomerAccessRequest,
    spreadsheet,
  ),
]);

export default customerAccessSagas;
