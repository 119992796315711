import ComponentIsVisible from 'components/utils/IsVisible';

import { CustomerPedcoinsItemLoading } from '../styles';

interface ICustomerPedcoinsItemsLoading {
  show: boolean;
}

function CustomerPedcoinsItemsLoading({
  show,
}: ICustomerPedcoinsItemsLoading): JSX.Element {
  const loadingItems = [1, 2, 3, 4, 5, 6];

  return (
    <ComponentIsVisible when={show}>
      {loadingItems.map(position => (
        <CustomerPedcoinsItemLoading key={position} />
      ))}
    </ComponentIsVisible>
  );
}

export default CustomerPedcoinsItemsLoading;
