import { useCallback, useEffect, useRef, useState } from 'react';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { BsArrowsCollapse, BsArrowsExpand } from 'react-icons/bs';
import { FaGripVertical } from 'react-icons/fa';
import { FiPlus, FiSettings, FiTrash2 } from 'react-icons/fi';

import IList from 'models/IList';
import IListItem from 'models/IListItem';
import { listActions } from 'store/slices/list';
import listSelectors from 'store/slices/list/selectors';
import { v4 as uuidV4 } from 'uuid';

import linkTypes from 'constants/linkTypes';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentAlert, {
  IComponentAlertRefProps,
} from 'components/modal/Alert';
import ComponentEmpty from 'components/utils/Empty';
import ComponentIsVisible from 'components/utils/IsVisible';

import ItemCreate, { IItemCreateRefProps } from './Create';
import ItemEdit, { IItemEditRefProps } from './Edit';
import ListItem from './Item';
import ItemRemove, { IItemRemoveRefProps } from './Remove';
import {
  Action,
  Actions,
  Body,
  Container,
  Header,
  ListEmpty,
  Status,
  Subtitle,
  Title,
  TitleContainer,
} from './styles';

interface IListContentProps {
  index: number;
  list: IList;
  openConfigEdit: (list: IList) => void;
}

const ListContent: React.FC<IListContentProps> = ({
  index,
  list,
  openConfigEdit,
}) => {
  const reduxDispatch = useReduxDispatch();
  const [isClearDnD, setIsClearDnD] = useState(true);
  const [currentListItem, setCurrentListItem] = useState<IListItem[]>([]);
  const componentAlertRef = useRef<IComponentAlertRefProps>(null);
  const itemCreateRef = useRef<IItemCreateRefProps>(null);
  const itemEditRef = useRef<IItemEditRefProps>(null);
  const itemRemoveRef = useRef<IItemRemoveRefProps>(null);

  const listRemoveLoading = useReduxSelector(listSelectors.removeIsLoading);

  const openListRemoveAlert = (list: IList) => {
    componentAlertRef.current?.open({
      id: list.id,
    });
  };
  const openItemCreate = () => {
    itemCreateRef.current?.open(list.id);
  };
  const openItemEdit = (item: IListItem) => {
    const typeOption = linkTypes.find(
      linkType => linkType.label === item.link.type,
    );

    itemEditRef.current?.open({
      ...item,
      link: {
        type: {
          label: typeOption?.label as string,
          value: typeOption?.value as number,
        },
        value: item.link.value,
        priceListId: item.link.priceListId,
      },
      listId: list.id,
    });
  };

  const openItemRemoveAlert = (item: IListItem) => {
    itemRemoveRef.current?.open({
      ...item,
      listId: list.id,
    });
  };

  const handleRemoveList = useCallback(
    (listId: number) => {
      reduxDispatch(
        listActions.removeRequest({
          data: {
            id: listId,
          },
          functions: {
            success() {
              componentAlertRef.current?.close();
            },
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleUpdateListItemPosition = useCallback(
    (items: IListItem[]) => {
      const ids = items.map(item => item.id);
      reduxDispatch(
        listActions.updateItemPositionRequest({
          data: {
            listId: list.id,
            ids,
          },
        }),
      );
    },
    [reduxDispatch, list.id],
  );

  const reorder = useCallback(
    (list: IListItem[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    },
    [],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      setIsClearDnD(false);
      const items = reorder(
        currentListItem,
        result.source.index,
        result.destination.index,
      );
      setCurrentListItem(items);
      handleUpdateListItemPosition(items);
      setTimeout(() => {
        setIsClearDnD(true);
      }, 600);
    },
    [currentListItem, handleUpdateListItemPosition, reorder],
  );

  const changeDropdown = useCallback(
    (id: number) => {
      reduxDispatch(
        listActions.setChangeDropdown({
          data: {
            id,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  useEffect(() => {
    setCurrentListItem(list.items);
  }, [list.items]);

  return (
    <Draggable
      draggableId={String(list.id)}
      index={index}
      isDragDisabled={!list.isCollapsed}
      key={list.id}
    >
      {provided => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Header>
            <TitleContainer>
              <Title className={list.isCollapsed ? 'isClosed' : 'isOpen'}>
                {list.title}
                <ComponentIsVisible when={!list.active}>
                  <Status>(Inativa)</Status>
                </ComponentIsVisible>
              </Title>
              <ComponentIsVisible when={!!list.subtitle}>
                <Subtitle className={list.isCollapsed ? 'isClosed' : 'isOpen'}>
                  {list.subtitle}
                </Subtitle>
              </ComponentIsVisible>
            </TitleContainer>
            <Actions>
              <Action
                className={list.isCollapsed ? 'isClosed' : 'isOpen'}
                onClick={openItemCreate}
              >
                Novo item
                <FiPlus size={18} type="button" />
              </Action>
              <Action
                className={list.isCollapsed ? 'isClosed' : 'isOpen'}
                isSettings
                onClick={() => openConfigEdit(list)}
                type="button"
              >
                Alterar configurações
                <FiSettings size={18} />
              </Action>
              <Action
                className={list.isCollapsed ? 'isClosed' : 'isOpen'}
                isRemove
                onClick={() => openListRemoveAlert(list)}
                type="button"
              >
                <FiTrash2 size={18} />
              </Action>
              <ComponentIsVisible when={!list.isCollapsed}>
                <Action
                  onClick={() => changeDropdown(list.id)}
                  title="Recolher"
                  type="button"
                >
                  <BsArrowsCollapse size={18} />
                </Action>
              </ComponentIsVisible>
              <ComponentIsVisible when={list.isCollapsed}>
                <Action
                  onClick={() => changeDropdown(list.id)}
                  title="Expandir"
                  type="button"
                >
                  <BsArrowsExpand size={18} />
                </Action>
              </ComponentIsVisible>
              <Action
                disabled={!list.isCollapsed}
                title="Arrastar"
                type="button"
              >
                <FaGripVertical size={18} />
              </Action>
            </Actions>
          </Header>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction="horizontal" droppableId={uuidV4()}>
              {provided => (
                <Body ref={provided.innerRef} {...provided.droppableProps}>
                  <ComponentIsVisible when={!list.isCollapsed}>
                    {currentListItem.map((item, index) => (
                      <ListItem
                        index={index}
                        isClearDnD={isClearDnD}
                        item={item}
                        key={item.id}
                        openEdit={openItemEdit}
                        openRemoveAlert={openItemRemoveAlert}
                      />
                    ))}
                    {provided.placeholder}

                    <ComponentIsVisible when={!list.items.length}>
                      <ListEmpty>
                        <ComponentEmpty
                          message="Nenhum item cadastrado a essa lista"
                          show={!list.items.length}
                        />
                      </ListEmpty>
                    </ComponentIsVisible>
                  </ComponentIsVisible>
                </Body>
              )}
            </Droppable>
          </DragDropContext>

          <ComponentAlert
            isLoading={listRemoveLoading}
            message={`Tem certeza que deseja remover a lista: ${list.title}`}
            onConfirm={handleRemoveList}
            ref={componentAlertRef}
          />

          <ItemCreate ref={itemCreateRef} />
          <ItemEdit ref={itemEditRef} />
          <ItemRemove ref={itemRemoveRef} />
        </Container>
      )}
    </Draggable>
  );
};

export default ListContent;
