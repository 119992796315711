import Tables from 'styles/tables';

import { PriceListItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <PriceListItemsHeader>
      <Tables.ItemHeader center>Código</Tables.ItemHeader>
      <Tables.ItemHeader>Nome</Tables.ItemHeader>
      <Tables.ItemHeader>Fabricante</Tables.ItemHeader>
      <Tables.ItemHeader center>SKU</Tables.ItemHeader>
      <Tables.ItemHeader right>Vlr. Bruto</Tables.ItemHeader>
      <Tables.ItemHeader right>Desconto</Tables.ItemHeader>
      <Tables.ItemHeader right>Vlr. Líquido</Tables.ItemHeader>
      <Tables.ItemHeader right>Impostos</Tables.ItemHeader>
      <Tables.ItemHeader right>Vlr. Final</Tables.ItemHeader>
    </PriceListItemsHeader>
  );
};

export default ItemHeader;
