import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { EProductType } from 'models/IProduct';

import { IManufacturerInitialData } from '../../index';

export interface IGetAllProductsSuccessAction {
  data: {
    list: {
      barcode: string;
      hasStock: boolean;
      id: number;
      name: string;
      sku: string;
      type: EProductType;
    }[];
    totalPages: number;
  };
}

function getAllProductsSuccess(
  draft: Draft<IManufacturerInitialData>,
  action: PayloadAction<IGetAllProductsSuccessAction>,
) {
  draft.getAllProducts.config.totalPages = action.payload.data.totalPages;
  draft.getAllProducts.list = action.payload.data.list;
  draft.getAllProducts.config.isLoading = false;
}

export default getAllProductsSuccess;
