export enum EOrderStatus {
  Billing = 'FATURADO',
  Cancellation = 'CANCELADO',
  Closing = 'FECHADO',
  Processing = 'PROCESSANDO',
}

export enum EOrderOrigin {
  App = 'APP',
  Site = 'SITE',
}

interface IOrder {
  amount: {
    billing: string;
    final: string;
  };
  companyName: string;
  customer: string;
  date: string;
  id: number;
  originEnd: 'SITE' | 'APP' | null;
  status: string;
}

export default IOrder;
