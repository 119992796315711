import Tables from 'styles/tables';

import { OrdersItemsHeader } from '../styles';

const ItemHeader = (): JSX.Element => {
  return (
    <OrdersItemsHeader>
      <Tables.ItemHeader center>ID</Tables.ItemHeader>
      <Tables.ItemHeader>Cliente</Tables.ItemHeader>
      <Tables.ItemHeader right>Vlr. Orçado</Tables.ItemHeader>
      <Tables.ItemHeader right>Vlr. Faturado</Tables.ItemHeader>
      <Tables.ItemHeader center>Data envio</Tables.ItemHeader>
      <Tables.ItemHeader center>Origem</Tables.ItemHeader>
      <Tables.ItemHeader>Status</Tables.ItemHeader>
    </OrdersItemsHeader>
  );
};

export default ItemHeader;
