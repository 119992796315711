import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EListTypes from 'enums/listType';

import { ISettingInitialData } from '../../index';

interface IPurchasesSuccessAction {
  block: {
    creditLimit: boolean;
    drugList: boolean;
    situation: boolean;
  };
  product: {
    productsViewMode: EListTypes;
    sellWithoutStock: boolean;
    showExpirationWarning: boolean;
    showStockQuantity: boolean;
    showWithoutStock: boolean;
  };
}

function purchasesSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IPurchasesSuccessAction>,
) {
  draft.purchases.config.isLoading = false;
  draft.data.purchase = {
    blockCredit: action.payload.block.creditLimit,
    blockDrugList: action.payload.block.drugList,
    blockSituation: action.payload.block.situation,
  };
  draft.data.products = {
    sellOutOfStock: action.payload.product.sellWithoutStock,
    showExpiryWarning: action.payload.product.showExpirationWarning,
    showStockQuantity: action.payload.product.showStockQuantity,
    showWithoutStock: action.payload.product.showWithoutStock,
    productsViewMode: action.payload.product.productsViewMode,
  };
}

export default purchasesSuccess;
