import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IBanner from 'models/IBanner';
import { EListTypeView } from 'models/IList';
import IListItem from 'models/IListItem';
import ISettings from 'models/ISettings';
import { formatters } from 'racsys-js-tools';

import { IAuthInitialData } from '../../index';

interface ISetProfileAction {
  banners: IBanner;
  id: number;
  lists: {
    active: boolean;
    id: number;
    items: IListItem[];
    position: number;
    quantityColumns: number;
    subtitle: string;
    title: string;
    typeView: string;
  }[];
  settings: ISettings;
}

function setProfile(
  draft: Draft<IAuthInitialData>,
  action: PayloadAction<ISetProfileAction>,
) {
  const list = action.payload.lists.map(list => {
    let label = 'Ambos';
    switch (list.typeView) {
      case EListTypeView.Authenticated:
        label = 'Autenticado';
        break;
      case EListTypeView.NotAuthenticated:
        label = 'Não autenticado';
        break;
      default:
        label = 'Ambos';
        break;
    }
    return {
      active: list.active,
      id: list.id,
      items: list.items,
      position: list.position,
      quantityColumns: list.quantityColumns,
      subtitle: list.subtitle,
      title: list.title,
      isCollapsed: true,
      typeView: {
        value: list.typeView,
        label,
      },
    };
  });
  draft.profile = {
    banners: {
      images: action.payload.banners.images,
      thumbnails: action.payload.banners.thumbnails,
      type: action.payload.banners.type,
    },
    id: action.payload.id,
    lists: list,
    settings: {
      internal: action.payload.settings.internal,
      address: action.payload.settings.address,
      columns: action.payload.settings.columns,
      company: action.payload.settings.company,
      design: action.payload.settings.design,
      filters: action.payload.settings.filters,
      meuCatalogo: action.payload.settings.meuCatalogo,
      others: action.payload.settings.others,
      pedcoins: action.payload.settings.pedcoins,
      products: action.payload.settings.products,
      purchase: action.payload.settings.purchase,
      shipping: action.payload.settings.shipping,
      socialMedia: {
        facebook: action.payload.settings.socialMedia?.facebook ?? '',
        instagram: action.payload.settings.socialMedia?.instagram ?? '',
        linkedin: action.payload.settings.socialMedia?.linkedin ?? '',
        whatsapp: action.payload.settings.socialMedia?.whatsapp
          ? formatters.phone(action.payload.settings.socialMedia.whatsapp, {
              defaultValue: '',
            })
          : '',
      },
    },
  };
}

export default setProfile;
