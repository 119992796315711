import ELinkType from 'enums/linkType';

const linkTypes = [
  {
    label: ELinkType.Product,
    value: 1,
  },
  {
    label: ELinkType.Kit,
    value: 2,
  },
  {
    label: ELinkType.Manufacturer,
    value: 3,
  },
  {
    label: ELinkType.WithoutAction,
    value: 4,
  },
];

export default linkTypes;
