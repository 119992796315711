import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface INonPositiveRequestAction {
  data: {
    dateEnd?: string;
    dateStart?: string;
    page: number;
    state?: string;
  };
}

function nonPositiveRequest(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<INonPositiveRequestAction>,
) {
  draft.nonPositive.data = [];
  draft.nonPositive.config.isLoading = true;
  draft.nonPositive.config.isError = false;
  draft.nonPositive.config.page = action.payload.data.page;
}

export default nonPositiveRequest;
