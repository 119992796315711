import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatterOrderStatus from 'formatters/orderStatus';
import { EOrderStatus } from 'models/IOrder';
import { formatters } from 'racsys-js-tools';

import { IOrderInitialData } from '../../index';

interface IListOrderSuccessAction {
  list: {
    amount: {
      billing: number;
      final: number;
    };
    companyName: string;
    customer: string;
    date: string;
    id: number;
    originEnd: 'SITE' | 'APP' | null;
    status: EOrderStatus;
  }[];
  totalPages: number;
}

function listOrderSuccess(
  draft: Draft<IOrderInitialData>,
  action: PayloadAction<IListOrderSuccessAction>,
) {
  draft.list.config.isLoading = false;
  draft.list.config.pagination.totalPages = action.payload.totalPages;
  draft.list.data = action.payload.list.map(item => ({
    amount: {
      billing: item.amount.billing.toFixed(2).toString().replace('.', ','),
      final: item.amount.final.toFixed(2).toString().replace('.', ','),
    },
    customer: item.customer,
    companyName: item.companyName,
    date: formatters.date(item.date),
    originEnd: item.originEnd,
    id: item.id,
    status: formatterOrderStatus(item.status),
  }));
}

export default listOrderSuccess;
