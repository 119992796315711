import { useCallback, useEffect } from 'react';

import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentPaginate from 'components/utils/Paginate';

import colors from 'styles/colors';

import CustomerPedcoinsLoading from '../Loading';
import { Content } from '../styles';
import ExtractCard from './Card';
import PedcoinsExtractItem from './Item';
import { ExtractCards, Warning, WarningMessage } from './styles';

interface ICustomerPedcoinsExtractProps {
  customerId: number;
}

function CustomerPedcoinsExtract({
  customerId,
}: ICustomerPedcoinsExtractProps): JSX.Element {
  const reduxDispatch = useReduxDispatch();

  const extractLoading = useReduxSelector(
    customerSelectors.pedcoinsExtractIsLoading,
  );
  const summaryAvailable = useReduxSelector(
    customerSelectors.pedcoinsSummaryAvailable,
  );
  const summaryEntries = useReduxSelector(
    customerSelectors.pedcoinsSummaryEntries,
  );
  const summaryExpiresToday = useReduxSelector(
    customerSelectors.pedcoinsSummaryExpiresToday,
  );
  const summaryOutputs = useReduxSelector(
    customerSelectors.pedcoinsSummaryOutputs,
  );
  const summaryLoading = useReduxSelector(
    customerSelectors.pedcoinsSummaryIsLoading,
  );
  const extractList = useReduxSelector(customerSelectors.pedcoinsExtract);
  const isError = useReduxSelector(customerSelectors.pedcoinsExtractIsError);
  const page = useReduxSelector(customerSelectors.pedcoinsExtractPage);
  const totalPages = useReduxSelector(
    customerSelectors.pedcoinsExtractTotalPages,
  );

  const pedcoins = useReduxSelector(settingSelectors.pedcoins);

  const loadPedcoinsSummary = useCallback(() => {
    reduxDispatch(
      customerActions.pedcoinsSummaryRequest({
        data: {
          customerId,
        },
      }),
    );
  }, [customerId, reduxDispatch]);

  const loadPedcoinsExtract = useCallback(() => {
    reduxDispatch(
      customerActions.pedcoinsExtractRequest({
        data: {
          customerId,
          page: 1,
        },
      }),
    );
  }, [customerId, reduxDispatch]);

  const handlePagination = useCallback(
    (page: number) => {
      reduxDispatch(
        customerActions.pedcoinsExtractRequest({
          data: {
            customerId,
            page,
          },
        }),
      );
    },
    [customerId, reduxDispatch],
  );

  useEffect(() => {
    loadPedcoinsSummary();
  }, [loadPedcoinsSummary]);

  useEffect(() => {
    loadPedcoinsExtract();
  }, [loadPedcoinsExtract]);

  return (
    <Content>
      <ExtractCards>
        <ExtractCard
          icon={FiArrowUpCircle}
          iconColor={colors.green}
          isLoading={summaryLoading}
          points={summaryEntries}
          title="Entradas"
        />
        <ExtractCard
          icon={FiArrowDownCircle}
          iconColor={colors.red}
          isLoading={summaryLoading}
          points={summaryOutputs}
          title="Saídas"
        />
        <ExtractCard
          isLoading={summaryLoading}
          pedcoinsIcon
          points={summaryAvailable}
          title="Total"
          total
        />
        <Warning>
          Aviso:
          <WarningMessage>
            {`${summaryExpiresToday} ${pedcoins.name} expiram nos próximos 30 dias`}
          </WarningMessage>
        </Warning>
      </ExtractCards>

      <ComponentIsVisible when={!extractLoading}>
        <ComponentIsVisible when={!!extractList.length && !isError}>
          <PedcoinsExtractItem.Header />

          {extractList.map(extractItem => (
            <PedcoinsExtractItem.Body
              extractItem={extractItem}
              key={extractItem.id}
            />
          ))}
        </ComponentIsVisible>

        <ComponentEmpty
          message="Não existem entradas e/ou saídas"
          show={!extractList.length && !isError}
        />

        <ComponentIsVisible when={isError}>
          <ComponentError
            message="Não foi possível carregar o seu extrato"
            onClick={loadPedcoinsExtract}
          />
        </ComponentIsVisible>
      </ComponentIsVisible>
      <ComponentIsVisible when={extractLoading}>
        <CustomerPedcoinsLoading show={extractLoading} />
      </ComponentIsVisible>

      <ComponentPaginate
        currentPage={page}
        onPage={handlePagination}
        show={!!extractList.length}
        totalPages={totalPages}
      />
    </Content>
  );
}

export default CustomerPedcoinsExtract;
