import styled from 'styled-components';

import Tables from 'styles/tables';

export const ProductItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 1fr 0.5fr 8rem;
  padding: 1.25rem 1rem;
`;

export const ProductItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 1fr 0.5fr 8rem;
  padding: 1rem;
`;
