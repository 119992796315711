import coinsPerRealFailure from './coinsPerReal/failure';
import coinsPerRealRequest from './coinsPerReal/request';
import coinsPerRealSuccess from './coinsPerReal/success';
import detailsFailure from './details/failure';
import detailsRequest from './details/request';
import detailsSuccess from './details/success';
import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccess from './getAll/success';
import selectFailure from './select/failure';
import selectRequest from './select/request';
import selectSuccess from './select/success';

const productReducers = {
  coinsPerRealFailure,
  coinsPerRealRequest,
  coinsPerRealSuccess,
  detailsFailure,
  detailsRequest,
  detailsSuccess,
  getAllFailure,
  getAllRequest,
  getAllSuccess,
  selectFailure,
  selectRequest,
  selectSuccess,
};

export default productReducers;
