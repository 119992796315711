import { useCallback, useEffect, useRef } from 'react';

import { FormikProps } from 'formik';
import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import CustomerNonPositiveFilter, {
  ICustomerNonPositiveFilterFormData,
} from './Filter';
import CustomerNonPositiveItem from './Item';
import { FilterContainer } from './styles';

const CustomersNonPositive: React.FC = () => {
  const reduxDispatch = useReduxDispatch();
  const nonPositiveCustomersFilterRef =
    useRef<FormikProps<ICustomerNonPositiveFilterFormData>>(null);

  const customersNonPositiveLoading = useReduxSelector(
    customerSelectors.nonPositiveIsLoading,
  );
  const customersNonPositivePdfLoading = useReduxSelector(
    customerSelectors.nonPositivePdfIsLoading,
  );
  const customersNonPositiveSpreadsheetLoading = useReduxSelector(
    customerSelectors.nonPositiveSpreadsheetIsLoading,
  );
  const customersNonPositive = useReduxSelector(customerSelectors.nonPositive);
  const customerNonPositivePage = useReduxSelector(
    customerSelectors.nonPositivePage,
  );
  const customerNonPositiveTotalPages = useReduxSelector(
    customerSelectors.nonPositiveTotalPages,
  );
  const isError = useReduxSelector(customerSelectors.nonPositiveIsError);

  const loadNonPositiveCustomers = useCallback(() => {
    reduxDispatch(
      customerActions.nonPositiveRequest({
        data: {
          page: 1,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleOnClear = useCallback(() => {
    nonPositiveCustomersFilterRef.current?.resetForm();
    reduxDispatch(
      customerActions.nonPositiveRequest({
        data: {
          page: 1,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleNonPositiveCustomersPagination = useCallback(
    (page: number) => {
      const dateEnd = nonPositiveCustomersFilterRef.current?.values.dateEnd;
      const dateStart = nonPositiveCustomersFilterRef.current?.values.dateStart;
      const state = nonPositiveCustomersFilterRef.current?.values.state;
      reduxDispatch(
        customerActions.nonPositiveRequest({
          data: {
            page,
            dateEnd,
            dateStart,
            state: state?.value,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilterNonPositiveCustomers = useCallback(
    (data: ICustomerNonPositiveFilterFormData) => {
      reduxDispatch(
        customerActions.nonPositiveRequest({
          data: {
            page: 1,
            dateEnd: data.dateEnd,
            dateStart: data.dateStart,
            state: data.state?.value,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleGeneratePdf = useCallback(() => {
    const dateEnd = nonPositiveCustomersFilterRef.current?.values.dateEnd;
    const dateStart = nonPositiveCustomersFilterRef.current?.values.dateStart;
    const state = nonPositiveCustomersFilterRef.current?.values.state;
    reduxDispatch(
      customerActions.nonPositivePdfRequest({
        data: {
          dateEnd,
          dateStart,
          state: state?.value,
        },
      }),
    );
  }, [reduxDispatch]);

  const handleGenerateSpreadsheet = useCallback(() => {
    const dateEnd = nonPositiveCustomersFilterRef.current?.values.dateEnd;
    const dateStart = nonPositiveCustomersFilterRef.current?.values.dateStart;
    const state = nonPositiveCustomersFilterRef.current?.values.state;
    reduxDispatch(
      customerActions.nonPositiveSpreadsheetRequest({
        data: {
          dateEnd,
          dateStart,
          state: state?.value,
        },
      }),
    );
  }, [reduxDispatch]);

  useEffect(() => {
    loadNonPositiveCustomers();
  }, [loadNonPositiveCustomers]);

  return (
    <Containers.Global>
      <General.PageTitle>Clientes não positivados</General.PageTitle>

      <FilterContainer>
        <CustomerNonPositiveFilter
          isLoading={customersNonPositiveLoading}
          onClear={handleOnClear}
          onSubmit={handleFilterNonPositiveCustomers}
          ref={nonPositiveCustomersFilterRef}
        />
        <ComponentButtonBase
          disabled={customersNonPositiveSpreadsheetLoading}
          isLoading={customersNonPositiveSpreadsheetLoading}
          onClick={handleGenerateSpreadsheet}
          title="Gerar Planilha"
        >
          Gerar Planilha
        </ComponentButtonBase>
        <ComponentButtonBase
          disabled={customersNonPositivePdfLoading}
          isLoading={customersNonPositivePdfLoading}
          onClick={handleGeneratePdf}
          title="Gerar PDF"
        >
          Gerar PDF
        </ComponentButtonBase>
      </FilterContainer>

      <Tables.Items hasBorder marginTop=".5rem">
        <CustomerNonPositiveItem.Header />

        <ComponentIsVisible when={!customersNonPositiveLoading}>
          <ComponentIsVisible
            when={!loadNonPositiveCustomers.length && !isError}
          >
            {customersNonPositive.map((customer, position) => (
              <CustomerNonPositiveItem.Body
                customer={customer}
                key={customer.id}
                position={position}
              />
            ))}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há clientes para serem exibidos"
            show={!customersNonPositive.length && !isError}
          />

          <ComponentIsVisible when={isError}>
            <ComponentError
              message="Não foi possível carregar os clientes"
              onClick={loadNonPositiveCustomers}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
        <ComponentLoadingList show={customersNonPositiveLoading} />
      </Tables.Items>
      <ComponentPaginate
        currentPage={customerNonPositivePage}
        onPage={handleNonPositiveCustomersPagination}
        show={!!customersNonPositive.length}
        totalPages={customerNonPositiveTotalPages}
      />
    </Containers.Global>
  );
};

export default CustomersNonPositive;
