import { PayloadAction } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { formatters } from 'racsys-js-tools';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsContactRequest from 'services/httpClient/request/settings/IPutSettingsContactRequest';

import { ICompanyRequestAction } from '../reducers/company/request';

import { settingActions } from '..';

function* company(action: PayloadAction<ICompanyRequestAction>) {
  try {
    const phoneWithoutMask = formatters.removeMask(action.payload.data.phone);
    const body: IPutSettingsContactRequest = {
      email: action.payload.data.email,
      phone: phoneWithoutMask,
      corporateName: action.payload.data.corporateName,
    };
    yield call(httpClient.put, 'admin/settings/contacts', body);
    ToastManager.show({
      title: 'Dados gerais da empresa alterados com sucesso!',
      type: 'success',
    });
    yield put(
      settingActions.companySuccess({
        corporateName: action.payload.data.corporateName,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.companyFailure());
  }
}

export default company;
