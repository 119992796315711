import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import {
  CustomerPedcoinsItemHeader,
  CustomerPedcoinsItemsHeader,
} from 'pages/Customers/Pedcoins/styles';

function RedeemItemHeader(): JSX.Element {
  const pedcoins = useReduxSelector(settingSelectors.pedcoins);
  return (
    <CustomerPedcoinsItemsHeader>
      <CustomerPedcoinsItemHeader>{pedcoins.name}</CustomerPedcoinsItemHeader>
      <CustomerPedcoinsItemHeader>NF Bônus</CustomerPedcoinsItemHeader>
      <CustomerPedcoinsItemHeader center>Data envio</CustomerPedcoinsItemHeader>
    </CustomerPedcoinsItemsHeader>
  );
}

export default RedeemItemHeader;
