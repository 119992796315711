import styled from 'styled-components';

import Tables from 'styles/tables';

export const PriceListItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 5rem 10rem 1fr 6rem 12rem 8rem 6rem;
  padding: 1.25rem 1rem;
`;

export const PriceListItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 5rem 10rem 1fr 6rem 12rem 8rem 6rem;
  padding: 1rem;
`;
