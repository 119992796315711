import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IShippingRequestAction {
  data: {
    timeLimitMessage: string;
  };
}

function shippingRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IShippingRequestAction>,
) {
  draft.shipping.config.isLoading = true;
}

export default shippingRequest;
