import { all, takeLatest } from 'redux-saga/effects';

import { settingActions } from '..';

import address from './address';
import columns from './columns';
import company from './company';
import contacts from './contacts';
import externalScript from './externalScript';
import favicon from './favicon';
import filters from './filters';
import homeControl from './homeControl';
import internalControl from './internalControl';
import logo from './logos';
import maintenance from './maintenance';
import meuCatalogo from './meuCatalogo';
import pedcoins from './pedcoins';
import productsViewMode from './productViewMode';
import purchases from './purchases';
import shipping from './shipping';
import socialMedia from './socialMedia';
import terms from './terms';
import termsControl from './termsControl';
import themes from './themes';

const settingSagas = all([
  takeLatest(settingActions.addressRequest, address),
  takeLatest(settingActions.columnsRequest, columns),
  takeLatest(settingActions.companyRequest, company),
  takeLatest(settingActions.contactsRequest, contacts),
  takeLatest(settingActions.externalScriptRequest, externalScript),
  takeLatest(settingActions.faviconRequest, favicon),
  takeLatest(settingActions.filtersRequest, filters),
  takeLatest(settingActions.homeControlRequest, homeControl),
  takeLatest(settingActions.logoRequest, logo),
  takeLatest(settingActions.meuCatalogoRequest, meuCatalogo),
  takeLatest(settingActions.pedcoinsRequest, pedcoins),
  takeLatest(settingActions.purchasesRequest, purchases),
  takeLatest(settingActions.shippingRequest, shipping),
  takeLatest(settingActions.socialMediaRequest, socialMedia),
  takeLatest(settingActions.termsRequest, terms),
  takeLatest(settingActions.termsControlRequest, termsControl),
  takeLatest(settingActions.themesRequest, themes),
  takeLatest(settingActions.internalControlRequest, internalControl),
  takeLatest(settingActions.maintenanceRequest, maintenance),
  takeLatest(settingActions.productsViewModeRequest, productsViewMode),
]);

export default settingSagas;
