import { useCallback, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { FormikProps } from 'formik';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import pages from 'constants/pages';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';
import ComponentPaginate from 'components/utils/Paginate';

import Containers from 'styles/containers';
import General from 'styles/general';
import Tables from 'styles/tables';

import ProductFilter, { IProductFilterFormData } from './Filter';
import ProductItem from './Item';
import Pedcoins, { IPedcoin, IPedcoinsRefProps } from './Pedcoin';
import { ButtonContainer } from './styles';

const ProductList = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();
  const navigate = useNavigate();
  const pedcoinRef = useRef<IPedcoinsRefProps>(null);
  const productFilterRef = useRef<FormikProps<IProductFilterFormData>>(null);

  const isLoading = useReduxSelector(productSelectors.getAllIsLoading);
  const products = useReduxSelector(productSelectors.getAll);
  const productPage = useReduxSelector(productSelectors.getAllPage);
  const productTotalPages = useReduxSelector(productSelectors.getAllTotalPages);
  const isError = useReduxSelector(productSelectors.getAllIsError);

  const loadProducts = useCallback(() => {
    reduxDispatch(productActions.getAllRequest({ data: { page: 1 } }));
  }, [reduxDispatch]);

  const handlePagination = useCallback(
    (page: number) => {
      const id = productFilterRef.current?.values.id;
      const name = productFilterRef.current?.values.name;
      const sku = productFilterRef.current?.values.sku;
      const status = productFilterRef.current?.values.status;
      const manufacturer = productFilterRef.current?.values.manufacturer;
      const barcode = productFilterRef.current?.values.barcode;
      reduxDispatch(
        productActions.getAllRequest({
          data: {
            barcode,
            page,
            id,
            name,
            sku,
            manufacturer,
            status: status?.value,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleFilterProducts = useCallback(
    (data: IProductFilterFormData) => {
      reduxDispatch(
        productActions.getAllRequest({
          data: {
            page: 1,
            id: data.id,
            name: data.name,
            sku: data.sku,
            status: data.status?.value,
            barcode: data.barcode,
            manufacturer: data.manufacturer,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnClear = useCallback(() => {
    productFilterRef.current?.resetForm();
    reduxDispatch(productActions.getAllRequest({ data: { page: 1 } }));
  }, [reduxDispatch]);

  const handleOpenDetails = useCallback(
    (id: number) => {
      navigate(pages.product.details(id));
    },
    [navigate],
  );

  const openPedcoin = useCallback((data: IPedcoin) => {
    pedcoinRef.current?.open(data);
  }, []);

  const handleOpenProductsRequest = useCallback(() => {
    navigate(pages.product.request);
  }, [navigate]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle>Produtos</General.PageTitle>
      </General.PageTitleContainer>
      <ButtonContainer>
        <ComponentButtonBase onClick={handleOpenProductsRequest} width="10rem">
          Produtos solicitados
        </ComponentButtonBase>
      </ButtonContainer>

      <ProductFilter
        isLoading={isLoading}
        onClear={handleOnClear}
        onSubmit={handleFilterProducts}
        ref={productFilterRef}
      />

      <Tables.Items hasBorder marginTop=".5rem">
        <ComponentIsVisible when={!isLoading}>
          <ComponentIsVisible when={!!products.length && !isError}>
            <ProductItem.Header />

            {products.map((product, position) => (
              <ProductItem.Body
                key={product.id}
                openDetails={() => handleOpenDetails(product.id)}
                openPedcoin={openPedcoin}
                position={position}
                product={product}
              />
            ))}
          </ComponentIsVisible>

          <ComponentEmpty
            message="Não há produtos para serem exibidos"
            show={!products.length && !isError}
          />

          <ComponentIsVisible when={isError}>
            <ComponentError
              message="Não foi possível carregar os produtos"
              onClick={loadProducts}
            />
          </ComponentIsVisible>
        </ComponentIsVisible>
        <ComponentIsVisible when={isLoading}>
          <ComponentLoadingList rows={8} show={isLoading} />
        </ComponentIsVisible>
      </Tables.Items>
      <ComponentIsVisible when={!isLoading}>
        <ComponentPaginate
          currentPage={productPage}
          onPage={handlePagination}
          show={!!products.length}
          totalPages={productTotalPages}
        />
      </ComponentIsVisible>

      <Pedcoins ref={pedcoinRef} />
    </Containers.Global>
  );
};

export default ProductList;
