import { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const disappear = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const shimmer = keyframes`
  from {
    background-position: -1000px 0;
  }

  to {
    background-position: 1000px 0;
  }
`;

export default {
  appear,
  disappear,
  shimmer,
};
