import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormikProps } from 'formik';
import IList from 'models/IList';
import { listActions } from 'store/slices/list';

import { useReduxDispatch } from 'hooks/useReduxDispatch';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';

import ListConfigForm, { IListConfigFormData } from '../Form';
import { Content, Title } from '../styles';

export interface IListConfigEditRefProps {
  open: (list: IList) => void;
}

const ListConfigEdit: React.ForwardRefRenderFunction<
  IListConfigEditRefProps
> = (_, ref) => {
  const reduxDispatch = useReduxDispatch();
  const [list, setList] = useState<IList>({} as IList);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);
  const listConfigFormRef = useRef<FormikProps<IListConfigFormData>>(null);

  const openModal = useCallback((data: IList) => {
    setList(data);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleUpdateList = useCallback(
    (data: IListConfigFormData) => {
      reduxDispatch(
        listActions.updateRequest({
          data: {
            quantityColumns: data.quantityColumns,
            subtitle: data.subtitle,
            title: data.title,
            active: data.active,
            id: list.id,
            typeView: data.typeView,
            position: list.position,
            isCollapsed: list.isCollapsed,
          },
          functions: {
            success() {
              closeModal();
            },
          },
        }),
      );
    },
    [closeModal, reduxDispatch, list.id, list.isCollapsed, list.position],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Editar configurações da listagem</Title>
        <ListConfigForm
          closeModal={closeModal}
          initialValues={{
            active: list.active,
            quantityColumns: list.quantityColumns,
            subtitle: list.subtitle,
            title: list.title,
            typeView: list.typeView,
          }}
          onSubmit={handleUpdateList}
          ref={listConfigFormRef}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ListConfigEdit);
