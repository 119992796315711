import styled from 'styled-components';

import Tables from 'styles/tables';

export const ManufacturerProductItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 6rem 1fr repeat(2, 12rem) repeat(2, 8rem);
  padding: 1.25rem 1rem;
`;

export const ManufacturerProductItemsBody = styled(Tables.ItemsBody)`
  grid-template-columns: 6rem 1fr repeat(2, 12rem) repeat(2, 8rem);
  padding: 1rem;
`;
