import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IHomeControlSuccessAction {
  showHome: boolean;
}

function homeControlSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IHomeControlSuccessAction>,
) {
  draft.homeControl.config.isLoading = false;
  draft.data.others.showHome = action.payload.showHome;
}

export default homeControlSuccess;
