import { all, takeLatest } from 'redux-saga/effects';

import { orderActions } from '../index';
import listOrder from './list';
import pdf from './pdf';
import spreadsheet from './spreadsheet';

const orderSagas = all([
  takeLatest(orderActions.listRequest, listOrder),
  takeLatest(orderActions.pdfOrderRequest, pdf),
  takeLatest(orderActions.spreadsheetOrderRequest, spreadsheet),
]);

export default orderSagas;
