import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsExternalScripsRequest from 'services/httpClient/request/settings/IPutSettingsExternalScripsRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IExternalScriptRequestAction } from '../reducers/externalScript/request';

import { settingActions } from '..';

function* externalScript(action: PayloadAction<IExternalScriptRequestAction>) {
  try {
    const body: IPutSettingsExternalScripsRequest = {
      externalScript: action.payload.data.externalScript,
    };
    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/external-scripts',
      body,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });

    yield put(
      settingActions.externalScriptSuccess({
        externalScript: action.payload.data.externalScript,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.externalScriptFailure());
  }
}

export default externalScript;
