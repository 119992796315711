import styled from 'styled-components';

import Tables from 'styles/tables';

export const PriceTableHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: repeat(4, 1fr);
  padding: 1.25rem 1rem;
`;

export const PriceTableBody = styled(Tables.ItemsBody)`
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
`;
