import { useCallback, useEffect, useState } from 'react';

import { ClipLoader } from 'react-spinners';

import dashboardSelectors from 'store/slices/dashboard/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  BodyBillingAmount,
  Card,
  ContainerBody,
  ContainerLoading,
  Item,
  ItemsHeader,
  ItemText,
  Main,
} from './styles';

enum ETitleType {
  Daily = 'Diário',
  Monthly = 'Mensal',
  Semiannual = 'Semestral',
  Weekly = 'Semanal',
  Yearly = 'Anual',
}

const BillingAmount = (): JSX.Element => {
  const [title, setTitle] = useState<ETitleType>(ETitleType.Daily);

  const billingDayAmount = useReduxSelector(
    dashboardSelectors.billingDayAmount,
  );
  const billingWeeklyAmount = useReduxSelector(
    dashboardSelectors.billingWeeklyAmount,
  );
  const billingMonthlyAmount = useReduxSelector(
    dashboardSelectors.billingMonthlyAmount,
  );
  const billingSemiannualAmount = useReduxSelector(
    dashboardSelectors.billingSemiannualAmount,
  );
  const billingYearlyAmount = useReduxSelector(
    dashboardSelectors.billingYearlyAmount,
  );
  const billingDayIsLoading = useReduxSelector(
    dashboardSelectors.billingDayIsLoading,
  );
  const billingWeeklyIsLoading = useReduxSelector(
    dashboardSelectors.billingWeeklyIsLoading,
  );
  const billingMonthlyIsLoading = useReduxSelector(
    dashboardSelectors.billingMonthlyIsLoading,
  );
  const billingSemiannualIsLoading = useReduxSelector(
    dashboardSelectors.billingSemiannualIsLoading,
  );
  const billingYearlyIsLoading = useReduxSelector(
    dashboardSelectors.billingYearlyIsLoading,
  );

  const handleSetTitle = useCallback((title: ETitleType) => {
    setTitle(title);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      if (title === ETitleType.Daily) {
        setTitle(ETitleType.Weekly);
      }
      if (title === ETitleType.Weekly) {
        setTitle(ETitleType.Monthly);
      }
      if (title === ETitleType.Monthly) {
        setTitle(ETitleType.Semiannual);
      }
      if (title === ETitleType.Semiannual) {
        setTitle(ETitleType.Yearly);
      }
      if (title === ETitleType.Yearly) {
        setTitle(ETitleType.Daily);
      }
    }, 10000);
    return () => {
      clearInterval(id);
    };
  }, [title]);

  return (
    <Card>
      <Main>
        <ItemsHeader>
          <Item
            isFocused={title === ETitleType.Daily}
            onClick={() => {
              handleSetTitle(ETitleType.Daily);
            }}
          >
            <ItemText isFocused={title === ETitleType.Daily}>Diário</ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Weekly}
            onClick={() => {
              handleSetTitle(ETitleType.Weekly);
            }}
          >
            <ItemText isFocused={title === ETitleType.Weekly}>Semanal</ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Monthly}
            onClick={() => {
              handleSetTitle(ETitleType.Monthly);
            }}
          >
            <ItemText isFocused={title === ETitleType.Monthly}>Mensal</ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Semiannual}
            onClick={() => {
              handleSetTitle(ETitleType.Semiannual);
            }}
          >
            <ItemText isFocused={title === ETitleType.Semiannual}>
              Semestral
            </ItemText>
          </Item>
          <Item
            isFocused={title === ETitleType.Yearly}
            onClick={() => {
              handleSetTitle(ETitleType.Yearly);
            }}
          >
            <ItemText isFocused={title === ETitleType.Yearly}>Anual</ItemText>
          </Item>
        </ItemsHeader>

        <ContainerBody>
          <ComponentIsVisible when={title === ETitleType.Daily}>
            <ComponentIsVisible when={!billingDayIsLoading}>
              <BodyBillingAmount>{title}</BodyBillingAmount>
              <BodyBillingAmount>{billingDayAmount}</BodyBillingAmount>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingDayIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Weekly}>
            <ComponentIsVisible when={!billingWeeklyIsLoading}>
              <BodyBillingAmount>{title}</BodyBillingAmount>
              <BodyBillingAmount>{billingWeeklyAmount}</BodyBillingAmount>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingWeeklyIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Monthly}>
            <ComponentIsVisible when={!billingMonthlyIsLoading}>
              <BodyBillingAmount>{title}</BodyBillingAmount>
              <BodyBillingAmount>{billingMonthlyAmount}</BodyBillingAmount>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingMonthlyIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Semiannual}>
            <ComponentIsVisible when={!billingSemiannualIsLoading}>
              <BodyBillingAmount>{title}</BodyBillingAmount>
              <BodyBillingAmount>{billingSemiannualAmount}</BodyBillingAmount>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingSemiannualIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>

          <ComponentIsVisible when={title === ETitleType.Yearly}>
            <ComponentIsVisible when={!billingYearlyIsLoading}>
              <BodyBillingAmount>{title}</BodyBillingAmount>
              <BodyBillingAmount>{billingYearlyAmount}</BodyBillingAmount>
            </ComponentIsVisible>
            <ComponentIsVisible when={billingYearlyIsLoading}>
              <ContainerLoading>
                <ClipLoader color={colors.blue} size={40} />
              </ContainerLoading>
            </ComponentIsVisible>
          </ComponentIsVisible>
        </ContainerBody>
      </Main>
    </Card>
  );
};

export default BillingAmount;
