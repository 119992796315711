import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IMaintenanceRequestAction {
  data: {
    isMaintenance: boolean;
  };
}

function maintenanceRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IMaintenanceRequestAction>,
) {
  draft.isMaintenance.config.isLoading = true;
}

export default maintenanceRequest;
