import Containers from 'styles/containers';
import General from 'styles/general';

import ColorControl from './Color';
import ColumnsControl from './Columns';
import CompanyFavicon from './Favicon';
import FiltersControl from './Filters';
import HomeControl from './HomeControl';
import InternalControl from './InternalControl';
import CompanyLogo from './Logo';
import MaintenanceControl from './Maintenance';
import MeuCatalogoCredentials from './MeuCatalogo';
import PedcoinsRules from './Pedcoins';
import ProductViewMode from './ProductViewMode';
import PurchasesControl from './Purchases';
import ScriptsControl from './Scripts';
import ShippingTimeLimit from './ShippingTimeLimit';
import { Content } from './styles';

const Settings: React.FC = () => {
  return (
    <Containers.Global>
      <General.PageTitle>Configurações gerais</General.PageTitle>

      <Content>
        <ColumnsControl />
        <FiltersControl />
        <ColorControl />
        <CompanyLogo />
        <CompanyFavicon />
        <PurchasesControl />
        <PedcoinsRules />
        <MeuCatalogoCredentials />
        <ScriptsControl />
        <ShippingTimeLimit />
        <HomeControl />
        <InternalControl />
        <MaintenanceControl />
        <ProductViewMode />
      </Content>
    </Containers.Global>
  );
};

export default Settings;
