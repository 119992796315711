import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EImageType from 'enums/imageType';

import { IListInitialData } from '../../index';

export interface IUpdateItemRequestAction {
  data: {
    id: number;
    image?: {
      file: Blob;
      uploadType: EImageType;
    };
    link: {
      priceListId?: number;
      type: string;
      value: number;
    };
    listId: number;
    url: string;
  };
  functions: {
    success: () => void;
  };
}

function updateItemRequest(
  draft: Draft<IListInitialData>,
  _: PayloadAction<IUpdateItemRequestAction>,
) {
  draft.updateItem.config.isLoading = true;
}

export default updateItemRequest;
