import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IDashboardInitialData } from '../../index';

export interface IOrderRequestAction {
  data: {
    billing: number;
    cancellation: number;
    closing: number;
    opening: number;
    processing: number;
  };
}

function orderSuccess(
  draft: Draft<IDashboardInitialData>,
  action: PayloadAction<IOrderRequestAction>,
) {
  draft.order.config.isLoading = false;
  draft.order.values.billing = action.payload.data.billing;
  draft.order.values.cancellation = action.payload.data.cancellation;
  draft.order.values.closing = action.payload.data.closing;
  draft.order.values.opening = action.payload.data.opening;
  draft.order.values.processing = action.payload.data.processing;
}

export default orderSuccess;
