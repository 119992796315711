import { InputHTMLAttributes, useRef } from 'react';

import { Checkbox, Container, Input, Mark } from './styles';

interface ICheckboxInputProps
  extends Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'disabled'> {
  isChecked?: boolean;
  label: string;
  name: string;
}

const ComponentInputCheckbox: React.FC<ICheckboxInputProps> = ({
  isChecked = false,
  label,
  name,
  ...rest
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  return (
    <Container>
      <Checkbox>
        <Input
          checked={isChecked}
          className="inputCheckbox"
          name={name}
          ref={checkboxRef}
          type="checkbox"
          {...rest}
        />
        <Mark className="checkmark" />
      </Checkbox>
      {label}
    </Container>
  );
};

export default ComponentInputCheckbox;
