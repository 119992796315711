import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IProductRequestInitialData } from '../../index';

export interface IGetAllRequestAction {
  data: {
    list: {
      customer: string;
      date: string;
      id: number;
      product: string;
    }[];
    totalPages: number;
  };
}

function getAllSuccess(
  draft: Draft<IProductRequestInitialData>,
  action: PayloadAction<IGetAllRequestAction>,
) {
  draft.getAll.config.isLoading = false;
  draft.getAll.data = action.payload.data.list.map(item => ({
    customer: item.customer,
    date: formatters.date(item.date),
    id: item.id,
    product: item.product,
  }));
  draft.getAll.config.totalPages = action.payload.data.totalPages;
}

export default getAllSuccess;
