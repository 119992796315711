import { Draft, PayloadAction } from '@reduxjs/toolkit';
import ELaunchPedcoinsType from 'enums/launchPedcoinsType';

import { ICustomerInitialData } from '../../index';

export interface ILaunchPedcoinsRequestAction {
  data: {
    customerId: number;
    quantity: string;
    reason: string;
    type: ELaunchPedcoinsType;
  };
  functions: {
    success: () => void;
  };
}

function launchPedcoinsRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<ILaunchPedcoinsRequestAction>,
) {
  draft.launchPedcoins.config.isLoading = true;
}

export default launchPedcoinsRequest;
