import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IProductInitialData } from '../../index';

export interface ISelectSuccessAction {
  data: {
    list: {
      name: string;
      sku: string;
    }[];
    page?: number;
  };
}

function selectSuccess(
  draft: Draft<IProductInitialData>,
  action: PayloadAction<ISelectSuccessAction>,
) {
  const list = action.payload.data.list.map(listItem => ({
    label: listItem.name,
    value: listItem.sku,
  }));
  draft.select.data = list;
  draft.select.config.page = action.payload.data.page;
  draft.select.config.isLoading = false;
}

export default selectSuccess;
