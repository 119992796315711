import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

export interface ISelectSuccessAction {
  data: {
    list: {
      id: number;
      name: string;
    }[];
  };
}

function selectSuccess(
  draft: Draft<IPriceListInitialData>,
  action: PayloadAction<ISelectSuccessAction>,
) {
  const listParsed = action.payload.data.list.map(listItem => ({
    label: listItem.name,
    value: listItem.id,
  }));
  draft.select.data = listParsed;
  draft.select.config.isLoading = false;
}

export default selectSuccess;
