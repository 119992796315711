import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface IFeedbackData {
  createdAt: string;
  customer: {
    id: number;
    name: string;
  };
  id: number;
  message: string;
  rating: number;
}

export interface IFeedbackInitialData {
  getAll: {
    config: {
      isError: boolean;
      isLoading: boolean;
    };
    data: IFeedbackData[];
  };
}

const initialState: IFeedbackInitialData = {
  getAll: {
    config: {
      isError: false,
      isLoading: false,
    },
    data: [],
  },
};

const feedbacksSlice = createSlice({
  name: '@feedback',
  initialState,
  reducers,
});

export const feedbackActions = feedbacksSlice.actions;
export const feedbackReducers = feedbacksSlice.reducer;
