import formatters from 'formatters';
import { IProductPriceList } from 'store/slices/product';

import Tables from 'styles/tables';

import { PriceListItemsBody } from '../styles';

interface IItemBodyProps {
  position: number;
  priceList: IProductPriceList;
}

const formatQuantity = (quantity: string | number): string => {
  if (quantity === -1) {
    return 'Ilimitado';
  }
  if (quantity === 0) {
    return 'Não informado';
  }
  return quantity.toString();
};

const ItemBody = ({ position, priceList }: IItemBodyProps): JSX.Element => {
  const expirationDateFormatted = formatters.date(priceList.expirationDate);
  const quantityMinFormatted = formatQuantity(priceList.quantityMin);
  const quantityMaxFormatted = formatQuantity(priceList.quantityMax);

  return (
    <PriceListItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{priceList.id}</Tables.ItemBody>
      <Tables.ItemBody center>{priceList.sku}</Tables.ItemBody>
      <Tables.ItemBody>{priceList.name}</Tables.ItemBody>
      <Tables.ItemBody>{expirationDateFormatted}</Tables.ItemBody>
      <Tables.ItemBody>{quantityMinFormatted}</Tables.ItemBody>
      <Tables.ItemBody>{quantityMaxFormatted}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountGross}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountDiscount}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountNet}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountFee}</Tables.ItemBody>
      <Tables.ItemBody right>{priceList.amountFinal}</Tables.ItemBody>
    </PriceListItemsBody>
  );
};

export default ItemBody;
