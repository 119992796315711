import { Draft, PayloadAction } from '@reduxjs/toolkit';
import formatters from 'formatters';

import { IProductInitialData } from '../../index';

export interface IGetAllSuccessAction {
  data: {
    list: {
      active: boolean;
      barcode: string | null;
      coinsPerReal: number;
      id: number;
      manufacturer: string;
      name: string;
      sku: string;
    }[];
    totalPages: number;
  };
}

function getAllSuccess(
  draft: Draft<IProductInitialData>,
  action: PayloadAction<IGetAllSuccessAction>,
) {
  draft.details.data = null;
  draft.getAll.config.isLoading = false;
  draft.getAll.data = action.payload.data.list.map(item => ({
    barcode: item.barcode ? item.barcode : 'NÃO INFORMADO',
    coinsPerRealFormatted: formatters.money(
      Number(item.coinsPerReal.toFixed(2)),
    ),
    active: item.active,
    coinsPerReal: item.coinsPerReal,
    id: item.id,
    manufacturer: item.manufacturer,
    name: item.name,
    sku: item.sku,
  }));
  draft.getAll.config.totalPages = action.payload.data.totalPages;
}

export default getAllSuccess;
