import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EUploadImageType from 'enums/uploadImageType';
import { v4 as uuid } from 'uuid';

import { IBannerInitialData } from '../index';

interface IConcatRequestAction {
  data: {
    uploadType: EUploadImageType;
    url: string;
  };
}

function concat(
  draft: Draft<IBannerInitialData>,
  action: PayloadAction<IConcatRequestAction>,
) {
  const imageToConcat = {
    id: uuid(),
    url: action.payload.data.url,
  };
  if (action.payload.data.uploadType === EUploadImageType.thumbnail) {
    const newThumbnailImages = draft.thumbnails.concat(imageToConcat);
    draft.thumbnails = newThumbnailImages;
  } else {
    const newBannerImages = draft.images.concat(imageToConcat);
    draft.images = newBannerImages;
  }
}

export default concat;
