import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IPutSettingsMeuCatalogoRequest from 'services/httpClient/request/settings/IPutSettingsMeuCatalogoRequest';
import IHttpClientSuccess from 'services/httpClient/responses/default/IHttpClientSuccess';

import { IMeuCatalogoRequestAction } from '../reducers/meuCatalogo/request';

import { settingActions } from '..';

function* meuCatalogo(action: PayloadAction<IMeuCatalogoRequestAction>) {
  try {
    const body: IPutSettingsMeuCatalogoRequest = {
      email: action.payload.data.email,
      password: action.payload.data.password,
    };

    const {
      data: { message },
    }: AxiosResponse<IHttpClientSuccess> = yield call(
      httpClient.put,
      'admin/settings/meu-catalogo',
      body,
    );

    ToastManager.show({
      title: message,
      type: 'success',
    });

    yield put(
      settingActions.meuCatalogoSuccess({
        email: action.payload.data.email,
        password: action.payload.data.password,
      }),
    );
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(settingActions.meuCatalogoFailure());
  }
}

export default meuCatalogo;
