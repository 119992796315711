import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface IPedcoinsRequestAction {
  data: {
    expirationTermInMonths: string;
    isEnable: boolean;
    name: string;
    pointsPerReal: string;
  };
}

function pedcoinsRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<IPedcoinsRequestAction>,
) {
  draft.pedcoins.config.isLoading = true;
}

export default pedcoinsRequest;
