import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

export interface ITermsControlRequestAction {
  data: {
    showTerms: boolean;
  };
}

function termsControlRequest(
  draft: Draft<ISettingInitialData>,
  _: PayloadAction<ITermsControlRequestAction>,
) {
  draft.termsControl.config.isLoading = true;
}

export default termsControlRequest;
