import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IPedcoinsRedeemRequestAction {
  data: {
    customerId: number;
    page: number;
  };
}

function pedcoinsRedeemRequest(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<IPedcoinsRedeemRequestAction>,
) {
  draft.pedcoins.redeem.data = [];
  draft.pedcoins.redeem.config.isLoading = true;
  draft.pedcoins.redeem.config.isError = false;
  draft.pedcoins.redeem.config.page = action.payload.data.page;
}

export default pedcoinsRedeemRequest;
