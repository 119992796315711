import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import states from 'constants/states';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputDate from 'components/input/Date';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';

import colors from 'styles/colors';

import { FormikForm } from './styles';

const schema = Yup.object().shape(
  {
    dateEnd: Yup.date().when('dateStart', {
      is: (val: string) => !!val,
      then: Yup.date()
        .required('Informe uma data final')
        .min(
          Yup.ref('dateStart'),
          'Data final não pode ser inferior a data inicial',
        ),
      otherwise: Yup.date().nullable(),
    }),
    dateStart: Yup.date().when('dateEnd', {
      is: (val: string) => !!val,
      then: Yup.date().required('Informe uma data inicial'),
      otherwise: Yup.date().nullable(),
    }),
  },
  [['dateEnd', 'dateStart']],
);

export interface ICustomerNonPositiveFilterFormData {
  dateEnd: string;
  dateStart: string;
  state: {
    label: string;
    value: string;
  } | null;
}

interface ICustomerNonPositiveFilterProps {
  isLoading: boolean;
  onClear: () => void;
  onSubmit: (data: ICustomerNonPositiveFilterFormData) => void;
}

const CustomerNonPositiveFilter: React.ForwardRefRenderFunction<
  FormikProps<ICustomerNonPositiveFilterFormData>,
  ICustomerNonPositiveFilterProps
> = ({ isLoading, onClear, onSubmit }, ref) => {
  const filterFormRef = useRef<FormikProps<ICustomerNonPositiveFilterFormData>>(
    {} as FormikProps<ICustomerNonPositiveFilterFormData>,
  );

  const handleSelectChange = useCallback((option: IOptionTypeBase | null) => {
    filterFormRef.current?.setFieldValue(
      'state',
      option ?? {
        label: '',
        value: '',
      },
    );
  }, []);

  useImperativeHandle(ref, () => ({
    ...filterFormRef.current,
  }));

  return (
    <Formik
      initialValues={{
        dateEnd: '',
        dateStart: '',
        state: null,
      }}
      innerRef={filterFormRef}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <ComponentSelectSimple
            backgroundColor={colors.white}
            isClearable
            label="UF do cliente"
            name="state"
            onChange={handleSelectChange}
            options={states}
            placeholder="Selecione uma UF"
            value={values.state}
          />
          <ComponentInputDate
            backgroundColor={colors.white}
            errorMessage={errors.dateStart}
            hasError={!!errors.dateStart}
            label="Data início"
            max={values.dateEnd}
            name="dateStart"
            onChange={handleChange('dateStart')}
            value={values.dateStart}
          />
          <ComponentInputDate
            backgroundColor={colors.white}
            errorMessage={errors.dateEnd}
            hasError={!!errors.dateEnd}
            label="Data fim"
            min={values.dateStart}
            name="dateEnd"
            onChange={handleChange('dateEnd')}
            value={values.dateEnd}
          />
          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={isLoading}
            onClick={onClear}
            title="Limpar pesquisa"
            type="button"
          >
            <FiX size={18} />
          </ComponentButtonBase>
          <ComponentButtonBase
            disabled={isLoading}
            title="Pesquisar"
            type="submit"
          >
            <FiSearch size={18} />
          </ComponentButtonBase>
        </FormikForm>
      )}
    </Formik>
  );
};

export default forwardRef(CustomerNonPositiveFilter);
