import { useRef } from 'react';

import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import Containers from 'styles/containers';
import General from 'styles/general';

import CompanyAddress from './Address';
import Contact from './Contact';
import CompanyGeneral from './General';
import SocialMedia from './SocialMedia';
import { CompanySocialName, Content, Small } from './styles';
import Terms from './Terms';
import UpdatePassword, { IUpdatePasswordRefProps } from './UpdatePassword';

const Company: React.FC = () => {
  const company = useReduxSelector(settingSelectors.company);

  const updatePasswordRef = useRef<IUpdatePasswordRefProps>(null);

  const openUpdatePassword = () => {
    updatePasswordRef.current?.open();
  };
  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle>Configurações da empresa</General.PageTitle>
        <CompanySocialName>
          <Small>Nome social</Small>
          {company.name}
        </CompanySocialName>
      </General.PageTitleContainer>

      <Content>
        <CompanyGeneral openUpdatePassword={openUpdatePassword} />
        <Contact />
        <CompanyAddress />
        <SocialMedia />
        <Terms />
      </Content>

      <UpdatePassword ref={updatePasswordRef} />
    </Containers.Global>
  );
};

export default Company;
