import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import ComponentButtonBase from 'components/button/Base';

import colors from 'styles/colors';

import ComponentModalBase, { IComponentModalBaseRefProps } from '../Base';
import { Actions, Content, Message, Title } from './styles';

interface IAlertData {
  id: number;
}

export interface IComponentAlertRefProps {
  close: () => void;
  open: (data: IAlertData) => void;
}

interface IComponentAlertProps {
  isLoading?: boolean;
  message: string;
  onConfirm: (id: number) => void;
}

const ComponentAlert: React.ForwardRefRenderFunction<
  IComponentAlertRefProps,
  IComponentAlertProps
> = ({ isLoading = false, message, onConfirm }, ref) => {
  const [item, setItem] = useState<IAlertData>({} as IAlertData);

  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const openModal = useCallback((data: IAlertData) => {
    setItem(data);
    componentModalBaseRef.current?.open();
  }, []);
  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm(item.id);
  }, [item.id, onConfirm]);

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content>
        <Title>Atenção</Title>

        <Message>{message}</Message>

        <Actions>
          <ComponentButtonBase
            backgroundColor={colors.subtitle}
            disabled={isLoading}
            onClick={closeModal}
          >
            Cancelar
          </ComponentButtonBase>

          <ComponentButtonBase
            backgroundColor={colors.red}
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleConfirm}
          >
            Confirmar
          </ComponentButtonBase>
        </Actions>
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(ComponentAlert);
