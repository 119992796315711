import styled, { css } from 'styled-components';

import { shade } from 'polished';

import colors from './colors';

const PageTitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

interface IPageTitleProps {
  hasBackButton?: boolean;
}
const PageTitle = styled.h1<IPageTitleProps>`
  align-items: center;
  color: ${colors.title};
  display: grid;
  flex: 1;
  font-size: 1.35rem;
  font-weight: 600;
  grid-template-columns: 1fr;

  ${props =>
    props.hasBackButton &&
    css`
      grid-template-columns: 2.5rem 1fr;
    `};
`;

const PageActions = styled.div`
  margin-left: auto;
  max-width: 15rem;
  width: 100%;
`;

const PageAction = styled.button`
  align-items: center;
  background-color: ${colors.primary};
  border: 0;
  border-radius: 0.5rem;
  color: ${colors.white};
  column-gap: 0.5rem;
  display: flex;
  font-size: 0.875rem;
  height: 2rem;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: ${shade(0.2, colors.primary)};
  }
`;

export default {
  PageTitleContainer,
  PageTitle,
  PageActions,
  PageAction,
};
