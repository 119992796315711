function getToken(): string {
  const token =
    window.location.search.replace('?token=', '') ||
    `${process.env.REACT_APP_PED_TOKEN_SBX as string}`;
  return token;
}

const companyConfig = { getToken };

export default companyConfig;
