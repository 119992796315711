import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthInitialData } from '../../index';

export interface IUpdatePasswordRequestAction {
  data: {
    current: string;
    newPassword: string;
  };
  functions: {
    close: () => void;
  };
}

function updatePasswordRequest(
  draft: Draft<IAuthInitialData>,
  _: PayloadAction<IUpdatePasswordRequestAction>,
) {
  draft.updatePassword.config.isLoading = true;
}

export default updatePasswordRequest;
