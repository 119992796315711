import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';
import formatters from 'formatters';
import ICustomerAccess from 'models/ICustomerAccess';

import { ICustomerAccessInitialData } from '../../index';

export interface IGetAllSuccessAction {
  data: {
    list: ICustomerAccess[];
    totalPages: number;
  };
}

function getAllSuccess(
  draft: Draft<ICustomerAccessInitialData>,
  action: PayloadAction<IGetAllSuccessAction>,
) {
  const listFormatted = action.payload.data.list.map(listData => ({
    ...listData,
    document: formatters.cpfOrCnpj(listData.document),
    dateAccess: format(parseISO(listData.dateAccess), 'dd/MM/yyyy HH:mm:ss'),
  }));
  draft.getAll.config.totalPages = action.payload.data.totalPages;
  draft.getAll.data = listFormatted;
  draft.getAll.config.isLoading = false;
}

export default getAllSuccess;
