import Containers from 'styles/containers';
import General from 'styles/general';

import Billing from './Billing';
import BillingAmount from './BillingAmount';
import Graphics from './Graphics';
import List from './List';
import { ContainerHeader, Header, Subtitle } from './styles';

const Dashboard = (): JSX.Element => {
  return (
    <Containers.Global>
      <General.PageTitleContainer>
        <General.PageTitle>Dashboards</General.PageTitle>
      </General.PageTitleContainer>

      <Header>
        <ContainerHeader>
          <Subtitle>Faturamento</Subtitle>
          <Billing />
        </ContainerHeader>
        <ContainerHeader>
          <Subtitle>Pedidos faturados</Subtitle>
          <BillingAmount />
        </ContainerHeader>
      </Header>

      <Graphics />
      <List />
    </Containers.Global>
  );
};

export default Dashboard;
