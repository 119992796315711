import styled, { css } from 'styled-components';

import colors from 'styles/colors';

interface IContainerProps {
  selected?: boolean;
}

export const Container = styled.button<IContainerProps>`
  background-color: ${colors.tabBackground};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  color: ${colors.subtitle};
  display: grid;
  padding: 1.5rem;
  position: relative;
  text-align: left;
  width: fit-content;

  ${props =>
    props.selected &&
    css`
      border: 1px solid ${colors.orange};
      color: ${colors.orange};

      &::after {
        align-items: center;
        background-color: ${colors.orange};
        border-radius: 0.325rem;
        bottom: -0.5rem;
        color: ${colors.white};
        content: '✓';
        display: flex;
        font-size: 1.115rem;
        height: 1.75rem;
        justify-content: center;
        position: absolute;
        right: -0.5rem;
        width: 1.75rem;
      }
    `};
`;

export const Name = styled.p`
  font-size: 0.875rem;
`;

interface IColumnsProps {
  columns?: number;
}

export const Columns = styled.div<IColumnsProps>`
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: ${props =>
    props.columns ? `repeat(${props.columns}, 1fr)` : 'repeat(2, 1fr)'};
  margin-top: 0.5rem;
  width: fit-content;
`;

interface IColumnProps {
  selected?: boolean;
}

export const Column = styled.div<IColumnProps>`
  background-color: ${colors.border};
  border-radius: 0.5rem;
  height: 4rem;
  width: 4rem;
`;
