import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import IAdmin from 'models/IAdmin';
import { adminActions } from 'store/slices/admin';
import adminSelectors from 'store/slices/admin/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentModalBase, {
  IComponentModalBaseRefProps,
} from 'components/modal/Base';
import { Content, Title } from 'components/modal/styles';

import AdminForm, { IAdminFormData } from '../Form';

export interface IAdminEditRefProps {
  close: () => void;
  open: (data: IAdmin) => void;
}

const schema = Yup.object().shape({
  name: Yup.string()
    .max(100, "Tamanho máximo do campo 'Nome' é de 100 caracteres")
    .required("O campo 'Nome' é obrigatório"),
  password: Yup.string().min(
    6,
    "Tamanho mínimo do campo 'Senha' deve ser 6 caracteres",
  ),
  email: Yup.string()
    .required("O campo 'E-mail' é obrigatório")
    .max(100, "Tamanho máximo do campo 'E-mail' deve ser 100 caracteres"),
});

const AdminEdit: React.ForwardRefRenderFunction<IAdminEditRefProps> = (
  _,
  ref,
) => {
  const reduxDispatch = useReduxDispatch();

  const [admin, setAdmin] = useState<IAdmin>({} as IAdmin);
  const componentModalBaseRef = useRef<IComponentModalBaseRefProps>(null);

  const isLoading = useReduxSelector(adminSelectors.updateIsLoading);

  const openModal = useCallback((data: IAdmin) => {
    setAdmin(data);
    componentModalBaseRef.current?.open();
  }, []);

  const closeModal = useCallback(() => {
    componentModalBaseRef.current?.close();
  }, []);

  const handleEdit = useCallback(
    (data: IAdminFormData) => {
      reduxDispatch(
        adminActions.updateRequest({
          data: {
            id: admin.id,
            email: data.email,
            name: data.name,
            password: data.password,
          },
          functions: {
            success() {
              closeModal();
            },
          },
        }),
      );
    },
    [admin.id, closeModal, reduxDispatch],
  );

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <ComponentModalBase ref={componentModalBaseRef}>
      <Content maxWidth="25">
        <Title>Editar administrador</Title>
        <AdminForm
          close={closeModal}
          initialValues={{
            email: admin.email,
            name: admin.name,
            password: '',
          }}
          isLoading={isLoading}
          onSubmit={handleEdit}
          validationSchema={schema}
        />
      </Content>
    </ComponentModalBase>
  );
};

export default forwardRef(AdminEdit);
