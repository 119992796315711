import listFailure from './list/failure';
import listRequest from './list/request';
import listSuccess from './list/success';
import pdfOrderFailure from './pdf/failure';
import pdfOrderRequest from './pdf/request';
import pdfOrderSuccess from './pdf/success';
import spreadsheetOrderFailure from './spreadsheet/failure';
import spreadsheetOrderRequest from './spreadsheet/request';
import spreadsheetOrderSuccess from './spreadsheet/success';

const orderReducers = {
  listFailure,
  listRequest,
  listSuccess,
  pdfOrderFailure,
  pdfOrderRequest,
  pdfOrderSuccess,
  spreadsheetOrderFailure,
  spreadsheetOrderRequest,
  spreadsheetOrderSuccess,
};

export default orderReducers;
