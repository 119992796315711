import { useCallback, useEffect } from 'react';

import { BrowserRouter, Link } from 'react-router-dom';

import Routes from 'routes';
import ErrorBoundary from 'routes/ErrorBoundary';
import authSelectors from 'store/slices/auth/selectors';
import configSelectors from 'store/slices/config/selectors';
import { customerActions } from 'store/slices/customer';
import settingSelectors from 'store/slices/setting/selectors';

import pages from 'constants/pages';

import AppProvider from 'hooks';
import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import Sidebar from 'components/Sidebar';
import ComponentIsVisible from 'components/utils/IsVisible';

import { CardMaintenance, CardTitleMaintenance } from 'pages/Settings/styles';

import WsClient from 'services/webSocket';

import Containers from 'styles/containers';
import GlobalStyle from 'styles/global';

const App: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const authToken = useReduxSelector(authSelectors.authToken);
  const pedToken = useReduxSelector(configSelectors.pedToken);
  const others = useReduxSelector(settingSelectors.others);

  const startWebSocket = useCallback(() => {
    if (authToken && pedToken) {
      WsClient.start({
        token: authToken,
        pedToken,
        callback: (customerIds: string[]) => {
          reduxDispatch(
            customerActions.setCustomersConnected({ data: { customerIds } }),
          );
        },
      });
    }
  }, [reduxDispatch, pedToken, authToken]);

  useEffect(() => {
    startWebSocket();
  }, [startWebSocket, authToken]);

  return (
    <AppProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <ComponentIsVisible when={!authToken}>
            <Routes />
          </ComponentIsVisible>

          <ComponentIsVisible when={!!authToken}>
            <Containers.Root>
              <Sidebar />
              <Containers.Main>
                {others.isMaintenance && (
                  <CardMaintenance>
                    <CardTitleMaintenance>
                      A página está em manutenção
                    </CardTitleMaintenance>
                    <Link to={pages.settings}>
                      <ComponentButtonBase>Configurações</ComponentButtonBase>
                    </Link>
                  </CardMaintenance>
                )}
                <Routes />
              </Containers.Main>
            </Containers.Root>
          </ComponentIsVisible>
        </BrowserRouter>
      </ErrorBoundary>

      <GlobalStyle />
    </AppProvider>
  );
};

export default App;
