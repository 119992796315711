import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { orderActions } from '..';

import { IPdfOrderRequestAction } from '../reducers/pdf/request';

function* pdf(action: PayloadAction<IPdfOrderRequestAction>) {
  try {
    const params = {
      status: action.payload.data.status
        ? action.payload.data.status
        : undefined,
      origin: action.payload.data.origin
        ? action.payload.data.origin
        : undefined,
      name: action.payload.data.name ? action.payload.data.name : undefined,
      dateEnd: action.payload.data.dateEnd
        ? action.payload.data.dateEnd
        : undefined,
      dateStart: action.payload.data.dateStart
        ? action.payload.data.dateStart
        : undefined,
    };
    const { data: ordersPdf } = yield call(httpClient.get, `admin/orders/pdf`, {
      params,
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(
      new Blob([ordersPdf], {
        type: 'application/pdf',
      }),
    );
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute(
      'download',
      `pedido-eletronico_${format(new Date(), 'dd-MM-yyyy')}.pdf`,
    );
    tempLink.click();
    yield put(orderActions.pdfOrderSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(orderActions.pdfOrderFailure());
  }
}

export default pdf;
