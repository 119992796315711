import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface ITermsSuccessAction {
  terms: string;
}

function termsSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<ITermsSuccessAction>,
) {
  draft.terms.config.isLoading = false;
  draft.data.others.terms = action.payload.terms;
}

export default termsSuccess;
