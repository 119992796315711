import { Draft } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

function updateItemPositionFailure(
  _: Draft<IListInitialData>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {}

export default updateItemPositionFailure;
