import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccess from './getAll/success';
import pdfCustomerAccessFailure from './pdf/failure';
import pdfCustomerAccessRequest from './pdf/request';
import pdfCustomerAccessSucess from './pdf/success';
import spreadsheetCustomerAccessFailure from './spreadsheet/failure';
import spreadsheetCustomerAccessRequest from './spreadsheet/request';
import spreadsheetCustomerAccessSucess from './spreadsheet/success';

const customerAccessReducers = {
  getAllFailure,
  getAllRequest,
  getAllSuccess,
  pdfCustomerAccessFailure,
  pdfCustomerAccessSucess,
  pdfCustomerAccessRequest,
  spreadsheetCustomerAccessFailure,
  spreadsheetCustomerAccessRequest,
  spreadsheetCustomerAccessSucess,
};

export default customerAccessReducers;
