import styled, { css } from 'styled-components';

import colors from './colors';

interface IItemsProps {
  hasBorder?: boolean;
  marginTop?: string;
}
const Items = styled.div<IItemsProps>`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.marginTop ? props.marginTop : '1rem')};
  min-height: 20rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${props =>
    props.hasBorder &&
    css`
      border: 1px solid ${colors.border};
    `};
`;

const ItemsHeader = styled.div`
  align-items: center;
  column-gap: 0.5rem;
  display: grid;
  min-height: 2.5rem;
  padding: 0 0.5rem;
  width: 100%;
`;

interface IItemHeaderProps {
  center?: boolean;
  right?: boolean;
}
const ItemHeader = styled.label<IItemHeaderProps>`
  color: ${colors.label};
  font-size: 0.875rem;
  width: 100%;

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};

  ${props =>
    props.right &&
    css`
      text-align: right;
    `};
`;

interface IItemBodyProps {
  addColorRow?: boolean;
}

const ItemsBody = styled.div<IItemBodyProps>`
  align-items: center;
  background-color: ${colors.transparent};
  column-gap: 0.5rem;
  display: grid;
  min-height: 2.5rem;
  padding: 0 0.5rem;
  width: 100%;

  ${props =>
    props.addColorRow &&
    css`
      background: ${colors.background};
    `}
`;

const ItemsBodyContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.5rem;
  width: 100%;
`;

interface IItemBodyProps {
  active?: boolean;
  center?: boolean;
  inactive?: boolean;
  overflowVisible?: boolean;
  right?: boolean;
}

const ItemBody = styled.div<IItemBodyProps>`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: ${colors.primary};
  display: -webkit-box;
  float: left;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  overflow: ${props => (props.overflowVisible ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
  width: 100%;
  word-break: break-word;

  @media (min-width: 768px) {
    -webkit-line-clamp: 3;
  }

  ${props =>
    props.center &&
    css`
      text-align: center;
    `};

  ${props =>
    props.right &&
    css`
      text-align: right;
    `};

  ${props =>
    props.active &&
    css`
      color: ${colors.orange};
      font-weight: 600;
    `};

  ${props =>
    props.inactive &&
    css`
      color: ${colors.red};
    `};
`;

const ItemActions = styled.div`
  column-gap: 0.5rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const ItemAction = styled.button`
  align-items: center;
  background-color: ${colors.transparent};
  border: 0;
  display: flex;
  font-size: 0;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;

  @media screen and (min-width: 768px) {
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default {
  Items,
  ItemsHeader,
  ItemsBody,
  ItemHeader,
  ItemBody,
  ItemsBodyContent,
  ItemActions,
  ItemAction,
};
