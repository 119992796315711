import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface ICompanySuccessAction {
  corporateName: string;
}

function companySuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<ICompanySuccessAction>,
) {
  draft.company.config.isLoading = false;
  draft.data.company.corporateName = action.payload.corporateName;
}

export default companySuccess;
