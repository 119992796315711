import { useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { customerActions } from 'store/slices/customer';
import customerSelectors from 'store/slices/customer/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentEmpty from 'components/utils/Empty';
import ComponentError from 'components/utils/Error/List';
import ComponentIsVisible from 'components/utils/IsVisible';
import ComponentLoadingList from 'components/utils/Loading/List';

import { ListContainer, Subtitle } from '../styles';
import DrugsItem from './Item';

interface IDrugsListParams extends Record<string, string | undefined> {
  id: string;
}

const DrugsList = () => {
  const params = useParams<IDrugsListParams>();
  const reduxDispatch = useReduxDispatch();
  const drugs = useReduxSelector(customerSelectors.drugs);
  const isLoading = useReduxSelector(customerSelectors.drugsIsLoading);
  const isError = useReduxSelector(customerSelectors.drugsIsError);

  const handleDrugs = useCallback((): void => {
    if (!params.id) {
      return;
    }
    reduxDispatch(
      customerActions.drugsRequest({
        data: {
          customerId: Number(params.id),
        },
      }),
    );
  }, [params.id, reduxDispatch]);

  useEffect(() => {
    handleDrugs();
  }, [handleDrugs]);

  return (
    <>
      <Subtitle>Medicamentos</Subtitle>
      <ListContainer>
        <ComponentIsVisible when={!isLoading && !isError && drugs.length !== 0}>
          <DrugsItem.Header />
          {drugs.map((drug, position) => (
            <DrugsItem.Body drug={drug} key={position} position={position} />
          ))}
        </ComponentIsVisible>
        <ComponentEmpty
          message="Nenhum medicamento encontrado"
          show={!isError && drugs.length === 0 && !isLoading}
        />
        <ComponentIsVisible when={isError}>
          <ComponentError
            message="Erro na busca pelos medicamentos"
            onClick={handleDrugs}
          />
        </ComponentIsVisible>
        <ComponentLoadingList rows={8} show={isLoading} />
      </ListContainer>
    </>
  );
};

export default DrugsList;
