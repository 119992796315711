import styled from 'styled-components';

import { Form as FormikForm } from 'formik';

import colors from 'styles/colors';

export const Versions = styled.div`
  display: grid;
  margin-bottom: 1rem;
`;

export const VersionContainer = styled.div`
  display: grid;
  margin-bottom: 1rem;
`;

export const Version = styled.h4`
  color: ${colors.primary};
  font-weight: 600;
`;

export const Bold = styled.b`
  color: ${colors.orange};
  font-size: 0.925rem;
  font-weight: 500;
  margin-top: 1rem;
`;

export const Notes = styled.ul`
  margin-top: 0.5rem;
  padding-left: 1.5rem;
`;

export const Note = styled.li`
  font-size: 0.875rem;
`;

export const Form = styled(FormikForm)`
  display: flex;
  justify-content: flex-end;
  margin: 1.5rem 0 0;
`;
