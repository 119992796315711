import styled from 'styled-components';

import colors from 'styles/colors';

export const Content = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3rem;
  @media (max-width: 1563px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MainContainer = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.primary};
  border-radius: 0.5rem;
  padding: 1.5rem;
`;

export const Title = styled.h1`
  color: ${colors.primary};
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerStatus = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;
interface IStatusProps {
  statusColor: string;
}
export const Status = styled.span<IStatusProps>`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin: 0 1rem;
  &::before {
    background: ${props => props.statusColor};
    border-radius: 0.5rem;
    content: '';
    height: 1rem;
    width: 1rem;
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const TextStatus = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
  margin-right: 1rem;
`;

export const Items = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  justify-content: space-between;
`;

export const TotalNumber = styled.h1`
  color: ${colors.primary};
  font-size: 1.2rem;
  font-weight: 600;
`;

export const LinePercentage = styled.div`
  align-items: center;
  border-bottom: 2px solid;
  display: flex;
  margin: auto 1rem;
  width: 25px;
`;

export const TotalPercentage = styled.h1`
  color: ${colors.primary};
  font-size: 1.2rem;
  font-weight: 600;
`;

export const DashboardContainer = styled.div`
  height: 300px;
  width: 300px;
`;

export const Dashboard = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerLoading = styled.div`
  align-items: center;
  display: flex;
  height: 18.5rem;
  justify-content: center;
`;
