import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ISettingInitialData } from '../../index';

interface IThemesSuccessAction {
  mainColor: string;
}

function themesSuccess(
  draft: Draft<ISettingInitialData>,
  action: PayloadAction<IThemesSuccessAction>,
) {
  draft.themes.config.isLoading = false;
  draft.data.design.mainColor = action.payload.mainColor;
}

export default themesSuccess;
