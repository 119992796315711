import { format, parseISO } from 'date-fns';

interface IDateOptions {
  defaultValue?: string;
  mask?: string;
}

function date(value?: string | null, options?: IDateOptions): string {
  if (!value) {
    return options?.defaultValue ?? '';
  }

  const dateFormatted = format(parseISO(value), options?.mask ?? 'dd/MM/yyyy');

  return dateFormatted;
}

export default date;
