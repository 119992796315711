import { Draft } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

function priceTablesFailure(draft: Draft<ICustomerInitialData>) {
  draft.priceTables.config.isLoading = false;
  draft.priceTables.config.isError = true;
}

export default priceTablesFailure;
