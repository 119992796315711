import React from 'react';

import { useNavigate } from 'react-router-dom';

import { FiChevronLeft } from 'react-icons/fi';

import { Container } from './styles';

const ComponentBackButton: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container onClick={handleGoBack}>
      <FiChevronLeft size={24} />
    </Container>
  );
};

export default ComponentBackButton;
