import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { customerActions } from '..';

import { INonPositiveSpreadsheetRequestAction } from '../reducers/nonPositiveSpreadsheet/request';

function* nonPositiveSpreadsheet(
  action: PayloadAction<INonPositiveSpreadsheetRequestAction>,
) {
  try {
    const params = {
      dateStart: action.payload.data.dateStart
        ? action.payload.data.dateStart
        : undefined,
      dateEnd: action.payload.data.dateEnd
        ? action.payload.data.dateEnd
        : undefined,
      state: action.payload.data.state ? action.payload.data.state : undefined,
    };
    const { data: customerNonPositiveSpreadsheet } = yield call(
      httpClient.get,
      `admin/customers/non-positive/spreadsheet`,
      {
        params,
        responseType: 'blob',
      },
    );
    const url = window.URL.createObjectURL(
      new Blob([customerNonPositiveSpreadsheet], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      }),
    );

    const link = document.createElement('a');
    link.href = url;
    link.download = `planilha_nao_positivados-${format(
      new Date(),
      'dd-MM-yyyy',
    )}`;
    document.body.appendChild(link);
    link.click();
    yield put(customerActions.nonPositiveSpreadsheetSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.nonPositiveSpreadsheetFailure());
  }
}

export default nonPositiveSpreadsheet;
