import { Draft, PayloadAction } from '@reduxjs/toolkit';
import IRankingProduct from 'models/IRankingProduct';

import { IDashboardInitialData } from '../../index';

export interface IRankingProductSuccessAction {
  data: {
    list: IRankingProduct[];
  };
}

function rankingProductSuccess(
  draft: Draft<IDashboardInitialData>,
  action: PayloadAction<IRankingProductSuccessAction>,
) {
  draft.rankingProduct.config.isLoading = false;
  draft.rankingProduct.list = action.payload.data.list;
}

export default rankingProductSuccess;
