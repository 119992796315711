import { PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { call, put } from 'redux-saga/effects';
import helpers from 'helpers';

import httpClient from 'services/httpClient';

import { IPdfCustomerAccessRequestAction } from '../reducers/pdf/request';

import { customerAccessActions } from '..';

function* pdf(action: PayloadAction<IPdfCustomerAccessRequestAction>) {
  try {
    const params = {
      date: action.payload.data.date ? action.payload.data.date : undefined,
      page: action.payload.data.page ? action.payload.data.page : undefined,
    };
    const { data: customersAccessPdf } = yield call(
      httpClient.get,
      'admin/customers-access/pdf',
      { params, responseType: 'blob' },
    );
    const url = window.URL.createObjectURL(
      new Blob([customersAccessPdf], { type: 'application/pdf' }),
    );
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute(
      'download',
      `pedido-eletronico_${format(new Date(), 'dd-MM-yyyy')}.pdf`,
    );
    tempLink.click();
    yield put(customerAccessActions.pdfCustomerAccessSucess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerAccessActions.pdfCustomerAccessFailure());
  }
}

export default pdf;
