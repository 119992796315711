import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface ISetOnlyConnectedAction {
  data: {
    value: boolean;
  };
}

function setOnlyConnected(
  draft: Draft<ICustomerInitialData>,
  action: PayloadAction<ISetOnlyConnectedAction>,
) {
  draft.getAll.onlyConnected = action.payload.data.value;
}

export default setOnlyConnected;
