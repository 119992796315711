import formatters from 'formatters';
import { ICustomerOrdersData } from 'store/slices/customer';

import Tables from 'styles/tables';

import { CustomerOrdersBody } from '../styles';

interface IProps {
  customerOrder: ICustomerOrdersData;
  position: number;
}

const ItemBody = ({ customerOrder, position }: IProps) => {
  return (
    <CustomerOrdersBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody center>{customerOrder.id}</Tables.ItemBody>

      <Tables.ItemBody>
        {customerOrder.dateOpening
          ? formatters.date(new Date(customerOrder.dateOpening).toISOString())
          : 'Não informado'}
      </Tables.ItemBody>

      <Tables.ItemBody>
        {customerOrder.dateCancellation
          ? formatters.date(
              new Date(customerOrder.dateCancellation).toISOString(),
            )
          : 'Não informado'}
      </Tables.ItemBody>

      <Tables.ItemBody>
        {customerOrder.dateClosing
          ? formatters.date(new Date(customerOrder.dateClosing).toISOString())
          : 'Não informado'}
      </Tables.ItemBody>

      <Tables.ItemBody>
        {customerOrder.dateProcessing
          ? formatters.date(
              new Date(customerOrder.dateProcessing).toISOString(),
            )
          : 'Não informado'}
      </Tables.ItemBody>

      <Tables.ItemBody>
        {customerOrder.dateBilling
          ? formatters.date(new Date(customerOrder.dateBilling).toISOString())
          : 'Não informado'}
      </Tables.ItemBody>

      <Tables.ItemBody>
        {customerOrder.paymentMethod.id
          ? customerOrder.paymentMethod.id
          : 'Não informado'}
      </Tables.ItemBody>

      <Tables.ItemBody>
        {customerOrder.paymentMethod.name
          ? customerOrder.paymentMethod.name
          : 'Não informado'}
      </Tables.ItemBody>
    </CustomerOrdersBody>
  );
};

export default ItemBody;
