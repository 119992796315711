import { all } from 'redux-saga/effects';

import adminSagas from './slices/admin/sagas';
import authSagas from './slices/auth/sagas';
import bannerSagas from './slices/banner/sagas';
import customerSagas from './slices/customer/sagas';
import customerAccessSagas from './slices/customerAccess/sagas';
import dashboardSagas from './slices/dashboard/sagas';
import feedbackSagas from './slices/feedback/sagas';
import imageSagas from './slices/image/sagas';
import kitSagas from './slices/kit/sagas';
import listSagas from './slices/list/sagas';
import manufacturerSagas from './slices/manufacturer/sagas';
import orderSagas from './slices/order/sagas';
import priceListSagas from './slices/priceList/sagas';
import productSagas from './slices/product/sagas';
import productRequestSagas from './slices/productRequest/sagas';
import settingSagas from './slices/setting/sagas';

function* rootSagas() {
  yield all([
    adminSagas,
    authSagas,
    bannerSagas,
    customerSagas,
    customerAccessSagas,
    dashboardSagas,
    feedbackSagas,
    imageSagas,
    kitSagas,
    listSagas,
    manufacturerSagas,
    orderSagas,
    priceListSagas,
    productSagas,
    productRequestSagas,
    settingSagas,
  ]);
}

export default rootSagas;
