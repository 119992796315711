import { IProductRequestList } from 'store/slices/productRequest';

import Tables from 'styles/tables';

import { ProductItemsBody } from '../styles';

interface IItemBodyProps {
  position: number;
  product: IProductRequestList;
}

const ItemBody = ({ position, product }: IItemBodyProps): JSX.Element => {
  return (
    <ProductItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{product.product}</Tables.ItemBody>
      <Tables.ItemBody>{product.customer}</Tables.ItemBody>
      <Tables.ItemBody center>{product.date}</Tables.ItemBody>
    </ProductItemsBody>
  );
};

export default ItemBody;
