import { useCallback, useRef, useState } from 'react';

import { FaInfo } from 'react-icons/fa';

import EUploadImageType from 'enums/uploadImageType';
import imageSelectors from 'store/slices/image/selectors';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import validators from 'validators';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentButtonBase from 'components/button/Base';
import ComponentImageCropper, {
  IComponentImageCropperRefProps,
} from 'components/modal/Cropper';

import { Card, CardSubtitle, CardTitle, FormActions } from '../styles';
import {
  ActuallyImage,
  ActuallyImageLoading,
  CardButton,
  CardGrid,
  CardInfo,
  CardTitleInformation,
  ChoosePicture,
  Thumbnail,
  ThumbnailInfo,
} from './styles';

const CompanyLogo: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const { show: toastShow } = useToast();

  const isLoading = useReduxSelector(settingSelectors.logoIsLoading);
  const designer = useReduxSelector(settingSelectors.designer);
  const picture = useReduxSelector(imageSelectors.picture);

  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);

  const uploadFileRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const componentImageCropperRef = useRef<IComponentImageCropperRefProps>(null);

  const handleUploadImage = () => {
    uploadFileRef.current.click();

    uploadFileRef.current.addEventListener('change', () => {
      if (uploadFileRef.current.files) {
        const file = uploadFileRef.current.files.item(0);

        if (file) {
          const fileVerified = validators.fileType(file?.type);

          if (!fileVerified) {
            toastShow({
              title: 'Formato de arquivo inválido',
              description: 'Formatos permitidos: png, jpg e jpeg',
              type: 'error',
            });
            return;
          }

          const reader = new FileReader();

          reader.readAsDataURL(file);

          reader.onload = event => {
            componentImageCropperRef.current?.open({
              imageType: EUploadImageType.logo,
              imageUrl: event.target?.result as string,
            });
          };
        }
      }
    });
  };

  const handleCompanyLogo = useCallback(() => {
    if (!picture) {
      return;
    }

    reduxDispatch(
      settingActions.logoRequest({
        data: {
          url: picture,
        },
      }),
    );
  }, [picture, reduxDispatch]);

  return (
    <Card>
      <CardGrid>
        <CardInfo>
          <CardTitle>
            Adicione sua logo
            <CardTitleInformation>
              <FaInfo size={10} />
            </CardTitleInformation>
          </CardTitle>
          <CardSubtitle>Faça upload da logo de sua empresa</CardSubtitle>
          <CardButton onClick={handleUploadImage}>
            <ChoosePicture
              accept="image/png, image/jpg, image/jpeg"
              ref={uploadFileRef}
              type="file"
            />
            Escolher foto
          </CardButton>
        </CardInfo>
        <ThumbnailInfo>
          <Thumbnail>
            <ActuallyImage
              isVisible={!isLoadingImage}
              loading="eager"
              onLoad={() => setIsLoadingImage(false)}
              src={picture ?? designer.logo}
            />
            <ActuallyImageLoading isVisible={isLoadingImage} />
          </Thumbnail>
        </ThumbnailInfo>
      </CardGrid>

      <FormActions>
        <ComponentButtonBase
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleCompanyLogo}
          type="button"
        >
          Salvar
        </ComponentButtonBase>
      </FormActions>

      <ComponentImageCropper ref={componentImageCropperRef} />
    </Card>
  );
};

export default CompanyLogo;
