import styled, { css } from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';
import Tables from 'styles/tables';

export const Subheader = styled.div`
  align-items: flex-end;
  border-bottom: 1px solid ${colors.border};
  display: grid;
  grid-template-columns: 1fr 15rem;
  padding-bottom: 0.5rem;
  width: 100%;
`;

export const TitleContainer = styled.div``;

interface ITitleProps {
  hasBackButton?: boolean;
}

export const Title = styled.h2<ITitleProps>`
  align-items: center;
  color: ${colors.title};
  display: grid;
  flex: 1;
  font-size: 1.275rem;
  font-weight: 600;

  ${props =>
    props.hasBackButton &&
    css`
      grid-template-columns: 2.5rem 1fr;
    `};
`;

export const Subtitle = styled.p`
  color: ${colors.subtitle};
  font-size: 1rem;
  margin-top: 0.5rem;
`;

export const Tabs = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  border-radius: 0.5rem;
  column-gap: 0.25rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0.32rem 0.4rem;
  width: 100%;
`;

interface ITabProps {
  isSelected?: boolean;
}

export const Tab = styled.button<ITabProps>`
  background-color: ${colors.tabBackground};
  border: 0;
  border-radius: 0.25rem;
  color: ${colors.label};
  font-size: 0.875rem;
  height: 1.5rem;

  ${props =>
    props.isSelected &&
    css`
      background-color: ${colors.title};
      color: ${colors.white};
    `}
`;

interface ITabContentProps {
  isActivated?: boolean;
  rightOut?: boolean;
}

export const TabContent = styled.div<ITabContentProps>`
  height: 0;
  opacity: 0;
  overflow: hidden;
  transform: ${props =>
    !props.rightOut ? 'translateX(-100%)' : 'translateX(100%)'};
  transition: all 0.5s ease-in-out;
  ${props =>
    props.isActivated &&
    css`
      height: auto;
      opacity: 1;
      overflow: auto;
      transform: translateX(0);
    `};
`;

export const Content = styled.div``;

export const CustomerPedcoinsItemHeader = styled(Tables.ItemHeader)`
  color: ${colors.label};
  font-weight: 400;
`;

export const CustomerPedcoinsItemsHeader = styled(Tables.ItemsHeader)`
  grid-template-columns: 8rem 1fr 10rem 15rem 10rem 5rem;
  margin-top: 1.5rem;
  padding: 1rem 1.25rem 0;
`;
export const CustomerPedcoinsItemsBody = styled(Tables.ItemsBody)`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  grid-template-columns: 8rem 1fr 10rem 15rem 10rem 5rem;
  margin-top: 0.5rem;
  padding: 1rem 1.25rem;
`;

interface ICustomerPedcoinsItemBodyProps {
  cancelled?: boolean;
  entry?: boolean;
  output?: boolean;
}

export const CustomerPedcoinsItemBody = styled(
  Tables.ItemBody,
)<ICustomerPedcoinsItemBodyProps>`
  color: ${colors.text};
  font-weight: 400;

  ${props =>
    props.entry &&
    css`
      color: ${colors.green};
    `};
  ${props =>
    props.output &&
    css`
      color: ${colors.red};
    `};
  ${props =>
    props.cancelled &&
    css`
      color: ${colors.orange};
    `};
`;

export const ReasonInfo = styled.div`
  align-items: center;
  cursor: help;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  & svg {
    color: ${colors.title};
  }
`;

interface IReasonInfoMessageProps {
  show?: boolean;
}

export const ReasonInfoMessage = styled.div<IReasonInfoMessageProps>`
  background-color: ${colors.backgroundDark};
  border-radius: 0.25rem;
  bottom: calc(100% + 0.8rem);
  color: ${colors.text};
  display: none;
  font-size: 0.875rem;
  font-weight: 500;
  height: auto;
  opacity: 0;
  padding: 0.35rem 0.25rem;
  position: absolute;
  right: 0.9rem;
  transition: all 0.3s ease-in-out;
  width: 24rem;
  z-index: 4;
  &::before {
    border-color: ${colors.backgroundDark} transparent;
    border-style: solid;
    border-width: 0.45rem 0.45rem 0 0.45rem;
    content: '';
    position: absolute;
    right: 5%;
    top: 100%;
  }
  ${props =>
    props.show &&
    css`
      display: block;
      opacity: 1;
    `};
`;

export const CustomerPedcoinsItemLoading = styled.div`
  animation: ${animations.shimmer} 3.5s infinite linear;
  background: linear-gradient(to right, #fefefe 4%, #ececec 25%, #fefefe 36%);
  background-color: ${colors.white};
  background-size: 1000px 100%;
  border-radius: 0.5rem;
  height: 3.5rem;
  margin-top: 0.5rem;
  width: 100%;
`;
