import { createSlice } from '@reduxjs/toolkit';
import IRankingCustomer from 'models/IRankingCustomer';
import IRankingProduct from 'models/IRankingProduct';

import reducers from './reducers';

export enum EDashboardCustomer {
  Active = 'ATIVO',
  Defaulter = 'INADIMPLENTE',
  Inactive = 'INATIVO',
  NotPositive = 'NAO_POSITIVADO',
}

export enum EDashboardBilling {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Semiannual = 'SEMIANNUAL',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export interface IDashboardInitialData {
  billing: {
    daily: {
      amount: number;
      config: {
        isLoading: boolean;
      };
      value: string;
    };
    monthly: {
      amount: number;
      config: {
        isLoading: boolean;
      };
      value: string;
    };
    semiannual: {
      amount: number;
      config: {
        isLoading: boolean;
      };
      value: string;
    };
    weekly: {
      amount: number;
      config: {
        isLoading: boolean;
      };
      value: string;
    };
    yearly: {
      amount: number;
      config: {
        isLoading: boolean;
      };
      value: string;
    };
  };
  customer: {
    config: {
      isLoading: boolean;
    };
    values: {
      active: number;
      defaulter: number;
      inactive: number;
      nonPositive: number;
    };
  };
  order: {
    config: {
      isLoading: boolean;
    };
    values: {
      billing: number;
      cancellation: number;
      closing: number;
      opening: number;
      processing: number;
    };
  };
  rankingCustomer: {
    config: {
      isLoading: boolean;
    };
    list: IRankingCustomer[];
  };
  rankingProduct: {
    config: {
      isLoading: boolean;
    };
    list: IRankingProduct[];
  };
}

const initialState: IDashboardInitialData = {
  billing: {
    daily: {
      value: '0,00',
      amount: 0,
      config: {
        isLoading: false,
      },
    },
    monthly: {
      value: '0,00',
      amount: 0,
      config: {
        isLoading: false,
      },
    },
    semiannual: {
      value: '0,00',
      amount: 0,
      config: {
        isLoading: false,
      },
    },
    weekly: {
      value: '0,00',
      amount: 0,
      config: {
        isLoading: false,
      },
    },
    yearly: {
      value: '0,00',
      amount: 0,
      config: {
        isLoading: false,
      },
    },
  },
  customer: {
    config: {
      isLoading: false,
    },
    values: { active: 0, defaulter: 0, inactive: 0, nonPositive: 0 },
  },
  order: {
    config: {
      isLoading: false,
    },
    values: {
      billing: 0,
      cancellation: 0,
      closing: 0,
      opening: 0,
      processing: 0,
    },
  },
  rankingProduct: {
    config: {
      isLoading: false,
    },
    list: [],
  },
  rankingCustomer: {
    config: {
      isLoading: false,
    },
    list: [],
  },
};

const dashboardSlice = createSlice({
  name: '@dashboard',
  initialState,
  reducers,
});

export const dashboardActions = dashboardSlice.actions;
export const dashboardReducers = dashboardSlice.reducer;
