import changePasswordFailure from './changePassword/failure';
import changePasswordRequest from './changePassword/request';
import changePasswordSuccess from './changePassword/success';
import detailsFailure from './details/failure';
import detailsRequest from './details/request';
import detailsSuccess from './details/success';
import drugsFailure from './drugs/failure';
import drugsRequest from './drugs/request';
import drugsSuccess from './drugs/success';
import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccess from './getAll/success';
import launchPedcoinsFailure from './launchPedcoins/failure';
import launchPedcoinsRequest from './launchPedcoins/request';
import launchPedcoinsSuccess from './launchPedcoins/success';
import nonPositiveFailure from './nonPositive/failure';
import nonPositiveRequest from './nonPositive/request';
import nonPositiveSuccess from './nonPositive/success';
import nonPositivePdfFailure from './nonPositivePdf/failure';
import nonPositivePdfRequest from './nonPositivePdf/request';
import nonPositivePdfSuccess from './nonPositivePdf/success';
import nonPositiveSpreadsheetFailure from './nonPositiveSpreadsheet/failure';
import nonPositiveSpreadsheetRequest from './nonPositiveSpreadsheet/request';
import nonPositiveSpreadsheetSuccess from './nonPositiveSpreadsheet/success';
import ordersFailure from './orders/failure';
import ordersRequest from './orders/request';
import ordersSuccess from './orders/success';
import paymentMethodsFailure from './paymentMethods/failure';
import paymentMethodsRequest from './paymentMethods/request';
import paymentMethodsSuccess from './paymentMethods/success';
import pedcoinsExtractFailure from './pedcoinsExtract/failure';
import pedcoinsExtractRequest from './pedcoinsExtract/request';
import pedcoinsExtractSuccess from './pedcoinsExtract/success';
import pedcoinsRedeemFailure from './pedcoinsRedeem/failure';
import pedcoinsRedeemRequest from './pedcoinsRedeem/request';
import pedcoinsRedeemSuccess from './pedcoinsRedeem/success';
import pedcoinsSummaryFailure from './pedcoinsSummary/failure';
import pedcoinsSummaryRequest from './pedcoinsSummary/request';
import pedcoinsSummarySuccess from './pedcoinsSummary/success';
import priceTablesFailure from './priceTables/failure';
import priceTablesRequest from './priceTables/request';
import priceTablesSuccess from './priceTables/success';
import setCustomersConnected from './set/customersConnected';
import setOnlyConnected from './set/onlyConnected';

const customerReducers = {
  drugsSuccess,
  drugsRequest,
  drugsFailure,
  ordersRequest,
  ordersSuccess,
  ordersFailure,
  priceTablesSuccess,
  priceTablesRequest,
  priceTablesFailure,
  paymentMethodsSuccess,
  paymentMethodsRequest,
  paymentMethodsFailure,
  detailsSuccess,
  detailsRequest,
  detailsFailure,
  changePasswordFailure,
  changePasswordRequest,
  changePasswordSuccess,
  getAllFailure,
  getAllRequest,
  getAllSuccess,
  launchPedcoinsFailure,
  launchPedcoinsRequest,
  launchPedcoinsSuccess,
  nonPositiveFailure,
  nonPositiveRequest,
  nonPositiveSuccess,
  nonPositivePdfFailure,
  nonPositivePdfRequest,
  nonPositivePdfSuccess,
  nonPositiveSpreadsheetFailure,
  nonPositiveSpreadsheetRequest,
  nonPositiveSpreadsheetSuccess,
  pedcoinsExtractFailure,
  pedcoinsExtractRequest,
  pedcoinsExtractSuccess,
  pedcoinsRedeemFailure,
  pedcoinsRedeemRequest,
  pedcoinsRedeemSuccess,
  pedcoinsSummaryFailure,
  pedcoinsSummaryRequest,
  pedcoinsSummarySuccess,
  setCustomersConnected,
  setOnlyConnected,
};

export default customerReducers;
