import ICustomerNonPositive from 'models/ICustomerNonPositive';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import Tables from 'styles/tables';

import { CustomerItemsBody } from './styles';

interface ICustomerNonPositiveItemBodyProps {
  customer: ICustomerNonPositive;
  position: number;
}

const CustomerNonPositiveItemBody: React.FC<
  ICustomerNonPositiveItemBodyProps
> = ({ customer, position }) => {
  const internalControl = useReduxSelector(settingSelectors.internalControl);
  return (
    <CustomerItemsBody addColorRow={position % 2 === 0}>
      <Tables.ItemBody>{customer.id}</Tables.ItemBody>
      <Tables.ItemBody>{customer.sku}</Tables.ItemBody>
      <Tables.ItemBody>
        {internalControl.hasPreferenceCompanyName
          ? customer.companyName
          : customer.name}
      </Tables.ItemBody>
      <Tables.ItemBody>{customer.email}</Tables.ItemBody>
      <Tables.ItemBody>{customer.dateAccess}</Tables.ItemBody>
      <Tables.ItemBody>{customer.phone}</Tables.ItemBody>
      <Tables.ItemBody>{customer.state}</Tables.ItemBody>
    </CustomerItemsBody>
  );
};

export default CustomerNonPositiveItemBody;
