import { PayloadAction } from '@reduxjs/toolkit';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';

import { customerActions } from '..';

import { INonPositivePdfRequestAction } from '../reducers/nonPositivePdf/request';

function* nonPositivePdf(action: PayloadAction<INonPositivePdfRequestAction>) {
  try {
    const params = {
      dateStart: action.payload.data.dateStart
        ? action.payload.data.dateStart
        : undefined,
      dateEnd: action.payload.data.dateEnd
        ? action.payload.data.dateEnd
        : undefined,
      state: action.payload.data.state ? action.payload.data.state : undefined,
    };
    const { data: customerNonPositivePdf } = yield call(
      httpClient.get,
      `admin/customers/non-positive/pdf`,
      {
        params,
        responseType: 'blob',
      },
    );
    const url = window.URL.createObjectURL(
      new Blob([customerNonPositivePdf], {
        type: 'application/pdf',
      }),
    );
    window.open(url);
    yield put(customerActions.nonPositivePdfSuccess());
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(customerActions.nonPositivePdfFailure());
  }
}

export default nonPositivePdf;
