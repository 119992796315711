import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IListInitialData } from '../../index';

export interface ICreateRequestAction {
  data: {
    quantityColumns: number;
    subtitle: string;
    title: string;
    typeView: {
      label: string;
      value: string;
    };
  };
  functions: {
    success: () => void;
  };
}

function createRequest(
  draft: Draft<IListInitialData>,
  _: PayloadAction<ICreateRequestAction>,
) {
  draft.create.config.isLoading = true;
}

export default createRequest;
