import { EProductDrugType } from 'models/IProduct';

function dragType(value?: string | null): string {
  switch (value) {
    case EProductDrugType.Ethical:
      return 'ÉTICO';
    case EProductDrugType.Generic:
      return 'GENÉRICO';
    case EProductDrugType.NoDrug:
      return 'NÃO MEDICAMENTO';
    case EProductDrugType.Others:
      return 'OUTROS';
    case EProductDrugType.Similar:
      return 'SIMILAR';
    default:
      return 'NÃO INFORMADO';
  }
}

export default dragType;
