import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IAuthInitialData } from '../../index';

export interface ISignInRequestAction {
  data: {
    email: string;
    password: string;
  };
  functions: {
    navigateTo: () => void;
  };
}

function signInRequest(
  draft: Draft<IAuthInitialData>,
  _: PayloadAction<ISignInRequestAction>,
) {
  draft.signIn.config.isLoading = true;
}

export default signInRequest;
