import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IDashboardInitialData } from '../../index';

function rankingProductRequest(
  draft: Draft<IDashboardInitialData>,
  _: PayloadAction,
) {
  draft.rankingProduct.config.isLoading = true;
}

export default rankingProductRequest;
