import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import { Formik, FormikProps } from 'formik';
import IComponentSelectProps from 'interfaces/IComponentSimpleProps';
import { EPriceListType } from 'models/IPriceList';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputNumber from 'components/input/Number';
import ComponentSelectSimple, {
  IOptionTypeBase,
} from 'components/input/select/Simple';
import ComponentInputSimple from 'components/input/Simple';

import colors from 'styles/colors';

import { FormikForm, Grid } from './styles';

const statusOptions: IComponentSelectProps<string>[] = [
  {
    label: 'BASE',
    value: EPriceListType.Base,
  },
  {
    label: 'OFERTA',
    value: EPriceListType.Offer,
  },
  {
    label: 'ESPECIAL',
    value: EPriceListType.SpecialPrice,
  },
];

export interface IPriceListFilterFormData {
  id: string;
  name: string;
  sku: string;
  type: IComponentSelectProps<string> | null;
}

interface IPriceListFilterProps {
  isLoading: boolean;
  onClear: () => void;
  onSubmit: (data: IPriceListFilterFormData) => void;
}

const PriceListFilter: React.ForwardRefRenderFunction<
  FormikProps<IPriceListFilterFormData>,
  IPriceListFilterProps
> = ({ isLoading, onClear, onSubmit }, ref) => {
  const filterFormRef = useRef<FormikProps<IPriceListFilterFormData>>(
    {} as FormikProps<IPriceListFilterFormData>,
  );

  const handleSelectChange = useCallback((option: IOptionTypeBase | null) => {
    filterFormRef.current?.setFieldValue('type', option);
  }, []);

  useImperativeHandle(ref, () => ({
    ...filterFormRef.current,
  }));

  return (
    <Formik
      initialValues={{
        id: '',
        name: '',
        sku: '',
        type: null,
      }}
      innerRef={filterFormRef}
      onSubmit={onSubmit}
    >
      {({ handleChange, values }) => (
        <FormikForm>
          <Grid>
            <ComponentInputNumber
              backgroundColor={colors.white}
              label="ID"
              name="id"
              onChange={handleChange('id')}
              placeholder="Pesquisar por ID"
              value={values.id}
            />
            <ComponentInputSimple
              backgroundColor={colors.white}
              label="SKU"
              name="sku"
              onChange={handleChange('sku')}
              placeholder="Pesquisar por SKU"
              value={values.sku}
            />
            <ComponentInputSimple
              backgroundColor={colors.white}
              label="Nome"
              name="name"
              onChange={handleChange('name')}
              placeholder="Pesquisar por nome"
              value={values.name}
            />
            <ComponentSelectSimple
              backgroundColor={colors.white}
              isClearable
              label="Tipo"
              name="type"
              onChange={handleSelectChange}
              options={statusOptions}
              placeholder="Selecionar o tipo"
              value={values.type}
            />
            <ComponentButtonBase
              backgroundColor={colors.red}
              disabled={isLoading}
              onClick={onClear}
              type="button"
            >
              <FiX size={18} />
            </ComponentButtonBase>

            <ComponentButtonBase disabled={isLoading} type="submit">
              <FiSearch size={18} />
            </ComponentButtonBase>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  );
};

export default forwardRef(PriceListFilter);
