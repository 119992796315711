import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { IPriceListInitialData } from '../../index';

export interface ISetPriceListOptionIdAction {
  data: {
    id: number;
  };
}

function setPriceListOptionId(
  draft: Draft<IPriceListInitialData>,
  action: PayloadAction<ISetPriceListOptionIdAction>,
) {
  if (draft.select.data.length >= 1) {
    const priceListSelected = draft.select.data.find(
      item => item.value === action.payload.data.id,
    );
    draft.select.selected = priceListSelected ?? null;
  }
}

export default setPriceListOptionId;
