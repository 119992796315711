import { Draft, PayloadAction } from '@reduxjs/toolkit';
import EBannersType from 'enums/bannersType';

import { IBannerInitialData } from '../../index';

interface ISetDataAction {
  data: {
    images: {
      id: number | string;
      url: string;
    }[];
    thumbnails: {
      id: number | string;
      url: string;
    }[];
    type: EBannersType;
  };
}

function setData(
  draft: Draft<IBannerInitialData>,
  action: PayloadAction<ISetDataAction>,
) {
  draft.type = action.payload.data.type;
  draft.images = action.payload.data.images;
  draft.thumbnails = action.payload.data.thumbnails;
}

export default setData;
