import styled, { css } from 'styled-components';

import animations from 'styles/animations';
import colors from 'styles/colors';

export const Subtitle = styled.h4`
  color: ${colors.primary};
  font-size: 1.115rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
`;

export const Content = styled.div`
  background-color: ${colors.white};
  border-radius: 0.5rem;
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 5rem 1fr 8rem 8rem 8rem 8rem 8rem;
  padding: 1.5rem;
`;

export const Info = styled.div`
  display: grid;
  row-gap: 0.5rem;
`;

interface ILabelProps {
  center?: boolean;
}

export const Label = styled.label<ILabelProps>`
  font-size: 0.925rem;
  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
`;

interface IValueProps {
  center?: boolean;
  isLoading?: boolean;
}

export const Value = styled.p<IValueProps>`
  color: ${colors.title};
  font-size: 0.925rem;
  font-weight: 600;
  ${props =>
    props.isLoading &&
    css`
      animation: ${animations.shimmer} 2s infinite linear;
      background: linear-gradient(
        to right,
        #f2f3f5 4%,
        #ebeced 25%,
        #f2f3f5 36%
      );
      background-size: 1000px 100%;
      border-radius: 0.375rem;
      display: flex;
      font-size: 0;
      height: 1rem;
      width: 100%;
    `}
  ${props =>
    props.center &&
    css`
      text-align: center;
    `}
`;
