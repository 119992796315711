import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EUploadImageType from 'enums/uploadImageType';
import helpers from 'helpers';
import { call, put } from 'redux-saga/effects';
import { bannerActions } from 'store/slices/banner';

import ToastManager from 'components/utils/Toast/ToastManager';

import httpClient from 'services/httpClient';
import IUploadImageResponse from 'services/httpClient/responses/image/IUploadImageResponse';

import { imageActions } from '..';

import { IUploadRequestAction } from '../reducers/upload/request';

function* upload(action: PayloadAction<IUploadRequestAction>) {
  try {
    const formData = new FormData();

    formData.append('file', action.payload.data.file);
    formData.append('type', action.payload.data.type);

    const response: AxiosResponse<IUploadImageResponse> = yield call(
      httpClient.post,
      'admin/pictures',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    ToastManager.show({
      title: 'Imagem adicionada com sucesso',
      type: 'success',
    });

    yield put(
      imageActions.uploadSuccess({
        url: response.data.url,
        uploadType: action.payload.data.uploadType,
      }),
    );
    if (
      action.payload.data.uploadType === EUploadImageType.banner ||
      action.payload.data.uploadType === EUploadImageType.thumbnail
    ) {
      yield put(
        bannerActions.concat({
          data: {
            uploadType: action.payload.data.uploadType,
            url: response.data.url,
          },
        }),
      );
    }
    action.payload.functions.success();
  } catch (err: any) {
    helpers.errorHandling(err);
    yield put(imageActions.uploadFailure());
  }
}

export default upload;
