import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentInputCheckbox from 'components/input/Checkbox';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import { Card, CardSubtitle, CardTitle, FormikForm, Options } from '../styles';

interface IFormData {
  isMaintenance: boolean;
}

const MaintenanceControl: React.FC = () => {
  const reduxDispatch = useReduxDispatch();

  const formikRef = useRef<FormikProps<IFormData>>(null);

  const isLoading = useReduxSelector(settingSelectors.maintenanceIsLoading);
  const others = useReduxSelector(settingSelectors.others);

  const handleSubmit = useCallback(
    (data: IFormData) => {
      reduxDispatch(
        settingActions.maintenanceRequest({
          data: {
            isMaintenance: data.isMaintenance,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleOnChange = useCallback(() => {
    const newValue = !formikRef.current?.values.isMaintenance;
    formikRef.current?.setFieldValue('isMaintenance', newValue);
    formikRef.current?.submitForm();
  }, []);

  return (
    <Formik
      initialValues={{
        isMaintenance: others.isMaintenance,
      }}
      innerRef={formikRef}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <FormikForm>
          <Card>
            <CardTitle>
              Manutenção
              <ComponentIsVisible when={!isLoading}>
                <FiCheckCircle color={colors.green} size={18} />
              </ComponentIsVisible>
              <ComponentIsVisible when={isLoading}>
                <ClipLoader size={18} />
              </ComponentIsVisible>
            </CardTitle>

            <CardSubtitle>Define se a página está em manutenção</CardSubtitle>

            <Options gridTemplateColumns="1fr">
              <ComponentInputCheckbox
                disabled={isLoading}
                isChecked={values.isMaintenance}
                label="Ativar"
                name="isMaintenance"
                onChange={handleOnChange}
              />
            </Options>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default MaintenanceControl;
