import styled from 'styled-components';

interface IFieldsProps {
  gridTemplateColumns?: string;
  gridTemplateColumnsSmall?: string;
}

export const Fields = styled.div<IFieldsProps>`
  align-items: flex-end;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: ${props =>
    props.gridTemplateColumnsSmall
      ? props.gridTemplateColumnsSmall
      : '6rem 1fr 4rem 7rem'};
  justify-content: flex-end;
  row-gap: 0.5rem;

  @media screen and (min-width: 1600px) {
    grid-template-columns: ${props =>
      props.gridTemplateColumns
        ? props.gridTemplateColumns
        : '8rem 1fr 4.5rem 9rem'};
  }
`;

export const ContainerButton = styled.div`
  align-self: flex-end;
  margin-top: 0.8rem;
`;
