import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICustomerInitialData } from '../../index';

export interface IOrdersRequestAction {
  data: {
    customerId: number;
  };
}

function ordersRequest(
  draft: Draft<ICustomerInitialData>,
  _: PayloadAction<IOrdersRequestAction>,
) {
  draft.orders.config.isLoading = true;
  draft.orders.config.isError = false;
}

export default ordersRequest;
