import IOrder from 'models/IOrder';
import settingSelectors from 'store/slices/setting/selectors';

import { useReduxSelector } from 'hooks/useReduxSelector';

import Tables from 'styles/tables';

import { OrdersItemsBody } from '../styles';

interface IItemBodyProps {
  order: IOrder;
  position: number;
}

const ItemBody = ({ order, position }: IItemBodyProps): JSX.Element => {
  const internalControl = useReduxSelector(settingSelectors.internalControl);
  return (
    <OrdersItemsBody addColorRow={position % 2 === 0} key={order.id}>
      <Tables.ItemBody center>{order.id}</Tables.ItemBody>
      <Tables.ItemBody>
        {internalControl.hasPreferenceCompanyName
          ? order.companyName
          : order.customer}
      </Tables.ItemBody>
      <Tables.ItemBody right>{order.amount.final}</Tables.ItemBody>
      <Tables.ItemBody right>{order.amount.billing}</Tables.ItemBody>
      <Tables.ItemBody center>{order.date}</Tables.ItemBody>
      <Tables.ItemBody center>{order.originEnd ?? '-'}</Tables.ItemBody>
      <Tables.ItemBody>{order.status}</Tables.ItemBody>
    </OrdersItemsBody>
  );
};

export default ItemBody;
