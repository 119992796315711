import { useCallback, useRef } from 'react';

import { FiCheckCircle } from 'react-icons/fi';
import { ClipLoader } from 'react-spinners';

import formatters from 'formatters';
import { Formik, FormikProps } from 'formik';
import { settingActions } from 'store/slices/setting';
import settingSelectors from 'store/slices/setting/selectors';
import * as Yup from 'yup';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';

import ComponentButtonBase from 'components/button/Base';
import ComponentInputPhone from 'components/input/Phone';
import ComponentInputSimple from 'components/input/Simple';
import ComponentIsVisible from 'components/utils/IsVisible';

import colors from 'styles/colors';

import {
  Card,
  CardContent,
  CardSubtitle,
  CardTitle,
  FormikForm,
} from '../styles';
import { Fields } from './styles';

interface IContactFormData {
  email: string;
  phone: string;
}

const contactSchema = Yup.object().shape({
  email: Yup.string().email('Informe um e-mail válido'),
  phone: Yup.string()
    .transform((value, originalValue) => (value ? originalValue : null))
    .min(10, "Tamanho mínimo do campo 'Telefone' deve ser 10 dígitos.")
    .max(15, "Tamanho máximo do campo 'Telefone' deve ser 15 dígitos.")
    .nullable(true),
});

const Contact = (): JSX.Element => {
  const reduxDispatch = useReduxDispatch();

  const isLoading = useReduxSelector(settingSelectors.contactIsLoading);
  const company = useReduxSelector(settingSelectors.company);

  const contactFormRef = useRef<FormikProps<IContactFormData>>(null);

  const handleContactData = useCallback(
    (data: IContactFormData) => {
      reduxDispatch(
        settingActions.contactsRequest({
          data: {
            email: data.email,
            phone: data.phone,
            corporateName: company.corporateName,
          },
        }),
      );
    },
    [company.corporateName, reduxDispatch],
  );

  return (
    <Formik
      initialValues={{
        email: company.email,
        phone: company.phone ? formatters.phone(company.phone) : '',
      }}
      innerRef={contactFormRef}
      onSubmit={handleContactData}
      validationSchema={contactSchema}
    >
      {({ errors, handleChange, values }) => (
        <FormikForm>
          <Card>
            <CardContent>
              <CardTitle>
                Dados para contato
                <ComponentIsVisible when={!isLoading}>
                  <FiCheckCircle color={colors.green} size={18} />
                </ComponentIsVisible>
                <ComponentIsVisible when={isLoading}>
                  <ClipLoader size={18} />
                </ComponentIsVisible>
              </CardTitle>
              <CardSubtitle>
                Preencha os campos abaixo com os dados para contato
              </CardSubtitle>

              <Fields>
                <ComponentInputSimple
                  errorMessage={errors.email}
                  hasError={!!errors.email}
                  label="E-mail"
                  name="email"
                  onChange={handleChange('email')}
                  value={values.email}
                />
                <ComponentInputPhone
                  errorMessage={errors.phone}
                  hasError={!!errors.phone}
                  label="Telefone"
                  name="phone"
                  onChange={handleChange('phone')}
                  value={values.phone}
                />
                <ComponentButtonBase
                  disabled={isLoading}
                  isLoading={isLoading}
                  type="submit"
                >
                  Salvar
                </ComponentButtonBase>
              </Fields>
            </CardContent>
          </Card>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Contact;
